import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";

/**
 * MainGuard component to manage access to routes based on user authentication.
 * @component MainGuard
 * @author Priyanshu
 *
 */
const MainGuard = ({ children }: any) => {
  const { user } = useSelector((state: any) => state.user);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    // If user is not authenticated, redirect to "/login"
    if (!user) {
      return navigate("/login");
    }

    if (user && location.pathname === "/") {
      return navigate("/cases");
    }
  }, [user, navigate, location]);

  return children;
};

export default MainGuard;
