import AutoStoriesTwoToneIcon from "@mui/icons-material/AutoStoriesTwoTone";
import CurrencyExchangeTwoToneIcon from "@mui/icons-material/CurrencyExchangeTwoTone";
import FactCheckTwoToneIcon from "@mui/icons-material/FactCheckTwoTone";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import LinkTwoToneIcon from "@mui/icons-material/LinkTwoTone";
import PersonOutlineTwoToneIcon from "@mui/icons-material/PersonOutlineTwoTone";
import VpnKeyTwoToneIcon from "@mui/icons-material/VpnKeyTwoTone";
import {
  Avatar,
  Box,
  Button,
  CardContent,
  Grid,
  Icon,
  IconButton,
  Stack,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from "@mui/material";
import { IconCamera, IconEdit } from "@tabler/icons-react";
import { useModal } from "mui-modal-provider";
import { useCallback, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import useAuth from "../../../hooks/useAuth";
import useSite from "../../../hooks/useSite";
import useUser from "../../../hooks/useUser";
import { capitalizeFirstLetter } from "../../../utils/capitalizeFirstLetter";
import { useSnackbarHelper } from "../../components/Snackbar";
import MainCard from "../../components/cards/MainCard";
import SubCard from "../../components/cards/SubCard";
import { uploadImageToS3 } from "../../components/common/uploadImages";
import TableSkeleton from "../../components/skeleton/TableSkeleton";
import CropImageModal from "./CropImageModal";
import RepTypes from "./RepTypes";
import SignatureModal from "./SignatureModal";
import Workflows from "./Workflows";
import ChangePassword from "./changePassword";
import SystemSetting from "./systemSetting";

function TabPanels({ children, value, index, ...other }: any) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </div>
  );
}

function a11yProps(index?: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const tabOption = [
  {
    key: 0,
    value: "user",
    path: "&setting=user",
    label: "User Profile",
    icon: <PersonOutlineTwoToneIcon />,
    caption: "Profile Settings",
  },
  {
    key: 1,
    value: "password",
    path: "&setting=password",
    label: "Change Password",
    icon: <VpnKeyTwoToneIcon />,
    caption: "Update Profile Security",
  },
  {
    key: 2,
    value: "repTypes",
    path: "&setting=rep-type",
    label: "Rep Types",
    icon: <CurrencyExchangeTwoToneIcon />,
    caption: "Rep Types/Values",
  },
  {
    key: 3,
    value: "workflows",
    path: "&setting=workflows",
    label: "Workflows",
    icon: <FactCheckTwoToneIcon />,
    caption: "Workflows",
  },
  // {
  //   key: 4,
  //   value: "quickbookIntegration",
  //   path: "&setting=quick-book-integration",
  //   label: "Quickbook",
  //   icon: <AutoStoriesTwoToneIcon />,
  //   caption: "Quickbook Integration",
  // },
  {
    key: 4,
    value: "zohoIntegration",
    path: "&setting=zoho-integration",
    label: "Zoho",
    icon: <LinkTwoToneIcon />,
    caption: "Zoho Integration",
  },
  {
    key: 5,
    value: "docuSignIntegration",
    path: "&setting=docuSign-integration",
    label: "DocuSign",
    icon: <LinkTwoToneIcon />,
    caption: "DocuSign Integration",
  },
];

const AccountSetting = () => {
  const fileInputRef = useRef<any>(null);
  const { site, isSiteLoading } = useSite();
  const navigate = useNavigate();
  const location = useLocation();
  const [tabsOption, setTabsOption] = useState(tabOption || []);

  // Parse query parameters
  const queryParams = new URLSearchParams(location.search);
  const currentTab = queryParams.get("tab") || "user";

  const showSnackbar = useSnackbarHelper();
  const [tabValue, setTabValue] = useState(
    () => tabOption?.find((tab: any) => tab?.value === currentTab)?.key || 0
  );

  const { showModal } = useModal();
  const { userId } = useAuth();

  const {
    user,
    updateUser,
    isUpdatingUser,
    isLoadingUser,
    // refetchUserData,
    changeRepTypes,
    isChangingRepTypes,
    refetchUserDataId,
    updateProfile,
    // isUpdatingProfile,
  } = useUser(userId);

  useEffect(() => {
    if (!user?.isSuperAdmin) {
      const tabs = tabsOption?.filter(
        (tab: any) =>
          tab?.value !== "repTypes" &&
          tab?.value !== "workflows" &&
          tab?.value !== "quickbookIntegration" &&
          tab?.value !== "zohoIntegration"
      );

      setTabsOption(tabs);
    } else {
      setTabsOption(tabOption);
    }

    //eslint-disable-next-line
  }, [user]);

  const handleTabChange = (event: any, newValue: number) => {
    const tabvalue = tabsOption.find((option) => option.key === newValue);

    setTabValue(newValue);
    navigate(`/settings?tab=${tabvalue?.value}`);
  };

  const [image, setImage] = useState<any>("");

  useEffect(() => {
    if (user?.profileImg) {
      setImage(user.profileImg);
    } else {
      setImage(require("../../../../src/assets/images/profilepic.avif"));
    }
  }, [user]);

  const handleAvatarClick = () => {
    fileInputRef.current.click();
  };

  const handleCrop = useCallback(
    (preview: any, handleCroppedFileChange: any) => {
      const modal: any = showModal(CropImageModal, {
        onClose: () => modal.hide(),
        preview,
        handleCroppedFileChange,
      });
    },
    [showModal]
  );
  const handleCroppedFileChange = async (croppedFile: any) => {
    const folderName = "profileImages";
    const data = {
      file: croppedFile,
      userId: user._id,
      folderName: folderName,
    };
    const res = await uploadImageToS3(data);
    if (!res?.Location) {
      return showSnackbar("Error while uploading profile image", "error");
    }
    try {
      const payload = {
        profileImg: res?.Location,
        signature: user?.signature || "",
      };
      const updateUsr = await updateProfile(payload);
      setImage(updateUsr?.user?.profileImg);
      const message = updateUsr?.message || "Success";
      showSnackbar(message, "success");
    } catch (error: any) {
      const message = error?.response?.data?.message || "An error occurred";
      showSnackbar(message, "error");
    }
  };
  const handleFileChange = async (event: any) => {
    const file = event?.target?.files[0];
    if (file?.size > 5 * 1024 * 1024) {
      return showSnackbar("File size should be less than 5MB", "error");
    }
    if (file) {
      const preview = URL.createObjectURL(file);
      handleCrop(preview, handleCroppedFileChange);
    }
    event.target.value = "";
  };
  const handleSignature = useCallback(() => {
    const modal: any = showModal(SignatureModal, {
      onClose: () => {
        modal.hide();
        refetchUserDataId();
      },
      userId,
    });
  }, [showModal, userId, refetchUserDataId]);

  const handleIntegration = () => {
    window.location.href = `${process.env.REACT_APP_API_URL}/quick-books/auth`;
  };

  const handleZohoIntegration = () => {
    window.location.href = `${process.env.REACT_APP_API_URL}/zoho/auth`;
  };

  const handleDocuSignIntegration = () => {
    window.location.href = `${process.env.REACT_APP_API_URL}/docuSign/auth`;
  };

  const checkQuickBooksIntegrated = useCallback(() => {
    const accessToken = site?.quickBooks?.accessToken;
    return !!accessToken;
  }, [site]);

  const checkZohoIntegrated = useCallback(() => {
    const accessToken = site?.zoho?.accessToken;
    return !!accessToken;
  }, [site]);

  const checkDocusignIntegrated = useCallback(() => {
    const accessToken = site?.docuSign?.accessToken;
    return !!accessToken;
  }, [site]);

  return (
    <Box>
      <MainCard
        title={
          <Typography variant="h1" sx={{ mx: 2 }}>
            Account Setting
          </Typography>
        }
        actions={false}
        content={
          <Grid container spacing={2}>
            <Grid item xs={12} md={3}>
              <CardContent>
                <Tabs
                  value={tabValue}
                  onChange={handleTabChange}
                  orientation="vertical"
                  variant="scrollable"
                  sx={{
                    "& .MuiTabs-flexContainer": {
                      borderBottom: "none",
                      flexBasis: "20% !important",
                    },
                    "& button": {
                      color: "grey",
                      minHeight: "auto",
                      minWidth: "100%",
                      py: 1.5,
                      px: 2,
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "flex-start",
                      textAlign: "left",
                      justifyContent: "flex-start",
                      borderRadius: 5,
                    },
                    "& button.Mui-selected": {
                      color: "#008EBB",
                      background: "#f9f8f8",
                    },
                    "& button > svg": {
                      marginBottom: "0px !important",
                      marginRight: 1.25,
                      marginTop: 1.25,
                      height: 20,
                      width: 20,
                    },
                    "& button > div > span": {
                      display: "block",
                    },
                    "& > div > span": {
                      display: "none",
                    },
                  }}
                >
                  {tabsOption?.map((tab, index) => (
                    <Tab
                      key={index}
                      icon={tab?.icon}
                      label={
                        <Grid container direction="column">
                          <Typography
                            variant="subtitle1"
                            color="inherit"
                            sx={{ textTransform: "capitalize" }}
                          >
                            {tab?.label}
                          </Typography>
                          <Typography
                            component="div"
                            variant="caption"
                            sx={{ textTransform: "capitalize" }}
                          >
                            {tab?.caption}
                          </Typography>
                        </Grid>
                      }
                      {...a11yProps(index)}
                    />
                  ))}
                </Tabs>
              </CardContent>
            </Grid>

            <Grid item xs={12} md={9}>
              <CardContent
                sx={{
                  borderLeft: "1px solid",
                  borderColor: "#ebe8e8",
                  height: "100%",
                }}
              >
                {/* User Profile */}
                <TabPanels value={tabValue} index={0}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} lg={3.5}>
                      {isLoadingUser ? (
                        <TableSkeleton />
                      ) : (
                        <SubCard
                          title={"Profile Picture"}
                          sx={{
                            // border: "1px solid #FFCDD2",
                            borderRadius: "20px",
                            boxShadow: "0 2px 14px 0 #F3F6FE",
                          }}
                        >
                          <Grid container spacing={2} justifyContent="center">
                            <Grid item xs={12}>
                              <Stack
                                direction="column"
                                spacing={2}
                                alignItems="center"
                                justifyContent="center"
                              >
                                <Box
                                  sx={{
                                    position: "relative",
                                    display: "inline-block",
                                  }}
                                >
                                  <Avatar
                                    alt="Remy Sharp"
                                    sx={{
                                      width: 120,
                                      height: 120,
                                      border: "2px solid  #008EBB",
                                      cursor: "pointer",
                                    }}
                                    src={image}
                                    onClick={handleAvatarClick}
                                  />
                                  <IconButton
                                    color="primary"
                                    onClick={handleAvatarClick}
                                    disableRipple
                                    sx={{
                                      position: "absolute",
                                      bottom: 0,
                                      right: 10,
                                      backgroundColor: "#008EBB",
                                      border: "1px solid #008EBB",
                                      color: "#fff",
                                      padding: "2px",
                                    }}
                                  >
                                    <IconCamera stroke={2} size={18} />
                                  </IconButton>
                                </Box>
                                <input
                                  type="file"
                                  accept="image/*"
                                  style={{ display: "none" }}
                                  ref={fileInputRef}
                                  onChange={(event) => handleFileChange(event)}
                                />
                                <Typography
                                  variant="subtitle1"
                                  color="inherit"
                                  sx={{
                                    textTransform: "capitalize",
                                    color: "#808080",
                                    fontWeight: 400,
                                  }}
                                >
                                  {user?.firstName}
                                </Typography>
                              </Stack>
                            </Grid>
                            <Grid item xs={12}>
                              <Stack
                                direction="column"
                                spacing={2}
                                alignItems="center"
                                justifyContent="center"
                              >
                                <Box
                                  sx={{
                                    position: "relative",
                                    display: "inline-block",
                                  }}
                                >
                                  <Box
                                    p={1}
                                    // component="img"
                                    sx={{
                                      width: user?.signature ? "90%" : 220,
                                      height: "90px",
                                      border: "1px solid  #008EBB",
                                      borderRadius: 2,
                                    }}
                                    // src={user?.signature}
                                  >
                                    {user?.signature ? (
                                      <img
                                        src={user?.signature}
                                        style={{ width: 220, height: "100%" }}
                                        alt=""
                                      />
                                    ) : (
                                      <Typography
                                        variant="caption"
                                        textAlign="left"
                                        sx={{
                                          textTransform: "capitalize",
                                          color: "#808080",
                                        }}
                                      >
                                        Click on Icon to upload or Draw
                                        signature
                                      </Typography>
                                    )}

                                    {/* <SignaturePad
                                  // ref={signaturssePadRef}
                                  options={{
                                    penColor: "black", // Change stroke color here
                                    minWidth: 2, // Minimum thickness of the stroke
                                    maxWidth: 4, // Maximum thickness of the stroke
                                    throttle: 16, // Draws at most 60 points per second
                                  }}
                                /> */}
                                  </Box>
                                  <IconButton
                                    color="primary"
                                    onClick={handleSignature}
                                    disableRipple
                                    sx={{
                                      position: "absolute",
                                      bottom: 90,
                                      right: 0,
                                      backgroundColor: "#008EBB",
                                      border: "1px solid #008EBB",
                                      color: "#fff",
                                      padding: "2px",
                                    }}
                                  >
                                    <IconEdit stroke={2} size={18} />
                                  </IconButton>
                                </Box>
                                {/* <Button onClick={clearSignature}>
                                  Clear Signature
                                </Button> */}

                                <Stack direction="row" spacing={2} m={1}>
                                  <Typography
                                    variant="subtitle1"
                                    textAlign="left"
                                    sx={{
                                      textTransform: "capitalize",
                                      color: "#808080",
                                      fontWeight: 400,
                                    }}
                                  >
                                    {`${capitalizeFirstLetter(user?.role)}'s signature`}
                                  </Typography>
                                  <Tooltip
                                    title={`${capitalizeFirstLetter(user?.role)}'s signature information`}
                                  >
                                    <Icon>
                                      <InfoOutlinedIcon
                                        sx={{ fontSize: 20, color: "#008EBB" }}
                                      />
                                    </Icon>
                                  </Tooltip>
                                </Stack>
                              </Stack>
                            </Grid>
                          </Grid>
                        </SubCard>
                      )}
                    </Grid>

                    <Grid item xs={12} lg={8.5}>
                      {isLoadingUser ? (
                        <TableSkeleton />
                      ) : (
                        <SystemSetting
                          user={user}
                          updateUser={updateUser}
                          isUpdatingUser={isUpdatingUser}
                        />
                      )}
                    </Grid>
                  </Grid>
                </TabPanels>
                {/* Change Password */}
                <TabPanels value={tabValue} index={1}>
                  <Grid>
                    <Grid
                      container
                      spacing={2}
                      sx={{ p: 2 }}
                      justifyContent="center"
                    >
                      <Grid item xs={12} sm={12} lg={8}>
                        <ChangePassword userId={userId} />
                      </Grid>
                    </Grid>
                  </Grid>
                </TabPanels>
                <TabPanels value={tabValue} index={2}>
                  <RepTypes
                    user={user}
                    changingRepTypes={changeRepTypes}
                    isChangingRepTypes={isChangingRepTypes}
                    userId={userId}
                  />
                </TabPanels>
                <TabPanels value={tabValue} index={3}>
                  <Workflows />
                </TabPanels>
                {/* <TabPanels value={tabValue} index={4}>
                  <Grid
                    container
                    spacing={2}
                    sx={{ p: 2 }}
                    justifyContent="center"
                  >
                    {isSiteLoading ? (
                      <TableSkeleton />
                    ) : (
                      <Grid item xs={12} sm={12} lg={12}>
                        <Typography
                          sx={{
                            fontSize: "15px",
                            lineHeight: "21px",
                            fontFamily: "'Poppins', sans-serif",
                            fontWeight: checkQuickBooksIntegrated()
                              ? "bold"
                              : 400,

                            color: checkQuickBooksIntegrated()
                              ? "green"
                              : "#808080",
                          }}
                        >
                          {checkQuickBooksIntegrated()
                            ? "Quickbook is integrated!"
                            : "Integrate Quickbook with your Coxlaw Application"}
                        </Typography>
                        <Button
                          variant="contained"
                          disabled={checkQuickBooksIntegrated()}
                          sx={{ mt: 2 }}
                          onClick={handleIntegration}
                        >
                          Quickbook Integration
                        </Button>
                      </Grid>
                    )}
                  </Grid>
                </TabPanels> */}
                <TabPanels value={tabValue} index={4}>
                  {isSiteLoading ? (
                    <TableSkeleton />
                  ) : (
                    <Grid
                      container
                      spacing={2}
                      sx={{ p: 2 }}
                      justifyContent="center"
                    >
                      <Grid item xs={12} sm={12} lg={12}>
                        <Typography
                          sx={{
                            fontSize: "15px",
                            lineHeight: "21px",
                            fontFamily: "'Poppins', sans-serif",
                            fontWeight: checkZohoIntegrated() ? "bold" : 400,

                            color: checkZohoIntegrated() ? "green" : "#808080",
                          }}
                        >
                          {checkZohoIntegrated()
                            ? "Zoho is integrated!"
                            : "Integrate Zoho with your Coxlaw Application"}
                        </Typography>
                        <Button
                          variant="contained"
                          disabled={checkZohoIntegrated()}
                          sx={{ mt: 2 }}
                          onClick={handleZohoIntegration}
                        >
                          Zoho Integration
                        </Button>
                      </Grid>
                    </Grid>
                  )}
                </TabPanels>
                <TabPanels value={tabValue} index={5}>
                  <Grid
                    container
                    spacing={2}
                    sx={{ p: 2 }}
                    justifyContent="center"
                  >
                    {isSiteLoading ? (
                      <TableSkeleton />
                    ) : (
                      <Grid item xs={12} sm={12} lg={12}>
                        <Typography
                          sx={{
                            fontSize: "15px",
                            lineHeight: "21px",
                            fontFamily: "'Poppins', sans-serif",
                            fontWeight: checkDocusignIntegrated()
                              ? "bold"
                              : 400,

                            color: checkDocusignIntegrated()
                              ? "green"
                              : "#808080",
                          }}
                        >
                          {checkDocusignIntegrated()
                            ? "DocuSign is integrated!"
                            : "Integrate DocuSign with your Coxlaw Application"}
                        </Typography>
                        <Button
                          variant="contained"
                          disabled={checkDocusignIntegrated()}
                          sx={{ mt: 2 }}
                          onClick={handleDocuSignIntegration}
                        >
                          DocuSign Integration
                        </Button>
                      </Grid>
                    )}
                  </Grid>
                </TabPanels>
                {/* Address */}
              </CardContent>
            </Grid>
          </Grid>
        }
      />
    </Box>
  );
};

export default AccountSetting;
