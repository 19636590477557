import { useMutation, useQuery } from "react-query";
import useAuth from "./useAuth";

/**
 * Custom hook to manage fetching, adding, and deleting PDF documents
 * related to the criminal history of a case.
 * @hook
 * @author Sanjay
 */

export default function usePdf({ caseId }: any) {
  const { request } = useAuth();

  const {
    data: criminalHistoryUrl,
    refetch: refetchCasePdf,
    isLoading,
  } = useQuery(
    "/criminalHistory/case",
    () =>
      request.get(`/criminalHistory/case/${caseId}`).then(({ data }) => {
        return data?.fileUrl as any;
      }),
    { enabled: !!caseId }
  );

  const { mutateAsync: addPdf, isLoading: isPdfLoading } = useMutation(
    "/criminalHistory",
    (data: any) =>
      request.put("/criminalHistory", data).then((response) => response as any),
    {
      onSuccess: () => {
        refetchCasePdf();
      },
    }
  );

  const { mutateAsync: deletePdf, isLoading: isDeleteLoading } = useMutation(
    "/criminalHistory/id",
    (id: any) =>
      request
        .delete(`/criminalHistory/case/${caseId}`)
        .then((response) => response as any),
    {
      onSuccess: () => {
        refetchCasePdf();
      },
    }
  );

  return {
    casePdf: criminalHistoryUrl,
    deletePdf,
    refetchCasePdf,
    addPdf,
    isLoading: isLoading || isPdfLoading || isDeleteLoading,
  };
}
