export function searchLeads(records: any[], query: string): any[] {
  if (!query) return records;

  const lowerCaseQuery = query.toLowerCase();

  return records.filter((record) => {
    const fullName = `${record?.firstName || ""} ${record?.lastName || ""}`
      .toLowerCase()
      .trim();

    return (
      (fullName && fullName?.toLowerCase().includes(lowerCaseQuery)) ||
      (record?.firstName &&
        record?.firstName.toLowerCase().includes(lowerCaseQuery)) ||
      (record?.lastName &&
        record?.lastName.toLowerCase().includes(lowerCaseQuery)) ||
      (record?.email && record?.email.toLowerCase().includes(lowerCaseQuery)) ||
      (record?.mobile &&
        record?.mobile.toLowerCase().includes(lowerCaseQuery)) ||
      (record?.phoneNumber &&
        record?.phoneNumber.toLowerCase().includes(lowerCaseQuery)) ||
      (record?.leadSource &&
        record?.leadSource.toLowerCase().includes(lowerCaseQuery)) ||
      (record?.owner && record?.owner.toLowerCase().includes(lowerCaseQuery))
    );
  });
}