import React, { useCallback, useEffect, useMemo, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button, DialogProps, Stack } from "@mui/material";
import { useForm } from "react-hook-form";
import * as Yup from "yup";

//components
import useCases from "../../../hooks/useCases";
// import useRiskItemForm from "../../../hooks/useRiskItemForm";
import BasicModal from "../../components/modal/BasicModal";
import RiskItemForm from "./RiskItemForm";
import { useSnackbarHelper } from "../../components/Snackbar";
import OffenseList from "../../../constants/NcicCode.json";
interface Props extends DialogProps {
  onClose: () => void;
  tdcjId?: string;
  inmateName?: string;
  data?: any;
  caseId?: string;
  refetchCase: () => void;
  addRiskItemForm: any;
}
const RiskItemFormModal = ({
  onClose,
  data,
  tdcjId,
  inmateName,
  caseId,
  refetchCase,
  addRiskItemForm,
  ...props
}: Props) => {
  const { caseData } = useCases(caseId);
  const showSnackbar = useSnackbarHelper();
  // const { addRiskItemForm,isRiskItemFormLoading } = useRiskItemForm(caseId);
  const [riskItemForm] = useState<any>(
    caseData?.result?.caseDetails?.riskItemForm || null
  );
  const [selectedParole, setSelectedParole] = React.useState(
    riskItemForm?.parole || ""
  );
  const [selectedDenyParole, setSelectedDenyParole] = React.useState(
    riskItemForm?.denyParole || ""
  );
  const [selectedDiscretionaryMS, setSelectedDiscretionaryMS] = React.useState(
    riskItemForm?.discretionaryMS || ""
  );
  const [selectedDenyDiscretionaryMS, setSelectedDenyDiscretionaryMS] =
    React.useState(riskItemForm?.denyDiscretionaryMS || "");
  const handleParoleChange = (event: any) => {
    const { value } = event.target;
    setSelectedParole(value === selectedParole ? "" : value);
  };
  const handleDenyParoleChange = (event: any) => {
    const { value } = event.target;
    setSelectedDenyParole(value === selectedDenyParole ? "" : value);
  };
  const handleDiscretionaryMSChange = (event: any) => {
    const { value } = event.target;
    setSelectedDiscretionaryMS(value === selectedDiscretionaryMS ? "" : value);
  };
  const handleDenyDiscretionaryMSChange = (event: any) => {
    const { value } = event.target;
    setSelectedDenyDiscretionaryMS(
      value === selectedDenyDiscretionaryMS ? "" : value
    );
  };

  const [scores, setScores] = useState<any>({
    ageAtFirstCommitment: {
      value: riskItemForm?.ageAtFirstCommitment?.value || "",
      point: riskItemForm?.ageAtFirstCommitment?.point ?? null,
    },
    historyOfRevocations: {
      value: riskItemForm?.historyOfRevocations?.value || "",
      point: riskItemForm?.historyOfRevocations?.point ?? null,
    },
    otherIncarcerations: {
      value: riskItemForm?.otherIncarcerations?.value || "",
      point: riskItemForm?.otherIncarcerations?.point ?? null,
    },
    employmentHistory: {
      value: riskItemForm?.employmentHistory?.value || "",
      point: riskItemForm?.employmentHistory?.point ?? null,
    },
    commitmentOffense: {
      value: riskItemForm?.commitmentOffense?.value || "",
      point: riskItemForm?.commitmentOffense?.point ?? null,
    },
    currentAge: {
      value: riskItemForm?.currentAge?.value || "",
      point: riskItemForm?.currentAge?.point ?? null,
    },
    securityThreatGroupMembership: {
      value: riskItemForm?.securityThreatGroupMembership?.value || "",
      point: riskItemForm?.securityThreatGroupMembership?.point ?? null,
    },
    completedEducation: {
      value: riskItemForm?.completedEducation?.value || "",
      point: riskItemForm?.completedEducation?.point ?? null,
    },
    disciplinaryConduct: {
      value: riskItemForm?.disciplinaryConduct?.value || "",
      point: riskItemForm?.disciplinaryConduct?.point ?? null,
    },
    currentCustodyLevel: {
      value: riskItemForm?.currentCustodyLevel?.value || "",
      point: riskItemForm?.currentCustodyLevel?.point ?? null,
    },
  });

  const validationSchema = Yup.object().shape({
    primaryOffense: Yup.string().required("Required"),
    ncicCode: Yup.number()
      .transform((value, originalValue) =>
        originalValue === "" ? null : value
      )
      .nullable()
      .typeError("Only numbers are allowed"),
    // .required("Required"),
    penalCode: Yup.number()
      .required("Required")
      .typeError("penal code code must be a number"),
    degreeLevel: Yup.string(),
    // .required("Required"),
    aggravatedOffense: Yup.string().required("Required"),
    lifeSentence: Yup.string().required("Required"),
    capitalOffense: Yup.string().required("Required"),
    offenseSeverity: Yup.string().required("Required"),
    riskLevel: Yup.string().required("Required"),
    term: Yup.number()
      .transform((value, originalValue) =>
        originalValue === "" ? null : value
      )
      .nullable()
      .typeError("Only numbers are allowed")
      .required("Required"),

    setOffDate: Yup.date().required("Required").typeError("Required "),
    firDate: Yup.date().required("Required").typeError("Required"),
    reasonForDecision: Yup.string().required("Required"),
    boardOfMembers: Yup.string().required("Required"),
    date: Yup.date().required("required"),
  });

  const initialValues = useMemo(
    () => ({
      primaryOffense: riskItemForm?.primaryOffense || null,
      ncicCode: riskItemForm?.ncicCode || null,
      penalCode: riskItemForm?.penalCode || null,
      degreeLevel: riskItemForm?.degreeLevel || "",
      aggravatedOffense: riskItemForm?.aggravatedOffense || "",
      lifeSentence: riskItemForm?.lifeSentence || "",
      capitalOffense: riskItemForm?.capitalOffense || "",
      offenseSeverity: riskItemForm?.offenseSeverity || "",
      riskLevel: riskItemForm?.overAllRiskLevel || "",
      term: riskItemForm?.term || null,
      setOffDate: riskItemForm?.setOffDate || "",
      firDate: riskItemForm?.firDate || "",
      reasonForDecision: riskItemForm?.reasonForDecision || "",
      boardOfMembers: riskItemForm?.boardOfMembers || "",
      date: riskItemForm?.date || new Date(),
    }),
    [riskItemForm]
  );

  const formContext = useForm({
    defaultValues: initialValues,
    resolver: yupResolver<any>(validationSchema),
  });
  const { watch, setValue } = formContext;
  const primaryOffense = watch("primaryOffense");

  useEffect(() => {
    const ncicCode = OffenseList?.find(
      (item) => item?.["OFFENSE DESCRIPTION"] === primaryOffense
    );
    setValue("ncicCode", ncicCode?.["NCIC CODE"]);
    setValue("degreeLevel", ncicCode?.DEG);
  }, [primaryOffense, setValue]);

  // calculate the total risk score
  const computeRiskLevel = useCallback(() => {
    const totalStaticRiskScore =
      scores?.ageAtFirstCommitment?.point +
      scores?.historyOfRevocations?.point +
      scores?.otherIncarcerations?.point +
      scores?.employmentHistory?.point +
      scores?.commitmentOffense?.point;
    const totalDynamicRiskScore =
      +scores?.currentAge?.point +
      +scores?.securityThreatGroupMembership?.point +
      +scores?.completedEducation?.point +
      +scores?.disciplinaryConduct?.point +
      +scores?.currentCustodyLevel?.point;
    const totalRiskScore = +totalStaticRiskScore + +totalDynamicRiskScore;
    let riskLevel = "Low Risk";
    if (totalRiskScore >= 12) {
      riskLevel = "Highest Risk";
    } else if (totalRiskScore >= 9) {
      riskLevel = "High Risk";
    } else if (totalRiskScore >= 6) {
      riskLevel = "Moderate Risk";
    }
    return {
      totalStaticRiskScore,
      totalDynamicRiskScore,
      totalRiskScore,
      riskLevel,
    };
  }, [scores]);

  const validateScores = (): string | null => {
    for (const key in scores) {
      if (scores.hasOwnProperty(key)) {
        const item = scores[key];
        if (!item.value) {
          return `${key}  is required`;
        }
        if (item.point === null) {
          return `${key} point is required`;
        }
      }
    }
    return null;
  };

  const handleSubmit = async (values: any) => {
    try {
      const message = validateScores();
      if (message) {
        showSnackbar(message, "error");
      } else {
        const {
          totalStaticRiskScore,
          totalDynamicRiskScore,
          totalRiskScore,
          riskLevel,
        } = computeRiskLevel();
        const payload = {
          ...values,
          ...scores,
          totalStaticRiskScore,
          totalDynamicRiskScore,
          totalRiskScore,
          overAllRiskLevel: riskLevel,
          parole: selectedParole,
          denyParole: selectedDenyParole,
          discretionaryMS: selectedDiscretionaryMS,
          denyDiscretionaryMS: selectedDenyDiscretionaryMS,
        };
        const response = await addRiskItemForm(payload);
        showSnackbar(response?.data?.message, "success");
        refetchCase();
        onClose();
      }
    } catch (error: any) {
      showSnackbar(error?.response?.data?.message, "error");
    }
  };
  // change risk level according to score
  useEffect(() => {
    const { riskLevel } = computeRiskLevel();
    setValue("riskLevel", riskLevel);
  }, [computeRiskLevel, setValue]);

  return (
    <BasicModal
      formContext={formContext}
      onClose={onClose}
      onSubmit={handleSubmit}
      title={"Risk Item Form"}
      size={"lg"}
      height={{ xl: 700, lg: 550, sm: 500 }}
      content={
        <Stack>
          <RiskItemForm
            scores={scores}
            setScores={setScores}
            computeRiskLevel={computeRiskLevel}
            tdcjId={tdcjId}
            inmateName={inmateName}
            handleParoleChange={handleParoleChange}
            handleDenyParoleChange={handleDenyParoleChange}
            handleDiscretionaryMSChange={handleDiscretionaryMSChange}
            handleDenyDiscretionaryMSChange={handleDenyDiscretionaryMSChange}
            selectedParole={selectedParole}
            selectedDenyParole={selectedDenyParole}
            selectedDiscretionaryMS={selectedDiscretionaryMS}
            selectedDenyDiscretionaryMS={selectedDenyDiscretionaryMS}
            primaryOffense={primaryOffense}
          />
        </Stack>
      }
      actions={
        <>
          <Button color="error" variant="outlined" onClick={onClose}>
            Cancel
          </Button>
          <Button
            variant="contained"
            color="success"
            sx={{ textTransform: "capitalize" }}
            type="submit"
            // disabled={isLoading}
          >
            {riskItemForm ? "Update" : "Add"}
          </Button>
        </>
      }
      {...props}
    />
  );
};
export default RiskItemFormModal;
