import { Box, Button, DialogProps } from "@mui/material";
import BasicModal from "../../components/modal/BasicModal";
// import { Textarea } from "@mui/joy";
import { useState } from "react";
import useContacts from "../../../hooks/useContacts";
import { useSnackbarHelper } from "../../components/Snackbar";

interface Props extends DialogProps {
  onClose: () => void;
  data?: any;
  leads?: any;
  convertLead?: any;
  isConvertingLead?: any;
  inLoadingLeads?: any;
  userId?: string;
}

const ConvertLeadToContact = ({
  onClose,
  data,
  leads,
  convertLead,
  isConvertingLead,
  inLoadingLeads,
  userId,
  ...props
}: Props) => {
  const { AddSupport, isLoading } = useContacts();

  const lead = leads?.find((val: any) => val?.id === data?.leadId);
  const [isLoadingLeads, setIsLoadingLeads] = useState(false);

  const showSnackbar = useSnackbarHelper();
  const onSubmit = async () => {
    const suffix_salutation = (lead?.Salutation || "")
      .replace(/[^a-zA-Z]/g, "")
      .toLowerCase();
    try {
      setIsLoadingLeads(true);
      const payload = {
        firstName: lead?.First_Name || "",
        lastName: lead?.Last_Name || "",
        suffix: suffix_salutation || "",
        middleName: "",
        mi: "",
        nickName: "",
        address: lead?.Street || "",
        city: lead?.City || "",
        state: lead?.State || "",
        country: lead?.Country || "",
        zip: lead?.Zip_Code || "",
        phone: [{ phone: lead?.Phone, phoneType: "home" }],
        email: [{ email: lead?.Email, emailType: "home" }],
        createdBy: userId,
      };
      const leadid = {
        leadId: data?.leadId,
      };
      const support_contact = await AddSupport(payload);
      const message = support_contact?.message || "Success";
      await convertLead(leadid);
      showSnackbar("Lead Converted Successfully", "success");
      setIsLoadingLeads(false);
      onClose();
    } catch (error: any) {
      const message = error?.response?.data?.message || "An error occurred";
      showSnackbar(message, "error");
    }
  };
  return (
    <Box>
      <BasicModal
        onClose={onClose}
        title="Convert Prospect to Contact"
        content="Convert this prospect to an active contact?"
        actions={
          <>
            <Button
              variant="contained"
              color="success"
              sx={{ textTransform: "capitalize" }}
              onClick={onSubmit}
              disabled={isConvertingLead || isLoadingLeads}
            >
              Convert
            </Button>
            <Button
              color="error"
              variant="outlined"
              sx={{ textTransform: "capitalize" }}
              onClick={() => onClose()}
              disabled={isConvertingLead || isLoadingLeads}
            >
              Cancel
            </Button>
          </>
        }
        {...props}
      />
    </Box>
  );
};

export default ConvertLeadToContact;
