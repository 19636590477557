import AuthLayout from "../views/layouts/AuthLayout";

import { Navigate } from "react-router";
import AuthGuard from "./route-guard/AuthGuard";
import ForgotPassword from "../views/pages/authentication/forgot-password";
import ResetPassword from "../views/pages/authentication/reset-password";
import Login from "../views/pages/authentication/login";
import CreatePassword from "../views/pages/authentication/create-password";
import TwoFactorAuthentication from "../views/pages/authentication/twoFactorAuthentication";

/**
 * AuthRoutes component that defines the routing structure for authentication-related pages.
 * @component AuthRoutes
 * @author Priyanshu
 *
 */
const AuthRoutes = () => ({
  path: "/", // Base path for authentication routes
  element: (
    <AuthGuard>
      <AuthLayout />
    </AuthGuard>
  ),
  children: [
    {
      path: "/login",
      element: <Login />,
    },
    {
      path: "/forgotPassword",
      element: <ForgotPassword />,
    },
    {
      path: "/password/reset",
      element: <ResetPassword />,
    },
    {
      path: "/twoFactorAuthentication",
      element: <TwoFactorAuthentication />,
    },
    {
      path: "/password/create",
      element: <CreatePassword />,
    },
    {
      path: "*",
      element: <Navigate to={"/login"} />,
    },
  ],
});

export default AuthRoutes;
