import { createSlice } from "@reduxjs/toolkit";

/**
 *  Redux slice for managing user authentication state.
 * @slice userSlice
 * @author Priyanshu
 *
 */
export const userSlice = createSlice({
  name: "user",
  initialState: {
    user: null,
  },
  reducers: {
    loginUser: (state, action) => {
      state.user = action.payload;
    },
    logoutUser: (state) => {
      state.user = null;
    },
  },
});

export const { loginUser, logoutUser } = userSlice.actions;
export default userSlice.reducer;
