import { Navigate } from "react-router";
import MainLayout from "../views/layouts/MainLayout";

import MainGuard from "./route-guard/MainGuard";
// import Contacts from "../views/pages/Contacts";
import Users from "../views/pages/users";

import Contacts from "../views/pages/contacts";
import ChangePassword from "../views/pages/users/change-password";
import AccountSetting from "../views/pages/accountSetting";
import Cases from "../views/pages/cases/index";
import Prospects from "../views/pages/prospects";
import Todos from "../views/pages/todos";
import TimeEntry from "../views/pages/timeEntry";
import Sms from "../views/pages/sms";
import Payments from "../views/pages/payments";
import DocuSign from "../views/pages/docuSign";
import Case from "../views/pages/cases/Case";

/**
 * Routes component that conditionally renders application routes based on user authentication status.
 * @component MainRoutes
 * @author Priyanshu
 */

const MainRoutes = () => ({
  path: "/", // Base path for the main routes
  element: (
    <MainGuard>
      <MainLayout />
    </MainGuard>
  ),
  children: [
    {
      path: "/contacts",
      element: <Contacts />,
    },
    {
      path: "/payments",
      element: <Payments />,
    },
    {
      path: "/docusign-waiting",
      element: <DocuSign />,
    },
    {
      path: "/todo",
      element: <Todos />,
    },
    {
      path: "/cases",
      element: <Cases />,
    },
    {
      path: "/cases/:id",
      element: <Case />,
    },
    {
      path: "/users",
      element: <Users />,
    },
    {
      path: "/prospects",
      element: <Prospects />,
    },
    {
      path: "/sms",
      element: <Sms />,
    },
    {
      path: "/change-password",
      element: <ChangePassword />,
    },
    {
      path: "/settings",
      element: <AccountSetting />,
    },
    {
      path: "/time-entry",
      element: <TimeEntry />,
    },
    {
      path: "/sms",
      element: <Sms />,
    },
    {
      path: "*",
      element: <Navigate to={"/cases"} />,
    },
  ],
});

export default MainRoutes;
