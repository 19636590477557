import {
  Box,
  Button,
  CardContent,
  Grid,
  Stack,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import * as Yup from "yup";
import MainCard from "../../../components/cards/MainCard";
import VpnKeyTwoToneIcon from "@mui/icons-material/VpnKeyTwoTone";
import { useState } from "react";
import FormContainer from "../../../components/FormContainer";
import SubCard from "../../../components/cards/SubCard";
import PasswordStrength from "../../../components/PasswordStrength";
import Password from "../../../components/Password";
import useUser from "../../../../hooks/useUser";
import useAuth from "../../../../hooks/useAuth";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useSnackbarHelper } from "../../../components/Snackbar";

// import { TabContext, TabList, TabPanel } from "@mui/lab";

function TabPanels({ children, value, index, ...other }: any) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </div>
  );
}

function a11yProps(index?: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const tabsOption = [
  {
    key: 0,
    value: "password",
    path: "&setting=password",
    label: "Change Password",
    icon: <VpnKeyTwoToneIcon />,
    caption: "Update Profile Security",
  },
];

const ChangePassword = () => {
  const { userId } = useAuth();
  const showSnackbar = useSnackbarHelper();

  const { changePassword, isChangingPassword } = useUser(userId);
  const [tabValue, setTabValue] = useState(0);

  const validationSchema = Yup.object().shape({
    currentPassword: Yup.string().max(255).required("Required"),
    newPassword: Yup.string()
      .max(255)
      .required("Required")
      .matches(
        /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
        "Password must contain at least 8 characters, one uppercase, one number and one special case character"
      ),
    confirmPassword: Yup.string()
      .required("Please confirm your password")
      .oneOf([Yup.ref("newPassword")], "Passwords must match"),
  });

  type FormValues = Yup.InferType<typeof validationSchema>;

  const initialValues = {
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  };

  const formContext = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
  });

  const { reset } = formContext;

  const onFormSubmit = async (values: FormValues) => {
    try {
      const data: any = await changePassword({
        currentPassword: values?.currentPassword,
        newPassword: values?.newPassword,
      });

      const message = data?.message || "Success";

      showSnackbar(message, "success");

      reset();
    } catch (error: any) {
      const message = error?.response?.data?.message || "An error occurred";

      showSnackbar(message, "error");
    }
  };

  const handleTabChange = (event: any, newValue: number) => {
    setTabValue(newValue);
  };

  return (
    <Box>
      <MainCard
        title={
          <Typography sx={{ fontSize: 30, fontWeight: "bold", mx: 2 }}>
            Account Setting
          </Typography>
        }
        actions={false}
        content={
          <Grid container spacing={3}>
            <Grid item xs={12} lg={3}>
              <CardContent>
                <Tabs
                  value={tabValue}
                  onChange={handleTabChange}
                  orientation="vertical"
                  variant="scrollable"
                  sx={{
                    "& .MuiTabs-flexContainer": {
                      borderBottom: "none",
                      flexBasis: "20% !important",
                    },
                    "& button": {
                      color: "grey",
                      minHeight: "auto",
                      minWidth: "100%",
                      py: 1.5,
                      px: 2,
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "flex-start",
                      textAlign: "left",
                      justifyContent: "flex-start",
                      borderRadius: 5,
                    },
                    "& button.Mui-selected": {
                      color: "#E3001B",
                      background: "#f9f8f8",
                    },
                    "& button > svg": {
                      marginBottom: "0px !important",
                      marginRight: 1.25,
                      marginTop: 1.25,
                      height: 20,
                      width: 20,
                    },
                    "& button > div > span": {
                      display: "block",
                    },
                    "& > div > span": {
                      display: "none",
                    },
                  }}
                >
                  {tabsOption.map((tab, index) => (
                    <Tab
                      key={index}
                      icon={tab?.icon}
                      label={
                        <Grid container direction="column">
                          <Typography
                            variant="subtitle1"
                            color="inherit"
                            sx={{ textTransform: "capitalize" }}
                          >
                            {tab?.label}
                          </Typography>
                          <Typography
                            component="div"
                            variant="caption"
                            sx={{ textTransform: "capitalize" }}
                          >
                            {tab?.caption}
                          </Typography>
                        </Grid>
                      }
                      {...a11yProps(index)}
                    />
                  ))}
                </Tabs>
              </CardContent>
            </Grid>

            <Grid item xs={12} md={12} lg={9}>
              <CardContent
                sx={{
                  borderLeft: "1px solid",
                  borderColor: "#ebe8e8",
                  height: "100%",
                }}
              >
                {/* Change Password */}
                <TabPanels value={tabValue} index={0}>
                  <FormContainer
                    formContext={formContext}
                    onSuccess={onFormSubmit}
                  >
                    <Grid>
                      <Grid container spacing={2} sx={{ p: 2 }}>
                        <Grid item xs={12} sm={12} md={5} lg={9}>
                          <Grid container spacing={2}>
                            <Grid item xs={12}>
                              <SubCard title={"Change Password"}>
                                <Grid container spacing={2} sx={{ mb: 2 }}>
                                  <Grid item xs={8}>
                                    <Password
                                      name="currentPassword"
                                      label="Current Password"
                                      fullWidth
                                    />
                                  </Grid>{" "}
                                </Grid>
                                <Grid container spacing={2} sx={{ mb: 2 }}>
                                  <Grid item xs={8}>
                                    <Password
                                      name="newPassword"
                                      label="New Password"
                                      fullWidth
                                    />
                                  </Grid>{" "}
                                </Grid>
                                <Grid item xs={8}>
                                  <Password
                                    name="confirmPassword"
                                    label="Confirm Password"
                                    fullWidth
                                  />
                                </Grid>
                                <Grid item xs={12}>
                                  <PasswordStrength />
                                </Grid>
                                <Grid container spacing={2} sx={{ mt: 2 }}>
                                  <Grid item xs={12}>
                                    <Stack
                                      direction="row"
                                      justifyContent="flex-end"
                                    >
                                      <Button
                                        type="submit"
                                        color="success"
                                        variant="contained"
                                        disabled={isChangingPassword}
                                      >
                                        Update Password
                                      </Button>
                                    </Stack>
                                  </Grid>
                                </Grid>
                              </SubCard>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </FormContainer>
                </TabPanels>
              </CardContent>
            </Grid>
          </Grid>
        }
      />
    </Box>
  );
};

export default ChangePassword;
