import styled from "@emotion/styled";
import {
  Button,
  Skeleton,
  Stack,
  Table,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import moment from "moment";
import { useModal } from "mui-modal-provider";
import { useCallback, useMemo } from "react";
import usePayment from "../../../../hooks/usePayment";
import useSite from "../../../../hooks/useSite";
import { capitalizeFirstLetter } from "../../../../utils/capitalizeFirstLetter";
import SubCard from "../../../components/cards/SubCard";
import NoResultFound from "../../../components/NoResultFound";
import AddPaymentsModal from "../AddPaymentsModal";
import AddScheduledPaymentsModal from "./AddScheduledPaymentModal";
// import MarkPaymentDoneModal from "./MarkPaymentDoneModal";
import { useNavigate } from "react-router";
import { IconPlus } from "@tabler/icons-react";
// import useAuth from "../../../../hooks/useAuth";
// import useUser from "../../../../hooks/useUser";

const CustomTableRow = styled(TableRow)(() => ({
  height: "50px",
}));
const CustomTableCell = styled(TableCell)(() => ({
  paddingTop: 0,
  paddingBottom: 0,
  gap: 5,
  // fontSize: "15px",
}));

const PaymentScheduling = ({
  caseData,
  caseId,
  isCaseClosed,
  isAgreementNotSigned,
  isExistingClient,
}: any) => {
  const { showModal } = useModal();
  const navigate = useNavigate();
  const { paymentScheduling, refetchPayment } = usePayment(caseId?.id);
  const { site, isSiteLoading } = useSite();
  // const { userId } = useAuth();
  // const { isStaff } = useUser(userId);

  const isClientHiredAnotherAttorney = useMemo(
    () => caseData?.isClientHiredAnotherAttorney,
    [caseData]
  );

  const checkQuickBooksIntegrated = useCallback(() => {
    const accessToken = site?.quickBooks?.accessToken;
    return !!accessToken;
  }, [site]);

  const isUnRetained = useMemo(
    () => caseData?.caseStatus === "unretained",
    [caseData]
  );

  const isPaymentCompleted = useMemo(() => {
    return paymentScheduling?.payment?.every(
      (payment: any) => payment.isPaymentCompleted === true
    );
  }, [paymentScheduling]);

  const handleAddScheduledPayment = useCallback(() => {
    const modal: any = showModal(AddScheduledPaymentsModal, {
      onClose: () => modal.hide(),
      caseData,
      caseId,
    });
  }, [showModal, caseId, caseData]);

  // const handleAdd = useCallback(() => {
  //   const modal: any = showModal(AddPaymentsModal, {
  //     onClose: () => modal.hide(),
  //   });
  // }, [showModal]);

  const handlePay = useCallback(
    (paymentScheduling: any, payment_spread: any) => {
      const modal: any = showModal(AddPaymentsModal, {
        onClose: () => modal.hide(),
        data: paymentScheduling,
        contactName:
          caseData?.contactId?.firstName + " " + caseData?.contactId?.lastName,
        clientEmail: caseData?.contactId?.email[0].email,
        clientZip: caseData?.contactId?.zip,
        caseId,
        payment_spread,
        refetchPayment,
      });
    },
    [showModal, caseId, caseData, refetchPayment]
  );

  if (isSiteLoading) {
    return <Skeleton />;
  }

  return (
    <SubCard
      title={"Payments Scheduled"}
      secondary={
        isPaymentCompleted ? (
          checkQuickBooksIntegrated() ? (
            <Button
              sx={{
                color: "green",
                cursor: "pointer",
              }}
              onClick={handleAddScheduledPayment}
              // disabled={isUnRetained || isCaseClosed}
              disabled={
                isUnRetained ||
                isCaseClosed ||
                isClientHiredAnotherAttorney ||
                (isAgreementNotSigned && !isExistingClient)
              }
            >
              {/* <IconPlus /> */}
              {isAgreementNotSigned && !isExistingClient ? (
                "Rep Agreement Not Signed"
              ) : (
                <IconPlus />
              )}
            </Button>
          ) : (
            <Button
              variant="contained"
              size="small"
              color="success"
              onClick={() => {
                navigate("/settings?tab=quickbookIntegration");
              }}
            >
              Integrate Quickbook
            </Button>
          )
        ) : (
          ""
        )
      }
    >
      {!!paymentScheduling?.payment?.length ? (
        <TableContainer sx={{ maxHeight: 400, overflow: "auto" }}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <CustomTableRow
              sx={{ position: "sticky", top: 0, bgcolor: "white", zIndex: 1 }}
            >
              <CustomTableCell>
                {" "}
                <Typography variant="h5">Date</Typography>
              </CustomTableCell>
              <CustomTableCell>
                {" "}
                <Typography variant="h5">Amount(USD)</Typography>
              </CustomTableCell>
              {/* <CustomTableCell>Payment Interval</CustomTableCell> */}
              <CustomTableCell>
                {" "}
                <Typography variant="h5">Type</Typography>
              </CustomTableCell>
              <CustomTableCell>
                {" "}
                <Typography variant="h5">
                  Reminder Type / Email Status
                </Typography>
              </CustomTableCell>
              {/* <CustomTableCell>UnPaid</CustomTableCell> */}
              {/* <CustomTableCell>Paid</CustomTableCell> */}
              <CustomTableCell width={50} align="center">
                <Typography variant="h5">Actions</Typography>
              </CustomTableCell>
            </CustomTableRow>
            {paymentScheduling?.payment?.map((paymentItem: any) =>
              paymentItem?.payment_spreads?.map((payment: any) =>
                !payment?.isPaid ? (
                  <CustomTableRow key={payment?._id}>
                    <CustomTableCell>
                      {moment(payment?.dueDate).format("MM/DD/YYYY")}
                    </CustomTableCell>
                    <CustomTableCell>
                      $ {Number(payment?.payment)?.toFixed(2)}
                    </CustomTableCell>
                    {/* <CustomTableCell>{payment.paymentInterval}</CustomTableCell> */}
                    <CustomTableCell>
                      {capitalizeFirstLetter(paymentItem?.type)}
                    </CustomTableCell>
                    <CustomTableCell width={250}>
                      {" "}
                      <Typography>
                        {payment?.reminder?.map((val: any, index: any) => (
                          <Typography variant="h5" key={index}>
                            {capitalizeFirstLetter(val?.reminderType)} /{" "}
                            {val?.isReminderSent ? "Sent" : "Not Sent"}
                          </Typography>
                        ))}
                      </Typography>
                    </CustomTableCell>
                    {/* <CustomTableCell>{payment.noOfPayments}</CustomTableCell> */}
                    {/* <CustomTableCell>0</CustomTableCell> */}
                    <CustomTableCell>
                      <Stack direction={"row"} spacing={2}>
                        {/* <Button
                        variant="outlined"
                        size="small"
                        color="success"
                        onClick={() => handleEditScheduling(payment)}
                      >
                        View
                      </Button> */}
                        {/* <IconEdit
                        stroke={2}
                        style={{ cursor: "pointer", color: "gray" }}
                        onClick={() => handleEditScheduling(payment)}
                      /> */}
                        {/* <IconEye
                      stroke={2}
                      style={{ cursor: "pointer", color: "gray" }}
                      onClick={() => handleEditScheduling(payment)}
                        /> */}
                        <Button
                          variant="contained"
                          size="small"
                          // disabled={paymentItem?.type === "lawpay" || isStaff}
                          color="success"
                          onClick={() => handlePay(paymentItem, payment)}
                        >
                          Pay
                        </Button>
                        {/* <IconTrash
                        stroke={2}
                        style={{ cursor: "pointer", color: "red" }}
                        onClick={() => handleDelete(payment)}
                      /> */}
                      </Stack>
                    </CustomTableCell>
                  </CustomTableRow>
                ) : null
              )
            )}
          </Table>
        </TableContainer>
      ) : (
        <NoResultFound>No Payment Schedule</NoResultFound>
      )}
    </SubCard>
  );
};

export default PaymentScheduling;
