import styled from "@emotion/styled";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  IconCheck,
  IconEdit,
  IconMail,
  IconMailOff,
  IconPlus,
  IconTrash,
  IconX,
} from "@tabler/icons-react";
import { useCallback, useEffect, useMemo, useState } from "react";
import FormContainer from "../../components/FormContainer";

// import { Textarea } from "@mui/joy";
import moment from "moment";
import { useModal } from "mui-modal-provider";
import { useParams } from "react-router";
import * as Yup from "yup";
import useTodo from "../../../hooks/useTodo";
import NoResultFound from "../../components/NoResultFound";
import { useSnackbarHelper } from "../../components/Snackbar";
import SubCard from "../../components/cards/SubCard";
import DeleteTodoModal from "./DeleteTodoModal";

// import { Textarea } from "@mui/joy";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { LETTERS } from "../../../constants/MenuSelection";
import useAuth from "../../../hooks/useAuth";
import useLetters from "../../../hooks/useLetters";
import useProgress from "../../../hooks/useProgress";
import useUser from "../../../hooks/useUser";
import MuiTextField from "../../components/inputs/MuiTextField";
import TableSkeleton from "../../components/skeleton/TableSkeleton";
import AddNewTodoModal from "../todos/AddNewTodoModal";
import ConfirmationModal from "../todos/ConfirmationModal";
import PreviewPdfModal from "./PreviewPdfModal";

const CustomTableRow = styled(TableRow)(({ theme }) => ({
  height: "40px",
}));
const CustomTableCell = styled(TableCell)(({ theme }) => ({
  paddingTop: 0,
  paddingBottom: 0,
}));

function Activities({ caseData }: any) {
  const caseId = useParams();
  const { showModal } = useModal();
  const { userId } = useAuth();
  const { user } = useUser(userId);
  const [template, setTemplate] = useState<string | null>(null);
  const [loader, setLoader] = useState<string | null>(null);
  const {
    caseTodo,
    isLoadingCaseTodo,
    progressHistory,
    isLoadingProgressHistory,
  } = useTodo(caseId?.id, undefined, undefined, undefined, true);

  const { progress, isLoadingProgress, refetchProgress } = useProgress(
    caseId?.id
  );

  const { getLetterData } = useLetters({ caseId: caseId?.id });

  const [isCompleted, setIsCompleted] = useState<any>(null);
  const showSnackbar = useSnackbarHelper();

  const isClosed = caseData?.caseStatus === "closed";

  const initialValues = useMemo(
    () => ({
      progress: progress || "",
    }),
    [progress]
  );

  const validationSchema = Yup.object().shape({
    progress: Yup.string().required("Required"),
  });

  const formContext = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
  });

  const { setValue } = formContext;

  useEffect(() => {
    setValue("progress", progress);
  }, [progress, setValue]);

  //handle add new todo
  const handleAddNewTodo = useCallback(
    (caseId: any) => {
      const modal: any = showModal(AddNewTodoModal, {
        onClose: () => modal.hide(),
        caseId,
        isCaseTodo: true,
      });
    },
    [showModal]
  );

  //handle delete todo
  const handleDelete = useCallback(
    (todoId: any, caseId: any) => {
      const modal: any = showModal(DeleteTodoModal, {
        onClose: () => modal.hide(),
        todoId,
        caseId,
      });
    },
    [showModal]
  );

  //handle edit todo
  const handleEditTodo = useCallback(
    (data: any) => {
      const modal: any = showModal(AddNewTodoModal, {
        onClose: () => modal.hide(),
        caseId: data?.caseId,
        data: data,
        isCaseTodo: true,
      });
    },
    [showModal]
  );

  //preview letter
  const handleShowLetterPreview = useCallback(
    (Buffer: any) => {
      const modal: any = showModal(PreviewPdfModal, {
        onClose: () => modal.hide(),
        buffer: Buffer,
      });
    },
    [showModal]
  );

  // handle view letter
  const handleViewLetter = async (template: any, todoId: string) => {
    try {
      setLoader(todoId);
      setTemplate(template);
      const response = await getLetterData({
        template: template,
        sendMail: false,
      });
      handleShowLetterPreview(response?.data);
      setLoader(null);
      setTemplate(null);
    } catch (error: any) {
      const message = error?.response?.data?.message || "An error occurred";
      showSnackbar(message, "error");
    }
  };

  // handle mark as complete
  const markAsComplete = (todo: any) => {
    const template = todo?.letter;
    const todoId = todo?._id;
    const recipient = todo?.letterSentTo;
    const isWorkflowTask = todo?.isWorkflowTask;

    const mailType = caseData?.mailType;
    const supportContactMail = caseData?.contactId?.email?.find(
      (e: any) => e?.emailType === mailType
    )?.email;

    const modal: any = showModal(ConfirmationModal, {
      onClose: () => {
        setIsCompleted(null);
        return modal.hide();
      },
      template,
      caseId: caseId?.id,
      todoId,
      recipient,
      isWorkflowTask,
      supportContactMail,
      refetchProgress,
    });
  };

  if (isLoadingProgressHistory || isLoadingProgress || isLoadingCaseTodo) {
    return <TableSkeleton />;
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <FormContainer formContext={formContext} onSuccess={() => {}}>
          <MuiTextField
            label="Progress"
            name="progress"
            // options={PROGRESS}
            // getOptionValue={(option: any) => option?.value}
            disabled
          />
        </FormContainer>
      </Grid>

      <Grid item xs={12}>
        <SubCard title="Progress History">
          <Box>
            {!!progressHistory?.length ? (
              <TableContainer sx={{ maxHeight: 280 }}>
                <Table>
                  <TableHead
                    sx={{
                      position: "sticky",
                      top: 0,
                      backgroundColor: "#cce8f1",
                      zIndex: 2,
                    }}
                  >
                    <CustomTableRow>
                      <CustomTableCell sx={{ minWidth: 80 }}>
                        <Typography variant="h5"> CreatedAt</Typography>
                      </CustomTableCell>
                      <CustomTableCell sx={{ minWidth: 80 }}>
                        <Typography variant="h5">UpdatedAt</Typography>
                      </CustomTableCell>
                      <CustomTableCell sx={{ minWidth: 350 }}>
                        <Typography variant="h5"> Task</Typography>
                      </CustomTableCell>
                      <CustomTableCell sx={{ minWidth: 80 }}>
                        <Typography variant="h5">Due Date</Typography>
                      </CustomTableCell>
                      <CustomTableCell sx={{ minWidth: 50 }}>
                        <Typography variant="h5">Letter</Typography>
                      </CustomTableCell>
                      <CustomTableCell sx={{ minWidth: 80 }}>
                        <Typography variant="h5">Letter Sent ?</Typography>
                      </CustomTableCell>
                      <CustomTableCell sx={{ minWidth: 50 }}>
                        <Typography variant="h5">Status</Typography>
                      </CustomTableCell>
                    </CustomTableRow>
                  </TableHead>
                  <TableBody>
                    {progressHistory?.map((row: any, index: number) => {
                      return (
                        <CustomTableRow key={index}>
                          <CustomTableCell>
                            {row?.createdAt
                              ? moment(row?.createdAt).format("MM/DD/YYYY")
                              : " -"}
                          </CustomTableCell>
                          <CustomTableCell>
                            {row?.updatedAt
                              ? moment(row?.updatedAt).format("MM/DD/YYYY")
                              : " -"}
                          </CustomTableCell>
                          <CustomTableCell>
                            {row?.task?.length > 150
                              ? row?.task?.substring(0, 150) + "...."
                              : row?.task}
                          </CustomTableCell>
                          <CustomTableCell>
                            {row?.dueDate
                              ? moment(row?.dueDate).format("MM/DD/YYYY")
                              : " -"}
                          </CustomTableCell>
                          <CustomTableCell>
                            {!!row?.letter?.length ? (
                              loader === row?._id ? (
                                <CircularProgress size="20px" sx={{ ml: 1 }} />
                              ) : (
                                <LetterMenu
                                  row={row}
                                  handleViewLetter={handleViewLetter}
                                  template={template}
                                />
                              )
                            ) : (
                              <IconButton disabled={true}>
                                <IconMailOff />
                              </IconButton>
                            )}
                          </CustomTableCell>
                          <CustomTableCell>
                            {row?.isEmailSentToClient ? (
                              <IconButton
                                disableRipple
                                color="success"
                                sx={{ pointerEvents: "none" }}
                              >
                                <IconCheck />
                              </IconButton>
                            ) : (
                              <IconButton
                                disableRipple
                                color="error"
                                sx={{ pointerEvents: "none" }}
                              >
                                <IconX />
                              </IconButton>
                            )}
                          </CustomTableCell>
                          <CustomTableCell>
                            <Button
                              sx={{
                                pointerEvents: "none",
                                textTransform: "capitalize",
                                color: "black",
                                width: 80,
                                "&:hover": {
                                  backgroundColor: row?.isCompleted
                                    ? "#ADDFAD"
                                    : "#FF9494",
                                },
                                backgroundColor: row?.isCompleted
                                  ? "#ADDFAD"
                                  : "#FF9494",
                              }}
                              size="small"
                            >
                              {row?.isCompleted ? "Completed" : "Pending"}
                            </Button>
                          </CustomTableCell>
                        </CustomTableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <NoResultFound>No Progress History</NoResultFound>
            )}
          </Box>
        </SubCard>
      </Grid>

      <Grid item xs={12}>
        <SubCard
          title={
            <Stack direction={"row"} justifyContent={"space-between"}>
              <Box>To Do's</Box>
              <Box>
                <Tooltip title="Add Todo">
                  <Button
                    sx={{
                      color: "green",
                      cursor: "pointer",
                    }}
                    disabled={isClosed}
                    onClick={() => handleAddNewTodo(caseId?.id)}
                  >
                    <IconPlus />
                  </Button>
                </Tooltip>
              </Box>
            </Stack>
          }
        >
          <Box overflow={"auto"}>
            {!!caseTodo?.length ? (
              <Table>
                <TableHead>
                  <CustomTableRow>
                    <CustomTableCell sx={{ minWidth: 80 }}>
                      <Typography variant="h5"> Due Date</Typography>
                    </CustomTableCell>
                    <CustomTableCell sx={{ minWidth: 400 }}>
                      <Typography variant="h5">Task</Typography>
                    </CustomTableCell>
                    <CustomTableCell sx={{ minWidth: 150 }}>
                      <Typography variant="h5"> Task For</Typography>
                    </CustomTableCell>
                    <CustomTableCell sx={{ minWidth: 90 }}>
                      <Typography variant="h5"> Completed ?</Typography>
                    </CustomTableCell>
                    <CustomTableCell sx={{ minWidth: 50 }}>
                      <Typography variant="h5"> Priority</Typography>
                    </CustomTableCell>
                    <CustomTableCell sx={{ minWidth: 80 }}>
                      <Typography variant="h5"> Actions</Typography>
                    </CustomTableCell>
                  </CustomTableRow>
                </TableHead>
                <TableBody>
                  {caseTodo?.map((row: any, index: any) => {
                    const dueDate = moment(row?.dueDate);
                    // Check if dueDate is less than the current date
                    const isBeforeToday =
                      dueDate?.isBefore(moment(), "day") || false;

                    return (
                      <CustomTableRow key={row?._id}>
                        <CustomTableCell
                          sx={{ color: isBeforeToday ? "#d32f2f" : "none" }}
                        >
                          {row?.dueDate
                            ? moment(row?.dueDate).format("MM/DD/YYYY")
                            : "-"}
                        </CustomTableCell>
                        <CustomTableCell>{row?.task || ""}</CustomTableCell>
                        <CustomTableCell>
                          {row?.createdFor
                            ? `${row?.createdFor?.firstName} ${row?.createdFor?.lastName}`
                            : "-"}
                        </CustomTableCell>
                        <CustomTableCell>
                          <Checkbox
                            checked={
                              isCompleted && isCompleted?.index === index
                                ? true
                                : false
                            }
                            disabled={isClosed}
                            name="checkbox"
                            color="success"
                            onChange={(event: any) => {
                              setIsCompleted({ index: index, value: true });
                              markAsComplete(row);
                            }}
                          />
                        </CustomTableCell>
                        <CustomTableCell>
                          <Button
                            sx={{
                              textTransform: "capitalize",
                              color: "black",
                              width: 80,
                              pointerEvents: "none",
                              bgcolor:
                                row?.priority === "LOW"
                                  ? "#ADDFAD"
                                  : row?.priority === "MEDIUM"
                                    ? "#fdde6c"
                                    : "#FF9494",
                              "&:hover": {
                                bgcolor:
                                  row?.priority === "LOW"
                                    ? "#ADDFAD"
                                    : row?.priority === "MEDIUM"
                                      ? "#fdde6c"
                                      : "#FF9494",
                              },
                            }}
                            size="small"
                          >
                            {row?.priority}
                          </Button>
                        </CustomTableCell>
                        <CustomTableCell>
                          <Stack direction={"row"} alignItems={"center"}>
                            <Tooltip title="Edit To do">
                              <IconButton
                                onClick={() => handleEditTodo(row)}
                                disabled={
                                  (user?.role === "STAFF" &&
                                    row?.createdBy !== row?.createdFor?._id) ||
                                  isClosed
                                }
                              >
                                <IconEdit />
                              </IconButton>
                            </Tooltip>
                            <IconButton
                              disabled={
                                (user?.role === "STAFF" &&
                                  row?.createdBy !== row?.createdFor?._id) ||
                                isClosed
                              }
                              onClick={() => handleDelete(row?._id, caseId?.id)}
                            >
                              <Tooltip title="Delete To do">
                                <IconTrash
                                  color={
                                    (user?.role === "STAFF" &&
                                      row?.createdBy !==
                                        row?.createdFor?._id) ||
                                    isClosed
                                      ? "#bbbbbb"
                                      : "red"
                                  }
                                />
                              </Tooltip>
                            </IconButton>
                          </Stack>
                        </CustomTableCell>
                      </CustomTableRow>
                    );
                  })}
                </TableBody>
              </Table>
            ) : (
              <NoResultFound>No Todo Found</NoResultFound>
            )}
          </Box>
        </SubCard>
      </Grid>
    </Grid>
  );
}

export default Activities;

const LetterMenu = ({ row, handleViewLetter, template }: any) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Tooltip title="View Letter">
        <IconButton
          id="basic-button"
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={(e) => {
            handleClick(e);
          }}
        >
          <IconMail />
        </IconButton>
      </Tooltip>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {row?.letter?.map((l: any, i: any) => (
          <MenuItem
            key={i}
            disabled={l === template}
            onClick={() => {
              handleViewLetter(l, row?._id);
              handleClose();
            }}
          >
            {LETTERS?.find((letter) => letter.value === l)?.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
