import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { IconButton, Stack, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Drawer from "@mui/material/Drawer";
import * as React from "react";

/**
 * A basic drawer component that can be opened and closed.
 * @component BasicDrawer
 * @author Priyanshu
 *
 */
export default function BasicDrawer({
  name,
  content,
  buttonText,
  color,
  openDrawer,
  setOpenDrawer,
  currentYear,
  setCurrentYear,
}: any) {
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    setOpen(openDrawer === name);
  }, [openDrawer, name]);

  const DrawerList = (
    <Box
      role="presentation"
      sx={{ display: "flex", flexDirection: "column", rowGap: 2 }}
    >
      {name === "calender" && (
        <Stack direction={"row"} justifyContent={"space-between"}>
          <Box sx={{ width: 36 }}></Box>
          <Stack
            mt={1}
            direction={"row"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <IconButton
              sx={{ color: "black" }}
              onClick={() =>
                setCurrentYear((currentYear: number) => currentYear - 3)
              }
            >
              <KeyboardArrowLeftIcon />
            </IconButton>

            <Typography variant="h3" fontWeight={"bold"}>
              {currentYear - 1} - {currentYear + 1}
            </Typography>

            <IconButton
              sx={{ color: "black" }}
              onClick={() =>
                setCurrentYear((currentYear: number) => currentYear + 3)
              }
            >
              <KeyboardArrowRightIcon />
            </IconButton>
          </Stack>
          <IconButton
            title="Close"
            onClick={() => setOpenDrawer(null)}
            disableRipple
            sx={{ "&:hover": { color: "black" } }}
          >
            <CancelOutlinedIcon />
          </IconButton>
        </Stack>
      )}
      {content}
    </Box>
  );

  return (
    <Box
      sx={{
        width: 40,
        height: "fit-content",
      }}
    >
      <Button
        variant="contained"
        disableElevation
        sx={{
          borderRadius: "12px 12px 0px 0px",
          zIndex: 9,
          transform: "rotate(-90deg)",
          whiteSpace: "nowrap",
          transformOrigin: "left top",
          "&:hover": {
            backgroundColor: color, // or specify any other color
            boxShadow: "none", // remove any box-shadow
          },

          backgroundColor: color,
          color: "#364152",
        }}
        onClick={() => {
          open ? setOpenDrawer(null) : setOpenDrawer(name);
        }}
      >
        {open ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}

        <Typography variant="h5">{buttonText}</Typography>
      </Button>
      <Drawer
        // ModalProps={{
        //   BackdropProps: {
        //     style: {
        //       display: "none",
        //     },
        //   },
        // }}
        open={openDrawer === name}
        onClose={() => setOpenDrawer(null)}
        sx={{
          border: "1px solid",
          zIndex: 8,
          "& .MuiDrawer-paper": {
            marginLeft: "56px",
            color: "#333",
            height: "calc(100% - 115px)",
            top: 78,
            bgcolor: name === "calender" ? "#FFF5F5" : "white",
            border: `2px solid ${color}`,
            borderRadius: "10px",
            width: "calc(100% - 80px)",
          },
        }}
      >
        {DrawerList}
      </Drawer>
    </Box>
  );
}
