import React from "react";

import { Box, Button, DialogProps } from "@mui/material";
import BasicModal from "../../components/modal/BasicModal";
import { useSnackbarHelper } from "../../components/Snackbar";
import useDocuSign from "../../../hooks/useDocuSign";
import useCases from "../../../hooks/useCases";

interface Props extends DialogProps {
  onClose: () => void;
  updateCase: (payload: any) => void;
  caseId?: any;
}
const ResendAgreement = ({ onClose, updateCase, caseId, ...props }: Props) => {
  const { caseData } = useCases(caseId);

  const { SendDocuSignMail, isLoadingDocuSignMail } = useDocuSign();
  const showSnackbar = useSnackbarHelper();

  const resend = async () => {
    const caseDetails = caseData?.result;

    try {
      const contactDetails = caseDetails?.contactId;

      const mailType = caseDetails?.mailType;
      const contactName =
        contactDetails?.firstName + " " + contactDetails?.lastName;
      const inmateName = contactDetails?.inmate?.name;
      const tdcjNumber = contactDetails?.inmate?.tdcjNumber;
      const sidNumber = contactDetails?.inmate?.sidNumber;

      const contactMail = contactDetails?.email?.find(
        (e: any) => e.emailType === mailType
      )?.email;

      const docuSignResponse: any = await handleDocuSignMail({
        contactName,
        contactMail,
        inmateName,
        tdcjNumber,
        sidNumber,
      });

      const envelopeId: any = docuSignResponse?.envelopeId;
      const docuSignUrl: any = docuSignResponse?.docuSignUrl;

      if (envelopeId) {
        // create new case
        const payload = {
          envelopeId,
          docuSignUrl,
        };
        await updateCase(payload);
        onClose();
      }
    } catch (error: any) {
      const message = error?.response?.data?.message || "An error occurred";

      showSnackbar(message, "error");
    }
  };

  const handleDocuSignMail = async (values: any) => {
    try {
      const data = await SendDocuSignMail(values);
      const message = data?.message || "Success";
      showSnackbar(message, "success");
      return data;
    } catch (error: any) {
      const message = error?.response?.data?.message || "An error occurred";
      showSnackbar(message, "error");
    }
  };

  return (
    <Box>
      <BasicModal
        onClose={onClose}
        title="Resend Agreement?"
        content="If you resend the agreement, the old one will expire, and the new agreement must be completed."
        actions={
          <>
            <Button
              color="error"
              variant="outlined"
              sx={{ textTransform: "capitalize" }}
              onClick={() => onClose()}
              disabled={isLoadingDocuSignMail}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="error"
              sx={{ textTransform: "capitalize" }}
              onClick={resend}
              disabled={isLoadingDocuSignMail}
            >
              Resend
            </Button>
          </>
        }
        {...props}
      />
    </Box>
  );
};

export default ResendAgreement;
