import { useCallback, useEffect, useMemo, useState } from "react";
import { useModal } from "mui-modal-provider";
import { useParams } from "react-router";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import {
  FormControlLabel,
  Grid,
  InputLabel,
  Radio,
  RadioGroup,
  Stack,
} from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
//components

import FormContainer from "../../components/FormContainer";
import { useSnackbarHelper } from "../../components/Snackbar";
import BasicButton from "../../components/buttons/BasicButton";
import SubCard from "../../components/cards/SubCard";
import MuiTextField from "../../components/inputs/MuiTextField";
import useCases from "../../../hooks/useCases";
import NumberField from "../../components/inputs/NumberField";
import RiskItemFormModal from "./RiskItemFormModal";
import TableSkeleton from "../../components/skeleton/TableSkeleton";
import useRiskItemForm from "../../../hooks/useRiskItemForm";

function CaseDetails({ caseData, refetchCase, isCaseLoading }: any) {
  const showSnackbar = useSnackbarHelper();
  const { showModal } = useModal();
  const caseId = useParams();

  const { updateCaseDetails, isCaseDetailUpdating } = useCases(caseId?.id);
  const { addRiskItemForm, isRiskItemFormLoading } = useRiskItemForm(
    caseId?.id
  );

  const handleRiskItemForm = useCallback(
    (caseId: any) => {
      const modal: any = showModal(RiskItemFormModal, {
        onClose: () => {
          modal.hide();
          refetchCase();
        },
        tdcjId: caseData?.contactId?.tdcj,
        inmateName: caseData?.contactId?.inmate?.name,
        caseId,
        refetchCase,
        addRiskItemForm,
      });
    },
    //eslint-disable-next-line
    [showModal, caseData]
  );

  const [isCriminalHistoryOutsideOfTexas, setIsCriminalHistoryOutsideOfTexas] =
    useState<any>();

  useMemo(() => {
    setIsCriminalHistoryOutsideOfTexas(
      caseData?.caseDetails?.isCriminalHistoryOutsideOfTexas !== undefined
        ? caseData?.caseDetails?.isCriminalHistoryOutsideOfTexas
          ? "yes"
          : "no"
        : null
    );
  }, [caseData]);

  type FormValues = {
    otherIncarcerations: number;
    historyOfRevocations: number;
    numberOfCases: string;
    numberOfMajarDisciplinaries: string;
    overAllRiskLevel: string;
    offenseSeverity: string;
    certificates: string;
    priorEmployment: string;
    tdcjJobAssignment: string;
  };

  const initialValues: FormValues = useMemo(
    () => ({
      otherIncarcerations:
        caseData?.caseDetails?.riskItemForm?.otherIncarcerations?.point ?? "",
      historyOfRevocations:
        caseData?.caseDetails?.riskItemForm?.historyOfRevocations?.point ?? "",
      numberOfCases: caseData?.caseDetails?.numberOfCases ?? "",
      numberOfMajarDisciplinaries:
        caseData?.caseDetails?.numberOfMajarDisciplinaries ?? "",
      overAllRiskLevel:
        caseData?.caseDetails?.riskItemForm?.overAllRiskLevel ?? "",
      offenseSeverity:
        caseData?.caseDetails?.riskItemForm?.offenseSeverity ?? "",
      certificates: caseData?.caseDetails?.certificates ?? "",
      priorEmployment: caseData?.caseDetails?.priorEmployment ?? "",
      tdcjJobAssignment: caseData?.caseDetails?.tdcjJobAssignment ?? "",
    }),
    [caseData]
  );

  const validationSchema: any = Yup.object().shape({
    numberOfCases: Yup.number()
      .transform((value, originalValue) =>
        originalValue === "" ? null : value
      )
      .nullable()
      .typeError("Only numbers are allowed")
      .required("Required"),
    numberOfMajarDisciplinaries: Yup.string().required("Required"),
    certificates: Yup.string().required("Required"),
    priorEmployment: Yup.string().required("Required"),
    tdcjJobAssignment: Yup.string().required("Required"),
  });

  const formContext = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    if (caseData) {
      formContext.reset(initialValues);
    }
  }, [caseData, formContext, initialValues]);

  const getPayload = (values: any) => {
    const riskItemForm = caseData?.caseDetails?.riskItemForm;
    return {
      riskItemForm: {
        otherIncarcerations: {
          value: riskItemForm?.otherIncarcerations?.value,
          point: values?.otherIncarcerations,
        },
        historyOfRevocations: {
          value: riskItemForm?.historyOfRevocations?.value,
          point: values?.historyOfRevocations,
        },
        ageAtFirstCommitment: {
          value: riskItemForm?.ageAtFirstCommitment?.value,
          point: riskItemForm?.ageAtFirstCommitment?.point,
        },
        employmentHistory: {
          value: riskItemForm?.employmentHistory.value,
          point: riskItemForm?.employmentHistory.point,
        },
        commitmentOffense: {
          value: riskItemForm?.commitmentOffense.value,
          point: riskItemForm?.commitmentOffense.point,
        },
        currentAge: {
          value: riskItemForm?.currentAge.value,
          point: riskItemForm?.currentAge.point,
        },
        securityThreatGroupMembership: {
          value: riskItemForm?.securityThreatGroupMembership.value,
          point: riskItemForm?.securityThreatGroupMembership.point,
        },
        completedEducation: {
          value: riskItemForm?.completedEducation.value,
          point: riskItemForm?.completedEducation.point,
        },
        disciplinaryConduct: {
          value: riskItemForm?.disciplinaryConduct.value,
          point: riskItemForm?.disciplinaryConduct.point,
        },
        currentCustodyLevel: {
          value: riskItemForm?.currentCustodyLevel.value,
          point: riskItemForm?.currentCustodyLevel.point,
        },
        totalStaticRiskScore: riskItemForm?.totalStaticRiskScore,
        totalDynamicRiskScore: riskItemForm?.totalDynamicRiskScore,
        totalRiskScore: riskItemForm?.totalRiskScore,
        primaryOffense: riskItemForm?.primaryOffense,
        ncicCode: riskItemForm?.ncicCode,
        penalCode: riskItemForm?.penalCode,
        degreeLevel: riskItemForm?.degreeLevel,
        aggravatedOffense: riskItemForm?.aggravatedOffense,
        lifeSentence: riskItemForm?.lifeSentence,
        overAllRiskLevel: riskItemForm?.overAllRiskLevel,
        riskLevel: values?.overAllRiskLevel,
        offenseSeverity: values?.offenseSeverity,
        capitalOffense: riskItemForm?.capitalOffense,
        term: riskItemForm?.term,
        parole: riskItemForm?.parole,
        denyParole: riskItemForm?.denyParole,
        discretionaryMS: riskItemForm?.discretionaryMS,
        denyDiscretionaryMS: riskItemForm?.denyDiscretionaryMS,
        reasonForDecision: riskItemForm?.reasonForDecision,
        setOffDate: riskItemForm?.setOffDate,
        firDate: riskItemForm?.firDate,
        boardOfMembers: riskItemForm?.boardOfMembers,
        date: riskItemForm?.date,
      },
      numberOfCases: values?.numberOfCases,
      numberOfMajarDisciplinaries: values?.numberOfMajarDisciplinaries,
      certificates: values?.certificates,
      priorEmployment: values?.priorEmployment,
      tdcjJobAssignment: values?.tdcjJobAssignment,
      isCriminalHistoryOutsideOfTexas: values?.isCriminalHistoryOutsideOfTexas,
    };
  };
  const handleSubmit = async (values: any) => {
    values.isCriminalHistoryOutsideOfTexas =
      isCriminalHistoryOutsideOfTexas === "yes" ? true : false;
    const payload = getPayload(values);
    // console.log(payload);
    try {
      const data = await updateCaseDetails(payload);

      const message = data?.message || "Success";
      await refetchCase();
      showSnackbar(message, "success");
    } catch (error: any) {
      const message = error?.response?.data?.message || "An error occurred";

      showSnackbar(message, "error");
    }
  };
  const handleChange = (e: any) => {
    setIsCriminalHistoryOutsideOfTexas(e?.target?.value);
  };

  if (isCaseLoading || isRiskItemFormLoading || isCaseDetailUpdating) {
    return <TableSkeleton />;
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <FormContainer onSuccess={handleSubmit} formContext={formContext}>
          <SubCard
            title="Case Details"
            secondary={
              <Stack direction={"row"} spacing={2}>
                <BasicButton
                  label={"Risk Item Form"}
                  variant="contained"
                  color="success"
                  onClick={() => {
                    handleRiskItemForm(caseId?.id);
                  }}
                />
                <BasicButton
                  label={"Save"}
                  variant="contained"
                  color="success"
                  type="submit"
                  // onClick={() => {}}
                  disabled={
                    caseData?.caseDetails?.riskItemForm?.ageAtFirstCommitment
                      ?.point === undefined
                      ? true
                      : false
                  }
                />
              </Stack>
            }
          >
            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                {" "}
                <NumberField
                  label="Prior TDCJ Incarnations"
                  name="otherIncarcerations"
                  disabled
                />{" "}
              </Grid>
              <Grid item xs={12} md={4}>
                {" "}
                <NumberField
                  label="Prior Revocations"
                  name="historyOfRevocations"
                  disabled
                />{" "}
              </Grid>
              <Grid item xs={12} md={4}>
                {" "}
                <MuiTextField
                  label="Cases/Detainers"
                  name="numberOfCases"
                  type="number"
                  required
                />{" "}
              </Grid>
              <Grid item xs={12} md={4}>
                {" "}
                <MuiTextField
                  label="Major Disciplinaries"
                  name="numberOfMajarDisciplinaries"
                  required
                />{" "}
              </Grid>
              <Grid item xs={12} md={4}>
                {" "}
                <MuiTextField
                  label="Risk Level"
                  name="overAllRiskLevel"
                  disabled
                />{" "}
              </Grid>
              <Grid item xs={12} md={4}>
                {" "}
                <MuiTextField
                  label="Offense Security Level"
                  name="offenseSeverity"
                  disabled
                />{" "}
              </Grid>
              <Grid item xs={12}>
                {" "}
                <Grid columnGap={2} container alignItems={"center"}>
                  <Grid item>
                    {" "}
                    <InputLabel>
                      Do You have Criminal History outside of Texas ?
                    </InputLabel>
                  </Grid>
                  <Grid item>
                    <RadioGroup
                      row
                      // aria-labelledby="demo-row-radio-buttons-group-label"
                      // name="row-radio-buttons-group"
                      onChange={(e: any) => handleChange(e)}
                    >
                      <FormControlLabel
                        value="yes"
                        checked={
                          isCriminalHistoryOutsideOfTexas === "yes"
                            ? true
                            : false
                        }
                        control={<Radio size="small" />}
                        label="Yes"
                        name="isCriminalHistoryOutsideOfTexas"
                      />

                      <FormControlLabel
                        value="no"
                        control={<Radio size="small" />}
                        checked={
                          isCriminalHistoryOutsideOfTexas === "no"
                            ? true
                            : false
                        }
                        label="No"
                        name="isCriminalHistoryOutsideOfTexas"
                      />
                    </RadioGroup>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <MuiTextField
                  name="certificates"
                  label="Certificates"
                  required
                  fullWidth
                  multiline
                  rows={2}
                  InputProps={{
                    style: {
                      borderRadius: "12px",
                    },
                    classes: {
                      input: "custom-scrollbar", // Apply the custom scrollbar class here
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <MuiTextField
                  name="priorEmployment"
                  label="Prior Employement"
                  required
                  fullWidth
                  multiline
                  rows={2}
                  InputProps={{
                    style: {
                      borderRadius: "12px",
                    },
                    classes: {
                      input: "custom-scrollbar", // Apply the custom scrollbar class here
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <MuiTextField
                  name="tdcjJobAssignment"
                  label="TDCJ Job Assignments"
                  fullWidth
                  required
                  multiline
                  rows={2}
                  InputProps={{
                    style: {
                      borderRadius: "12px",
                    },
                    classes: {
                      input: "custom-scrollbar", // Apply the custom scrollbar class here
                    },
                  }}
                />
              </Grid>
            </Grid>
          </SubCard>
        </FormContainer>
      </Grid>

      {/* <Grid item xs={12}>
        <SubCard title="Progress History">
          <Table>
            <CustomTableRow sx={{ fontSize: { sm: "14px", md: "15px" } }}>
              <CustomTableCell>Date</CustomTableCell>
              <CustomTableCell>By</CustomTableCell>
              <CustomTableCell>Task</CustomTableCell>
              <CustomTableCell sx={{ textAlign: "right" }}>
                <Button
                  sx={{
                    color: "green",
                    cursor: "pointer",
                  }}
                  onClick={handleAdd}
                >
                  <IconPlus />
                </Button>
              </CustomTableCell>
            </CustomTableRow>
            {progressHistory?.map((row, index) => (
              <CustomTableRow
                key={index}
                sx={{ fontSize: { sm: "14px", md: "15px" } }}
              >
                <CustomTableCell style={{ width: "10vw" }}>
                  {row?.date ? moment(row?.date).format("MM/DD/YYYY") : " -"}
                </CustomTableCell>
                <CustomTableCell>{row.by}</CustomTableCell>
                <CustomTableCell>{row?.task}</CustomTableCell>
                <CustomTableCell></CustomTableCell>
              </CustomTableRow>
            ))}
          </Table>
        </SubCard>
      </Grid> */}

      {/* <Grid item xs={12}>
        <SubCard
          title={
            <Stack direction={"row"} justifyContent={"space-between"}>
              <Box>To-Dos</Box>
              <Box>
                <Tooltip title="Add Todo">
                  <Button
                    sx={{
                      color: "green",
                      cursor: "pointer",
                    }}
                    onClick={() => handleAddNewTodo(caseData, caseId?.id)}
                  >
                    <IconPlus />
                  </Button>
                </Tooltip>
              </Box>
            </Stack>
          }
        >
          <Box overflow={"auto"}>
            {caseTodo?.length > 0 ? (
              <Table>
                <CustomTableRow sx={{ fontSize: { sm: "14px", md: "15px" } }}>
                  <CustomTableCell>Due Date</CustomTableCell>
                  <CustomTableCell sx={{ width: "250px !important" }}>
                    Task
                  </CustomTableCell>
                  <CustomTableCell>Task For</CustomTableCell>
                  <CustomTableCell>Completed ?</CustomTableCell>

                  <CustomTableCell>Priority</CustomTableCell>
                  <CustomTableCell style={{ textAlign: "center" }}>
                    Actions
                  </CustomTableCell>

                  <CustomTableCell
                    sx={{ textAlign: "right" }}
                  ></CustomTableCell>
                </CustomTableRow>
                {caseTodo?.map((row: any, index: any) => (
                  <CustomTableRow key={row?._id}>
                    <CustomTableCell sx={{ fontSize: "13px" }}>
                      {moment(row?.dueDate).format("MM/DD/YYYY")}
                    </CustomTableCell>
                    <CustomTableCell>{row?.task || ""}</CustomTableCell>
                    <CustomTableCell>{`${row?.createdFor?.firstName} ${row.createdFor?.lastName}  `}</CustomTableCell>
                    <CustomTableCell>
                      <Checkbox
                        checked={
                          iscompleted && iscompleted?.index === index
                            ? true
                            : false
                        }
                        name="checkbox"
                        color="success"
                        onChange={(event: any) => {
                          setIsCompleted({ index: index, value: true });
                          handleUpdateTodo(event, row?._id, row);
                        }}
                      />
                    </CustomTableCell>

                    <CustomTableCell sx={{ textTransform: "capitalize" }}>
                      {row?.priority}
                    </CustomTableCell>
                    <CustomTableCell>
                      <Stack
                        direction={"row"}
                        alignItems={"center"}
                        spacing={2}
                        justifyContent={"end"}
                      >
                        <Tooltip title="Edit Todo">
                          <IconEdit
                            style={{ cursor: "pointer" }}
                            onClick={() => handleEditTodo(row, caseId?.id)}
                            color="gray"
                          />
                        </Tooltip>
                        <Button
                          onClick={() => handleDelete(row?._id, caseId?.id)}
                        >
                          <Tooltip title="Delete Todo">
                            <IconTrash color="red" />
                          </Tooltip>
                        </Button>
                      </Stack>
                    </CustomTableCell>
                    <CustomTableCell></CustomTableCell>
                  </CustomTableRow>
                ))}
              </Table>
            ) : (
              <NoResultFound>No Todo found</NoResultFound>
            )}
          </Box>
        </SubCard>
      </Grid> */}
    </Grid>
  );
}

export default CaseDetails;
