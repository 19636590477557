import {
  Button,
  Checkbox,
  CircularProgress,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";

import {
  IconEdit,
  IconMail,
  IconMailOff,
  IconTrash,
} from "@tabler/icons-react";
import DataGridTable from "../../components/DataGridTable";
import MainCard from "../../components/cards/MainCard";

import {
  GridActionsCellItem,
  GridColDef,
  GridPaginationModel,
  GridSortModel,
} from "@mui/x-data-grid";
import { debounce } from "lodash";
import moment from "moment";
import { useModal } from "mui-modal-provider";
import { useCallback, useRef, useState } from "react";
import useAuth from "../../../hooks/useAuth";
import useTodo from "../../../hooks/useTodo";
import { useSnackbarHelper } from "../../components/Snackbar";
import SearchField from "../../components/inputs/SearchField";
import PreviewPdfModal from "../cases/PreviewPdfModal";
import AddNewTodoModal from "./AddNewTodoModal";
import ConfirmationModal from "./ConfirmationModal";
import DeleteTodoModal from "./DeleteTodoModal";
import useUser from "../../../hooks/useUser";
import { LETTERS } from "../../../constants/MenuSelection";

export default function Todos() {
  const { showModal } = useModal();
  const { request, userId } = useAuth();
  const { user } = useUser(userId);
  const [isCompleted, setIsCompleted] = useState<any>(null);
  const [search, setSearch] = useState("");
  const [loader, setLoader] = useState<string | null>(null);
  const [sortModel, setSortModel] = useState<GridSortModel>([
    { field: "startTime", sort: "desc" },
  ]);
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    page: 0,
    pageSize: 12,
  });
  const [template, setTemplate] = useState<string | null>(null);

  // Add debounce to hold render events
  const debouncedSetSearch = useRef(
    debounce((value) => setSearch(value), 400)
  ).current;
  const showSnackbar = useSnackbarHelper();

  const handleSearchChange = useCallback(
    (event: any) => {
      debouncedSetSearch(event.target.value);
    },
    [debouncedSetSearch]
  );

  const { allTodo, totalTodo, isLoadingAllTodos } = useTodo(
    undefined,
    search,
    paginationModel,
    sortModel
  );

  const handleSortModelChange = (newModel: any[]) => {
    setSortModel(newModel);
  };

  //handle add todo
  const handleAddNewTodo = useCallback(() => {
    const modal: any = showModal(AddNewTodoModal, {
      onClose: () => modal.hide(),
    });
  }, [showModal]);

  //handle edit todo
  const handleEditTodo = useCallback(
    (data: any) => {
      const modal: any = showModal(AddNewTodoModal, {
        onClose: () => modal.hide(),
        data: data,
      });
    },
    [showModal]
  );

  //handle mark as complete
  const markAsComplete = (todo: any, caseId: string) => {
    const template = todo?.letter;
    const todoId = todo?._id;
    const recipient = todo?.letterSentTo;
    const isWorkflowTask = todo?.isWorkflowTask;

    const mailType = todo?.caseId?.mailType;
    const supportContactMail = todo?.client?.email?.find(
      (e: any) => e?.emailType === mailType
    )?.email;

    const modal: any = showModal(ConfirmationModal, {
      onClose: () => {
        setIsCompleted(null);
        return modal.hide();
      },
      isMasterTodoList: true,
      template,
      caseId,
      todoId,
      recipient,
      isWorkflowTask,
      supportContactMail,
    });
  };

  //preview letter
  const handleShowLetterPreview = useCallback(
    (Buffer: any) => {
      const modal: any = showModal(PreviewPdfModal, {
        onClose: () => modal.hide(),
        buffer: Buffer,
      });
    },
    [showModal]
  );

  //handle view letter
  const handleViewLetter = async (
    template: any,
    caseId: string,
    todoId: string
  ) => {
    try {
      setLoader(todoId);
      setTemplate(template);
      const response = await request.post(`/cases/${caseId}/letters`, {
        template,
        sendMail: false,
      });

      handleShowLetterPreview(response?.data);
      setLoader(null);
      setTemplate(null);
    } catch (error: any) {
      const message = error?.response?.data?.message || "An error occurred";
      showSnackbar(message, "error");
    }
  };

  //handle delete todo
  const handleDeleteTodo = useCallback(
    (id: any) => {
      const modal: any = showModal(DeleteTodoModal, {
        onClose: () => modal.hide(),
        todoId: id,
      });
    },
    [showModal]
  );

  const columns: GridColDef[] = [
    {
      field: "caseId",
      headerName: "Case ID#",
      headerClassName: "MuiDataGrid-cell",
      minWidth: 120,
      renderCell: (params: any) => params?.row?.caseId?.caseId || "-",
      renderHeader: (params: any) => params?.colDef?.headerName,
    },
    {
      field: "dueDate",
      headerName: "Due Date",
      headerClassName: "MuiDataGrid-cell",
      minWidth: 120,
      renderCell: (params: any) => {
        const dueDate = moment(params?.row?.dueDate);

        // Check if dueDate is less than the current date
        const isBeforeToday = dueDate?.isBefore(moment(), "day") || false;

        return (
          <Typography sx={{ color: isBeforeToday ? "#d32f2f" : "none" }}>
            {moment(params?.row?.dueDate).format("MM/DD/YYYY")}
          </Typography>
        );
      },
      renderHeader: (params: any) => params?.colDef?.headerName,
    },
    // {
    //   field: "updatedDate",
    //   headerName: "Updated Date",
    //   headerClassName: "MuiDataGrid-cell",
    //   minWidth: 180,
    //   renderCell: (params: any) => (
    //params?.row?.createdDate
    //   ),
    //   renderHeader: (params: any) =>params?.colDef?.headerName),
    // },
    {
      field: `createdFor`,
      headerName: "Created For",
      minWidth: 220,
      renderCell: (params: any) =>
        `${params?.row?.createdFor?.firstName} ${params?.row?.createdFor?.lastName}`,
      renderHeader: (params: any) => params?.colDef?.headerName,
    },
    {
      field: `createdBy`,
      headerName: "Created By",
      minWidth: 220,
      renderCell: (params: any) =>
        !!params?.row?.createdBy
          ? `${params?.row?.createdBy?.firstName} ${params?.row?.createdBy?.lastName}`
          : "-",
      renderHeader: (params: any) => params?.colDef?.headerName,
    },
    {
      field: "task",
      headerName: "Task Name",
      minWidth: 300,
      flex: 1,
      renderCell: (params: any) => params?.row?.task,
      renderHeader: (params: any) => params?.colDef?.headerName,
    },
    {
      field: "client",
      headerName: "Client Name",
      minWidth: 220,
      renderCell: (params: any) =>
        `${params?.row?.client?.firstName} ${params?.row?.client?.lastName}`,
      renderHeader: (params: any) => params?.colDef?.headerName,
    },
    // {
    //   field: "mailSent?",
    //   headerName: "Mail Sent?",
    //   minWidth: 250,
    //   renderCell: (params: any) =>
    //     params?.row?.isEmailtoReceipient || params?.row?.isAutomaticPriority ? (
    //       <IconButton
    //         disableRipple
    //         color="success"
    //         sx={{ pointerEvents: "none" }}
    //       >
    //         <IconCheck />
    //       </IconButton>
    //     ) : (
    //       <IconButton
    //         color="error"
    //         disableRipple
    //         sx={{ pointerEvents: "none" }}
    //       >
    //         <IconX />
    //       </IconButton>
    //     ),
    //   renderHeader: (params: any) => params?.colDef?.headerName,
    // },
    {
      field: "priority",
      headerName: "Priority",
      minWidth: 120,
      renderHeader: (params: any) => params?.colDef?.headerName,
      renderCell: (params: any) => (
        <Button
          sx={{
            textTransform: "capitalize",
            color: "black",
            width: 80,
            pointerEvents: "none",
            bgcolor:
              params?.row?.priority === "LOW"
                ? "#ADDFAD"
                : params?.row?.priority === "MEDIUM"
                  ? "#fdde6c"
                  : "#FF9494",
            "&:hover": {
              bgcolor:
                params?.row?.priority === "LOW"
                  ? "#ADDFAD"
                  : params?.row?.priority === "MEDIUM"
                    ? "#fdde6c"
                    : "#FF9494",
            },
          }}
          size="small"
        >
          {params?.row?.priority}
        </Button>
      ),
    },

    {
      field: "letter",
      headerName: "Letter",
      minWidth: 100,
      renderHeader: (params: any) => params?.colDef?.headerName,
      renderCell: (params: any) => (
        <>
          {!!params?.row?.letter?.length ? (
            loader === params?.row?._id ? (
              <CircularProgress size="25px" sx={{ mt: 2, ml: 0.5 }} />
            ) : (
              <LetterMenu
                row={params?.row}
                handleViewLetter={handleViewLetter}
                template={template}
              />
            )
          ) : (
            <IconButton disabled={true}>
              <IconMailOff />
            </IconButton>
          )}
        </>
      ),
    },
    {
      field: "createdAt",
      headerName: "Created At",
      headerClassName: "MuiDataGrid-cell",
      minWidth: 130,
      renderCell: (params: any) =>
        moment(params?.row?.createdDate).format("MM/DD/YYYY"),
      renderHeader: (params: any) => params?.colDef?.headerName,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      headerClassName: "MuiDataGrid-cell",
      minWidth: 180,
      cellClassName: "actions",
      getActions: (params: any) => {
        return [
          <Tooltip title="Mark as Complete">
            <Checkbox
              checked={
                isCompleted && isCompleted?.index === params?.row?._id
                  ? true
                  : false
              }
              name="checkbox"
              color="success"
              onChange={(event: any) => {
                setIsCompleted({ index: params?.row?._id, value: true });
                markAsComplete(params?.row, params?.row?.caseId?._id);
              }}
            />
          </Tooltip>,
          <GridActionsCellItem
            icon={<IconEdit />}
            label="view"
            disabled={
              user?.role === "STAFF" &&
              params?.row?.createdBy?._id !== params?.row?.createdFor?._id
            }
            onClick={() => {
              handleEditTodo(params?.row);
            }}
          />,

          <GridActionsCellItem
            color="error"
            icon={<IconTrash />}
            label="view"
            disabled={
              user?.role === "STAFF" &&
              params?.row?.createdBy?._id !== params?.row?.createdFor?._id
            }
            onClick={() => {
              handleDeleteTodo(params?.row?._id);
            }}
          />,
        ];
      },
      renderHeader: (params: any) => params?.colDef?.headerName,
    },
  ];

  return (
    <MainCard
      title={
        <Typography variant="h1" sx={{ mt: 1 }}>
          To Do's
        </Typography>
      }
      actions={
        <>
          <Stack direction={"row"} alignItems={"center"}>
            <SearchField
              onChange={handleSearchChange}
              size="small"
              placeholder={"Search task"}
            />

            <Button
              variant="contained"
              color="success"
              onClick={() => {
                handleAddNewTodo();
              }}
              size={window.innerWidth < 1420 ? "small" : "medium"}
              sx={{
                textTransform: "capitalize",
                my: 1,
                mx: 2,
              }}
            >
              Add To Do
            </Button>
          </Stack>
        </>
      }
      content={
        <>
          <DataGridTable
            column={columns}
            rows={allTodo || []}
            checkboxSelection={false}
            onPaginationModelChange={setPaginationModel}
            paginationModel={paginationModel}
            isLoading={isLoadingAllTodos}
            totalRows={totalTodo}
            sortModel={sortModel}
            onSortModelChange={handleSortModelChange}
          />
        </>
      }
    ></MainCard>
  );
}

const LetterMenu = ({ row, handleViewLetter, template }: any) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Tooltip title="View Letter">
        <IconButton
          id="basic-button"
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={(e) => {
            handleClick(e);
          }}
        >
          <IconMail />
        </IconButton>
      </Tooltip>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {row?.letter?.map((l: any, i: any) => (
          <MenuItem
            key={i}
            disabled={l === template}
            onClick={() => {
              handleViewLetter(l, row?.caseId?._id, row?._id);
              handleClose();
            }}
          >
            {LETTERS?.find((letter) => letter.value === l)?.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
