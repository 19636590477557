import {
  Stack,
  Table,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { useCallback } from "react";

import styled from "@emotion/styled";

import { IconEdit, IconTrash } from "@tabler/icons-react";
import moment from "moment";
import { useModal } from "mui-modal-provider";
import useTimeEntry from "../../../hooks/useTimeEntry";
import BasicButton from "../../components/buttons/BasicButton";
import SubCard from "../../components/cards/SubCard";
import AddTimeEntryModal from "./AddTimeEntryModal";
import DeleteTimeEntryModal from "./DeleteTimeEntryModal";
import NoResultFound from "../../components/NoResultFound";
import { Type } from "../../../constants/MenuSelection";

function TimeSpent({ caseData }: any) {
  const { timeEntry } = useTimeEntry({ caseId: caseData?._id });

  const CustomTableRow = styled(TableRow)(({ theme }) => ({
    height: "50px",
  }));
  const CustomTableCell = styled(TableCell)(({ theme }) => ({
    paddingTop: 0,
    paddingBottom: 0,
    // fontSize: "15px",
    width: 150,
  }));

  const { showModal } = useModal();
  const handleAdd = useCallback(() => {
    const modal: any = showModal(AddTimeEntryModal, {
      onClose: () => modal.hide(),
      caseData,
    });
  }, [showModal, caseData]);
  const handleEdit = useCallback(
    (data: any) => {
      const modal: any = showModal(AddTimeEntryModal, {
        onClose: () => modal.hide(),
        caseData,
        data,
      });
    },
    [showModal, caseData]
  );
  const handleDelete = useCallback(
    (data: any) => {
      const modal: any = showModal(DeleteTimeEntryModal, {
        onClose: () => modal.hide(),
        id: data?._id,
        caseData,
      });
    },
    [showModal, caseData]
  );

  return (
    <SubCard
      title="Time Spent"
      secondary={
        <Stack direction={"row"} justifyContent={"flex-end"} spacing={2}>
          {/* <BasicButton
            label={"Time Report"}
            variant="outlined"
            color="success"
            onClick={() => {}}
          /> */}

          <BasicButton
            label={" Add Time Entry"}
            variant="contained"
            color="success"
            onClick={handleAdd}
          />
        </Stack>
      }
    >
      {timeEntry?.length > 0 ? (
        <Stack overflow={"auto"}>
          {" "}
          <Table sx={{ tableLayout: "fixed" }}>
            <CustomTableRow sx={{ fontSize: { sm: "14px", md: "15px" } }}>
              {/* <CustomTableCell>Staff</CustomTableCell> */}
              <CustomTableCell>
                {" "}
                <Typography variant="h5">Type</Typography>
              </CustomTableCell>
              <CustomTableCell>
                {" "}
                <Typography variant="h5">Start Time</Typography>
              </CustomTableCell>
              <CustomTableCell>
                {" "}
                <Typography variant="h5">End Time</Typography>
              </CustomTableCell>
              <CustomTableCell>
                {" "}
                <Typography variant="h5">Billed Minutes</Typography>
              </CustomTableCell>
              <CustomTableCell>
                {" "}
                <Typography variant="h5">Actions</Typography>
              </CustomTableCell>

              {/* <CustomTableCell
          sx={{
            textAlign: "right",
            fontSize: "26px", // Increase the font size for the "+"
            color: "green",
            cursor: "pointer", // Indicates clickable
          }}
        >
          <IconPlus />
        </CustomTableCell> */}
            </CustomTableRow>
            {timeEntry?.map((row: any, index: any) => (
              <CustomTableRow
                key={index}
                sx={{ fontSize: { sm: "14px", md: "15px" } }}
              >
                {/* <CustomTableCell>
                  {row?.staffName
                    ? `${row?.staffName?.firstName} ${row?.staffName?.lastName}`
                    : "-"}
                </CustomTableCell> */}
                <CustomTableCell>
                  {Type?.find((t: any) => t?.value === row?.type)?.label || "-"}
                </CustomTableCell>
                <CustomTableCell>
                  {moment(row?.startTime).format("MM/DD/YYYY, h:mm a")}
                </CustomTableCell>
                <CustomTableCell>
                  {" "}
                  {row?.endTime ? (
                    moment(row?.endTime).format("MM/DD/YYYY, h:mm a")
                  ) : (
                    <Typography>--</Typography>
                  )}
                </CustomTableCell>
                <CustomTableCell>{row?.billedMinutes}</CustomTableCell>
                <CustomTableCell>
                  <Stack direction={"row"} spacing={2}>
                    <Tooltip title="Edit Time Entry">
                      <IconEdit
                        stroke={2}
                        style={{ cursor: "pointer", color: "gray" }}
                        onClick={() => {
                          handleEdit(row);
                        }}
                      />
                    </Tooltip>

                    <Tooltip title="Delete Time Entry">
                      <IconTrash
                        stroke={2}
                        style={{ cursor: "pointer", color: "red" }}
                        onClick={() => {
                          handleDelete(row);
                        }}
                      />
                    </Tooltip>
                  </Stack>
                </CustomTableCell>
              </CustomTableRow>
            ))}
          </Table>
        </Stack>
      ) : (
        <NoResultFound>No Time Entry found</NoResultFound>
      )}
    </SubCard>
  );
}

export default TimeSpent;
