import { Button, Grid, Stack } from "@mui/material";
import React, { useMemo } from "react";
import { RepType } from "../../../constants/MenuSelection";
import MuiTextField from "../../components/inputs/MuiTextField";
import FormContainer from "../../components/FormContainer";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useSnackbarHelper } from "../../components/Snackbar";
import useUser from "../../../hooks/useUser";
import NumberField from "../../components/inputs/NumberField";
import TableSkeleton from "../../components/skeleton/TableSkeleton";

type FormValues = {
  types: { type: string; value: string }[];
};

const RepTypes = ({
  user,
  changingRepTypes,
  isChangingRepTypes,
  userId,
}: any) => {
  const { repTypes, isLoadingRepTypes } = useUser(userId);
  const showSnackbar = useSnackbarHelper();

  const validationSchema = Yup.object({
    types: Yup.array().of(
      Yup.object().shape({
        type: Yup.string().required("Type is required"),
        value: Yup.string().required("Required"),
      })
    ),
  });

  const initialValues: FormValues = useMemo(
    () => ({
      types: [
        {
          type: repTypes?.[0]?.type || "InPerson",
          value: repTypes?.[0]?.value || "",
        },
        {
          type: repTypes?.[1]?.type || "Telephonic",
          value: repTypes?.[1]?.value || "",
        },
      ],
    }),
    [repTypes]
  );

  const formContext = useForm({
    defaultValues: initialValues,
    resolver: yupResolver<any>(validationSchema),
  });

  const onFormSubmit = async (values?: any) => {
    try {
      const data = await changingRepTypes(values);
      const message = data?.message || "Success";

      showSnackbar(message, "success");
    } catch (error: any) {
      const message = error?.response?.data?.message || "An error occurred";

      showSnackbar(message, "error");
    }
  };

  if (isLoadingRepTypes) {
    return <TableSkeleton />;
  }

  return (
    <FormContainer formContext={formContext} onSuccess={onFormSubmit}>
      <Grid container spacing={2} sx={{ p: 2 }} justifyContent="center">
        {RepType?.map((item, i) => (
          <React.Fragment key={i}>
            <Grid item xs={6}>
              <MuiTextField
                name={`types[${i}].type`}
                label="Type"
                fullWidth
                disabled
                required
              />
            </Grid>
            <Grid item xs={6}>
              {/* <MuiTextField
                name={`types[${i}].value`}
                label="Value"
                fullWidth
                required
              /> */}

              <NumberField
                name={`types[${i}].value`}
                label="Value"
                prefix="$ "
                decimalScale={2}
                fixedDecimalScale={true}
                required
                max={99999999}
                // options={Task}
              />
            </Grid>
          </React.Fragment>
        ))}
      </Grid>
      <Stack direction="row" justifyContent="flex-end" mt={2}>
        <Button
          variant="contained"
          type="submit"
          color="success"
          disabled={isChangingRepTypes}
        >
          Save
        </Button>
      </Stack>
    </FormContainer>
  );
};

export default RepTypes;
