/* eslint-disable jsx-a11y/control-has-associated-label */
import { MenuItem, TextField, TextFieldProps } from "@mui/material";
import React, { createElement } from "react";
import { Controller, ControllerProps, FieldError } from "react-hook-form";
export type SelectElementProps = Omit<
  TextFieldProps,
  "name" | "type" | "onChange"
> & {
  validation?: ControllerProps["rules"];
  name: string;
  options?: any[];
  valueKey?: string;
  labelKey?: string;
  type?: "string" | "number";
  parseError?: (error: FieldError) => string;
  onChange?: (value: any) => void;
  backgroundColor?: string;
};

const Select = React.memo(
  ({
    name,
    label,
    required,
    valueKey = "value",
    labelKey = "label",
    options = [],
    parseError,
    type,
    backgroundColor,
    disabled = false,
    ...rest
  }: SelectElementProps): JSX.Element => {
    const isNativeSelect = !!rest.SelectProps?.native;
    const ChildComponent = isNativeSelect ? "option" : MenuItem;

    return (
      <Controller
        name={name}
        render={({
          field: { onBlur, onChange, value },
          fieldState: { invalid, error },
        }) => {
          // handle shrink on number input fields
          if (type === "number" && value) {
            rest.InputLabelProps = rest.InputLabelProps || {};
            rest.InputLabelProps.shrink = true;
          }
          // if (typeof value === 'object') {
          //   value = value[valueKey]; // if value is object get key
          // }
          return (
            <TextField
              fullWidth
              disabled={disabled}
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": { borderRadius: "12px" },
                  fontSize: "1rem",
                  fontFamily: "'Arial', sans-serif",
                  backgroundColor: "#f5f5f500",
                  height: "auto",
                },
                "& .MuiInputBase-input": {
                  fontSize: "1rem",
                },
                "& .MuiInputLabel-root": {
                  fontSize: "0.875rem",
                },
                "& .Mui-focused fieldset": {
                  borderColor: "#6F7E8C !important",
                },
                "& label.Mui-focused": {
                  color: "#6F7E8C",
                },
                "& .css-1ald77x.Mui-focused": {
                  color: "#6F7E8C !important",
                },
              }}
              name={name}
              label={label ? `${label}` : ""}
              value={value || ""}
              onBlur={onBlur}
              onChange={(event) => {
                let item: number | string = event.target.value;
                if (type === "number") {
                  item = Number(item);
                }
                onChange(item);
              }}
              select
              required={required}
              error={invalid}
              // eslint-disable-next-line no-nested-ternary
              helperText={
                error
                  ? typeof parseError === "function"
                    ? parseError(error as any)
                    : error.message
                  : rest.helperText
              }
              {...rest}
            >
              {isNativeSelect && <option />}
              {options.map((item: any) => {
                const value = typeof item === "object" ? item[valueKey] : item;
                const label = typeof item === "object" ? item[labelKey] : item;
                return createElement(
                  ChildComponent,
                  {
                    key: `${name}_${value}`,
                    value,
                  },
                  label
                );
              })}
            </TextField>
          );
        }}
      />
    );
  }
);

export default Select;
