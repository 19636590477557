import { Box } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid/DataGrid";
import NoResultFound from "./NoResultFound";
import TableSkeleton from "./skeleton/TableSkeleton";
import { GridPaginationModel } from "@mui/x-data-grid";

type Props = {
  column: any[];
  rows: any[];
  checkboxSelection?: boolean;
  isLoading?: boolean;
  paginationModel?: GridPaginationModel;
  onPaginationModelChange?: any;
  totalRows?: number;
  pageSizeOptions?: any[];
  sortModel?: any;
  onSortModelChange?: any;
  paginationMode?: "server" | "client";
 
};

function DataGridTable({
  column,
  rows,
  checkboxSelection = false,
  isLoading,
  paginationModel,
  onPaginationModelChange,
  totalRows,
  pageSizeOptions = [12, 20, 50],
  sortModel,
  onSortModelChange,
  paginationMode = "server",
  
  
}: Props) {
  // const CustomFooterContainer = styled(GridFooterContainer)({
  //   minHeight: "40px", // Adjust this value to change the height
  //   display: "flex",
  //   justifyContent: "flex-end",
  // });

  // function CustomFooter() {
  //   return (
  //     <CustomFooterContainer>
  //       <GridPagination />
  //     </CustomFooterContainer>
  //   );
  // }

  if (isLoading) {
    return <TableSkeleton />;
  }

  return (
    <Box
      sx={{
        // height: "calc(100vh - 220px)", // Ensure the Box has a defined height
        display: "flex",
        flexDirection: "column",
      }}
    >
      {rows?.length > 0 ? (
        <DataGrid
          rowHeight={window.innerWidth < 1420 ? 40 : 52}
          getRowId={(row) => row?._id || row?.id}
          rows={rows}
          columns={column}
          className="tableWidth"
          paginationMode={paginationMode}
          paginationModel={paginationModel}
          onPaginationModelChange={onPaginationModelChange}
          checkboxSelection={checkboxSelection}
          pageSizeOptions={pageSizeOptions}
          rowCount={totalRows}
          
          disableColumnMenu
          disableRowSelectionOnClick
          sortModel={sortModel}
          onSortModelChange={onSortModelChange}
          slotProps={{
            cell: {
              style: {
                outline: "none",
                boxShadow: "none",
              },
            },
          }}
          getRowClassName={(params) =>
            params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
          }
          sx={{
            "& .MuiDataGrid-root": {
              overflow: "hidden",
            },
            "& .MuiDataGrid-cell": {
              border: "none",
              lineHeight: "none",
              fontSize: window.innerWidth < 1420 ? "13px" : "14px",
              color: "#364152",
              fontWeight: 400,
              fontFamily: "'Poppins', sans-serif",
            },
            "& .even": {
              backgroundColor: "#F3F6FE",
            },
            "& .odd": {
              backgroundColor: "#ffffff",
            },
            "& .MuiDataGrid-columnHeaderTitleContainer": {
              fontSize: window.innerWidth < 1420 ? "14px" : "16px",
              color: "#364152",
              fontWeight: 600,
              fontFamily: "'Noto Sans Arabic', sans-serif",
            },
            "& .MuiDataGrid-virtualScroller": {
              overflowY: "auto !important", // Ensure the virtual scroller allows vertical scroll
              maxHeight: "calc(100vh - 280px)", // Ensure the max height is set correctly
            },
            border: "none",
            "--DataGrid-rowBorderColor": "none",
          }}
        />
      ) : (
        <Box sx={{ m: 2 }}>
          <NoResultFound>No data found!</NoResultFound>
        </Box>
      )}
    </Box>
  );
}

export default DataGridTable;
