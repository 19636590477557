import {
  Box,
  Button,
  DialogProps,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useState } from "react";
import BasicModal from "../../components/modal/BasicModal";
// import { Textarea } from "@mui/joy";
import useLeads from "../../../hooks/useLeads";
import moment from "moment";
interface Props extends DialogProps {
  onClose: () => void;
  data?: any;
  site?: any;
}

const MAX_LENGTH = 100;
const ViewDetailOfInteractionModal = ({
  onClose,
  data,
  site,
  ...props
}: Props) => {
  const { prospect } = useLeads(!!site?.zoho?.accessToken, false, false, data);
  const [expandedRows, setExpandedRows] = useState<Record<number, boolean>>({});

  const handleToggleShowMore = (index: number) => {
    setExpandedRows((prevExpandedRows) => ({
      ...prevExpandedRows,
      [index]: !prevExpandedRows[index], // Toggle the current row's state
    }));
  };
  return (
    <Box>
      <BasicModal
        onClose={onClose}
        size={"md"}
        title="View Prospect Interaction"
        content={
          <TableContainer>
            <Table sx={{ minWidth: 500 }}>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ fontWeight: "bold", minWidth:120 }}>
                    Interaction Date
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold",  minWidth:120 }}>
                    Contact Method
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold",  minWidth:120 }}>Details</TableCell>
                  <TableCell sx={{ fontWeight: "bold",  minWidth:120 }}>
                    Contacted By
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {prospect?.detailOfInteraction?.map(
                  (val: any, index: number) => (
                    <TableRow key={index}>
                      <TableCell>
                        {moment(val?.createdAt).format("MM/DD/YYYY")}
                      </TableCell>
                      <TableCell>{val?.contactMethod}</TableCell>
                      <TableCell>
                        {val?.details?.length > MAX_LENGTH &&
                        !expandedRows[index]
                          ? `${val?.details?.substring(0, MAX_LENGTH)}....`
                          : val?.details}
                        {val?.details?.length > MAX_LENGTH && (
                          <Button
                            onClick={() => handleToggleShowMore(index)}
                            size="small"
                            color="primary"
                            sx={{ textTransform: "capitalize" }}
                          >
                            {expandedRows[index] ? "Show Less" : "Read More"}
                          </Button>
                        )}
                      </TableCell>
                      <TableCell>
                        {val?.user?.firstName} {val?.user?.lastName}
                      </TableCell>
                    </TableRow>
                  )
                )}
              </TableBody>
            </Table>
          </TableContainer>
        }
        actions={
          <>
            <Button
              color="error"
              variant="outlined"
              sx={{ textTransform: "capitalize" }}
              onClick={() => onClose()}
            >
              Cancel
            </Button>
          </>
        }
        {...props}
      />
    </Box>
  );
};

export default ViewDetailOfInteractionModal;
