import { Box, InputAdornment, OutlinedInput } from "@mui/material";
import { IconSearch } from "@tabler/icons-react";

const SearchField = ({
  onChange,
  size = "small",
  placeholder = "Search",
  ...rest
}: any) => {
  return (
    <Box>
      <OutlinedInput
        sx={{
          fontFamily: "'Poppins', sans-serif",
          fontSize: "14px",
          fontWeight: 300,
          height: window.innerWidth < 1420 ? 32 : 40,
        }}
        onChange={onChange}
        size={size}
        placeholder={placeholder}
        startAdornment={
          <InputAdornment position="start">
            <IconSearch />
          </InputAdornment>
        }
        {...rest}
      />
    </Box>
  );
};

export default SearchField;
