import { useMutation } from "react-query";
import useAuth from "./useAuth";

/**
 * Custom hook for scraping data with a specific SID (System Identification)
 * @hook useScrappingWithSid
 * @author Sanjay
 *
 */

export default function useScrappingWithSid() {
  const { request } = useAuth();

  const { mutateAsync: scrapDataWithSid, isLoading: isSidDataLoading } =
    useMutation("/get-site-data/get-with-sid", (payload: any) =>
      request
        .post("/get-site-data/get-with-sid", payload)
        .then((response) => response?.data as any)
    );

  return {
    scrapDataWithSid,
    isSidDataLoading,
  };
}
