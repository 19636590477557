import { useMutation, useQueryClient } from "react-query";
import useAuth from "./useAuth";

/**
 * custom hook for documents
 * @hook
 * @author Tushar Sharma
 */

interface Props {
  caseId?: string;
  documentId?: string;
}

export default function useDocuments({ caseId }: Props) {
  const { request } = useAuth();
  const queryClient = useQueryClient();
  const handleRefetchUsers = () => {
    queryClient.invalidateQueries(["/cases"]);
  };


  const { mutateAsync: addCaseDocument, isLoading: isCaseDocumentAdding } =
    useMutation(
      `/cases/${caseId}/documents`,
      (payload: any) =>
        request
          .post(`/cases/${caseId}/documents`, payload)
          .then((response) => response?.data as any),
      {
        onSuccess: () => handleRefetchUsers(),
      }
    );

    const { mutateAsync: updateDocument, isLoading: updateDocumentLoading } = useMutation(
        `/cases/${caseId}/documents/documentId`,
        ({documentId,payload}:{documentId:string,payload: any}) =>
          request
            .put(`/cases/${caseId}/documents/${documentId}`,payload)
            .then((response) => response?.data),
        {
          onSuccess: () => handleRefetchUsers(),
        }
      );

  const { mutateAsync: deleteDocument, isLoading: documentLoading } = useMutation(
    `/cases/${caseId}/documents/:documentId`,
    ({documentId}:{documentId:any}) =>
      request
        .delete(`/cases/${caseId}/documents/${documentId}`)
        .then((response) => response?.data),
    {
      onSuccess: () => handleRefetchUsers(),
    }
  );
  return {
    addCaseDocument,
    isLoading:documentLoading||updateDocumentLoading||isCaseDocumentAdding,
    deleteDocument,
    updateDocument,
    
  };
}
