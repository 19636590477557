import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, DialogProps, Grid } from "@mui/material";
import { useModal } from "mui-modal-provider";
import { useCallback, useMemo } from "react";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import usePayment from "../../../hooks/usePayment";
import Select from "../../components/inputs/Select";
import BasicModal from "../../components/modal/BasicModal";
import { useSnackbarHelper } from "../../components/Snackbar";
import PreviewPdfModal from "../cases/PreviewPdfModal";

interface Props extends DialogProps {
  onClose: () => void;
  data?: any;
  setPdfUrl?: any;
}

function ViewReceiptModal({ onClose, data, ...props }: Props) {
  const { showModal } = useModal();

  const { viewReceipt, isFetchingReceipt } = usePayment();
  const showSnackbar = useSnackbarHelper();

  const initialValues = useMemo(
    () => ({
      installment: "",
    }),
    []
  );

  const validationSchema: any = useMemo(
    () =>
      Yup.object({
        installment: Yup.string().required("Required"),
      }),
    []
  );

  const installment_options = useMemo(() => {
    const options = data?.payment_spreads
      ?.filter((val: any) => val?.isPaid)
      .map((option: any, index: number) => ({
        label: `${index + 1}${index === 0 ? "st" : index === 1 ? "nd" : index === 2 ? "rd" : "th"} installment`,
        value: option._id, // Assuming each option has a unique id field
      }));

    return options?.length > 0
      ? options
      : [{ label: "No data found", value: "", disabled: true }];
  }, [data]);

  const formContext = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
  });

  const handleShowLetterPreview = useCallback(
    (Buffer: any) => {
      const modal: any = showModal(PreviewPdfModal, {
        onClose: () => modal.hide(),
        buffer: Buffer,
        receipt: true,
      });
    },
    [showModal]
  );

  const handleSubmit = async (values: any) => {
    try {
      const receipt_data = data?.payment_spreads?.find(
        (val: any) => val?._id === values?.installment
      );
      const payload = {
        totalAmount: data?.feeQuoted,
        amountPaid: receipt_data?.payment,
        totalPaidAmount: receipt_data?.totalPaid,
        balance: receipt_data?.balance,
        accountHolderName: receipt_data?.paidBy,
        accountHolderId: "",
        recieptId: receipt_data?.recieptId,
        paymentMethod: data?.type,
        entryMode: "Manual",
        paymentDate: receipt_data?.paymentMadeDate || Date.now(),
        chequeNumber: receipt_data?.chequeNumber || "",
      };

      const response = await viewReceipt(payload);
      handleShowLetterPreview(response?.data);
      onClose();
    } catch (error: any) {
      const message = error?.response?.data?.message || "An error occurred";
      showSnackbar(message, "error");
    }
    // You can also send the form data to your API or perform any other action here
  };

  return (
    <BasicModal
      formContext={formContext}
      onClose={onClose}
      onSubmit={handleSubmit}
      title={"View Receipt"}
      content={
        <Box>
          <Grid container>
            <Grid container spacing={2} rowGap={0}>
              <Grid item xs={12}>
                <Select
                  fullWidth
                  label="Select Installment"
                  name="installment"
                  required
                  options={installment_options}
                  SelectProps={{
                    MenuProps: {
                      PaperProps: {
                        style: {
                          maxHeight: 250, // Adjust this value to control the maximum height
                        },
                      },
                    },
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Box>
      }
      actions={
        <>
          <Button
            color="error"
            variant="outlined"
            onClick={onClose}
            disabled={isFetchingReceipt}
            sx={{ textTransform: "capitalize" }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="success"
            sx={{ textTransform: "capitalize" }}
            type="submit"
            disabled={isFetchingReceipt}
          >
            View Receipt
          </Button>
        </>
      }
      {...props}
    />
  );
}

export default ViewReceiptModal;
