import { s3 } from "./AwsS3";

type Props = {
  file: any;
  userId: string;
  folderName: string;
};

/**
 * Uploads an image to AWS S3.
 * @component uploadImageToS3
 * @author Sanjay
 *
 */

export const uploadImageToS3 = async ({ file, userId, folderName }: Props) => {
  const params = {
    Bucket: `${process.env.REACT_APP_AWS_BUCKET_NAME}/${folderName}`,
    Key: `${userId}/${file.name}`,
    ContentType: file.type,
    Body: file,
  };

  try {
    const data = await s3.upload(params).promise();
    return data;
  } catch (err: any) {
    throw new Error(`Upload failed: ${err.message}`);
  }
};
// export const uploadImageToS3 = ({ file, userId, folderName }: Props) => {
//   const params = {
//     Bucket: `coxlaw/${folderName}`,
//     Key: `${userId}/${file.name}`,
//     ContentType: file.type,
//     Body: file,
//   };

//   return new Promise((resolve, reject) => {
//     s3.upload(params, (err: any, data: any) => {
//       if (err) {
//         reject(err);
//       } else {
//         resolve(data);
//       }
//     });
//   });
// };
