import { AppBar, Box, Stack } from "@mui/material";
import { Outlet } from "react-router-dom";
import Header from "./Header";
import { useState } from "react";
import BasicDrawer from "../components/drawer";
import Calendar from "./Calender";
import Todos from "../pages/todos";

export default function MainLayout() {
  const [openDrawer, setOpenDrawer] = useState<string | null>(null);
  const [currentYear, setCurrentYear] = useState<number>(
    new Date().getFullYear()
  );

  return (
    <Box sx={{ display: "flex" }}>
      {/* header  */}
      <AppBar enableColorOnDark position="fixed" color="inherit" elevation={0}>
        <Header setOpenDrawer={setOpenDrawer} />
      </AppBar>

      {/* side drawer */}
      <Stack
        spacing={25}
        sx={{
          marginTop: 10,
          paddingTop: 10,
          justifyContent: "center",
          marginLeft: "20px",
          height: "calc(100vh - 180px)",
        }}
      >
        <BasicDrawer
          name="todo"
          content={<Todos />}
          buttonText={"To Do's"}
          color="#86d690"
          openDrawer={openDrawer}
          setOpenDrawer={setOpenDrawer}
        />

        <BasicDrawer
          name="calender"
          color="#ec9c99"
          content={
            <Calendar currentYear={currentYear} setOpenDrawer={setOpenDrawer} />
          }
          currentYear={currentYear}
          setCurrentYear={setCurrentYear}
          buttonText={"Parole Eligibility"}
          openDrawer={openDrawer}
          setOpenDrawer={setOpenDrawer}
        />
      </Stack>

      {/* body  */}
      <Box sx={{ marginTop: 10, width: "calc(100% - 75px)" }}>
        <Outlet />
      </Box>
    </Box>
  );
}
