import { Box, Button, DialogProps } from "@mui/material";

import useUser from "../../../../hooks/useUser";
import BasicModal from "../../../components/modal/BasicModal";
import { useSnackbarHelper } from "../../../components/Snackbar";

interface Props extends DialogProps {
  onClose: () => void;
}

const LogoutModal = ({ onClose, ...props }: Props) => {
  const { userLogout, isLoggingOut } = useUser();
  const showSnackbar = useSnackbarHelper();

  const onSubmit = async () => {
    try {
      const data = await userLogout();

      const message = data?.message || "Success";

      showSnackbar(message, "success");

      onClose();
    } catch (error: any) {
      const message = error?.response?.data?.message || "An error occurred";

      showSnackbar(message, "error");
    }
  };

  return (
    <Box>
      <BasicModal
        onClose={onClose}
        title="Logout"
        content="Do you really want to logout?"
        actions={
          <>
            <Button
              variant="contained"
              color="error"
              sx={{ textTransform: "capitalize" }}
              onClick={onSubmit}
              disabled={isLoggingOut}
            >
              Logout
            </Button>
            <Button
              color="error"
              variant="outlined"
              sx={{ textTransform: "capitalize" }}
              onClick={() => onClose()}
            >
              Cancel
            </Button>
          </>
        }
        {...props}
      />
    </Box>
  );
};

export default LogoutModal;
