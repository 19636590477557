import { Button } from "@mui/material";

const BasicButton = ({
  color = "success",
  variant = "contained",
  onClick = () => {},
  label = "Button",
  type = "button",
  disabled = false,
  size = "medium",
  endIcon,
  ...rest
}: any) => {
  return (
    <Button
      endIcon={endIcon}
      size={size}
      type={type}
      variant={variant}
      color={color}
      onClick={onClick}
      disabled={disabled}
      {...rest}
    >
      {label}
    </Button>
  );
};

export default BasicButton;
