import { Button, Grid, Stack } from "@mui/material";
import FormContainer from "../../components/FormContainer";
import * as Yup from "yup";
import Password from "../../components/Password";
import PasswordStrength from "../../components/PasswordStrength";
import SubCard from "../../components/cards/SubCard";
import useUser from "../../../hooks/useUser";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSnackbarHelper } from "../../components/Snackbar";

const ChangePassword = ({ userId }: any) => {
  const { changePassword, isChangingPassword } = useUser(userId);
  const showSnackbar = useSnackbarHelper();

  const initialValues = {
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  };

  const validationSchema = Yup.object().shape({
    currentPassword: Yup.string().max(255).required("Required"),
    newPassword: Yup.string()
      .max(255)
      .required("Required")
      .matches(
        /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
        "Password must contain at least 8 characters, one uppercase, one number and one special case character"
      ),
    confirmPassword: Yup.string()
      .required("Please confirm your password")
      .oneOf([Yup.ref("newPassword")], "Passwords must match"),
  });

  type FormValues = Yup.InferType<typeof validationSchema>;

  const formContext = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
  });

  const { reset } = formContext;

  const onFormSubmit = async (values: FormValues) => {
    try {
      const data: any = await changePassword({
        currentPassword: values?.currentPassword,
        newPassword: values?.newPassword,
      });

      const message = data?.message || "Success";

      showSnackbar(message, "success");

      reset();
    } catch (error: any) {
      const message = error?.response?.data?.message || "An error occurred";

      showSnackbar(message, "error");
    }
  };

  return (
    <FormContainer formContext={formContext} onSuccess={onFormSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={11}>
          <SubCard title={"Change Password"}>
            <Grid container justifyContent="center" spacing={2} sx={{ mb: 2 }}>
              <Grid item xs={11}>
                <Password
                  name="currentPassword"
                  label="Current Password"
                  required
                  fullWidth
                  autoComplete="current-password"
                />
              </Grid>{" "}
            </Grid>
            <Grid container justifyContent="center" spacing={2} sx={{ mb: 2 }}>
              <Grid item xs={11}>
                <Password
                  name="newPassword"
                  label="New Password"
                  required
                  fullWidth
                  autoComplete="new-password"
                />
              </Grid>{" "}
            </Grid>
            <Grid container justifyContent="center" spacing={2} sx={{ mb: 2 }}>
              <Grid item xs={11}>
                <Password
                  name="confirmPassword"
                  label="Confirm Password"
                  required
                  fullWidth
                  autoComplete="new-password"
                />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <PasswordStrength />
            </Grid>
            <Grid container spacing={2} sx={{ mt: 2 }}>
              <Grid item xs={12}>
                <Stack direction="row" justifyContent="flex-end" mr={3}>
                  <Button
                    type="submit"
                    color="success"
                    variant="contained"
                    disabled={isChangingPassword}
                  >
                    Update Password
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </SubCard>
        </Grid>
      </Grid>
    </FormContainer>
  );
};

export default ChangePassword;
