import { Box, FormControl, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import useLabels from "../../utils/apiTranslations/useLabels";
// import { useIntl } from "react-intl";

interface StringColorProps {
  id?: string;
  label?: string;
  color?: string;
  primary?: string;
  secondary?: string;
}

const PasswordStrength = () => {
  const { strengthColor, strengthIndicator } = useLabels();
  const [strength, setStrength] = useState(0);
  //   const intl = useIntl();
  const [level, setLevel] = useState<StringColorProps>();

  const { control } = useFormContext();
  const password = useWatch({
    control,
    name: "password",
  });

  useEffect(() => {
    const changePassword = (value: any) => {
      const temp = strengthIndicator(value);
      setStrength(temp);

      setLevel(strengthColor(temp));
    };

    if (password) {
      changePassword(password);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [password]);

  const getFormattedLabelValue = (value?: string) => {
    switch (value) {
      case "Poor": {
        return "Poor";
      }
      case "Weak": {
        return "Weak";
      }
      case "Normal": {
        return "Normal";
      }
      case "Good": {
        return "Good";
      }
      case "Strong": {
        return "Strong";
      }
      case "Pauvre": {
        return "Poor";
      }
      case "Faible": {
        return "Weak";
      }
      case "Normale": {
        return "Normal";
      }
      case "Bien": {
        return "Good";
      }
      case "Fort": {
        return "Strong";
      }
      default: {
        return "";
      }
    }
  };

  if (strength !== 0) {
    return (
      <FormControl fullWidth>
        <Box sx={{ mb: 2 }}>
          <Grid container spacing={2} alignItems="center">
            <Grid item>
              <Box
                style={{ backgroundColor: level?.color }}
                sx={{
                  width: 85,
                  height: 8,
                  borderRadius: "7px",
                }}
              />
            </Grid>
            <Grid item>
              <Typography variant="subtitle1" fontSize="0.75rem">
                {getFormattedLabelValue(level?.label)}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </FormControl>
    );
  }

  return null;
};

export default PasswordStrength;
