import { useMutation, useQuery } from "react-query";
import useAuth from "./useAuth";

/**
 * Custom hook to manage parole eligibility cases and update parole decisions.
 * @hook useParoleEligibility
 * @author Priyanshu
 */

export default function useParoleEligibility(caseId?: string) {
  const { request } = useAuth();

  const {
    data: casesData,
    refetch: refetchCases,
    isLoading: isLoadingCases,
    isRefetching,
  } = useQuery("/cases/paroleEligibility", () =>
    request.get("/cases/paroleEligibility").then((response) => response?.data)
  );

  const {
    mutateAsync: updateParoleDecision,
    isLoading: isUpdatingParoleDecision,
  } = useMutation(
    `/cases/${caseId}/updateParoleDecision`,
    (payload: any) =>
      request
        .put(`/cases/${caseId}/updateParoleDecision`, payload)
        .then((response) => response?.data),
    {
      onSuccess: () => refetchCases(),
    }
  );

  return {
    cases: casesData?.data,
    refetchCases,
    isRefetching,
    updateParoleDecision,
    isUpdatingParoleDecision,
    isLoadingCases,
  };
}
