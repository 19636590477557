import { Grid, Skeleton } from "@mui/material";

const TableSkeleton = () => {
  return (
    <Grid container direction="column">
      <Grid item p={2}>
        <Skeleton variant="rectangular" height={30} />
      </Grid>
      <Grid item p={2}>
        <Skeleton variant="rectangular" height={30} />
      </Grid>
      <Grid item p={2}>
        <Skeleton variant="rectangular" height={30} />
      </Grid>
      <Grid item p={2}>
        <Skeleton variant="rectangular" height={30} />
      </Grid>
      <Grid item p={2}>
        <Skeleton variant="rectangular" height={30} />
      </Grid>
      <Grid item p={2}>
        <Skeleton variant="rectangular" height={30} />
      </Grid>
      <Grid item p={2}>
        <Skeleton variant="rectangular" height={30} />
      </Grid>
    </Grid>
  );
};

export default TableSkeleton;
