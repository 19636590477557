import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Typography } from "@mui/material";
import moment from "moment";

export default function BasicTable({ rows, columns }: any) {
  const cells = columns?.map((column: any) => column.value);

  return (
    <TableContainer component={Paper} elevation={0} sx={{ overflowX: "auto" }}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            {columns?.map((column: any, index: number) => (
              <TableCell key={index}>
                <Typography
                  width={
                    column?.value === "offenseDate" ||
                    column?.value === "selectOffence" ||
                    column?.value === "sentenceDate" ||
                    column?.value === "county"
                      ? 100
                      : 148
                  }
                  variant="h5"
                >
                  {column?.label}
                </Typography>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row: any, index: number) => (
            <TableRow
              key={index}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              {cells?.map((cell: string, index: number) => {
                return (
                  <TableCell key={index}>
                    {cell === "selectOffence" ? (
                      row?.select
                    ) : (
                      <Typography variant="h6">
                        {cell === "offenseDate" || cell === "sentenceDate"
                          ? moment(row[cell]).format("MM/DD/YYYY")
                          : row[cell]}
                      </Typography>
                    )}
                  </TableCell>
                );
              })}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
