import { Button, Divider, Grid, Stack } from "@mui/material";
import { useMemo } from "react";
import MuiTextField from "../../components/inputs/MuiTextField";
import FormContainer from "../../components/FormContainer";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import SubCard from "../../components/cards/SubCard";
import { useSnackbarHelper } from "../../components/Snackbar";
import PhoneNumber from "../../components/inputs/PhoneNumber";

type FormValues = {
  firstName: string;
  middleName?: string;
  lastName: string;
  phoneNumber?: string;
  mobileNumber: string;
  email: string;
};

const SystemSetting = ({ user, updateUser, isUpdatingUser }: any) => {
  // const { userId } = useAuth();
  // const { isAdmin } = useUser(userId);
  const showSnackbar = useSnackbarHelper();
  const emailRegex =
    /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.(com|org|net|edu|gov|mil|co|io|info|biz|name|me|mobi|ly|ai|us|uk|ca|au|de|fr|jp|cn|ru|br|in|za|nl|se|no|fi|dk|it|es|pt|ch|at|be|pl|gr|cz|ro|hu|il|mx|ar|cl|nz|kr|tr|tw|hk|sg|my|id|ph|th|vn)$/;
  const phoneRegExp = /^\+\d{1,3} \(\d{3}\) \d{3}-\d{4}$/;

  const validationSchema = Yup.object({
    firstName: Yup.string()
      .matches(
        /^[a-zA-Z\s]*$/,
        "First name should not contain symbols or numbers"
      )
      .required("Required"),
    middleName: Yup.string().matches(
      /^[a-zA-Z\s]*$/,
      "Middle name should not contain symbols or numbers"
    ),
    lastName: Yup.string()
      .matches(
        /^[a-zA-Z\s]*$/,
        "Last name should not contain symbols or numbers"
      )
      .required("Required"),
    email: Yup.string()
      .required("Required")
      .email("Invalid Email")
      .transform((value, originalValue) => originalValue.toLowerCase())
      .matches(emailRegex, "Must be a valid email"),
    phoneNumber: Yup.string(),
    mobileNumber: Yup.string()
      .required("Required")
      .matches(phoneRegExp, "Mobile number is not valid"),
  });

  const initialValues: FormValues = useMemo(
    () => ({
      firstName: user?.firstName || "",
      middleName: user?.middleName || "",
      lastName: user?.lastName || "",
      phoneNumber: user?.phoneNumber || "",
      mobileNumber: user?.mobileNumber || "",
      email: user?.email || "",
    }),
    [user]
  );

  const formContext = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
  });

  const onFormSubmit = async (values?: any) => {
    try {
      const data = await updateUser(values);

      const message = data?.message || "Success";

      showSnackbar(message, "success");
    } catch (error: any) {
      const message = error?.response?.data?.message || "An error occurred";

      showSnackbar(message, "error");
    }
  };

  return (
    <FormContainer formContext={formContext} onSuccess={onFormSubmit}>
      <SubCard
        title={"User Profile"}
        sx={{
          "& .changeRadius .MuiOutlinedInput-notchedOutline": {
            borderRadius: "12px !important",
          },
        }}
      >
        <Grid
          container
          spacing={2}
          sx={{
            mb: 3,
            mt: 1,
          }}
        >
          <Grid item xs={12} md={6}>
            <MuiTextField
              name="firstName"
              label="First Name"
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <MuiTextField name="middleName" label="Middle Name" fullWidth />
          </Grid>
        </Grid>

        <Grid container spacing={2} sx={{ mb: 3 }}>
          <Grid item xs={12} md={6}>
            <MuiTextField name="lastName" label="Last Name" fullWidth />
          </Grid>
          <Grid item xs={12} md={6}>
            <MuiTextField
              name="email"
              type="email"
              label="Email"
              // disabled={!isAdmin && !user?.canChangeEmail}
              fullWidth
              required
            />
          </Grid>
        </Grid>
        <Grid
          container
          spacing={2}
          sx={{
            mb: 3,
          }}
        >
          <Grid item xs={12} md={6}>
            <PhoneNumber
              fullWidth
              label="Phone Number"
              name="phoneNumber"
              className="changeRadius"
              // disabled={!isAdmin && !user?.canChangePhoneNumber}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <PhoneNumber
              fullWidth
              label="Mobile Number"
              name="mobileNumber"
              className="changeRadius"
              required
              // disabled={!isAdmin && !user?.canChangeMobileNumber}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ mt: 3 }}>
          <Grid item xs={12}>
            <Divider />
          </Grid>
        </Grid>
        <Stack direction="row" justifyContent="flex-end" mt={2}>
          <Button
            variant="contained"
            type="submit"
            color="success"
            disabled={isUpdatingUser}
          >
            Save
          </Button>
        </Stack>
      </SubCard>
    </FormContainer>
  );
};

export default SystemSetting;
