import { Grid, Stack, Typography, useTheme } from "@mui/material";
import laywerImage from "../../../assets/images/laywerImage.png";

// ==============================|| AUTHENTICATION CARD WRAPPER ||============================== //

const AuthCardWrapper = ({ children }: any) => {
  const theme = useTheme();

  return (
    <Grid
      container
      sx={{
        height: "100vh",
        width: "100vw",
        p: 0,
        m: 0,
        boxSizing: "border-box",
        overflow: "hidden",
      }}
    >
      <Grid
        item
        xs={false}
        sm={5}
        md={6}
        sx={{
          backgroundImage: `url(${laywerImage})`,
          backgroundRepeat: "no-repeat",
          backgroundColor: (t: any) =>
            t.palette.mode === "light"
              ? t.palette.grey[50]
              : t.palette.grey[900],
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      />
      <Grid
        item
        xs={12}
        sm={6}
        sx={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          overflow: "auto",
        }}
      >
        <Stack
          sx={{
            justifyContent: "flex-end",
            alignItems: "flex-end",
            pt: 4,
            pr: "18.5%",
          }}
        ></Stack>

        {children}
        <Typography textAlign="center" sx={{ mt: "auto", p: theme.spacing(2) }}>
          {new Date().getFullYear()} &#169; CoxLaw, All Rights Reserved
        </Typography>
      </Grid>
    </Grid>
  );
};

export default AuthCardWrapper;
