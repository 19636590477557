import { Box, Button, DialogProps } from "@mui/material";
import BasicModal from "../../components/modal/BasicModal";
import { useSnackbarHelper } from "../../components/Snackbar";
import useCases from "../../../hooks/useCases";

interface Props extends DialogProps {
  onClose: () => void;
  id: string;
}

const DeleteCaseModal = ({ onClose, id, ...props }: Props) => {
  const { deleteCase, isDeleteCaseLoading } = useCases(id);

  const showSnackbar = useSnackbarHelper();

  const onSubmit = async () => {
    try {
      const data = await deleteCase();

      const message = data?.message || "Success";

      showSnackbar(message, "success");

      onClose();
    } catch (error: any) {
      const message = error?.response?.data?.message || "An error occurred";

      showSnackbar(message, "error");
    }
  };

  return (
    <Box>
      <BasicModal
        onClose={onClose}
        title="Delete Case"
        content="Do you really want to delete this case? "
        actions={
          <>
            <Button
              variant="contained"
              color="error"
              sx={{ textTransform: "capitalize" }}
              onClick={onSubmit}
              disabled={isDeleteCaseLoading}
            >
              Delete
            </Button>
            <Button
              color="error"
              variant="outlined"
              sx={{ textTransform: "capitalize" }}
              onClick={() => onClose()}
            >
              Cancel
            </Button>
          </>
        }
        {...props}
      />
    </Box>
  );
};

export default DeleteCaseModal;
