import { Box, Button, DialogProps } from "@mui/material";
import BasicModal from "../../components/modal/BasicModal";
import { useSnackbarHelper } from "../../components/Snackbar";

interface Props extends DialogProps {
  onClose: () => void;
  id: string;
  deleteContact: any;
  isLoading: boolean;
}

const DeleteContactModal = ({
  onClose,
  id,
  deleteContact,
  isLoading,
  ...props
}: Props) => {
  const showSnackbar = useSnackbarHelper();
  const onSubmit = async () => {
    try {
      const response = await deleteContact(id);
      if (response?.status === 200) {
        const message = "Contact deleted successfully";
        showSnackbar(message, "success");
      }

      onClose();
    } catch (error: any) {
      const message = error?.response?.data?.message || "An error occurred";

      showSnackbar(message, "error");
    }
  };

  return (
    <Box>
      <BasicModal
        onClose={onClose}
        title="Delete Contact"
        content="Do you really want to delete this contact? "
        actions={
          <>
            <Button
              variant="contained"
              color="error"
              sx={{ textTransform: "capitalize" }}
              onClick={onSubmit}
              disabled={isLoading}
            >
              Delete
            </Button>
            <Button
              color="error"
              variant="outlined"
              sx={{ textTransform: "capitalize" }}
              onClick={() => onClose()}
            >
              Cancel
            </Button>
          </>
        }
        {...props}
      />
    </Box>
  );
};

export default DeleteContactModal;
