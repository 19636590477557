import { useMutation } from "react-query";
import { useNavigate } from "react-router";
import useAuth from "./useAuth";

// Type definitions for payloads and responses
type PasswordReset = {
  email: string;
};

type ResetResponse = {
  url: string;
  message: string;
};

type passwordChange = {
  email: string;
  newPassword: string;
  challenge: string;
  isCreatingNewPassword: boolean;
};
type ChangeResponse = {
  message: string;
  token: string;
  isCreatingNewPassword: boolean;
};

/**
 * Custom hook for handling password reset and password change.
 * @hook usePasswordReset
 * @author Rajni
 *
 */

export default function usePasswordReset() {
  const { request } = useAuth();
  const navigate = useNavigate();

  const { mutateAsync: passwordReset, isLoading } = useMutation(
    ({ email }: PasswordReset) =>
      request
        .post(`/passwordReset?email=${email}`)
        .then((response) => response?.data as ResetResponse),
    {
      onSuccess: () => {
        console.log("Link sent to email successfully");
      },
    }
  );

  const { mutateAsync: passwordChange, isLoading: isPasswordLoading } =
    useMutation(
      (payload: passwordChange) =>
        request
          .post("/passwordChange", payload)
          .then((response) => response?.data as ChangeResponse),
      {
        onSuccess: () => {
          navigate("/login");
        },
      }
    );

  //returns An object containing methods and loading states for password reset and change functionalities.
  return {
    passwordReset,
    isPasswordLoading,
    passwordChange,
    isLoading,
  };
}
