import {
  Box,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import Pagination from "../Pagination";
import { useState } from "react";
import moment from "moment";
import TableSkeleton from "../skeleton/TableSkeleton";
import NoResultFound from "../NoResultFound";

const CustomTableRow = styled(TableRow)(() => ({
  height: "40px",
}));

const CustomTableCell = styled(TableCell)(() => ({
  paddingTop: 0,
  paddingBottom: 0,
}));

const BasicTableContainer = ({
  rows,
  columns,
  id,
  isLoading,
  noResultLabel = "No data found!",
}: any) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  // Sliced rows based on pagination
  const paginatedRows = rows?.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  const getRowValue = (column: any, row: any, index: number) => {
    const id = column?.id;
    const actions = column?.actions;

    switch (id) {
      case "sno":
        return index + 1;
      case "actions":
        return actions({ workflowId: id, ...row });
      default:
        if (column?.isDate) {
          return moment(row[column?.id]).format("MM/DD/YYYY, h:mm A");
        }

        return row[column?.id]?.length > 70
          ? row[column?.id]?.substring(0, 70) + "...."
          : row[column?.id];
    }
  };

  if (isLoading) {
    return <TableSkeleton />;
  }

  return (
    <Box my={1}>
      {!!paginatedRows?.length ? (
        <TableContainer sx={{ maxHeight: 300 }}>
          <Table
            sx={{
              backgroundColor: "#d3d3d347",
            }}
          >
            <TableHead
              sx={{
                position: "sticky",
                top: 0,
                backgroundColor: "#ec9c99",
                zIndex: 1,
              }}
            >
              <CustomTableRow>
                {columns?.map((column: any) => (
                  <CustomTableCell
                    key={column?.id}
                    sx={{ minWidth: column?.minWidth }}
                  >
                    <Typography variant="h5">{column?.label}</Typography>
                  </CustomTableCell>
                ))}
              </CustomTableRow>
            </TableHead>
            <TableBody>
              {paginatedRows?.map((row: any, i: number) => (
                <CustomTableRow key={i}>
                  {columns?.map((column: any) => (
                    <CustomTableCell key={column?.id}>
                      {getRowValue(column, row, i)}
                    </CustomTableCell>
                  ))}
                </CustomTableRow>
              ))}
            </TableBody>
            <TableFooter>
              <CustomTableRow>
                <CustomTableCell colSpan={6}>
                  <Pagination
                    component="div"
                    count={rows?.length || 0} // Total number of rows
                    page={page}
                    setPage={setPage}
                    rowsPerPage={rowsPerPage}
                    setRowsPerPage={setRowsPerPage}
                    rowsPerPageOptions={[5, 10, 25]}
                  />
                </CustomTableCell>
              </CustomTableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      ) : (
        <NoResultFound>{noResultLabel}</NoResultFound>
      )}
    </Box>
  );
};

export default BasicTableContainer;
