import { Card, CardHeader, Divider } from "@mui/material";
import CardContent from "@mui/material/CardContent";
import FormContainer from "../FormContainer";

type CardProps = {
  title: any;
  actions?: any;
  content: any;
  formContext?: any;
  onSubmit?: any;
  allowFormContainer?: boolean;
  subheader?: any;
};

/**
 * A customizable card component that can optionally wrap its content in a form container.
 * @component MainCard
 * @author Priyanshu
 *
 */
export default function MainCard({
  title,
  actions,
  content,
  formContext,
  onSubmit,
  allowFormContainer = false,
  subheader,
}: CardProps) {
  return (
    <>
      {allowFormContainer ? (
        <FormContainer formContext={formContext} onSuccess={onSubmit}>
          <Card
            elevation={0}
            sx={{
              borderRadius: 3,
            }}
          >
            <CardHeader title={title} subheader={subheader} action={actions} />

            <Divider />
            <CardContent>{content}</CardContent>
          </Card>
        </FormContainer>
      ) : (
        <Card
          elevation={0}
          sx={{
            borderRadius: 3,
          }}
        >
          <CardHeader
            // sx={{ margin: 0, padding: 1.5, ml: 1.5 }}
            title={title}
            action={actions}
          />

          <Divider />
          <CardContent>{content}</CardContent>
        </Card>
      )}
    </>
  );
}
