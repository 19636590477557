import {
  Box,
  Button,
  DialogProps,
  Grid,
  styled,
  TextField,
} from "@mui/material";

import { contactTypes } from "../../../types/contactTypes";
import BasicModal from "../../components/modal/BasicModal";

import { yupResolver } from "@hookform/resolvers/yup";
import dayjs from "dayjs";
import { useMemo } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import { LETTERS } from "../../../constants/MenuSelection";
import useLetters from "../../../hooks/useLetters";
import Select from "../../components/inputs/Select";
import { useSnackbarHelper } from "../../components/Snackbar";

interface Props extends DialogProps {
  onClose: () => void;
  caseId: string;
  data?: contactTypes;
  handleShowLetterPreview?: any;
}

const CustomTextField = styled(TextField)({
  "& .MuiInputBase-input": {
    "&::-webkit-scrollbar": {
      width: "8px", // Change this value to adjust the scrollbar width
    },
    "&::-webkit-scrollbar-track": {
      background: "#f1f1f1",
      borderRadius: "10px",
    },
    "&::-webkit-scrollbar-thumb": {
      background: "#888",
      borderRadius: "10px",
    },
    "&::-webkit-scrollbar-thumb:hover": {
      background: "#555",
    },
  },
});

function SelectLetterModal({
  onClose,
  caseId,
  data,
  handleShowLetterPreview,
  ...props
}: Props) {
  const { addCaseLetter, getLetterData, isLetterLoading } = useLetters({
    caseId,
  });
  const { user } = useSelector((state: any) => state.user);
  const showSnackbar = useSnackbarHelper();
  // const { showModal } = useModal();

  const initialValues = useMemo(
    () => ({
      mode: "email",
      title: "",
    }),
    []
  );

  const validationSchema: any = useMemo(
    () => Yup.object({ title: Yup.string().required("Required") }),
    []
  );

  // const handleShowLetterPreview = useCallback(
  //   (Buffer: any) => {
  //     const modal: any = showModal(PreviewPdfModal, {
  //       onClose: () => modal.hide(),
  //       buffer: Buffer,
  //     });
  //   },
  //   [showModal]
  // );

  const formContext = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
  });

  const { watch } = formContext;
  const title = watch("title");

  const handleSubmit = async (values?: any) => {
    values.type = "Letter";
    values.docuSignInfo = "";
    values.date = dayjs(new Date());
    values.createdBy = `${user?.userId}`;
    try {
      if (values.mode === "print") {
        const response = await getLetterData({
          template: values?.title,
          sendMail: false,
        });
        const Buffer = response?.data;

        // Convert Base64 to Uint8Array
        const byteCharacters = atob(Buffer);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);

        // Create a Blob from the Uint8Array
        const blob = new Blob([byteArray], { type: "application/pdf" });
        const blobUrl = URL.createObjectURL(blob);

        // Create an iframe to display the PDF
        const iframe: any = document.createElement("iframe");
        iframe.style.position = "absolute";
        iframe.style.width = "0";
        iframe.style.height = "0";
        iframe.style.border = "none";
        iframe.src = blobUrl;

        // Append the iframe to the body
        document.body.appendChild(iframe);

        // Trigger print dialog
        iframe.onload = () => {
          iframe.contentWindow?.focus();
          iframe.contentWindow?.print();
          // Optionally, remove the iframe after printing
          iframe.onafterprint = () => {
            document.body.removeChild(iframe);
            URL.revokeObjectURL(blobUrl); // Clean up object URL
          };
        };
      } else if (values?.mode === "email") {
        await getLetterData({
          template: title,
          sendMail: true,
        });
      }
      const response = await addCaseLetter(values);
      const message = response?.message || "Success";
      showSnackbar(message, "success");
      onClose();
    } catch (err: any) {
      console.log(err?.response?.data?.message);
    }
  };

  const handleViewLetter = async (template: any) => {
    try {
      const response = await getLetterData({
        template: template,
        sendMail: false,
      });
      handleShowLetterPreview(response?.data);
    } catch (error: any) {
      const message = error?.response?.data?.message || "An error occurred";
    }
  };

  return (
    <BasicModal
      formContext={formContext}
      onSubmit={handleSubmit}
      onClose={onClose}
      title={"Select Letter"}
      content={
        <Box>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <Select
                label="Mode"
                name="mode"
                options={[
                  { label: "Email", value: "email" },
                  { label: "Print", value: "print" },
                ]}
                fullWidth
              />
            </Grid>
            <Grid item xs={9}>
              <Select
                label="Select Letter"
                name="title"
                options={LETTERS}
                fullWidth
              />
            </Grid>
          </Grid>
        </Box>
      }
      actions={
        <>
          <Button
            color="error"
            variant="outlined"
            onClick={onClose}
            disabled={isLetterLoading}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="success"
            sx={{ textTransform: "capitalize" }}
            type="submit"
            disabled={!!!title || isLetterLoading}
          >
            Generate
          </Button>
          <Button
            variant="contained"
            sx={{ textTransform: "capitalize" }}
            disabled={!!!title || isLetterLoading}
            onClick={() => handleViewLetter(title)}
          >
            Preview
          </Button>
        </>
      }
      {...props}
    />
  );
}
export default SelectLetterModal;
