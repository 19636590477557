import { Stack, Box, Grid, Typography } from "@mui/material";
import laywerLogo from "../../../../assets/images/laywerLogo.png";

import AuthCardWrapper from "../../../layouts/Wrappers/AuthCardWrapper";
import JWTResetPassword from "../../../components/authentication/JWTResetPassword";

export default function CreatePassword() {
  return (
    <AuthCardWrapper>
      <Grid
        container
        spacing={1}
        direction={"column"}
        sx={{
          display: "flex",
          justifyContent: "center",
          height: "100%",
          alignItems: "center",
        }}
      >
        <Box
          component="img"
          alt="The house from the offer."
          src={`${laywerLogo}`}
          width={"30%"}
          sx={{ alignSelf: "center", mb: 8 }}
        />
        <Stack sx={{ width: "50%", height: "fit-content" }} spacing={2}>
          <Grid item xs={12}>
            <Stack alignItems="flex-start" justifyContent="center" spacing={2}>
              <Typography color="black" gutterBottom variant={"h2"}>
                Create Password
              </Typography>
              <Typography
                variant="caption"
                fontSize="16px"
                textAlign="left"
                sx={{ pl: 0.5 }}
              >
                Please set your password
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <JWTResetPassword isCreatingNewPassword={true} />
          </Grid>
        </Stack>
      </Grid>
    </AuthCardWrapper>
  );
}
