import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";

/**
 * AuthGuard component to protect routes based on user authentication status.
 * @component AuthGuard
 * @author Priyanshu
 *
 */

const AuthGuard = ({ children }: any) => {
  const { user } = useSelector((state: any) => state.user);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    // If user is authenticated, redirect to "/cases"
    if (user) {
      return navigate("/cases");
    }
    if (!user && location.pathname === "/") {
      return navigate("/cases");
    }
  }, [user, navigate, location]);

  return children;
};

export default AuthGuard;
