import DescriptionIcon from "@mui/icons-material/Description";
import SendIcon from "@mui/icons-material/Send";
import {
  Box,
  Grid,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { IconChevronsRight } from "@tabler/icons-react";
import { useModal } from "mui-modal-provider";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import { v4 as uuidv4 } from "uuid";

//components
import useAuth from "../../../hooks/useAuth";
import useCases from "../../../hooks/useCases";
import useDocuSign from "../../../hooks/useDocuSign";
import useNotifications from "../../../hooks/useNotifications";
import useUser from "../../../hooks/useUser";
import MainCard from "../../components/cards/MainCard";
import TableSkeleton from "../../components/skeleton/TableSkeleton";
import NotificationModal from "../../layouts/Header/Notification/NotificationModal";
import Activities from "./Activities";
import CaseDetails from "./CaseDetails";
import CaseInfo from "./CaseInfo";
import CriminalHistory from "./CriminalHistory";
import Documents from "./Documents";
import InmateInfo from "./InmateInfo";
import Notes from "./Notes";
import ResendAgreement from "./ResendAgreement";
import SupportBilling from "./supportBilling/SupportBilling";
import TimeSpent from "./TimeSpent";
import ViewRepAgreementModal from "./ViewRepAgreementModal";
// import Message from "./Messages";
import Messages from "../sms/Messages";

const tabsPath = [
  { path: "InmateInfo", value: 0 },
  { path: "SupportBilling", value: 1 },
  { path: "activities", value: 2 },
  { path: "TimeSpent", value: 3 },
  { path: "CriminalHistory", value: 4 },
  { path: "CaseDetails", value: 5 },
  { path: "Notes", value: 6 },
  { path: "Documents", value: 7 },
  { path: "Messages", value: 8 },
];

const Case = () => {
  const [isLoadingRepAgreement, setLoadingRepAgreement] = useState(false);
  const caseId = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const params = new URLSearchParams(location.search);

  const notificationId: any = params.get("id");
  const tab: any = params.get("tab");

  const { userId } = useAuth();
  const { isStaff } = useUser(userId);
  const { showModal } = useModal();

  const { notification, isLoadingNotification, readNotification } =
    useNotifications(notificationId);

  const {
    caseData,
    updateCase,
    updateOffenceHistory,
    isCaseUpdating,
    isCaseLoading,
    refetchCase,
  } = useCases(caseId?.id);

  const { isLoadingDocuSignMail } = useDocuSign();

  const [tabValue, setTabValue] = React.useState(
    tabsPath.find((option: any) => option?.path === tab)?.value || 0
  );

  // const envelopeId = caseData?.result?.envelopeId;
  const docuSignStatus = caseData?.result?.docuSignStatus;
  const docuSignUrl = caseData?.result?.docuSignUrl;
  const isExistingClient = caseData?.result?.isExistingClient;

  const hideRoster = useCallback(() => setIsVisible(false), []);
  const showRoster = useCallback(() => setIsVisible(true), []);
  const [isVisible, setIsVisible] = useState(true);

  const contacts = useMemo(() => {
    const contact = caseData?.result?.contactId;
    const caseUniqueID = caseData?.result?.caseId;

    const supportContacts = contact?.phone?.map((p: any) => ({
      id: uuidv4(),
      name: `${contact?.firstName} ${contact?.lastName}`,
      phone: p.phone,
      isAdditionalSupport: false,
      caseUniqueID,
      caseId: contact?.caseId,
      contactId: contact?._id,
    }));

    const additionalContactName = `${contact?.additionalSupportContact?.firstName} ${contact?.additionalSupportContact?.lastName}`;

    const additionalContacts = contact?.additionalSupportContact?.phone.map(
      (p: any) => ({
        id: uuidv4(),
        name: additionalContactName,
        phone: p.phone,
        isAdditionalSupport: true,
        caseUniqueID,
        caseId: contact?.caseId,
        contactId: contact?._id,
      })
    );

    return supportContacts
      ?.concat(additionalContacts)
      ?.filter((contact: any) => !!contact?.phone);
  }, [caseData]);

  const handleNotification = React.useCallback(
    (notification?: any) => {
      const modal: any = showModal(NotificationModal, {
        onClose: async () => {
          modal.hide();
          await readNotification({ notificationId: notification?._id });
        },
        notification,
      });
    },
    [showModal, readNotification]
  );

  useEffect(() => {
    if (notificationId && notification && !notification?.isRead) {
      handleNotification(notification);
    }
    // eslint-disable-next-line
  }, [handleNotification, isLoadingNotification]);

  const isDocuSignPending = caseData?.result?.docuSignStatus === "PENDING";

  const isCaseClosed = useMemo(
    () => caseData?.result?.caseStatus === "closed",
    [caseData?.result?.caseStatus]
  );

  const isAgreementNotSigned = useMemo(
    () =>
      caseData?.result?.docuSignStatus === "PENDING" ||
      caseData?.result?.docuSignStatus === "NOT SIGNED",
    [caseData?.result?.docuSignStatus]
  );

  const TABS = [
    {
      label: "Inmate Info",
      path: "InmateInfo",
      value: 0,
      content: (
        <InmateInfo
          caseData={caseData}
          inmate={caseData?.result?.contactId?.inmate}
          isDocuSignPending={isDocuSignPending}
          updateOffenceHistory={updateOffenceHistory}
        />
      ),
    },
    {
      label: "Support/Billing",
      path: "SupportBilling",
      value: 1,
      content: (
        <SupportBilling
          caseData={caseData?.result}
          isCaseClosed={isCaseClosed}
          isAgreementNotSigned={isAgreementNotSigned}
          isExistingClient={isExistingClient}
        />
      ),
    },
    {
      label: "Activities",
      path: "activities",
      value: 2,
      content: <Activities caseData={caseData?.result} />,
    },
    {
      label: "Time Spent",
      path: "TimeSpent",
      value: 3,
      content: <TimeSpent caseData={caseData?.result} />,
    },
    {
      label: "Criminal History",
      path: "CriminalHistory",
      value: 4,
      content: <CriminalHistory caseData={caseData?.result} />,
    },
    {
      label: "Case Details",
      path: "CaseDetails",
      value: 5,
      content: (
        <CaseDetails
          caseData={caseData?.result}
          refetchCase={refetchCase}
          isCaseLoading={isCaseLoading}
        />
      ),
    },
    {
      label: "Notes",
      path: "Notes",
      value: 6,
      content: <Notes caseId={caseData?.result?._id} />,
    },
    {
      label: "Documents/Letters",
      path: "Documents&Letters",
      value: 7,
      content: <Documents caseData={caseData?.result} />,
    },
    {
      label: "Messages",
      path: "Messages",
      value: 8,
      content: <Messages contacts={contacts} isCase />,
    },
  ];

  const getTabsContent = () => {
    const content = TABS?.find((tab: any) => tab?.value === tabValue)?.content;
    return content;
  };

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    const tabvalue = TABS.find((option: any) => option.value === newValue);
    setTabValue(newValue);
    navigate(`/cases/${caseId?.id}?tab=${tabvalue?.path}`);
  };

  const handleViewRepAgreement = useCallback(
    async (docuSignUrl: any) => {
      const modal: any = showModal(ViewRepAgreementModal, {
        onClose: () => {
          setLoadingRepAgreement(false);
          modal.hide();
        },
        buffer: docuSignUrl,
        isAwsUrl: true,
      });
    },
    [showModal]
  );

  const checkDocuSignStatus: any = () => {
    let color = "warning";

    switch (docuSignStatus) {
      case "COMPLETED":
        color = "success";
        break;

      case "DECLINED":
        color = "error";
        break;
    }

    return color;
  };

  const checkDocuSignStatusForTooltip: any = () => {
    let title = "Pending Rep Agreement";

    switch (docuSignStatus) {
      case "COMPLETED":
        title = "Accepted Rep Agreement";
        break;

      case "DECLINED":
        title = "Declined Rep Agreement";
        break;
    }

    return title;
  };

  const handleResendAgreement = useCallback(
    () => {
      const modal: any = showModal(ResendAgreement, {
        onClose: () => modal.hide(),
        updateCase,
        caseId: caseId?.id,
      });
    },
    //eslint-disable-next-line
    [showModal]
  );

  if (isCaseLoading || isCaseUpdating) {
    return <TableSkeleton />;
  }

  return (
    <>
      <Grid container sx={{ height: "100%" }} spacing={2}>
        {isVisible && (
          <Grid item xs={4}>
            <CaseInfo
              caseData={caseData?.result}
              hideRoster={hideRoster}
              isCaseClosed={isCaseClosed}
              isStaff={isStaff}
            />
          </Grid>
        )}
        <Grid item xs={isVisible ? 8 : 12}>
          <Stack direction={"row"} sx={{ width: "100%" }}>
            <Box>
              {!isVisible && (
                <Tooltip title={"Show Case Info"}>
                  <IconButton
                    color="primary"
                    sx={{
                      mt: 2,
                      background: "white",
                      "&:hover": {
                        background: "white",
                      },
                    }}
                    onClick={showRoster}
                  >
                    <IconChevronsRight />
                  </IconButton>
                </Tooltip>
              )}
            </Box>

            <Grid container sx={{ height: "100%" }} spacing={2}>
              <Grid item xs={12}>
                <MainCard
                  title={
                    <Typography variant="h1" my={1}>
                      Case Details
                    </Typography>
                  }
                  content={
                    <Stack spacing={1}>
                      <Tabs
                        value={tabValue}
                        onChange={handleChangeTab}
                        variant="scrollable"
                        scrollButtons="auto"
                        sx={{
                          position: "sticky",
                          top: 0,
                          backgroundColor: "white",
                          zIndex: 2,
                          borderBottom: 1,
                          borderColor: "divider",
                        }}
                      >
                        {TABS?.map(({ label }: any) => (
                          <Tab
                            key={label}
                            label={label}
                            sx={{
                              fontSize: "16px",
                              color: "#364152",
                              fontWeight: 600,
                              fontFamily: "'Noto Sans Arabic', sans-serif",
                            }}
                          />
                        ))}
                      </Tabs>
                      <Box
                        sx={{
                          height: "calc(100vh - 285px)",
                          overflowY: "auto",
                          p: 1,
                        }}
                      >
                        {getTabsContent()}
                      </Box>
                    </Stack>
                  }
                  actions={
                    <Box>
                      {!isExistingClient ? (
                        <>
                          <Tooltip title={checkDocuSignStatusForTooltip()}>
                            <span>
                              <IconButton
                                disabled={
                                  isLoadingRepAgreement || isCaseLoading
                                }
                                onClick={() => {
                                  setLoadingRepAgreement(true);
                                  handleViewRepAgreement(docuSignUrl);
                                }}
                              >
                                <DescriptionIcon
                                  color={
                                    isCaseLoading || isLoadingRepAgreement
                                      ? "grey"
                                      : checkDocuSignStatus()
                                  }
                                  fontSize="large"
                                />
                              </IconButton>
                            </span>
                          </Tooltip>
                          {isDocuSignPending && (
                            <Tooltip title={"Resend Rep Agreement"}>
                              <IconButton
                                sx={{
                                  marginRight: "40px",
                                }}
                                onClick={() => handleResendAgreement()}
                                disabled={isLoadingDocuSignMail}
                              >
                                <SendIcon
                                  fontSize="large"
                                  style={{ color: "#1976d2" }}
                                />
                              </IconButton>
                            </Tooltip>
                          )}
                        </>
                      ) : (
                        ""
                      )}
                    </Box>
                  }
                />
              </Grid>
            </Grid>
          </Stack>
        </Grid>
      </Grid>
    </>
  );
};

export default Case;
