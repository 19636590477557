import { GridPaginationModel, GridSortModel } from "@mui/x-data-grid";
import { useMutation, useQuery, useQueryClient } from "react-query";
import useAuth from "./useAuth";

/**
 * Custom hook to Fetch contacts with the specified parameters
 * @hook
 * @author Priyanshu
 */

export default function useCases(
  caseId?: string,
  search?: string,

  paginationModel?: GridPaginationModel,
  sortModel?: GridSortModel,
  showDeletedCases?: any,
  filterData?: any
) {
  const { request } = useAuth();

  const queryClient = useQueryClient();

  const handleRefetchUsers = () => {
    queryClient.invalidateQueries(["/cases"]);
  };

  // Fetch contacts with the specified parameters
  const {
    data: casesData,
    isLoading: isCasesLoading,
    isRefetching,
  } = useQuery(
    [
      "/cases",
      search,
      paginationModel,
      sortModel,
      showDeletedCases,
      filterData,
    ],
    () =>
      request
        .get("/cases", {
          params: {
            search,
            paginationModel,
            sortModel,
            showDeletedCases,
            filterData,
          },
        })
        .then((response) => response?.data),
    {
      enabled: !!paginationModel,
    }
  );

  const { mutateAsync: addCase, isLoading: isAddCaseLoading } = useMutation(
    "/cases",
    (payload: any) =>
      request.post("/cases", payload).then((response) => response?.data as any),
    {
      onSuccess: () => handleRefetchUsers(),
    }
  );

  // const { data: lawyers, isLoading: isLawyersLoading } = useQuery(
  //   ["/lawyers"],
  //   () =>
  //     request.get("/users", { params: { role: "LAWYER" } }).then((response) =>
  //       response?.data?.users?.map((user: any) => ({
  //         label: user?.firstName + " " + user?.lastName,
  //         value: user?._id,
  //         role: user?.role,
  //         email: user?.email,
  //       }))
  //     )
  // );

  // const { data: staff, isLoading: isStaffLoading } = useQuery(["/staff"], () =>
  //   request.get("/users", { params: { role: "STAFF" } }).then((response) =>
  //     response?.data?.users?.map((user: any) => ({
  //       label: user?.firstName + " " + user?.lastName,
  //       value: user?._id,
  //       role: user?.role,
  //       email: user?.email,
  //     }))
  //   )
  // );

  const { mutateAsync: deleteCase, isLoading: isDeleteCaseLoading } =
    useMutation(
      `/cases/${caseId}`,
      () =>
        request
          .delete(`/cases/${caseId}`)
          .then((response) => response?.data as any),
      {
        onSuccess: () => handleRefetchUsers(),
      }
    );

  const { mutateAsync: updateCase, isLoading: isCaseUpdating } = useMutation(
    `/cases/${caseId}`,
    (payload: any) =>
      request
        .put(`/cases/${caseId}`, payload)
        .then((response) => response?.data as any),
    {
      onSuccess: () => handleRefetchUsers(),
    }
  );

  const { mutateAsync: updateOffenceHistory, isLoading: isOffenceHistoryUpdating } = useMutation(
    `/cases/${caseId}/offenceHistory`,
    (payload: any) =>
      request
        .put(`/cases/${caseId}/offenceHistory`, payload)
        .then((response) => response?.data as any),
    {
      onSuccess: () => handleRefetchUsers(),
    }
  );

  const { mutateAsync: updateCaseDetails, isLoading: isCaseDetailUpdating } =
    useMutation(
      `/cases/${caseId}/caseDetails`,
      (payload: any) =>
        request
          .put(`/cases/${caseId}/caseDetails`, payload)
          .then((response) => {
            // console.log(response?.data);
            return response?.data as any;
          }),
      {
        onSuccess: () => handleRefetchUsers(),
      }
    );
  const {
    data: caseData,
    isLoading: isCaseLoading,
    refetch: refetchCase,
  } = useQuery(
    ["/cases", caseId],
    () => request.get(`/cases/${caseId}`).then((response) => response?.data),
    { enabled: !!caseId }
  );

  const { mutateAsync: restoreCase, isLoading: isRestoreCaseLoading } =
    useMutation(
      `/cases/deleted/${caseId}`,
      () =>
        request
          .delete(`/cases/deleted/${caseId}`)
          .then((response) => response?.data as any),
      {
        onSuccess: () => handleRefetchUsers(),
      }
    );

  const { data: allBoardMembers, isLoading: isBoardMembersLoading } = useQuery(
    "/boardMembers",
    () => request.get("/boardMembers").then((response) => response?.data)
  );

  const filteredCases = casesData?.cases?.filter(
    (item: any) =>
      item?.caseStatus !== "closed" &&
      ((item?.lawyer !== undefined && item?.lawyer !== null) ||
        (item?.secondaryLawyer !== undefined &&
          item?.secondaryLawyer !== null) ||
        (item?.staff !== undefined && item?.staff !== null))
  );

  return {
    isLoading:
      isCasesLoading ||
      isAddCaseLoading ||
      isRestoreCaseLoading ||
      isRefetching,
    cases: casesData?.cases || [],
    totalCases: casesData?.totalCases || 0,
    isCasesLoading,
    isAddCaseLoading,
    deleteCase,
    isDeleteCaseLoading,
    isRefetching,
    addCase,
    caseData,
    isCaseLoading,
    updateOffenceHistory,
    isOffenceHistoryUpdating,
    refetchCase,
    updateCase,
    isCaseUpdating,
    restoreCase,
    updateCaseDetails,
    isCaseDetailUpdating,
    boardMembers: allBoardMembers?.members,
    isBoardMembersLoading,
    handleRefetchUsers,
    filteredCases,
  };
}
