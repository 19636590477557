import { TablePagination } from "@mui/material";
import React from "react";

const Pagination = ({
  setPage,
  page,
  setRowsPerPage,
  rowsPerPage,
  count,
  rowsPerPageOptions,
  ...rest
}: any) => {
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset to first page
  };

  return (
    <TablePagination
      component="div"
      count={count}
      page={page}
      onPageChange={handleChangePage}
      rowsPerPage={rowsPerPage}
      onRowsPerPageChange={handleChangeRowsPerPage}
      rowsPerPageOptions={rowsPerPageOptions}
      sx={{
        // Adjust the overall height of the pagination
        "& .MuiToolbar-root": {
          minHeight: "40px",
          padding: 0,
        },
      }}
      {...rest}
    />
  );
};

export default Pagination;
