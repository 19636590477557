import { useMutation, useQuery } from "react-query";
import useAuth from "./useAuth";

/**
 *
 * Custom hook to manage Zoho leads, handle lead conversions, and track prospect interactions.
 * @hook useLeads
 * @author Rajni
 */

export default function useLeads(
  isZohoIntegrated: boolean,
  converted: boolean,
  isColdLead?: boolean,
  prospectId?: string
) {
  // Query to fetch leads from Zoho based on their conversion status and lead type (cold or not).

  const { request } = useAuth();
  const {
    data: leadsData,
    isLoading: inLoadingLeads,
    refetch: refetchLeads,
    isRefetching: isRefetchingLeads,
  } = useQuery(
    ["/zoho/leads", converted, isColdLead],
    () =>
      request
        .get("/zoho/leads", {
          params: { converted, isColdLead },
        })
        .then((response) => response?.data),
    {
      enabled: isZohoIntegrated,
      refetchOnWindowFocus: false,
    }
  );

  const {
    data: prospect,
    refetch: refetchProspects,
    isLoading: isLoadingProspect,
  } = useQuery(
    ["/zoho/leads/prospectId", prospectId],
    () =>
      request
        .get(`/zoho/leads/${prospectId}`, {})
        .then((response) => response?.data),
    {
      enabled: !!prospectId, // Ensure query is run only if id exists
    }
  );

  const { mutateAsync: convertLead, isLoading: isConvertingLead } = useMutation(
    "/zoho/leads/convert",
    (payload) =>
      request
        .post(`/zoho/leads/convert`, payload)
        .then((response) => response?.data as any),

    {
      onSuccess: () => refetchLeads(),
    }
  );

  const { mutateAsync: AddDetailOfInteraction, isLoading: isAddingDetail } =
    useMutation(
      "/zoho/leads/interactionDetail",
      (payload) =>
        request
          .put(`/zoho/leads/interactionDetail`, payload)
          .then((response) => response?.data as any),

      {
        onSuccess: () => refetchLeads(),
      }
    );

  return {
    leads: leadsData?.leads || [],
    prospect: prospect?.prospects || {},
    isLoadingProspect,
    refetchProspects,
    prospects: leadsData?.prospects || [],
    inLoadingLeads,
    refetchLeads,
    isRefetchingLeads,
    convertLead,
    isConvertingLead,
    AddDetailOfInteraction,
    isAddingDetail,
  };
}
