import { Box, Button, DialogProps } from "@mui/material";
import useLetters from "../../../hooks/useLetters";
import BasicModal from "../../components/modal/BasicModal";
import { useSnackbarHelper } from "../../components/Snackbar";
interface Props extends DialogProps {
  onClose: () => void;
  caseId?: string;
  letterId?: any;
}

const DeleteUserModal = ({ onClose, caseId, letterId, ...props }: Props) => {
  const showSnackbar = useSnackbarHelper();
  const { deleteLetter, letterLoading } = useLetters({
    caseId: caseId,
    letterId: letterId,
  });
  const onSubmit = async () => {
    try {
      const data: any = await deleteLetter();
      const message = data?.message || "Success";
      showSnackbar(message, "success");
      onClose();
    } catch (error: any) {
      const message = error?.response?.data?.message || "An error occurred";

      showSnackbar(message, "error");
    }
  };

  return (
    <Box>
      <BasicModal
        onClose={onClose}
        title="Delete Letter"
        content="Do you really want to delete this letter? "
        actions={
          <>
            <Button
              variant="contained"
              color="error"
              sx={{ textTransform: "capitalize" }}
              onClick={onSubmit}
              // disabled={isDeletingUser}
            >
              Delete
            </Button>
            <Button
              variant="outlined"
              sx={{ textTransform: "capitalize" }}
              onClick={() => onClose()}
              color="error"
            >
              Cancel
            </Button>
          </>
        }
        {...props}
      />
    </Box>
  );
};

export default DeleteUserModal;
