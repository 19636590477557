import { useMutation, useQuery } from "react-query";
import useAuth from "./useAuth";

/**
 * Custom hook to manage DocuSign operations, including sending DocuSign emails
 * and fetching signed documents.
 * @hook
 * @author Priyanshu
 */

export default function useDocuSign(envelopeId?: string) {
  const { request } = useAuth();

  const { mutateAsync: SendDocuSignMail, isLoading: isLoadingDocuSignMail } =
    useMutation("/docuSign", (payload: any) =>
      request
        .post("/docuSign", payload)
        .then((response) => response?.data as any)
    );

  const { data: signedDocument, isLoading: isLoadingSignedDocument } = useQuery(
    "/docuSign/getSignedDocument",
    () =>
      request
        .get("/docuSign/getSignedDocument", {
          params: { envelopeId },
        })
        .then((response) => response?.data?.results),
    {
      enabled: !!envelopeId,
      refetchOnWindowFocus: false,
    }
  );

  return {
    SendDocuSignMail,
    isLoadingDocuSignMail,
    signedDocument,
    isLoadingSignedDocument,
  };
}
