import { useMutation, useQuery, useQueryClient } from "react-query";
import useAuth from "./useAuth";
import { GridPaginationModel, GridSortModel } from "@mui/x-data-grid";

/**
 * Custom hook for managing time entry operations related to a case.
 * @hook useTimeEntry
 * @author Pallavi
 *
 */

export default function useTimeEntry(
  { caseId }: any,
  search?: string,
  paginationModel?: GridPaginationModel,
  sortModel?: GridSortModel
) {
  const { request } = useAuth();
  const queryClient = useQueryClient();
  const handleRefetchUsers = () => {
    queryClient.invalidateQueries(["/timeEntry"]);
  };
  // Fetch contacts with the specified parameters
  const {
    data,
    refetch: refetchTimeEntry,
    isLoading: isTimeEntryLoading,
    isRefetching,
  } = useQuery(
    ["/timeEntry/case/:caseId"],

    () =>
      request
        .get(`/timeEntry/case/${caseId}`)
        .then((response) => response?.data),
    {
      enabled: !!caseId,
    }
  );

  const {
    data: allTimeEntry,
    refetch: refetchAllTimeEntry,
    isLoading: isAllTimeEntryLoading,
  } = useQuery(
    ["/timeEntry", search, paginationModel, sortModel],

    () =>
      request
        .get(`/timeEntry`, {
          params: { search, paginationModel, sortModel },
        })
        .then((response) => response?.data)
  );

  const { mutateAsync: addTimeEntry, isLoading: isAdTimeEntryLoading } =
    useMutation(
      "/timeEntry",
      (payload: any) =>
        request
          .post("/timeEntry", payload)
          .then((response) => response?.data as any),
      // {
      //   onSuccess: () => {return handleRefetchUsers(),refetchTimeEntry()}
      // }
      {
        onSuccess: () => (caseId ? refetchTimeEntry() : handleRefetchUsers()),
      }
    );
  const { mutateAsync: deleteTimeEntry, isLoading: deleteLoading } =
    useMutation(
      "/timeEntry/delete",
      (id: string) => request.delete(`/timeEntry/${id}`),
      {
        onSuccess: () => (caseId ? refetchTimeEntry() : handleRefetchUsers()),
      }
    );

  const { mutateAsync: updateTimeEntry, isLoading: updateLoading } =
    useMutation(
      `/timeEntry/:id`,
      ({ id, payload }: { id: string; payload: any }) =>
        request
          .put(`/timeEntry/${id}`, payload)
          .then((response) => response.data as any),
      // { onSuccess: () => {return handleRefetchUsers(),refetchTimeEntry()} }
      {
        onSuccess: () => (caseId ? refetchTimeEntry() : handleRefetchUsers()),
      }
    );

  return {
    timeEntry: data?.timeEntry,
    refetchTimeEntry,
    isLoading:
      isTimeEntryLoading ||
      isAdTimeEntryLoading ||
      deleteLoading ||
      updateLoading,
    isRefetching,
    addTimeEntry,
    deleteTimeEntry,
    updateTimeEntry,
    allTimeEntry: allTimeEntry?.timeEntry,
    totalTimeEntry: allTimeEntry?.totalTimeEntry,
    refetchAllTimeEntry,
    isAllTimeEntryLoading,
  };
}
