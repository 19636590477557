import { useQuery } from "react-query";
import useAuth from "./useAuth";

/**
 * Custom hook for fetching site information
 * @hook useSite
 * @author Priyanshu
 *
 */

export default function useSite() {
  const { request } = useAuth();

  const {
    data: site,
    refetch: refetchSite,
    isLoading: isSiteLoading,
  } = useQuery("/site", () =>
    request.get("/site").then((response) => response?.data?.site)
  );

  //returns site info
  return {
    site,
    refetchSite,
    isSiteLoading,
  };
}
