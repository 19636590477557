import * as Yup from "yup";
import { Box, Grid } from "@mui/material";

import FormContainer from "../FormContainer";
import Password from "../Password";
import PasswordStrength from "../PasswordStrength";
import AnimateButton from "../extended/AnimateButton";
import usePasswordReset from "../../../hooks/usePasswordReset";
import { useLocation } from "react-router";
import SubmitButton from "../buttons/SubmitButton";
import { useSnackbarHelper } from "../Snackbar";

// ===============================|| JWT LOGIN ||=============================== //

type Props = {
  isCreatingNewPassword?: boolean;
};

const JWTResetPassword = ({ isCreatingNewPassword = false }: Props) => {
  const { passwordChange, isPasswordLoading } = usePasswordReset();
  const showSnackbar = useSnackbarHelper();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const challenge = searchParams.get("challenge") || "";
  const encodedEmail = searchParams.get("address");
  const email = encodedEmail ? decodeURIComponent(encodedEmail) : "";

  const initialValues = {
    password: "",
    confirmPassword: "",
  };

  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .max(255)
      .required("Password is required")
      .matches(
        /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
        "Password must contain at least 8 characters, one uppercase, one number and one special case character"
      ),
    confirmPassword: Yup.string()
      .required("Please confirm your password")
      .oneOf([Yup.ref("password")], "Passwords must match"),
  });

  const onFormSubmit = async (values: any) => {
    const { password: newPassword } = values;
    try {
      const data = await passwordChange({
        email,
        newPassword,
        challenge,
        isCreatingNewPassword,
      });
      const message = data?.message || "Success";
      showSnackbar(message, "success");
    } catch (error: any) {
      console.log(error?.response?.data?.message);
      const message = error?.response?.data?.message || "An error occurred";

      showSnackbar(message, "error");
    }
  };

  return (
    <FormContainer
      validation={validationSchema}
      // formContext={formContext}
      defaultValues={initialValues}
      onSuccess={onFormSubmit}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Password name="password" label="Password" required />
          <PasswordStrength />
        </Grid>

        <Grid item xs={12}>
          <Password name="confirmPassword" label="Confirm Password" required />
        </Grid>

        <Grid item xs={12}>
          <Box sx={{ mt: 1 }}>
            <AnimateButton>
              <SubmitButton
                disableElevation
                fullWidth
                disabled={isPasswordLoading}
                size="large"
                type="submit"
                variant="contained"
              >
                {isCreatingNewPassword ? "Set Password" : "Reset Password"}
              </SubmitButton>
            </AnimateButton>
          </Box>
        </Grid>
      </Grid>
    </FormContainer>
  );
};

export default JWTResetPassword;
