import { useEffect, useMemo, useState } from "react";
import { Box, Button, DialogProps, Grid } from "@mui/material";
import { useForm } from "react-hook-form";
import * as Yup from "yup";

//components
import BasicModal from "../../components/modal/BasicModal";
import MuiTextField from "../../components/inputs/MuiTextField";
import { yupResolver } from "@hookform/resolvers/yup";
import { Type } from "../../../constants/MenuSelection";
import useCases from "../../../hooks/useCases";
import useTimeEntry from "../../../hooks/useTimeEntry";
import CustomDateTime from "../../components/inputs/CustomDateTime";
import { useSnackbarHelper } from "../../components/Snackbar";
import BasicAutocomplete from "../../components/inputs/BasicAutocomplete";
import NumberField from "../../components/inputs/NumberField";
import moment from "moment";

interface Props extends DialogProps {
  onClose: () => void;
  data?: any;
  end?: any;
  start?: any;
  handleReset: () => void;
}

function AddNewTimeEntryModal({
  onClose,
  data,
  end,
  start,
  handleReset,
  ...props
}: Props) {
  const { cases } = useCases(
    undefined,
    "",
    { page: 0, pageSize: 12 },
    [{ field: "createdAt", sort: "desc" }],
    false,
    [
      [
        { title: "Active" },
        { title: "Prospect" },
        { title: "InReview" },
        { title: "Retained" },
      ],
    ]
  );
  const calculatedTime = start ? true : false;

  const [caseData, setCaseData] = useState<any>();
  const showSnackbar = useSnackbarHelper();

  const { addTimeEntry, updateTimeEntry, isLoading } = useTimeEntry({});

  const initialValues = useMemo(
    () => ({
      startTime: !calculatedTime ? data?.startTime || new Date() : start,
      endTime: !calculatedTime ? data?.endTime || null : new Date(),
      billedMinutes: data?.billedMinutes || null,
      type: data?.type || null,
      note: data?.note || "",
      caseId: data?.caseId?._id || null,
      blank: data?.blank || "",
    }),
    [calculatedTime, data, start]
  );

  const validationSchema: any = useMemo(
    () =>
      Yup.object({
        startTime: Yup.date().required("Required"),
        endTime: Yup.date()
          .min(
            Yup.ref("startTime"),
            "End datetime must be after start datetime"
          )
          .required("Required"),
        billedMinutes: Yup.number(),
        type: Yup.string().required("Required"),
        note: Yup.string(),
        caseId: Yup.string().required("Required"),
        blank: Yup.string(),
      }),
    []
  );

  const caseOption = cases?.map((item: any, i: any) => ({
    label: `${item?.contact?.inmate?.name} (${item?.caseId})`,
    value: item?._id,
  }));

  const formContext = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
  });

  const { watch, setValue } = formContext;

  const startTime = watch("startTime");
  const endTime = watch("endTime");
  const caseField = watch("caseId");

  useEffect(() => {
    // Convert the time strings to Date objects
    const startDate = new Date(startTime) as Date;
    const endDate = new Date(endTime) as Date;

    // Define the two dates in your format
    const date1 = moment(startDate);
    const date2 = moment(endDate);
    let differenceInMinutes = 0;

    // Check if moment is able to parse them
    if (!date1.isValid() || !date2.isValid()) {
      console.error("Invalid date format. Please check the input.");
    } else {
      startDate.setSeconds(0);
      startDate.setMilliseconds(0);
      endDate.setSeconds(0);
      endDate.setMilliseconds(0);
      const differenceInMilliseconds = endDate.getTime() - startDate.getTime();

      // Convert milliseconds to minutes
      differenceInMinutes = Math.floor(differenceInMilliseconds / 1000 / 60);

      // Get the minute values
      // const minutes1 = date1.minutes();
      // const minutes2 = date2.minutes();
      // differenceInMinutes = minutes2 - minutes1;
    }
    if (differenceInMinutes > 0) {
      setValue("billedMinutes", differenceInMinutes);
    } else {
      setValue("billedMinutes", 0);
    }
  }, [endTime, setValue, startTime]);

  useEffect(() => {
    cases?.forEach((item: any) => {
      if (item?._id === caseField) {
        setCaseData(item);
        return;
      }
    });
  }, [caseField, cases]);

  const handleSubmit = async (values: any) => {
    values.client = caseData?.contact?._id;
    try {
      var response;

      if (data) {
        const timeEntryId = data?._id;
        response = await updateTimeEntry({
          id: timeEntryId,
          payload: values,
        });
      } else {
        // response= await
        response = await addTimeEntry(values);
        handleReset();
      }
      const message = response?.message || "Success";
      showSnackbar(message, "success");
      onClose();
    } catch (err: any) {
      console.log(err?.response?.data?.message);
    }
  };

  return (
    <BasicModal
      formContext={formContext}
      onClose={onClose}
      onSubmit={handleSubmit}
      title={data ? "Update Time Entry" : "New Time Entry"}
      content={
        <Box>
          <Grid container>
            <Grid item>
              <Grid container spacing={2} rowGap={0}>
                {/* <Grid item sm={6} mt={-1}>
                  <TimePicker label="Timer" name="timer" fullWidth />
                </Grid>
                <Grid item xs={6}>
                  <BasicDatePicker label="Date" name="date" />
                </Grid>
                <Grid item xs={6}>
                  <BasicAutocomplete
                    label="Country"
                    name="country"
                    options={[
                      { label: "Bright,Sharon", value: "brightSharon" },
                      { label: "Other....", value: "other" },
                    ]}
                    required
                    getOptionValue={(option: any) => option?.value}
                  />
                </Grid>
                <Grid item xs={6}>
                  {" "}
                  <BasicAutocomplete
                    label="Province/State"
                    name="state"
                    options={[
                      { label: "Bright,Sharon", value: "brightSharon" },
                      { label: "Other....", value: "other" },
                    ]}
                    required
                    getOptionValue={(option: any) => option?.value}
                  />
                </Grid> */}
                <Grid item xs={6}>
                  <CustomDateTime label="Start Time" name="startTime" />
                </Grid>
                <Grid item xs={6}>
                  <CustomDateTime
                    label="End Time"
                    name="endTime"
                    minDate={startTime}
                  />
                </Grid>
                <Grid item xs={6}>
                  <NumberField
                    label="Billed Minutes"
                    name="billedMinutes"
                    fullWidth
                    disabled
                  />
                </Grid>
                <Grid item xs={6}>
                  <BasicAutocomplete
                    label="Select a Type"
                    name="type"
                    options={Type}
                    disablePortal={false}
                    getOptionValue={(option: any) => option?.value}
                  />
                </Grid>
                <Grid item xs={12}>
                  <MuiTextField
                    multiline
                    minRows={4}
                    label="Notes/Details"
                    name="note"
                    sx={{ borderColor: "#B2BAC2" }}
                  />
                </Grid>
                <Grid item xs={12}>
                  {/* <InputLabel>Select a Case for Billing</InputLabel> */}
                  {/* <Autocomplete
                    freeSolo
                    options={[
                      { label: "Bright,Sharon", value: "brightSharon" },
                      { label: "Other....", value: "other" },
                    ]}
                    renderInput={(params) => (
                      <MuiTextField
                        {...params}
                        placeholder="Search By Last Name"
                        name="search"
                      />
                    )}
                  /> */}
                  <BasicAutocomplete
                    options={caseOption}
                    name="caseId"
                    label="Select a Case for Billing"
                    getOptionValue={(option: any) => option?.value}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      }
      actions={
        <>
          <Button
            color="error"
            variant="outlined"
            onClick={() => {
              onClose();
            }}
            sx={{ textTransform: "capitalize" }}
          >
            Cancel
          </Button>
          <Button
            disabled={isLoading}
            variant="contained"
            color="success"
            sx={{ textTransform: "capitalize" }}
            type="submit"
          >
            {data ? "Update" : "Add"}
          </Button>
        </>
      }
      {...props}
    />
  );
}

export default AddNewTimeEntryModal;
