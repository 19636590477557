import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, DialogProps, Stack } from "@mui/material";
import { useState } from "react";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { Reasons } from "../../../constants/MenuSelection";
import BasicAutocomplete from "../../components/inputs/BasicAutocomplete";
import BasicModal from "../../components/modal/BasicModal";
import { useSnackbarHelper } from "../../components/Snackbar";
// import { Textarea } from "@mui/joy";

interface Props extends DialogProps {
  onClose: () => void;
  AddDetailOfInteraction: any;
  isAddingDetail?: any;
  value?: any;
  userId?: string;
}

const MarkAsColdLead = ({
  onClose,
  value,
  userId,
  AddDetailOfInteraction,
  isAddingDetail,
  ...props
}: Props) => {
  const showSnackbar = useSnackbarHelper();

  const [isColdLeadMark, setIsColdLeadMark] = useState(false);
  const initialValues = {
    reason: "",
  };
  const validationSchema = Yup.object({
    reason: Yup.string().required("Required"),
  });

  const formContext = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
  });

  const handleSubmit = async (data: any) => {
    try {
      setIsColdLeadMark(true);
      const payload = {
        leadId: value?.leadId,
        reason: data?.reason,
        isColdLead: true,
        user: userId,
      };
      const response = await AddDetailOfInteraction(payload);
      const message = response?.message || "Success";
      showSnackbar(message, "success");
      setIsColdLeadMark(false);
      onClose();
    } catch (error: any) {
      console.log(error);
      // const message = error?.response?.data?.message || "An error occurred";
      // showSnackbar(message, "error");
    }
  };
  return (
    <Box>
      <BasicModal
        onSubmit={handleSubmit}
        formContext={formContext}
        onClose={onClose}
        title="Cold Lead"
        content={
          <Stack spacing={2}>
            {/* <Typography>Contact Method</Typography> */}
            <BasicAutocomplete
              label="Select Reason or Enter Manually"
              name="reason"
              options={Reasons}
              freeSolo={true}
              required
              disablePortal={false}
              getOptionValue={(option: any) => option?.value}
            />
          </Stack>
        }
        actions={
          <>
            <Button
              type="submit"
              variant="contained"
              color="success"
              sx={{ textTransform: "capitalize" }}
              disabled={isColdLeadMark}
            >
              Mark as Cold Lead
            </Button>
            <Button
              color="error"
              variant="outlined"
              sx={{ textTransform: "capitalize" }}
              onClick={() => onClose()}
              disabled={isColdLeadMark}
            >
              Cancel
            </Button>
          </>
        }
        {...props}
      />
    </Box>
  );
};

export default MarkAsColdLead;
