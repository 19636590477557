import { useRoutes } from "react-router-dom";
import MainRoutes from "./MainRoutes";
import AuthRoutes from "./AuthRoutes";
import { useSelector } from "react-redux";

/**
 * Routes component that conditionally renders application routes based on user authentication status.
 * @component Routes
 * @author Priyanshu
 */

const Routes = () => {
  const { user } = useSelector((state: any) => state.user);

  const routes = !!user ? MainRoutes() : AuthRoutes();

  return useRoutes([routes]);
};

export default Routes;
