import {
  TextField as MUITextField,
  TextFieldProps as MUITextFieldProps,
} from "@mui/material";
import React from "react";
import { Controller, FieldError } from "react-hook-form";
import ReactPhoneInput from "react-phone-input-mui";
import styled from "@emotion/styled";

const StyledTextField = styled(MUITextField)(({ theme }) => ({
  "& label.Mui-focused": {
    color: "#6F7E8C", // Change this color to your desired label color on focus
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#6F7E8C", // Change this color to your desired border color on focus
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#CED4DA",
    },
    "&:hover fieldset": {
      borderColor: "#6F7E8C", // Change this color to your desired border color on hover
    },
    "&.Mui-focused fieldset": {
      borderColor: "#6F7E8C", // Change this color to your desired border color on focus
    },
  },
}));

export type PhoneNumberProps = Omit<MUITextFieldProps, "name"> & {
  name: string;
  parseError?: (error: FieldError) => string;
  defaultCountry?: string;
};

const PhoneNumber = React.memo(
  ({
    required,
    parseError,
    defaultCountry,
    name,
    label,
    ...rest
  }: PhoneNumberProps): JSX.Element => (
    <Controller
      name={name}
      render={({
        field: { value, onChange },
        fieldState: { invalid, error },
      }) => (
        <ReactPhoneInput
          value={value}
          defaultCountry={defaultCountry || "us"}
          onChange={onChange}
          component={StyledTextField}
          inputExtraProps={{
            name,
            label: `${label} ${required ? " *" : ""}`,
            error: invalid,
            // eslint-disable-next-line no-nested-ternary
            helperText: error
              ? typeof parseError === "function"
                ? parseError(error as any)
                : error.message
              : rest.helperText,
            ...rest,
          }}
        />
      )}
    />
  )
);

export default PhoneNumber;
