import { Box, Button, DialogProps, Grid } from "@mui/material";

import BasicModal from "../../components/modal/BasicModal";

import { useEffect, useMemo } from "react";

import { useForm } from "react-hook-form";
// import MuiTextField from "../../components/inputs/MuiTextField";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import { Priority, Task } from "../../../constants/MenuSelection";
import useCases from "../../../hooks/useCases";
import useTodo from "../../../hooks/useTodo";
import useUser from "../../../hooks/useUser";
import BasicAutocomplete from "../../components/inputs/BasicAutocomplete";
import BasicDatePicker from "../../components/inputs/BasicDatePicker";
import Checkbox from "../../components/inputs/CheckBox";
import MuiTextField from "../../components/inputs/MuiTextField";
import { useSnackbarHelper } from "../../components/Snackbar";

interface Props extends DialogProps {
  onClose: () => void;
  data?: any;
  caseId?: string;
  isCaseTodo?: boolean;
  refetch?: any;
}

function AddNewTodoModal({
  onClose,
  caseId,
  isCaseTodo,
  data,
  refetch,
  ...props
}: Props) {
  const { user } = useSelector((state: any) => state.user);
  const showSnackbar = useSnackbarHelper();
  const { user: userData } = useUser(user?.userId);
  const { updateTodo, addTodo, isLoading } = useTodo(
    caseId,
    undefined,
    undefined,
    undefined,
    undefined,
    !isCaseTodo
  );

  const { cases, filteredCases } = useCases(
    undefined,
    undefined,
    { page: 0, pageSize: 12 },
    [{ field: "createdAt", sort: "desc" }],
    false,
    undefined
  );
  const caseOption = filteredCases?.map((item: any, i: any) => ({
    label: `${item?.contact?.inmate?.name} (${item?.caseId})`,
    value: item?._id,
    client: item?.contactId,
  }));

  const initialValues = useMemo(
    () => ({
      caseId: !!data ? data?.caseId?._id || data?.caseId || "" : caseId || "",
      createdFor: data?.createdFor?._id || "",
      dueDate: data?.dueDate || new Date(),
      priority: data?.priority || "",
      task: data?.task || "",
      description: data?.description || "",
      isCompleted: data?.isCompleted || false,
      // isEmailtoReceipient: data?.isEmailtoReceipient || false,
      isAutomaticPriority: data?.isAutomaticPriority || false,
    }),
    [data, caseId]
  );
  const validationSchema: any = useMemo(
    () =>
      Yup.object({
        caseId: Yup.string().required("Required"),
        createdFor: Yup.string().required("Required"),
        dueDate: Yup.date().required("Required"),
        priority: Yup.string()
          .when("isAutomaticPriority", (isAutomaticPriority, schema) => {
            return isAutomaticPriority?.[0] === false
              ? schema.required("Required")
              : schema.notRequired();
          })
          .required("Required"),
        task: Yup.string().required("Required"),
        description: Yup.string(),
        isCompleted: Yup.boolean().required("Required"),
        // isEmailtoReceipient: Yup.boolean().required("Required"),
        isAutomaticPriority: Yup.boolean().required("Required"),
      }),
    []
  );

  const formContext = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
  });
  const { watch, setValue } = formContext;
  const isAutomaticPriority = watch("isAutomaticPriority");

  const caseData = watch("caseId");
  const checkbox = watch("isAutomaticPriority");

  const selectedCaseId = data?.caseId?._id || data?.caseId;

  useEffect(() => {
    if (selectedCaseId !== caseData) {
      setValue("createdFor", null);
    }
  }, [selectedCaseId, caseData, setValue]);

  const filterCase = filteredCases?.filter(
    (item: any) => item?._id === caseData
  );
  // Check if filterCase has at least one element to avoid errors
  let lawyer: any, secondaryLawyer: any, staff: any;
  if (filterCase && filterCase.length > 0) {
    ({ lawyer, secondaryLawyer, staff } = filterCase[0]);
  } else {
    // Assign default values to avoid destructuring errors
    lawyer = secondaryLawyer = staff = null;
  }

  const lawyers = [lawyer, secondaryLawyer, staff].filter(Boolean); // Filter out null or undefined values
  const userOption = lawyers
    ?.filter((item: any) => {
      // Always allow active users
      if (!item?.isActive) return false;

      // If the logged-in user is ADMIN or the main lawyer, show all users
      if (userData?.role === "ADMIN" || userData?._id === lawyer?._id) {
        return true;
      }

      // If the logged-in user is the secondary lawyer, show only themselves and the staff
      if (userData?._id === secondaryLawyer?._id) {
        return item._id === userData?._id || item._id === staff?._id;
      }
      if (userData?._id === staff?._id) {
        return item._id === userData?._id;
      }
      // For any other users, do not show them
      return false;
    })
    ?.map((item: any, i: any) => ({
      label: `${item?.firstName} ${item?.lastName}`,
      value: item?._id,
    }));
  const handleSubmit = async (values: any) => {
    values.createdBy = user?.userId;

    values.client = cases?.filter(
      (item: any) => item?._id === values?.caseId
    )[0]?.contactId;
    try {
      // const payload = {
      //   client: data?.contactId?._id || value?.client?._id,
      //   createdFor: values?.todoisfor?._id,
      //   createdBy: user?.user?.userId,
      //   dueDate: values?.dueDate,
      //   priority: values?.priority,
      //   task: values?.task,
      //   isEmailtoReceipient: values?.emailtorecipient,
      //   caseId: caseId,
      // };

      let response;
      if (!data) {
        response = await addTodo(values);
      } else {
        response = await updateTodo({
          todoId: data?._id,
          payload: values,
        });
      }

      const message = response?.data?.message || "Success";
      showSnackbar(message, "success");

      onClose();
    } catch (error: any) {
      showSnackbar(error?.response?.data?.message, "error");
    }
  };

  return (
    <BasicModal
      formContext={formContext}
      onClose={onClose}
      onSubmit={handleSubmit}
      title={data ? "Update To Do" : "Add To Do"}
      content={
        <Box>
          <Grid container>
            <Grid item>
              <Grid container spacing={2} rowGap={0}>
                <Grid item xs={6}>
                  <BasicAutocomplete
                    label="Select Case"
                    name="caseId"
                    options={caseOption}
                    required
                    disablePortal={false}
                    getOptionValue={(option: any) => option?.value}
                    disabled={isCaseTodo}
                  />
                </Grid>
                <Grid item xs={6}>
                  <BasicAutocomplete
                    label="Task Assigned For"
                    name="createdFor"
                    options={userOption}
                    required
                    getOptionValue={(option: any) => option?.value}
                  />
                </Grid>

                <Grid item xs={6}>
                  <BasicDatePicker
                    label="Select Due Date"
                    name="dueDate"
                    required
                  />
                </Grid>
                <Grid item sm={6}>
                  <BasicAutocomplete
                    label="Select Task or Enter Manually"
                    name="task"
                    options={Task}
                    freeSolo={true}
                    required
                    disablePortal={false}
                    getOptionValue={(option: any) => option?.value}
                  />
                </Grid>
                <Grid item sm={6}>
                  <Checkbox
                    name="isAutomaticPriority"
                    label="Set priority automatically?"
                  />
                </Grid>
                <Grid item sm={6}>
                  {!checkbox ? (
                    <BasicAutocomplete
                      label="Priority"
                      name="priority"
                      options={Priority}
                      required={!checkbox}
                      getOptionValue={(option: any) => option?.value}
                      disabled={isAutomaticPriority}
                    />
                  ) : (
                    ""
                  )}
                </Grid>
                {/* <Grid item sm={6} mt={-1}>
                  <TimePicker
                    label="Add Entry Time"
                    name="addEntryTime"
                    fullWidth
                    required
                  />
                </Grid> */}

                <Grid item sm={12}>
                  <MuiTextField
                    fullWidth
                    label="Description"
                    name="description"
                    multiline
                    rows={4}
                  />
                </Grid>
                {/* <Grid item sm={6}>
                  <Checkbox
                    name="isEmailtoReceipient"
                    label="Is Email to lawyer?"
                    checked={data?.isEmailtoReceipient ? true : false}
                    disabled={
                      data?.isEmailtoReceipient
                        ? true
                        : false || isAutomaticPriority
                          ? true
                          : false
                    }
                  />
                </Grid> */}
              </Grid>
            </Grid>
          </Grid>
        </Box>
      }
      actions={
        <>
          <Button
            color="error"
            variant="outlined"
            onClick={onClose}
            disabled={isLoading}
            sx={{ textTransform: "capitalize" }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="success"
            disabled={isLoading}
            sx={{ textTransform: "capitalize" }}
            type="submit"
          >
            {data ? "Update" : "Add"}
          </Button>
        </>
      }
      {...props}
    />
  );
}

export default AddNewTodoModal;
