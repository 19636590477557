import { Grid, Stack, Typography, Box } from "@mui/material";
import { Link } from "react-router-dom";
import AuthCardWrapper from "../../../layouts/Wrappers/AuthCardWrapper";
import JWTForgotPassword from "../../../components/authentication/JWTForgotPassword";
import laywerLogo from "../../../../assets/images/laywerLogo.png";

// ============================|| AUTH1 - FORGOT PASSWORD ||============================ //
const ForgotPassword = () => {
  return (
    <AuthCardWrapper>
      <Grid
        container
        spacing={1}
        direction={"column"}
        sx={{
          display: "flex",
          justifyContent: "center",
          height: "100%",
          alignItems: "center",
        }}
      >
        <Box
          component="img"
          alt="The house from the offer."
          src={`${laywerLogo}`}
          width={"30%"}
          sx={{ alignSelf: "center", mb: 8 }}
        />
        <Stack sx={{ width: "50%", height: "fit-content" }} spacing={2}>
          <Grid item xs={12}>
            <Stack alignItems="flex-start" justifyContent="center" spacing={1}>
              <Typography
                // color="black"
                gutterBottom
                variant={"h2"}
              >
                Forgot password?
              </Typography>
              <Typography variant="subtitle1" textAlign="left">
                Enter your email address below and we'll send you password reset
                link.
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <JWTForgotPassword />
          </Grid>
          <Grid item xs={12}>
            <Grid item xs={12}>
              <Grid
                item
                container
                direction="column"
                alignItems="flex-end"
                xs={12}
              >
                <Link to="/login" style={{ textDecoration: "none" }}>
                  <Typography color="secondary" variant="subtitle1">
                    Have an account?
                  </Typography>
                </Link>
              </Grid>
            </Grid>
          </Grid>
        </Stack>
      </Grid>
    </AuthCardWrapper>
  );
};
export default ForgotPassword;
