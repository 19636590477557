// import value from "assets/scss/_themes-vars.module.scss";
// import { useIntl } from 'react-intl';
// import value from "assets/scss/_themes-vars.module.scss";
/**
 * @description Handle naming conventions
 */
function useLabels() {
  //   const intl = useIntl();

  const locationTypeLabels = {
    Office: "In-Person",
    Online: "Virtual",
    Phone: "Phone",
    Text: "Text",
  };

  const strengthIndicator: any = (number: any) => {
    const hasNumber: any = (number: any) => new RegExp(/[0-9]/).test(number);

    // has mix of small and capitals
    const hasMixed: any = (number: any) =>
      new RegExp(/[a-z]/).test(number) && new RegExp(/[A-Z]/).test(number);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    // has special chars
    const hasSpecial: any = (number: any) =>
      new RegExp(/[!#@$%^&*)(+=._-]/).test(number);
    let strengths = 0;
    if (number.length > 5) strengths += 1;
    if (number.length > 7) strengths += 1;
    if (hasNumber(number)) strengths += 1;
    if (hasSpecial(number)) strengths += 1;
    if (hasMixed(number)) strengths += 1;
    return strengths;
  };

  const strengthColor: any = (count: any) => {
    if (count < 2) return { label: "Poor", color: "rgb(244, 67, 54)" };
    if (count < 3) return { label: "Weak", color: "rgb(255, 193, 7)" };
    if (count < 4) return { label: "Normal", color: "rgb(0, 230, 118)" };
    if (count < 6) return { label: "Strong", color: "rgb(0, 200, 83)" };
    return { label: "Poor", color: "rgb(244, 67, 54)" };
  };

  return { locationTypeLabels, strengthIndicator, strengthColor };
}

export default useLabels;
