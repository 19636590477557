import { TextField, TextFieldProps as MUITextFieldProps } from "@mui/material";
import React, { useCallback } from "react";
import { Controller, FieldError } from "react-hook-form";
import { NumericFormat } from "react-number-format";

export type NumberFieldProps = Omit<MUITextFieldProps, "name"> & {
  name: string;
  parseError?: (error: FieldError) => string;
  isCurrency?: boolean;
  max?: number;
  prefix?: string;
  decimalScale?: number;
  fixedDecimalScale?: boolean;
};

const NumberField = React.memo(
  ({
    parseError,
    name,
    label,
    required,
    max,
    prefix,
    isCurrency,
    decimalScale = 2, // Default value for decimal places
    fixedDecimalScale = false, // Default to false if not provided
    hidden,
    ...rest
  }: NumberFieldProps): JSX.Element => {
    const withValueLimit = useCallback(
      ({ floatValue }: any) =>
        floatValue === undefined || floatValue <= (max || 999),
      [max]
    );

    const CustomTextField = (props: any) => (
      <TextField
        sx={{
          "& .MuiOutlinedInput-root": {
            "& fieldset": { borderRadius: "12px" },
            fontSize: "1rem",
            fontFamily: "'Arial', sans-serif",
            backgroundColor: "#f5f5f500",
            height: "3.2em",
          },
          "& .MuiInputBase-input": {
            fontSize: "1rem",
          },
          "& .MuiInputLabel-root": {
            fontSize: "0.875rem",
          },
          "& .Mui-focused fieldset": {
            borderColor: "#6F7E8C !important",
          },
          // "& .css-1jy569b-MuiFormLabel-root-MuiInputLabel-root.Mui-focused": {
          //   color: "#6F7E8C !important",
          // },
          "& label.Mui-focused": {
            color: "#6F7E8C",
          },
          "& .css-1ald77x.Mui-focused": {
            color: "#6F7E8C !important",
          },
        }}
        label={`${label} ${required ? " *" : ""}`}
        fullWidth
        {...rest}
        {...props}
      />
    );

    return (
      <Controller
        name={name}
        render={({
          field: { value = 0, onChange },
          fieldState: { invalid, error },
        }) => (
          <NumericFormat
            customInput={CustomTextField}
            thousandSeparator
            isAllowed={withValueLimit}
            error={invalid}
            helperText={
              error
                ? typeof parseError === "function"
                  ? parseError(error as any)
                  : error.message
                : rest.helperText
            }
            prefix={prefix}
            decimalScale={decimalScale}
            fixedDecimalScale={fixedDecimalScale}
            value={value}
            // allowEmptyFormatting
            max={max || 999}
            allowNegative={false}
            onValueChange={(v: any) => {
              onChange(v.floatValue === undefined ? 0 : v.floatValue);
            }}
          />
        )}
      />
    );
  }
);

export default NumberField;
