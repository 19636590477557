import { Box, Button, DialogProps } from "@mui/material";
import BasicModal from "../../components/modal/BasicModal";
import useUser from "../../../hooks/useUser";
import { User } from "../../../types/users";
import { useSnackbarHelper } from "../../components/Snackbar";
// import io from "socket.io-client";
interface Props extends DialogProps {
  onClose: () => void;
  user1: User;
}

const DeleteUserModal = ({ onClose, user1, ...props }: Props) => {
  const showSnackbar = useSnackbarHelper();
  const { deleteUser, isDeletingUser } = useUser(user1?._id);

  // const socket = io("http://localhost:8000", {
  //   transports: ["websocket", "polling", "flashsocket"],
  // });

  // const handleUserLogOutOnDelete = () => {
  //   socket.emit("register", {
  //     userId: user1._id,
  //     message: "User does not exist!",
  //   });
  // };

  const onSubmit = async () => {
    try {
      const data = await deleteUser();

      const message = data?.message || "Success";
      // handleUserLogOutOnDelete();
      showSnackbar(message, "success");

      onClose();
    } catch (error: any) {
      const message = error?.response?.data?.message || "An error occurred";

      showSnackbar(message, "error");
    }
  };

  return (
    <Box>
      <BasicModal
        onClose={onClose}
        title="Delete User"
        content="Do you really want to delete this user? "
        actions={
          <>
            <Button
              variant="contained"
              color="error"
              sx={{ textTransform: "capitalize" }}
              onClick={onSubmit}
              disabled={isDeletingUser}
            >
              Delete
            </Button>
            <Button
              color="error"
              variant="outlined"
              sx={{ textTransform: "capitalize" }}
              onClick={() => onClose()}
            >
              Cancel
            </Button>
          </>
        }
        {...props}
      />
    </Box>
  );
};

export default DeleteUserModal;
