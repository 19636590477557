import styled from "@emotion/styled";
import {
  Button,
  Grid,
  IconButton,
  Stack,
  Table,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { IconEdit, IconPlus, IconTrash } from "@tabler/icons-react";
import { useModal } from "mui-modal-provider";
import { useCallback } from "react";
import SubCard from "../../components/cards/SubCard";
import AddSupportContactModal from "./AddSupportContactModal";
import ConfirmationModal from "./DeleteSupportContactModal";

const CustomTableRow = styled(TableRow)(() => ({
  height: "50px",
}));
const CustomTableCell = styled(TableCell)(() => ({
  paddingTop: 0,
  width: 130,
  paddingBottom: 0,
  // fontSize: "15px",
  overflow: "hidden",
  textOverflow: "ellipsis",
}));

const SupportContactsTabel = ({ caseData }: any) => {
  const { showModal } = useModal();

  const handleAddSupportContact = useCallback(
    (data: any) => {
      const modal: any = showModal(AddSupportContactModal, {
        onClose: () => modal.hide(),
        data: data?.data,
        type: data?.type,
        contactId: data?.contactId,
        caseId: data?.caseId,
      });
    },
    [showModal]
  );

  const handleSupportDelete = useCallback(
    (data: any) => {
      const modal: any = showModal(ConfirmationModal, {
        onClose: () => modal.hide(),
        data: data?.data,
        contactId: data?.contactId,
        caseId: data?.caseId,
      });
    },
    [showModal]
  );
  return (
    <Grid item xs={12}>
      <SubCard title={"Support Contacts"}>
        <Stack sx={{ overflow: "auto" }}>
          <Table sx={{ tableLayout: "fixed" }}>
            <CustomTableRow>
              <CustomTableCell>
                {" "}
                <Typography variant="h5">Name</Typography>
              </CustomTableCell>
              <CustomTableCell>
                {" "}
                <Typography variant="h5">Phone</Typography>
              </CustomTableCell>
              <CustomTableCell>
                {" "}
                <Typography variant="h5">Email</Typography>
              </CustomTableCell>
              <CustomTableCell sx={{ textAlign: "center" }}>
                <Typography variant="h5"> Actions</Typography>
              </CustomTableCell>
              {caseData?.contactId?.additionalSupportContact?.firstName ===
                "" && (
                <CustomTableCell sx={{ textAlign: "right" }}>
                  <Tooltip title={"Add Additional Contact"}>
                    <Button
                      sx={{
                        color: "green",
                        cursor: "pointer",
                      }}
                      onClick={() =>
                        handleAddSupportContact({
                          data: caseData?.contactId,
                          type: "support",
                          contactId: caseData?.contactId?._id,
                          caseId: caseData?._id,
                        })
                      }
                    >
                      <IconPlus />
                    </Button>
                  </Tooltip>
                </CustomTableCell>
              )}
            </CustomTableRow>
            <CustomTableRow>
              <CustomTableCell>
                {`${caseData?.contactId?.firstName} ${caseData?.contactId?.lastName}`}
              </CustomTableCell>
              <CustomTableCell>
                {caseData?.contactId?.phone[0]?.phone}
              </CustomTableCell>
              <CustomTableCell>
                {caseData?.contactId?.email[0]?.email}
              </CustomTableCell>
              <CustomTableCell sx={{ textAlign: "center" }}>
                {/* <IconButton>
                  <IconMessageCircle color="green" />
                </IconButton> */}
                <Tooltip title={"Edit Support Contact"}>
                  <IconButton
                    onClick={() => {
                      handleAddSupportContact({
                        data: caseData?.contactId,
                        type: "main",
                        contactId: caseData?.contactId?._id,
                        caseId: caseData?._id,
                      });
                    }}
                  >
                    <IconEdit cursor={"pointer"} style={{ color: "grey" }} />
                  </IconButton>
                </Tooltip>
                <IconButton disabled>
                  <IconTrash
                    style={{ cursor: "pointer" }}
                    onClick={handleSupportDelete}
                  />
                </IconButton>
              </CustomTableCell>
              {caseData?.contactId?.additionalSupportContact?.firstName ===
                "" && <CustomTableCell></CustomTableCell>}
            </CustomTableRow>
            {caseData?.contactId?.additionalSupportContact?.firstName !==
              "" && (
              <CustomTableRow>
                <CustomTableCell>
                  {caseData?.contactId?.additionalSupportContact?.firstName}
                </CustomTableCell>
                <CustomTableCell>
                  {caseData?.contactId?.additionalSupportContact?.lastName}
                </CustomTableCell>
                <CustomTableCell>
                  {
                    caseData?.contactId?.additionalSupportContact?.email[0]
                      ?.email
                  }
                </CustomTableCell>
                <CustomTableCell sx={{ textAlign: "center" }}>
                  {/* <IconButton>
                    <IconMessageCircle color="green" />
                  </IconButton> */}
                  <Tooltip title={"Edit Additional Contact"}>
                    <IconButton>
                      <IconEdit
                        cursor={"pointer"}
                        onClick={() => {
                          handleAddSupportContact({
                            data: caseData?.contactId,
                            type: "support",
                            contactId: caseData?.contactId?._id,
                            caseId: caseData?._id,
                          });
                        }}
                      />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title={"Delete Additional Contact"}>
                    <IconButton>
                      <IconTrash
                        style={{ color: "#D83630", cursor: "pointer" }}
                        onClick={() =>
                          handleSupportDelete({
                            data: caseData?.contactId,
                            contactId: caseData?.contactId?._id,
                            caseId: caseData?._id,
                          })
                        }
                      />
                    </IconButton>
                  </Tooltip>
                </CustomTableCell>
                {caseData?.contactId?.additionalSupportContact?.firstName ===
                  "" && <CustomTableCell></CustomTableCell>}
              </CustomTableRow>
            )}
          </Table>
        </Stack>
      </SubCard>
    </Grid>
  );
};

export default SupportContactsTabel;
