import { Box, DialogProps } from "@mui/material";
import BasicModal from "../../components/modal/BasicModal";
import PDFViewer from "../../components/pdfViewer";

interface Props extends DialogProps {
  onClose: () => void;
  buffer?: any;
  isAwsUrl?: boolean;
}

function ViewRepAgreementModal({
  onClose,
  buffer,
  isAwsUrl = false,
  ...props
}: Props) {
  return (
    <BasicModal
      size={"lg"}
      onClose={onClose}
      title={"Representation Agreement"}
      height={800}
      content={
        buffer ? (
          <Box>
            <PDFViewer buffer={buffer} isAwsUrl={isAwsUrl} />
          </Box>
        ) : (
          <Box display={"flex"} justifyContent={"center"}>
            No data found
          </Box>
        )
      }
      // actions={
      //   <Button
      //     color="error"
      //     variant="outlined"
      //     onClick={onClose}
      //     sx={{ textTransform: "capitalize" }}
      //   >
      //     Cancel
      //   </Button>
      // }
      {...props}
    />
  );
}

export default ViewRepAgreementModal;
