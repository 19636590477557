import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography,
} from "@mui/material";
// material-ui
import { useTheme } from "@mui/material/styles";
import { ReactNode } from "react";

export interface SubCardProps {
  children: ReactNode | string | null;
  content?: boolean;
  className?: string;
  contentClass?: string;
  darkTitle?: boolean;
  largeTitle?: boolean;
  secondary?: ReactNode | string;
  sx?: any;
  contentSX?: any;
  title?: ReactNode | string;
}

// ==============================|| CUSTOM SUB CARD ||============================== //

/**
 * A customizable card component that can optionally wrap its content in a form container.
 * @component SubCard
 * @author Priyanshu
 *
 */

const SubCard = ({
  children,
  className,
  contentSX = {}, // Default value here
  content = true, // Default value here
  contentClass,
  darkTitle,
  largeTitle,
  secondary,
  sx = {},
  title,
  ...others
}: SubCardProps) => {
  const theme = useTheme();

  return (
    <Card
      variant="outlined"
      sx={{
        borderRadius: 3,
        boxShadow: "0 2px 14px 0 #F3F6FE",
        ":hover": {
          boxShadow:
            theme.palette.mode === "dark"
              ? "0 2px 14px 0 rgb(33 150 243 / 10%)"
              : "0 2px 14px 0 rgb(32 40 45 / 8%)",
        },
        ...sx,
      }}
      {...others}
    >
      {/* card header and action */}
      {!darkTitle && title && (
        <CardHeader
          sx={{ p: 2 }}
          title={<Typography variant="h4">{title}</Typography>}
          action={secondary}
        />
      )}
      {darkTitle && title && (
        <CardHeader
          sx={{ p: 2 }}
          title={<Typography variant="h4">{title}</Typography>}
          action={secondary}
        />
      )}

      {/* content & header divider */}
      {title && (
        <Divider
          sx={{
            opacity: 1,
          }}
        />
      )}

      {/* card content */}
      {content && (
        <CardContent
          sx={{ p: 2.5, ...(contentSX ?? {}) }}
          className={contentClass || ""}
        >
          {children}
        </CardContent>
      )}
      {!content && children}
    </Card>
  );
};

export default SubCard;
