import { Units } from "../constants/MenuSelection";

export const getUnitLocation = (unit?: string) => {
  if (unit) {
    const searchPattern = new RegExp(unit.split(/\s+/).join("|"), "i");

    const address = Units?.find((data: any) =>
      searchPattern.test(data?.value)
    )?.address;
    return address;
  }

  return "";
};
