import { VisibilityOffOutlined, VisibilityOutlined } from "@mui/icons-material";
import { IconButton, InputAdornment } from "@mui/material";
import React, { MouseEvent, useState } from "react";
import TextField, { TextFieldProps } from "./inputs/MuiTextField";

export type PasswordProps = TextFieldProps;

const Password = React.memo((props: PasswordProps): JSX.Element => {
  const [password, setPassword] = useState<boolean>(true);
  return (
    <TextField
      {...props}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              onMouseDown={(e: MouseEvent<HTMLButtonElement>) =>
                e.preventDefault()
              }
              onClick={() => setPassword(!password)}
              tabIndex={-1}
            >
              {password ? <VisibilityOffOutlined /> : <VisibilityOutlined />}
            </IconButton>
          </InputAdornment>
        ),
      }}
      type={password ? "password" : "text"}
    />
  );
});

export default Password;
