import * as React from "react";
import { Stack, Box, Grid, Typography } from "@mui/material";
import laywerLogo from "../../../../assets/images/laywerLogo.png";
import { Link } from "react-router-dom";
import AuthCardWrapper from "../../../layouts/Wrappers/AuthCardWrapper";
import JWTLogin from "../../../components/authentication/JWTLogin";

export default function Login() {
  return (
    <AuthCardWrapper>
      <Grid
        container
        spacing={1}
        direction={"column"}
        sx={{
          display: "flex",
          justifyContent: "center",
          height: "100%",
          alignItems: "center",
        }}
      >
        <Box
          component="img"
          alt="The house from the offer."
          src={`${laywerLogo}`}
          width={"30%"}
          sx={{ alignSelf: "center", mb: 8 }}
        />
        <Stack sx={{ width: "50%", height: "fit-content" }} spacing={2}>
          <Grid item xs={12}>
            <Stack alignItems="flex-start" justifyContent="center" spacing={1}>
              <Typography gutterBottom variant={"h2"}>
                Log in
              </Typography>
              <Typography variant="subtitle1" textAlign="left">
                Enter your credentials to access your account
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <JWTLogin />
          </Grid>
          <Grid item xs={12}>
            <Grid item xs={12}>
              <Grid
                item
                container
                direction="column"
                alignItems="flex-end"
                xs={12}
              >
                <Link to="/forgotPassword" style={{ textDecoration: "none" }}>
                  <Typography color="secondary" variant="subtitle1">
                    Forgot your password?
                  </Typography>
                </Link>
              </Grid>
            </Grid>
          </Grid>
        </Stack>
      </Grid>
    </AuthCardWrapper>
  );
}
