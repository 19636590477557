import { Box, Button, DialogProps } from "@mui/material";
import useCases from "../../../hooks/useCases";
import BasicModal from "../../components/modal/BasicModal";
import { useSnackbarHelper } from "../../components/Snackbar";

interface Props extends DialogProps {
  onClose: () => void;
  data?: any;
  todoId?: string;
  caseId?: string;
}

const RestoreCasesModal = ({ onClose, data, id, ...props }: Props) => {
  // const { deleteTodo } = useTodo({ caseId });
  const { restoreCase, isLoading } = useCases(id);
  const showSnackbar = useSnackbarHelper();

  const handleRestoreCase = async () => {
    if (data?.contact?.caseId !== null) {
      showSnackbar(
        "Case restoration failed. Case Already exist with this Support Contact.",
        "error"
      );
    } else {
      const response = await restoreCase();
      const message = response?.message;
      showSnackbar(message, "success");
    }

    onClose();
  };

  return (
    <Box>
      <BasicModal
        onClose={onClose}
        onSubmit={handleRestoreCase}
        title="Restore Case"
        content="Do you really want to restore this case?"
        actions={
          <>
            <Button
              variant="contained"
              type="submit"
              color="success"
              sx={{ textTransform: "capitalize" }}
              disabled={isLoading}
            >
              Restore
            </Button>
            <Button
              variant="outlined"
              sx={{ textTransform: "capitalize" }}
              onClick={() => onClose()}
              color="error"
            >
              Cancel
            </Button>
          </>
        }
        {...props}
      />
    </Box>
  );
};

export default RestoreCasesModal;
