import { useMutation, useQuery } from "react-query";
import useAuth from "./useAuth";
import { GridPaginationModel, GridSortModel } from "@mui/x-data-grid";

/**
 * Custom hook for managing payment-related functionality
 * @hook usePayment
 * @author Rajni
 *
 */

export default function usePayment(
  caseId?: string,
  search?: string,
  paginationModel?: GridPaginationModel,
  sortModel?: GridSortModel,
  selectedValue?: any
) {
  const { request } = useAuth();

  // Fetch  all payments
  const {
    data: payment,
    refetch: refetchPayment,
    isRefetching,
    isLoading: isLoadingPayments,
  } = useQuery(
    ["/payment", search, paginationModel, sortModel, selectedValue],
    () =>
      request
        .get("/payment", {
          params: { search, paginationModel, sortModel, selectedValue },
        })
        .then(({ data }) => {
          return data as any;
        })
  );

  // fetch Payment scheduling of particular case
  const { data: paymentScheduling, refetch: refetchCasePaymentScheduling } =
    useQuery(
      ["/payment/case"],
      () =>
        request
          .get(`/payment/payment-scheduling/${caseId}`)
          .then(({ data }) => {
            return data as any;
          }),
      { enabled: !!caseId }
    );

  const { mutateAsync: addPaymentScheduling, isLoading: isPaymentLoading } =
    useMutation(
      "/paymentScheduling",
      (payload: any) =>
        request
          .post("/payment/payment-scheduling", payload)
          .then((response) => response as any),
      {
        onSuccess: () => {
          refetchPayment();
          refetchCasePaymentScheduling();
        },
      }
    );

  const { mutateAsync: viewReceipt, isLoading: isFetchingReceipt } =
    useMutation(
      "/receipt",
      (payload: any) =>
        request
          .post("/payment/receipt", payload)
          .then((response) => response as any),
      {
        onSuccess: () => {
          refetchPayment();
        },
      }
    );

  const { mutateAsync: deleteScheduling } = useMutation(
    "/payment",
    (schedulingId: any) =>
      request
        .delete(`/payment/${schedulingId}`)
        .then((response) => response as any),
    {
      onSuccess: () => {
        refetchPayment();
        refetchCasePaymentScheduling();
      },
    }
  );

  const { mutateAsync: updateScheduling, isLoading: isUpdatingPayment } =
    useMutation(
      "/payments",
      ({ schedulingId, payload }: any) =>
        request
          .put(`/payment/${schedulingId}`, payload)
          .then((response) => response as any),
      {
        onSuccess: () => {
          refetchPayment();
          refetchCasePaymentScheduling();
        },
      }
    );

  return {
    payment,
    isLoadingPayments,
    viewReceipt,
    isFetchingReceipt,
    refetchPayment,
    addPaymentScheduling,
    isPaymentLoading,
    totalPayments: payment?.totalPayments,
    updateScheduling,
    deleteScheduling,
    paymentScheduling,
    isUpdatingPayment,
    isLoading: isPaymentLoading,
    isRefetching,
    refetchCasePaymentScheduling,
  };
}
