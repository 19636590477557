import { useMutation, useQuery, useQueryClient } from "react-query";
import useAuth from "./useAuth";
import { GridPaginationModel, GridSortModel } from "@mui/x-data-grid";

/**
 *  Custom hook for managing todo operations related to a case.
 * @hook useTodo
 * @author Priyanshu
 *
 */

export default function useTodo(
  caseId?: string,
  search?: string,
  paginationModel?: GridPaginationModel,
  sortModel?: GridSortModel,
  isRefetchProgressHistory = false,
  isMasterTodoList = false
) {
  const { request } = useAuth();

  const queryClient = useQueryClient();

  const handleRefetchTodos = () => {
    queryClient.invalidateQueries(["/todo"]);
  };

  // Fetch  all todo
  const {
    data: allTodo,
    refetch: refetchTodo,
    isLoading: isLoadingAllTodos,
    isRefetching,
  } = useQuery(["/todo", search, paginationModel, sortModel], () =>
    request
      .get("/todo", {
        params: { search, paginationModel, sortModel },
      })
      .then(({ data }) => {
        return data as any;
      })
  );

  // Fetch todos related to a specific case
  const {
    data: caseTodo,
    refetch: refetchCaseTodo,
    isLoading: isLoadingCaseTodo,
  } = useQuery(
    [`/todo/case/${caseId}`],
    () =>
      request.get(`/todo/case/${caseId}`).then(({ data }) => {
        return data as any;
      }),
    { enabled: !!caseId }
  );

  // Fetch progress history related to a case
  const {
    data: progressHistory,
    refetch: refetchProgressHistory,
    isLoading: isLoadingProgressHistory,
  } = useQuery(
    [`cases/${caseId}/progressHistory`],
    () =>
      request.get(`cases/${caseId}/progressHistory`).then(({ data }) => {
        return data?.progressHistory as any;
      }),
    { enabled: !!caseId }
  );

  // Mutation to add a new todo
  const { mutateAsync: addTodo, isLoading: isTodoLoading } = useMutation(
    "/addTodo",
    (payload: any) =>
      request.post("/todo", payload).then((response) => response as any),
    {
      onSuccess: () => {
        isMasterTodoList ? handleRefetchTodos() : refetchCaseTodo();
        isRefetchProgressHistory && refetchProgressHistory();
      },
    }
  );

  // Mutation to update an existing todo
  const { mutateAsync: updateTodo, isLoading: isUpdateTodoLoading } =
    useMutation(
      "/updateTodo",
      ({ todoId, payload }: any) =>
        request
          .put(`/todo/${todoId}`, payload)
          .then((response) => response as any),
      {
        onSuccess: () => {
          isMasterTodoList ? handleRefetchTodos() : refetchCaseTodo();
          isRefetchProgressHistory && refetchProgressHistory();
        },
      }
    );

  // Mutation to delete an existing todo

  const { mutateAsync: deleteTodo } = useMutation(
    "/deleteTodo",
    (todoId: any) =>
      request.delete(`/todo/${todoId}`).then((response) => response as any),
    {
      onSuccess: () => {
        isMasterTodoList ? handleRefetchTodos() : refetchCaseTodo();
        isRefetchProgressHistory && refetchProgressHistory();
      },
    }
  );

  // Mutation to mark a todo as complete
  const {
    mutateAsync: markTodoAsComplete,
    isLoading: isMarkingAsCompleteTodo,
  } = useMutation(
    `/todo/todoId/completed`,
    ({ todoId, payload }: any) =>
      request
        .put(`/todo/${todoId}/completed`, payload)
        .then((response) => response as any),
    {
      onSuccess: () => {
        isMasterTodoList ? handleRefetchTodos() : refetchCaseTodo();
        isRefetchProgressHistory && refetchProgressHistory();
      },
    }
  );

  return {
    allTodo: allTodo?.todo,
    totalTodo: allTodo?.totalTodo,
    caseTodo: caseTodo?.todos,
    isLoadingCaseTodo,
    refetchTodo,
    addTodo,
    isLoading: isTodoLoading || isUpdateTodoLoading,
    isRefetching,
    refetchCaseTodo,
    deleteTodo,
    updateTodo,
    progressHistory,
    refetchProgressHistory,
    isLoadingProgressHistory,
    markTodoAsComplete,
    isMarkingAsCompleteTodo,
    isLoadingAllTodos,
  };
}
