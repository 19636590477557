export function getParoleStatus(str: string) {
  if (str?.toLowerCase().includes("denied")) {
    return "DENIED";
  }
  if (str?.toLowerCase().includes("denied")) {
    return "APPROVED";
  }

  return "-";
}
