import { Box, DialogProps, Stack } from "@mui/material";
import { useEffect, useMemo } from "react";

import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import useNotifications from "../../../../hooks/useNotifications";
import BasicButton from "../../../components/buttons/BasicButton";
import NumberField from "../../../components/inputs/NumberField";
import RadioButtonGroup from "../../../components/inputs/RadioButtonGroup";
import Select from "../../../components/inputs/Select";
import BasicModal from "../../../components/modal/BasicModal";
import { useSnackbarHelper } from "../../../components/Snackbar";

// import io from "socket.io-client";

interface Props extends DialogProps {
  onClose: () => void;
  notification: any;
}
const NotificationModal = ({ onClose, notification, ...props }: Props) => {
  const showSnackbar = useSnackbarHelper();
  const { updateNotification, isUpdatingNotification } = useNotifications(
    notification?._id
  );

  const initialValues: any = useMemo(
    () => ({
      continueRepresentingThisCase: "yes",
      paymentType: null,
      price: null,
    }),
    []
  );

  const validationSchema: any = Yup.object({
    continueRepresentingThisCase: Yup.string().required("Required"),
    paymentType: Yup.string().when(
      "continueRepresentingThisCase",
      (continueRepresentingThisCase, schema) => {
        return continueRepresentingThisCase?.[0] === "yes"
          ? schema.required("Required")
          : schema.notRequired();
      }
    ),
    price: Yup.string().when(
      "continueRepresentingThisCase",
      (continueRepresentingThisCase, schema) =>
        continueRepresentingThisCase?.[0] === "yes"
          ? schema.required("Required")
          : schema.notRequired()
    ),
  });

  const formContext = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
  });

  const { watch, setValue } = formContext;

  const continueRepresentingThisCase = watch("continueRepresentingThisCase");
  const paymentType = watch("paymentType");

  useEffect(() => {
    if (paymentType === "free") {
      setValue("price", 0);
    }
    if (paymentType === "flat") {
      setValue("price", 1000);
    }
    if (paymentType === "custom") {
      setValue("price", "");
    }
  }, [paymentType, setValue]);

  const handleSubmit = async (values?: any) => {
    try {
      const caseId = notification?.caseId?._id || null;
      const data: any = await updateNotification({ caseId, ...values });

      const message = data?.message || "Success";
      showSnackbar(message, "success");
      onClose();
    } catch (error: any) {
      const message = error?.response?.data?.message || "An error occurred";

      showSnackbar(message, "error");
    }
  };

  return (
    <BasicModal
      onClose={onClose}
      formContext={formContext}
      onSubmit={handleSubmit}
      title={notification?.type || ""}
      content={
        <Stack spacing={2}>
          <Box>{notification?.message || ""}</Box>
          <RadioButtonGroup
            name="continueRepresentingThisCase"
            options={[
              { label: "Yes", id: "yes" },
              { label: "No", id: "no" },
            ]}
            align="row"
          />
          {continueRepresentingThisCase === "yes" && (
            <Stack direction={"row"} spacing={1}>
              <Select
                name="paymentType"
                label="Payment Type"
                options={[
                  { label: "Free", value: "free" },
                  { label: "Flat - $1000", value: "flat" },
                  { label: "Custom", value: "custom" },
                ]}
                required
              />

              <NumberField
                name="price"
                label="Price"
                prefix="$ "
                max={999999}
                required
                disabled={paymentType !== "custom"}
              />
            </Stack>
          )}
        </Stack>
      }
      actions={
        <Stack direction={"row"} spacing={1}>
          <BasicButton
            color="error"
            variant="outlined"
            onClick={() => {
              onClose();
            }}
            label={"Cancel"}
          />
          <BasicButton
            variant="contained"
            color="success"
            type="submit"
            disabled={isUpdatingNotification}
            label={"Save"}
          />
        </Stack>
      }
      {...props}
    />
  );
};

export default NotificationModal;
