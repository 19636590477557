import { useMutation } from "react-query";
import useAuth from "./useAuth";

/**
 * Custom hook for managing the risk item form related to a case
 * @hook useRiskItemForm
 * @author Tanish
 *
 */

const useRiskItemForm = (caseId: any) => {
  const { request } = useAuth();

  const { mutateAsync: addRiskItemForm, isLoading } = useMutation(
    "/cases/:id/riskItemForm",
    (payload: any) => request.put(`/cases/${caseId}/riskItemForm`, payload)
  );

  return { addRiskItemForm, isRiskItemFormLoading: isLoading };
};

export default useRiskItemForm;
