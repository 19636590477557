import { useMutation, useQueryClient } from "react-query";
import useAuth from "./useAuth";

/**
 * custom hook for letters
 * @hook
 * @author Tushar Sharma
 */

interface Props {
  caseId?: string;
  letterId?: string;
}

export default function useLetters({ caseId, letterId }: Props) {
  const { request } = useAuth();
  const queryClient = useQueryClient();
  const handleRefetchUsers = () => {
    queryClient.invalidateQueries(["/cases"]);
  };

  const { mutateAsync: getLetterData, isLoading: isLetterLoading } =
    useMutation(
      `/cases/${caseId}/letters`,
      ({ template, sendMail, recipient }: any) =>
        request.post(`/cases/${caseId}/letters`, {
          template,
          sendMail,
          recipient,
        })
    );

  const { mutateAsync: addCaseLetter, isLoading: isCaseLetterAdding } =
    useMutation(
      `/cases/${caseId}/letters`,
      (payload: any) =>
        request
          .put(`/cases/${caseId}/letters`, payload)
          .then((response) => response?.data as any),
      {
        onSuccess: () => handleRefetchUsers(),
      }
    );
  const { mutateAsync: deleteLetter, isLoading: letterLoading } = useMutation(
    `/cases/${caseId}/letters/${letterId}`,
    () =>
      request
        .delete(`/cases/${caseId}/letters/${letterId}`)
        .then((response) => response?.data),
    {
      onSuccess: () => handleRefetchUsers(),
    }
  );
  return {
    addCaseLetter,
    isCaseLetterAdding,
    getLetterData,
    isLetterLoading,
    deleteLetter,
    letterLoading,
  };
}
