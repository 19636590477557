import {
  Autocomplete,
  Box,
  Button,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  GridActionsCellItem,
  GridColDef,
  GridColumnHeaderParams,
  GridPaginationModel,
  GridSortModel,
} from "@mui/x-data-grid";
import { IconEye, IconReceipt } from "@tabler/icons-react";
import { useModal } from "mui-modal-provider";
import { useCallback, useRef, useState } from "react";
import MainCard from "../../components/cards/MainCard";
import DataGridTable from "../../components/DataGridTable";
import SearchField from "../../components/inputs/SearchField";
import usePayment from "../../../hooks/usePayment";
import moment from "moment";
import { useNavigate } from "react-router";
import AddPaymentsModal from "../cases/AddPaymentsModal";
import { debounce } from "lodash";
import ViewReceiptModal from "./ViewReceiptModal";
import useAuth from "../../../hooks/useAuth";
import useUser from "../../../hooks/useUser";

const paymentFilterOptions = [
  { label: "All", value: "all" },
  {
    label: "Pending",
    value: "pending",
  },
  { label: "Completed", value: "completed" },
];

export default function Payments() {
  const [search, setSearch] = useState("");
  const [selectedValue, setSelectedValue] = useState(paymentFilterOptions[0]);
  const { userId } = useAuth();
  const { isStaff } = useUser(userId);

  const [sortModel, setSortModel] = useState<GridSortModel>([
    { field: "createdAt", sort: "desc" },
  ]);
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    page: 0,
    pageSize: 12,
  });
  const { showModal } = useModal();
  const navigate = useNavigate();
  const {
    payment,
    refetchPayment,
    totalPayments,
    isRefetching,
    isLoadingPayments,
  } = usePayment(
    undefined,
    search,
    paginationModel,
    sortModel,
    selectedValue?.value
  );

  const debouncedSetSearch = useRef(
    debounce((value) => setSearch(value), 400)
  ).current;

  const handleSearchChange = useCallback(
    (event: any) => {
      debouncedSetSearch(event.target.value);
    },
    [debouncedSetSearch]
  );

  const handleChange = (event: any, newValue: any) => {
    setSelectedValue(newValue);
  };

  // const handleAddNewPayment = useCallback(() => {
  //   const modal: any = showModal(AddNewPaymentModal, {
  //     onClose: () => modal.hide(),
  //   });
  // }, [showModal]);

  const handleViewPayment = (row: any) => {
    navigate(`/cases/${row?.caseId?._id}?tab=SupportBilling`);
  };

  // const handleAllPayments =(values: any) => {
  //   values?.row?.every(
  //     (payment: any) => payment.isPaid === true
  //   );
  // }

  const handleViewReceipt = useCallback(
    (row: any) => {
      const modal: any = showModal(ViewReceiptModal, {
        onClose: () => modal.hide(),
        data: row,
      });
    },
    [showModal]
  );

  const handlePay = useCallback(
    (paymentScheduling: any, payment_spread: any) => {
      const case_Id = { id: paymentScheduling?._id };

      const modal: any = showModal(AddPaymentsModal, {
        onClose: () => modal.hide(),
        caseId: case_Id,
        data: paymentScheduling,
        contactName:
          paymentScheduling?.clientId?.firstName +
          " " +
          paymentScheduling?.clientId?.lastName,
        clientEmail: paymentScheduling?.clientId?.email[0]?.email,
        clientZip: paymentScheduling?.clientId?.zip,
        payment_spread,
        refetchPayment,
      });
    },
    //eslint-disable-next-line
    [showModal]
  );

  const handleSortModelChange = (newModel: any[]) => {
    setSortModel(newModel);
  };

  const columns: GridColDef[] = [
    {
      field: "dueDate",
      headerName: "Due Date",
      headerClassName: "MuiDataGrid-cell",
      minWidth: 200,
      renderCell: (params: any) => (
        <Box style={{ textTransform: "capitalize", marginLeft: 20 }}>
          {moment(
            params?.row?.payment_spreads?.[
              params?.row?.payment_spreads?.length - 1
            ]?.dueDate
          ).format("MM/DD/YYYY")}
        </Box>
      ),
      renderHeader: (params: GridColumnHeaderParams) => (
        <strong style={{ fontSize: 16, marginLeft: 20 }}>
          {params?.colDef?.headerName}
        </strong>
      ),
    },
    {
      field: "caseNo",
      headerName: "Case ID#",
      minWidth: 150,
      renderCell: (params: any) => (
        <Box style={{ textTransform: "capitalize", marginLeft: 20 }}>
          {params?.row?.caseId?.caseId || "-"}
        </Box>
      ),
      renderHeader: (params: GridColumnHeaderParams) => (
        <strong style={{ fontSize: 16, marginLeft: 20 }}>
          {params?.colDef?.headerName}
        </strong>
      ),
    },
    {
      field: "clientName",
      headerName: "Client Name",
      headerClassName: "MuiDataGrid-cell",
      minWidth: 200,
      renderCell: (params: any) => (
        <Box style={{ textTransform: "capitalize", marginLeft: 20 }}>
          {params?.row?.clientId?.firstName} {params?.row?.clientId?.lastName}
        </Box>
      ),
      renderHeader: (params: GridColumnHeaderParams) => (
        <strong style={{ fontSize: 16, marginLeft: 20 }}>
          {params?.colDef?.headerName}
        </strong>
      ),
    },
    {
      field: "paymentInterval",
      headerName: "Payment Interval",
      headerClassName: "MuiDataGrid-cell",
      minWidth: 200,
      renderCell: (params: any) => (
        <Box style={{ textTransform: "capitalize", marginLeft: 20 }}>
          {params?.row?.paymentInterval}
        </Box>
      ),
      renderHeader: (params: GridColumnHeaderParams) => (
        <strong style={{ fontSize: 16, marginLeft: 20 }}>
          {params?.colDef?.headerName}
        </strong>
      ),
    },
    {
      field: "paymentMethod",
      headerName: "Payment Method",
      headerClassName: "MuiDataGrid-cell",
      minWidth: 200,
      renderCell: (params: any) => (
        <Box style={{ textTransform: "capitalize", marginLeft: 20 }}>
          {params?.row?.type}
        </Box>
      ),
      renderHeader: (params: GridColumnHeaderParams) => (
        <strong style={{ fontSize: 16, marginLeft: 20 }}>
          {params?.colDef?.headerName}
        </strong>
      ),
    },

    {
      field: `amount`,
      headerName: "Installment",
      minWidth: 200,
      renderCell: (params: any) => (
        <Box style={{ textTransform: "capitalize", marginLeft: 20 }}>
          ${" "}
          {params?.row?.payment_spreads?.find((val: any) => !val?.isPaid)
            ?.payment || 0}
        </Box>
      ),
      renderHeader: (params: GridColumnHeaderParams) => (
        <strong style={{ fontSize: 16, marginLeft: 20 }}>
          {params?.colDef?.headerName}
        </strong>
      ),
    },

    {
      field: "totalPaid",
      headerName: "Total Paid",
      headerClassName: "MuiDataGrid-cell",
      minWidth: 200,
      renderCell: (params: any) => (
        <Box style={{ textTransform: "capitalize", marginLeft: 20 }}>
          $ {params?.row?.totalPaidAmount}
        </Box>
      ),
      renderHeader: (params: GridColumnHeaderParams) => (
        <strong style={{ fontSize: 16, marginLeft: 20 }}>
          {params?.colDef?.headerName}
        </strong>
      ),
    },
    {
      field: "balance",
      headerName: "Balance Due (USD)",
      minWidth: 200,
      flex: 1,
      renderCell: (params: any) => (
        <Box style={{ textTransform: "capitalize", marginLeft: 20 }}>
          ${" "}
          {Number(params?.row?.feeQuoted) -
            Number(params?.row?.totalPaidAmount)}
        </Box>
      ),
      renderHeader: (params: GridColumnHeaderParams) => (
        <strong style={{ fontSize: 16, marginLeft: 20 }}>
          {params?.colDef?.headerName}
        </strong>
      ),
    },
    {
      field: "actions",
      type: "actions",
      headerClassName: "MuiDataGrid-cell",
      minWidth: 200,
      cellClassName: "actions",
      getActions: (value: any) => {
        const options = value?.row?.payment_spreads?.filter(
          (val: any) => val?.isPaid
        );
        return [
          <Tooltip title="View in case">
            <GridActionsCellItem
              icon={<IconEye />}
              label="view"
              onClick={() => {
                handleViewPayment(value?.row);
              }}
            />
          </Tooltip>,
          <Tooltip title="View Receipt">
            <span>
              <GridActionsCellItem
                disabled={options?.length > 0 ? false : true}
                icon={<IconReceipt />}
                label="view"
                onClick={() => {
                  handleViewReceipt(value?.row);
                }}
              />
            </span>
          </Tooltip>,
          <Tooltip title="Payment Made">
            <span>
              {!isStaff ? (
                <GridActionsCellItem
                  disabled={
                    value?.row?.type === "lawpay" ||
                    value?.row?.isPaymentCompleted
                  }
                  icon={
                    <Button
                      variant="contained"
                      size="small"
                      disabled={
                        value?.row?.type === "lawpay" ||
                        value?.row?.isPaymentCompleted
                      }
                      color="success"
                      onClick={() => {
                        const findPaymentSpread =
                          value?.row?.payment_spreads?.find(
                            (val: any) => val?.isPaid === false
                          );
                        if (findPaymentSpread) {
                          handlePay(value?.row, findPaymentSpread);
                        }
                      }}
                    >
                      {Number(value?.row?.feeQuoted) ===
                      Number(value?.row?.totalPaidAmount)
                        ? "Paid"
                        : "Pay"}
                    </Button>
                  }
                  label="view"
                />
              ) : (
                <Typography></Typography>
              )}
            </span>
          </Tooltip>,
        ];
      },
      renderHeader: (params: GridColumnHeaderParams) => (
        <strong style={{ fontSize: 18 }}>Actions</strong>
      ),
    },
  ];

  return (
    <MainCard
      title={
        <Typography variant="h1" sx={{ mx: 2 }}>
          Payments
        </Typography>
      }
      actions={
        <Stack
          direction={"row"}
          alignItems={"center"}
          spacing={4}
          sx={{ mr: 2 }}
        >
          <Autocomplete
            disablePortal
            size="small"
            options={paymentFilterOptions}
            getOptionLabel={(option) => option.label}
            value={selectedValue}
            sx={{ width: 160 }}
            onChange={handleChange}
            renderInput={(params) => <TextField {...params} label="Filter" />}
          />
          <SearchField
            onChange={handleSearchChange}
            size="small"
            placeholder="Search payment"
          />
        </Stack>
      }
      content={
        <>
          <DataGridTable
            onPaginationModelChange={setPaginationModel}
            paginationModel={paginationModel}
            column={columns}
            sortModel={sortModel}
            isLoading={isLoadingPayments || isRefetching}
            totalRows={totalPayments}
            rows={payment?.payment}
            onSortModelChange={handleSortModelChange}
            checkboxSelection={false}
          />
        </>
      }
    />
  );
}
