import { Grid, Stack, Typography, Box, Button } from "@mui/material";

import AuthCardWrapper from "../../../layouts/Wrappers/AuthCardWrapper";
import JWTTwoFactorAuthentication from "../../../components/authentication/JWTTwoFactorAuthentication";
import laywerLogo from "../../../../assets/images/laywerLogo.png";
import { useCallback, useState } from "react";
import useUser from "../../../../hooks/useUser";
import { useSnackbarHelper } from "../../../components/Snackbar";
import { useNavigate } from "react-router";

// ============================|| AUTH1 - TWO FACTOR AUTHENTICATION ||============================ //
const TwoFactorAuthentication = () => {
  const { resendOTP, isResendOTPLoading } = useUser();
  const showSnackbar = useSnackbarHelper();
  const navigate = useNavigate();
  const [showCount, setShowCount] = useState(false);
  const [count, setCount] = useState<number>();
  const handleResendOTP = useCallback(async () => {
    const email = localStorage.getItem("verifyEmail");
    const payload = {
      email,
    };
    try {
      const response = await resendOTP(payload);
      const message = response?.message;
      setShowCount(true);
      setCount(3 - response?.resendOptCount);
      showSnackbar(message, "success");
    } catch (err: any) {
      const response = err?.response;
      const message = response?.data?.message;
      setShowCount(false);
      showSnackbar(message, "error");

      if (response?.status === 429) {
        setTimeout(() => {
          navigate("/login");
        }, 1200);
      }
    }

    //eslint-disable-next-line
  }, []);

  return (
    <AuthCardWrapper>
      <Grid
        container
        spacing={1}
        direction={"column"}
        sx={{
          display: "flex",
          justifyContent: "center",
          height: "100%",
          alignItems: "center",
        }}
      >
        <Box
          component="img"
          alt="The house from the offer."
          src={`${laywerLogo}`}
          width={"30%"}
          sx={{ alignSelf: "center", mb: 8 }}
        />
        <Stack sx={{ width: "50%", height: "fit-content" }} spacing={2}>
          <Grid item xs={12}>
            <Stack alignItems="flex-start" justifyContent="center" spacing={1}>
              <Typography
                // color="black"
                gutterBottom
                variant={"h2"}
              >
                Two Step Verification
              </Typography>
              <Typography variant="subtitle1" textAlign="left">
                Enter your code that has been sent to your registered phone
                number.
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <JWTTwoFactorAuthentication />
          </Grid>

          <Grid container direction={"row"} alignItems={"center"}>
            {showCount && (
              <Grid item xs={6}>
                <Typography color={"#9c27b0"} fontSize={"15px"} pr={2}>
                  {count} Attempt left
                </Typography>
              </Grid>
            )}
            <Grid item xs={showCount ? 6 : 12}>
              <Grid
                item
                container
                direction="column"
                alignItems="flex-end"
                // xs={12}
              >
                <Button
                  color="secondary"
                  sx={{
                    align: "right",
                    backgroundColor: "transparent",
                    "&:hover": {
                      backgroundColor: "transparent",
                    },
                  }}
                  onClick={handleResendOTP}
                  disabled={isResendOTPLoading}
                >
                  Resend OTP?
                </Button>
                {/* <Typography color={"#9c27b0"} variant="subtitle2" pr={2}>
                  2 Atempt left
                </Typography> */}
              </Grid>
            </Grid>
          </Grid>
        </Stack>
      </Grid>
    </AuthCardWrapper>
  );
};
export default TwoFactorAuthentication;
