import { Box, DialogProps, Grid, Stack } from "@mui/material";
import Typography from "@mui/material/Typography";

import { yupResolver } from "@hookform/resolvers/yup";
import moment from "moment";
import { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import * as Yup from "yup";
import useParoleEligibility from "../../../hooks/useParoleEligibility";
import BasicButton from "../buttons/BasicButton";
import BasicAutocomplete from "../inputs/BasicAutocomplete";
import MuiTextField from "../inputs/MuiTextField";
import RadioButtonGroup from "../inputs/RadioButtonGroup";
import BasicModal from "../modal/BasicModal";
import { useSnackbarHelper } from "../Snackbar";

//Denial codes for parole decisions
const DENIAL_CODES = [
  { label: "(1D) - Criminal History", value: "(1D) - Criminal History" },
  { label: "(2D) - Nature of Offense", value: "(2D) - Nature of Offense" },
  {
    label: "(3D) - Drug or Alcohol Involvement",
    value: "(3D) - Drug or Alcohol Involvement",
  },
  {
    label: "(4D) - Institutional Adjustment",
    value: "(4D) - Institutional Adjustment",
  },
  {
    label: "(5D) - Adjustment During Periods of Supervision",
    value: "(5D) - Adjustment During Periods of Supervision",
  },
  {
    label: "(6D) - Participation in TDCJ Programs",
    value: "(6D) - Participation in TDCJ Programs",
  },
  { label: "(7D) - Time Served", value: "(7D) - Time Served" },
  { label: "(8D) - Felony Offense", value: "(8D) - Felony Offense" },
  {
    label: "(9D1) - DMS Accrued Good Conduct Time",
    value: "(9D1) - DMS Accrued Good Conduct Time",
  },
  {
    label: "(9D2) - DMS Release Would Endanger the Public",
    value: "(9D2) - DMS Release Would Endanger the Public",
  },
  { label: "(10D) - Gang Affiliation", value: "(10D) - Gang Affiliation" },
  { label: "(11D) - Other", value: "(11D) - Other" },
];

interface Props extends DialogProps {
  onClose: () => void;
  record?: any;
  setOpenDrawer?: any;
}

/**
 *  MoveEligibilityDialog component for handling parole eligibility decisions.
 * @component MoveEligibilityDialog
 * @author Priyanshu
 *
 */
export default function MoveEligibilityDialog({
  record,
  setOpenDrawer,
  onClose,
  ...props
}: Props) {
  const navigate = useNavigate();
  const showSnackbar = useSnackbarHelper();
  const { updateParoleDecision, isUpdatingParoleDecision } =
    useParoleEligibility(record?.caseId);

  const initialValues = useMemo(
    () => ({
      decision: record?.paroleDecision?.decision || "conduct",
      conductReason: record?.paroleDecision?.conductReason || "",
      denialReason: record?.paroleDecision?.denialReason || [],
    }),
    [record]
  );

  const validationSchema: any = useMemo(
    () =>
      Yup.object({
        decision: Yup.string().required("Required"),
        conductReason: Yup.string().when("decision", (decision, schema) => {
          return decision?.[0] === "conduct"
            ? schema.required("Required")
            : schema.notRequired();
        }),
        denialReason: Yup.array().when("decision", (decision, schema) =>
          decision?.[0] === "denial"
            ? schema.of(Yup.string().required()).min(1, "Required")
            : schema.notRequired()
        ),
      }),
    []
  );

  const formContext = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
  });

  const { watch, setValue } = formContext;
  const decision = watch("decision");

  useEffect(() => {
    if (decision === "conduct") {
      setValue("denialReason", []);
    }

    if (decision === "denial") {
      setValue("conductReason", "");
    }
  }, [decision, setValue]);

  const handleSubmit = async (values: any) => {
    try {
      const data: any = await updateParoleDecision(values);

      const message = data?.message || "Success";

      showSnackbar(message, "success");
      onClose();
    } catch (error: any) {
      const message = error?.response?.data?.message || "An error occurred";

      showSnackbar(message, "error");
    }
  };

  return (
    <>
      <BasicModal
        onClose={onClose}
        formContext={formContext}
        onSubmit={handleSubmit}
        style={{ bgcolor: "#E6E6E6", border: "2px solid grey" }}
        title={
          <Stack spacing={0.5}>
            <Typography variant="h5" fontWeight={"bold"}>
              Select Parole Decision
            </Typography>
            <Stack direction={"row"} spacing={1}>
              <Typography variant="subtitle2" fontWeight={"bold"}>
                {record?.name}
              </Typography>
              <Box>
                {record?.date && (
                  <Typography variant="subtitle2">
                    {`(${moment(record?.date).format("MM/DD/YYYY")})`}
                  </Typography>
                )}
              </Box>
            </Stack>
          </Stack>
        }
        content={
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <RadioButtonGroup
                name="decision"
                options={[
                  { label: "Conduct", id: "conduct" },
                  { label: "Denial", id: "denial" },
                ]}
                align="row"
              />
            </Grid>

            <Grid item xs={12}>
              {decision === "conduct" && (
                <MuiTextField
                  fullWidth
                  sx={{ height: "80px" }}
                  multiline
                  rows={3}
                  label="Enter Your Text Here"
                  name="conductReason"
                />
              )}

              {decision === "denial" && (
                <BasicAutocomplete
                  disablePortal={false}
                  freeSolo
                  name="denialReason"
                  label="Please Select Reason For Denial"
                  options={DENIAL_CODES}
                  getOptionValue={(option: any) => option?.value}
                  multiple={true}
                  required
                />
              )}
            </Grid>
          </Grid>
        }
        actions={
          <Stack
            p={0.5}
            spacing={2}
            direction={"row"}
            sx={{
              width: "100%",
              justifyContent: "flex-end",
            }}
          >
            <BasicButton
              label={"View Case"}
              variant="outlined"
              color="error"
              // size="small"
              onClick={() => {
                setOpenDrawer(null);
                navigate(`/cases/${record?.caseId}`);
                onClose();
              }}
            />

            <BasicButton
              label={"Save Changes"}
              type="submit"
              variant="contained"
              color="success"
              sx={{
                textTransform: "capitalize",
              }}
              // size="small"
              disabled={isUpdatingParoleDecision}
            />
          </Stack>
        }
        {...props}
      />
    </>
  );
}
