import { useMutation } from "react-query";
import useAuth from "./useAuth";

/**
 * Custom hook for scraping data with a specific TDCJ ID
 * @hook useScrappingWithTdcj
 * @author Sanjay
 *
 */

export default function useScrappingWithTdcj() {
  const { request } = useAuth();

  const { mutateAsync: scrapDataWithTdcj, isLoading: isTdcjDataLoading } =
    useMutation("/get-site-data/get-with-tdcj", (payload: any) =>
      request
        .post("/get-site-data/get-with-tdcj", payload)
        .then((response) => response?.data as any)
    );

  return {
    scrapDataWithTdcj,
    isTdcjDataLoading,
  };
}
