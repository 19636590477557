import { useMutation, useQuery } from "react-query";
import useAuth from "./useAuth";

/**
 * Custom hook to manage notifications, mark them as read, and update them.
 * @hook useNotifications
 * @author Priyanshu
 */

export default function useNotifications(notificationId?: string) {
  const { request } = useAuth();

  // Query to fetch all notifications.

  const {
    data: notificationData,
    isLoading: isLoadingNotifications,
    refetch,
  } = useQuery("/notifications", () =>
    request.get("/notifications").then((response) => response?.data)
  );

  const { data: notiData, isLoading: isLoadingNotification } = useQuery(
    `/notifications/${notificationId}`,
    () =>
      request
        .get(`/notifications/${notificationId}`)
        .then((response) => response?.data),
    {
      enabled: !!notificationId,
    }
  );

  const { mutateAsync: readNotification, isLoading: isReadingNotification } =
    useMutation(
      "/notifications/notificationId/read",
      ({ notificationId }: any) =>
        request
          .put(`/notifications/${notificationId}/read`, {
            isRead: true,
          })
          .then((response) => response?.data as any),
      {
        onSuccess: () => refetch(),
      }
    );

  const { mutateAsync: updateNotification, isLoading: isUpdatingNotification } =
    useMutation(
      `/notifications/${notificationId}`,
      (payload: any) =>
        request
          .put(`/notifications/${notificationId}`, payload)
          .then((response) => response?.data as any),
      {
        onSuccess: () => refetch(),
      }
    );

  return {
    notifications: notificationData?.notifications,
    readCount: notificationData?.readCount,
    isLoadingNotifications,
    readNotification,
    isReadingNotification,
    updateNotification,
    isUpdatingNotification,
    notification: notiData?.notification,
    isLoadingNotification,
  };
}
