import SendIcon from "@mui/icons-material/Send";
import {
  Avatar,
  Box,
  ButtonBase,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import Badge from "@mui/material/Badge";
import { IconMessage } from "@tabler/icons-react";
import { debounce } from "lodash";
import moment from "moment";
import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import useAuth from "../../../hooks/useAuth";
import useSMS from "../../../hooks/useSMS";
import useUser from "../../../hooks/useUser";
import SearchField from "../../components/inputs/SearchField";
import TableSkeleton from "../../components/skeleton/TableSkeleton";
import { useSnackbarHelper } from "../../components/Snackbar";

function searchContactList(records: any[], query: string) {
  if (!query) return records;

  const lowerCaseQuery = query.toLowerCase();

  return records?.filter((record) => {
    return (
      (record?.name && record?.name.toLowerCase().includes(lowerCaseQuery)) ||
      (record?.caseUniqueID &&
        record?.caseUniqueID.toLowerCase().includes(lowerCaseQuery)) ||
      (record?.phone && record?.phone.toLowerCase().includes(lowerCaseQuery))
    );
  });
}

function addAndSortUnreadCounts(contacts: any[], unreadMessageCounts: any[]) {
  // Map phone numbers to their unread message count
  const unreadCountMap = unreadMessageCounts.reduce(
    (map, { sentTo, totalUnreadCount }) => {
      map[sentTo] = totalUnreadCount;
      return map;
    },
    {}
  );

  // Add unread count to contacts and sort them
  return contacts
    .map((contact) => ({
      ...contact,
      unreadCount: unreadCountMap[contact.phone] || 0,
    }))
    .sort((a, b) => b.unreadCount - a.unreadCount);
}

const Messages = ({ contacts, isCase }: any) => {
  const { userId } = useAuth();
  const { user } = useUser(userId);

  const [contactList, setContactList] = useState<any[] | null>(null);
  const [selectedContact, setSelectedContact] = useState<any | null>(null);

  const [isFirstRender, setFirstRender] = useState(true);

  const {
    sendMessage,
    isSendingMessage,
    messages,
    isLoadingMessages,
    readMessage,
    unreadMessageCounts,
  } = useSMS(undefined, undefined, selectedContact?.phone);

  const [message, setMessage] = useState("");
  const [search, setSearch] = useState<string>("");
  const chatEndRef: any = useRef<HTMLDivElement | null>(null);

  const showSnackbar = useSnackbarHelper();

  const loggedUser = {
    name: `${user?.firstName} ${user?.lastName}`,
    avatar: user?.profileImg || "/path/to/admin-avatar.png",
  };

  // Trigger scroll whenever the chat history changes (new messages)
  useLayoutEffect(() => {
    scrollToBottom();
  }, [messages]);

  useEffect(() => {
    if (!!search) {
      setContactList(searchContactList(contacts, search));
    } else if (isFirstRender && contacts && unreadMessageCounts) {
      const updatedContacts = addAndSortUnreadCounts(
        contacts || [],
        unreadMessageCounts || []
      );
      console.log({ updatedContacts });
      setContactList(updatedContacts);
      setFirstRender(false);
    }
  }, [search, contacts, unreadMessageCounts, isFirstRender]);

  const handleSendMessage = async () => {
    const { contactId, phone, isAdditionalSupport, caseUniqueID, caseId } =
      selectedContact;

    if (!!message?.trim()) {
      const newMessage = {
        isAdditionalSupport,
        message: message.trim(),
        contactId,
        caseId,
        caseUniqueID,
        sentTo: phone,
      };

      try {
        const response = await sendMessage(newMessage);

        const message = response?.message || "Success";
        showSnackbar(message, "success");
      } catch (err: any) {
        const message = err?.response?.data?.message || "An error occurred";
        showSnackbar(message, "error");
      }

      setMessage(""); // clear the input
    }
  };

  // Add debounce to hold render events
  const debouncedSetSearch = useRef(
    debounce((value) => setSearch(value), 400)
  ).current;

  const handleSearchChange = useCallback(
    (event: any) => {
      debouncedSetSearch(event.target.value.trim());
    },
    [debouncedSetSearch]
  );

  // Scroll to the bottom of the chat automatically
  const scrollToBottom = () => {
    chatEndRef.current?.scrollIntoView({ behavior: "instant" });
  };

  const handleSelectItem = async (contact: any) => {
    setSelectedContact(contact);
    await readMessage({ sentTo: contact?.phone });
  };

  if (contactList === null) {
    return <TableSkeleton />;
  }

  return (
    <Stack
      direction={"row"}
      justifyContent={"center"}
      sx={{
        backgroundColor: "grey.100",
        height: isCase ? "calc(100vh - 285px)" : "calc(100vh - 220px)",
      }}
    >
      {/* Sidebar */}
      <Box
        margin={1}
        width={isCase ? "30%" : "20%"}
        borderRadius="10px"
        sx={{
          backgroundColor: "white",
          padding: 1,
        }}
      >
        {/* Logged User Info */}
        <Stack direction={"row"} alignItems="center" m={2} spacing={2}>
          <Avatar
            src={loggedUser?.avatar}
            sx={{ border: "1px solid lightgrey" }}
          />
          <Box>
            <Typography variant="h4">{loggedUser?.name}</Typography>
          </Box>
        </Stack>

        {/* Search Bar */}
        <Box m={2}>
          <SearchField
            fullWidth
            onChange={handleSearchChange}
            size="small"
            placeholder="Search Contact"
          />
        </Box>

        {/* Contact List */}

        <Box m={1}>
          {!!contactList?.length ? (
            <List
              sx={{
                bgcolor: "white",
                height: isCase ? "calc(100vh - 480px)" : "calc(100vh - 420px)",
                overflowY: "auto",
              }}
            >
              {contactList?.map((contact: any, index: number) => (
                <ListItem
                  key={index}
                  component={ButtonBase}
                  onClick={() => handleSelectItem(contact)}
                  sx={{
                    borderBottom: "1px solid #ddd",
                    padding: 1,
                    bgcolor: "white",
                    backgroundColor:
                      selectedContact?.id === contact?.id ? "	#E8E8E8" : "none",
                    "&:hover": {
                      backgroundColor: "	#E8E8E8",
                    },
                  }}
                >
                  <ListItemAvatar>
                    <Avatar src={"/path/to/avatar1.png"} />
                  </ListItemAvatar>

                  {/* Badge to show message count */}
                  <ListItemText
                    primary={
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        {contact?.name}{" "}
                        {!isCase &&
                          contact?.caseUniqueID &&
                          "(" + contact?.caseUniqueID + ")"}
                        <Badge
                          badgeContent={
                            unreadMessageCounts?.find(
                              (message: any) =>
                                String(message?.sentBy) === String(userId) &&
                                String(message?.sentTo) ===
                                  String(contact?.phone)
                            )?.totalUnreadCount || 0
                          }
                          color="success"
                          sx={{ mr: 1 }}
                        />
                      </Box>
                    }
                    secondary={`${contact?.isAdditionalSupport ? "Additional Contact" : "Support Contact"} • ${contact?.phone}`}
                  />
                </ListItem>
              ))}
            </List>
          ) : (
            <Stack
              sx={{
                justifyContent: "center",
                height: isCase ? "calc(100vh - 480px)" : "calc(100vh - 420px)",
              }}
            >
              <Typography textAlign={"center"}>
                No Contacts available.
              </Typography>
            </Stack>
          )}
        </Box>
      </Box>

      {/* Chat Section */}
      <Box
        width="65%"
        display="flex"
        flexDirection="column"
        margin={1}
        borderRadius="10px"
        sx={{ backgroundColor: "white" }}
      >
        {/* Header */}
        {!!contactList?.length && !!selectedContact && (
          <Paper
            elevation={1}
            square
            sx={{
              padding: 2,
              display: "flex",
              alignItems: "center",
              border: "none",
              borderRadius: "10px 10px 0px 0px",
            }}
          >
            {selectedContact && (
              <>
                <Avatar src={selectedContact?.avatar} />
                <Box ml={2}>
                  <Typography variant="h4">
                    {selectedContact?.name}{" "}
                    {selectedContact?.caseUniqueID &&
                      "(" + selectedContact?.caseUniqueID + ")"}
                  </Typography>
                  <Typography variant="subtitle1">
                    {selectedContact?.phone}
                  </Typography>
                </Box>
              </>
            )}
          </Paper>
        )}

        {/* Chat Messages */}
        <Box
          flex={1}
          p={2}
          bgcolor="grey.50"
          overflow="auto" // Enable scroll bar
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: !!messages?.length ? "normal" : "center",
            alignItems: !!messages?.length ? "normal" : "center",
          }}
        >
          {!!contactList?.length && !!selectedContact ? (
            <Conversations
              conversations={messages}
              isLoadingSMS={isLoadingMessages}
              chatEndRef={chatEndRef}
            />
          ) : (
            <Box
              flex={1}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <IconMessage size={"100px"} color="grey" />
            </Box>
          )}
        </Box>
        {/* Message Input */}
        {!!contactList?.length && !!selectedContact && (
          <Stack direction={"row"} m={2} spacing={1}>
            <TextField
              disabled={isSendingMessage}
              fullWidth
              placeholder="Type a message..."
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              onKeyDown={(e) => e.key === "Enter" && handleSendMessage()}
              inputProps={{ maxLength: 200 }}
              helperText={`${message?.length || 0}/200`}
            />
            <IconButton
              color="primary"
              onClick={handleSendMessage}
              disableRipple
              disabled={isSendingMessage}
              style={{ marginBottom: "24px" }}
            >
              <SendIcon />
            </IconButton>
          </Stack>
        )}
      </Box>
    </Stack>
  );
};

const Conversations = ({ conversations, chatEndRef, isLoadingSMS }: any) => {
  if (isLoadingSMS) {
    return <TableSkeleton />;
  }

  return (
    <Box>
      {!!conversations?.length ? (
        conversations?.map((msg: any, index: number) => (
          <Box
            key={index}
            display="flex"
            justifyContent={msg?.isSent ? "flex-end" : "flex-start"}
            mb={2}
          >
            <Box
              bgcolor={msg?.isSent ? "lightblue" : "lightpink"}
              p={2}
              borderRadius="10px"
              maxWidth={"50%"}
            >
              <Typography>{msg?.message || ""}</Typography>
            </Box>
            <Typography variant="caption" align="right" mt={2} ml={0.5}>
              {moment(msg?.sentOn).format("LT")}
            </Typography>
          </Box>
        ))
      ) : (
        <Typography textAlign={"center"}>No messages available.</Typography>
      )}
      <Box ref={chatEndRef} />
    </Box>
  );
};

export default Messages;
