import { Typography } from "@mui/material";
import { useEffect, useMemo } from "react";
import { v4 as uuidv4 } from "uuid";
import useContacts from "../../../hooks/useContacts";
import MainCard from "../../components/cards/MainCard";
import Messages from "./Messages";
import TableSkeleton from "../../components/skeleton/TableSkeleton";

export default function Sms() {
  const { contacts, isContactsLoading } = useContacts();

  const contactList = useMemo(() => {
    // Ensure contacts is an array before proceeding
    if (!Array.isArray(contacts)) return [];

    const supportContacts = contacts?.flatMap((item: any) => {
      if (item?.case?.caseId && Array.isArray(item?.phone)) {
        return item.phone.map((p: any) => ({
          id: uuidv4(),
          name: `${item?.firstName || ""} ${item?.lastName || ""}`.trim(),
          phone: p.phone,
          isAdditionalSupport: false,
          caseUniqueID: item?.case?.caseId,
          caseId: item?.caseId,
          contactId: item?._id,
        }));
      }
      return [];
    });

    const additionalContacts = contacts.flatMap((item: any) => {
      const additionalContactName =
        `${item?.additionalSupportContact?.firstName || ""} ${item?.additionalSupportContact?.lastName || ""}`.trim();

      if (
        item?.case?.caseId &&
        Array.isArray(item?.additionalSupportContact?.phone)
      ) {
        return item?.additionalSupportContact?.phone.map((p: any) => ({
          id: uuidv4(),
          name: additionalContactName,
          phone: p.phone,
          isAdditionalSupport: true,
          caseUniqueID: item?.case?.caseId,
          caseId: item?.caseId,
          contactId: item?._id,
        }));
      }
      return [];
    });

    return supportContacts
      .concat(additionalContacts)
      .filter((contact: any) => !!contact?.phone);
  }, [contacts]);

  useEffect(() => {
    if (contacts?.length > 0) {
    }
  }, [contacts]);

  if (isContactsLoading) {
    return <TableSkeleton />;
  }

  return (
    <MainCard
      title={
        <Typography variant="h1" sx={{ mx: 2 }}>
          Messages
        </Typography>
      }
      content={<Messages contacts={contactList} />}
    />
  );
}
