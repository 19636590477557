import { useMutation, useQuery } from "react-query";
import useAuth from "./useAuth";

/**
 * Custom hook to manage workflows and tasks.
 * @hook useWorkflows
 * @author Priyanshu
 *
 */

export default function useWorkflows(workflowId?: string, taskId?: string) {
  const { request } = useAuth();

  // Fetch all workflows
  const {
    data: workflows,
    isLoading: isLoadingWorkflows,
    refetch: refetchWorkflows,
  } = useQuery("/workflows", () =>
    request.get("/workflows").then((response: any) => {
      return response?.data?.workflows as any;
    })
  );

  // Mutation to add a new workflow
  const { mutateAsync: addWorkflow, isLoading: isAddingWorkflow } = useMutation(
    "/workflows",
    (payload: any) =>
      request
        .post("/workflows", payload)
        .then((response) => response?.data as any),
    {
      onSuccess: () => {
        refetchWorkflows();
      },
    }
  );

  // Mutation to update an existing workflow
  const { mutateAsync: updateWorkflow, isLoading: isUpdatingWorkflow } =
    useMutation(
      `/workflows/${workflowId}`,
      (payload: any) =>
        request
          .put(`/workflows/${workflowId}`, payload)
          .then((response) => response?.data as any),
      {
        onSuccess: () => {
          refetchWorkflows();
        },
      }
    );

  // Mutation to delete a workflow
  const { mutateAsync: deleteWorkflow, isLoading: isDeletingWorkflow } =
    useMutation(
      `/workflows/${workflowId}`,
      () =>
        request
          .delete(`/workflows/${workflowId}`)
          .then((response) => response?.data as any),
      {
        onSuccess: () => {
          refetchWorkflows();
        },
      }
    );

  // Fetch tasks for a specific workflow
  const {
    data: tasks,
    isLoading: isLoadingTasks,
    refetch: refetchTasks,
  } = useQuery(
    `/workflows/${workflowId}/todo`,
    () =>
      request.get(`/workflows/${workflowId}/todo`).then((response: any) => {
        return response?.data?.tasks as any;
      }),
    { enabled: !!workflowId }
  );

  // Mutation to add a task to a workflow

  const { mutateAsync: addWorkflowTodo, isLoading: isAddingWorkflowTodo } =
    useMutation(
      `/workflows/${workflowId}/todo`,
      (payload: any) =>
        request
          .put(`/workflows/${workflowId}/todo`, payload)
          .then((response) => response?.data as any),
      {
        onSuccess: () => {
          refetchTasks();
        },
      }
    );

  const { mutateAsync: updateWorkflowTodo, isLoading: isUpdatingWorkflowTodo } =
    useMutation(
      `/workflows/${workflowId}/todo/${taskId}`,
      (payload: any) =>
        request
          .put(`/workflows/${workflowId}/todo/${taskId}`, payload)
          .then((response) => response?.data as any),
      {
        onSuccess: () => {
          refetchTasks();
        },
      }
    );

  // Mutation to delete a specific task from a workflow
  const { mutateAsync: deleteWorkflowTodo, isLoading: isDeletingWorkflowTodo } =
    useMutation(
      `/workflows/${workflowId}/todo/${taskId}`,
      () =>
        request
          .delete(`/workflows/${workflowId}/todo/${taskId}`)
          .then((response) => response?.data as any),
      {
        onSuccess: () => {
          refetchTasks();
        },
      }
    );

  return {
    addWorkflow,
    isAddingWorkflow,
    workflows,
    isLoadingWorkflows,
    updateWorkflow,
    isUpdatingWorkflow,
    deleteWorkflow,
    isDeletingWorkflow,
    addWorkflowTodo,
    isAddingWorkflowTodo,
    updateWorkflowTodo,
    isUpdatingWorkflowTodo,
    deleteWorkflowTodo,
    isDeletingWorkflowTodo,
    tasks,
    isLoadingTasks,
  };
}
