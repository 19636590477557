import { useQuery } from "react-query";
import useAuth from "./useAuth";
import { GridPaginationModel, GridSortModel } from "@mui/x-data-grid";

/**
 *
 * @param search
 * @param paginationModel
 * @param sortModel
 * @param filterData
 * @returns /docusign-waitingList
 * @author Sanjay
 */
export default function useDocuSignWaitingList(
  search?: string,
  paginationModel?: GridPaginationModel,
  sortModel?: GridSortModel,
  filterData?: any
) {
  const { request } = useAuth();

  const {
    data: docuSignWaitingList,
    refetch: refetchWaitingList,
    isLoading,
    isRefetching,
  } = useQuery(
    ["/docusign-waitingList", search, paginationModel, sortModel, filterData],
    () =>
      request
        .get("/docusign-waitingList", {
          params: {
            search,
            paginationModel,
            sortModel,
            filterData,
          },
        })
        .then(({ data }) => {
          return data as any;
        })
  );

  return {
    docuSignWaitingList: docuSignWaitingList?.data,
    refetchWaitingList,
    isLoading: isLoading || isRefetching,
    totalDocuSignWaiting: docuSignWaitingList?.listCount || 0,
  };
}
