/**
 * Typography used in theme
 * @param {JsonObject} theme theme customization object
 */

export default function themeTypography(theme: any) {
  return {
    fontFamily: theme?.customization?.fontFamily,
    h6: {
      lineHeight: "none",
      fontSize: "12px",
      color: "#364152",
      fontWeight: 400,
      fontFamily: "'Poppins', sans-serif",
    },
    h5: {
      fontSize: "14px",
      color: "#364152",
      fontWeight: 600,
      fontFamily: "'Noto Sans Arabic', sans-serif",
    },
    h4: {
      fontSize: "16px",
      color: "#364152",
      fontWeight: 600,
      fontFamily: "'Noto Sans Arabic', sans-serif",
    },
    h3: {
      lineHeight: "none",
      fontSize: "24px",
      color: "#364152",
      fontWeight: 400,
      fontFamily: "'Noto Sans Arabic', sans-serif",
    },
    h2: {
      fontSize: "26px",
      color: "#364152",
      fontWeight: 700,
      fontFamily: "'Noto Sans Arabic', sans-serif",
    },
    h1: {
      fontSize: "32px",
      color: "#364152",
      fontWeight: 700,
      fontFamily: "'Noto Sans Arabic', sans-serif",
    },
    subtitle1: {
      fontSize: "16px",
      fontWeight: 500,
      color: theme.darkTextSecondary,
      fontFamily: "'Poppins', sans-serif",
    },
    subtitle2: {
      fontSize: "10px",
      fontFamily: "'Poppins', sans-serif",
      fontWeight: 600,
      color: "#364152",
    },
    caption: {
      fontSize: "0.75rem",
      color: theme.darkTextSecondary,
      fontWeight: 400,
    },
    body1: {
      lineHeight: "none",
      fontSize: "14px",
      color: "#364152",
      fontWeight: 400,
      fontFamily: "'Poppins', sans-serif",
    },
    body2: {
      lineHeight: "none",
      fontSize: "13px",
      color: "#364152",
      fontWeight: 400,
      fontFamily: "'Poppins', sans-serif",
    },

    button: {
      textTransform: "capitalize",
      fontFamily: "'Poppins', sans-serif",
      fontSize: "16px",
      fontWeight: 500,
    },
    customInput: {
      marginTop: 1,
      marginBottom: 1,
      "& > label": {
        top: 23,
        left: 0,
        color: theme.grey500,
        '&[data-shrink="false"]': {
          top: 5,
        },
      },
      "& > div > input": {
        padding: "30.5px 14px 11.5px !important",
      },
      "& legend": {
        display: "none",
      },
      "& fieldset": {
        top: 0,
      },
    },
    mainContent: {
      backgroundColor: "#F3F6FE",
      width: "100%",
      // minHeight: "calc(100vh - 100px)",
      flexGrow: 1,
      marginTop: "78px",
      marginRight: "15px",
      // marginRight: "20px",
      borderRadius: `${theme?.customization?.borderRadius}px`,
    },
    menuCaption: {
      fontSize: "0.875rem",
      fontWeight: 500,
      color: "#364152",
      padding: "6px",
      textTransform: "capitalize",
      marginTop: "10px",
    },
    commonAvatar: {
      cursor: "pointer",
      borderRadius: "8px",
    },
    smallAvatar: {
      width: "22px",
      height: "22px",
      fontSize: "1rem",
    },
    mediumAvatar: {
      width: "34px",
      height: "34px",
      fontSize: "1.2rem",
    },
    largeAvatar: {
      width: "44px",
      height: "44px",
      fontSize: "1.5rem",
    },
  };
}
