import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  Stack,
  Table,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import BasicDatePicker from "../../components/inputs/BasicDatePicker";
import MuiTextField from "../../components/inputs/MuiTextField";
interface Props {
  tdcjId?: string;
  inmateName?: string;
  handleParoleChange?: () => void;
  handleDenyParoleChange?: () => void;
  handleDiscretionaryMSChange?: () => void;
  handleDenyDiscretionaryMSChange?: () => void;
  selectedParole?: string;
  selectedDenyParole?: string;
  selectedDenyDiscretionaryMS?: string;
  selectedDiscretionaryMS?: string;
  primaryOffense?: any;
}
const ParoleBoardGuidelineMatrixScore = ({
  tdcjId,
  inmateName,
  handleParoleChange,
  handleDenyParoleChange,
  handleDiscretionaryMSChange,
  handleDenyDiscretionaryMSChange,
  selectedParole,
  selectedDenyParole,
  selectedDenyDiscretionaryMS,
  selectedDiscretionaryMS,
  primaryOffense,
}: Props) => {
  const tableData = [
    {
      Offense_severity_class: "Highest",
      highest: "0-5%(1)",
      high: "6-10%(2)",
      moderate: "11-15%(3)",
      low: "16-20%(2)",
    },
    {
      Offense_severity_class: "High",
      highest: "5-15%(2)",
      high: "16-25%(3)",
      moderate: "26-35%(4)",
      low: "36-50%(4)",
    },
    {
      Offense_severity_class: "Moderate",
      highest: "10-20%(2)",
      high: "21-35%(4)",
      moderate: "36-50%(5)",
      low: "51-75%(6)",
    },
    {
      Offense_severity_class: "Low",
      highest: "15-25%(3)",
      high: "26-50%(4)",
      moderate: "51-75%(6)",
      low: "76-100%(7)",
    },
  ];
  const parole = [
    "FI-1",
    "  FI-2",
    "  FI-4",
    "  FI-5",
    " FI-3R",
    "  FI-6R",
    "  FI-9R",
    "  FI-18R",
  ];
  const denyParole = ["SA", "NR"];
  return (
    <Stack alignItems={"center"} mt={5}>
      <Stack spacing={2}>
        <Typography variant="h6" alignSelf={"center"}>
          PAROLE BOARD GUIDELINE MATRIX SCORE
        </Typography>
        <Stack
          direction={"row"}
          justifyContent={"space-around"}
          alignItems={"center"}
        >
          <Stack direction={"row"} spacing={1}>
            <Typography fontWeight={"900"} fontSize={"16px"}>
              Inmate's Name:
            </Typography>
            <Typography fontSize={"15px"}>{inmateName}</Typography>
          </Stack>
          <Stack direction={"row"} spacing={1}>
            <Typography fontWeight={"900"} variant="subtitle1">
              TDCJ-ID#:
            </Typography>
            <Typography
              variant="subtitle1"
              sx={{ borderBottom: "1px solid", width: 80 }}
            >
              {tdcjId}
            </Typography>
          </Stack>
        </Stack>
        <Box justifyContent={"left"}>
          Instructions: Based on the risk assessment and the offense severity
          score, mark with an "x" the appropriate guideline cell that the inmate
          falls within. The percentages in the matrix reflect the expected grant
          rates by the Board for these types of cases.
        </Box>
        <Grid container spacing={2}>
          {!primaryOffense && (
            <Grid item xs={11.5}>
              <Typography color={"red"}>
                Note: You have not selected any primary offense yet from the
                inmate information.
              </Typography>
            </Grid>
          )}
          <Grid item xs={11.5}>
            <MuiTextField
              label="Primary Offense"
              name="primaryOffense"
              size="small"
              disabled
              required
            />
          </Grid>
          <Grid item xs={4} sm={5.75}>
            <MuiTextField
              label="NCIC Code"
              name="ncicCode"
              size="small"
              type="number"
              required
              // disabled
            />
          </Grid>
          <Grid item xs={3.5} sm={5.75}>
            <MuiTextField
              label="Penal Code"
              size="small"
              name="penalCode"
              type="number"
              required
            />
          </Grid>
          <Grid item xs={4} sm={5.75}>
            <MuiTextField
              label="Degree Level"
              name="degreeLevel"
              size="small"
              required
              // disabled
            />
          </Grid>
          <Grid item xs={5.5} sm={5.75}>
            <MuiTextField
              label="Aggravated (3G) Offense"
              name="aggravatedOffense"
              size="small"
              required
            />
          </Grid>
          <Grid item xs={3} sm={5.75}>
            <MuiTextField
              label="Life Sentence"
              size="small"
              name="lifeSentence"
              required
            />
          </Grid>
          <Grid item xs={3} sm={5.75}>
            <MuiTextField
              label="Capital Offense"
              name="capitalOffense"
              size="small"
              required
            />
          </Grid>
          <Grid item xs={4} sm={5.75}>
            <MuiTextField
              label="Offense Severity"
              name="offenseSeverity"
              size="small"
              required
            />
          </Grid>
          <Grid item xs={4} sm={5.75}>
            <MuiTextField
              label="Risk Level"
              disabled
              name="riskLevel"
              size="small"
            />
          </Grid>
          <Grid item xs={3.5} sm={11.5}>
            <MuiTextField
              label="Term (Years)"
              name="term"
              size="small"
              type="number"
              required
            />
          </Grid>
        </Grid>
        <Box p={3}>
          <Table sx={{ border: "1px solid red" }}>
            <TableRow sx={{ border: "1px solid", textAlign: "center" }}>
              <TableCell
                sx={{
                  border: "1px solid",
                  textAlign: "center",
                  fontWeight: 900,
                  padding: "4px",
                }}
                rowSpan={2}
              >
                Offense Severity Class
              </TableCell>
              <TableCell
                colSpan={4}
                sx={{
                  border: "1px solid",
                  textAlign: "center",
                  fontWeight: 900,
                  padding: "4px",
                }}
              >
                Risk Class Based on Projected Re-arrest Rates
              </TableCell>
            </TableRow>
            <TableRow sx={{ border: "1px solid", textAlign: "center" }}>
              <TableCell
                sx={{
                  border: "1px solid",
                  textAlign: "center",
                  fontWeight: 900,
                  padding: "4px",
                }}
              >
                Highest 40% and higher
              </TableCell>
              <TableCell
                sx={{
                  border: "1px solid",
                  textAlign: "center",
                  fontWeight: 900,
                  padding: "4px",
                }}
              >
                High 30-39% re-arrest
              </TableCell>
              <TableCell
                sx={{
                  border: "1px solid",
                  textAlign: "center",
                  fontWeight: 900,
                  padding: "4px",
                }}
              >
                Moderate 20-29% re-arrest
              </TableCell>
              <TableCell
                sx={{
                  border: "1px solid",
                  textAlign: "center",
                  fontWeight: 900,
                  padding: "4px",
                }}
              >
                Low 0-19% re-arrest
              </TableCell>
            </TableRow>
            {tableData?.map((data: any, index) => (
              <TableRow key={index}>
                <TableCell
                  sx={{
                    border: "1px solid",
                    fontWeight: 900,
                    padding: "4px",
                    textAlign: "center",
                  }}
                >
                  {data.Offense_severity_class}
                </TableCell>
                <TableCell
                  sx={{
                    border: "1px solid",
                    padding: "4px",
                    textAlign: "center",
                  }}
                >
                  {data.highest}
                </TableCell>
                <TableCell
                  sx={{
                    border: "1px solid",
                    padding: "4px",
                    textAlign: "center",
                  }}
                >
                  {data.high}
                </TableCell>
                <TableCell
                  sx={{
                    border: "1px solid",
                    padding: "4px",
                    textAlign: "center",
                  }}
                >
                  {data.moderate}
                </TableCell>
                <TableCell
                  sx={{
                    border: "1px solid",
                    padding: "4px",
                    textAlign: "center",
                  }}
                >
                  {data.low}
                </TableCell>
              </TableRow>
            ))}
          </Table>
        </Box>
        <Typography variant="h6" gutterBottom sx={{ textAlign: "center" }}>
          RECORD OF PAROLE BOARD DECISION:
        </Typography>
        <Grid container>
          <Grid item xs={12} sm={3}>
            <Typography
              variant="subtitle1"
              gutterBottom
              sx={{ textDecorationLine: "underline" }}
            >
              Parole
            </Typography>
            <FormGroup>
              {parole?.map((value: any) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={selectedParole === value}
                      onChange={handleParoleChange}
                      value={value}
                    />
                  }
                  label={value}
                  name="parole"
                />
              ))}
            </FormGroup>
          </Grid>
          <Grid item xs={9}>
            <Stack gap={4}>
              <Stack direction={"row"} spacing={3}>
                <Grid item xs={12} sm={3}>
                  <Typography
                    variant="subtitle1"
                    gutterBottom
                    sx={{ textDecorationLine: "underline" }}
                  >
                    Deny Parole
                  </Typography>
                  <FormGroup>
                    {denyParole?.map((value: any) => (
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={selectedDenyParole === value}
                            onChange={handleDenyParoleChange}
                            value={value}
                          />
                        }
                        label={value}
                      />
                    ))}
                    {/* Add the Set Off date field under NR */}
                  </FormGroup>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Typography
                    variant="subtitle1"
                    gutterBottom
                    sx={{ textDecorationLine: "underline" }}
                  >
                    Discretionary MS
                  </Typography>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={selectedDiscretionaryMS === "RMS"}
                          onChange={handleDiscretionaryMSChange}
                          value="RMS"
                        />
                      }
                      label="RMS"
                    />
                  </FormGroup>
                </Grid>
                <Grid item xs={12} sm={5}>
                  <Typography
                    variant="subtitle1"
                    gutterBottom
                    sx={{ textDecorationLine: "underline" }}
                  >
                    Deny Discretionary MS
                  </Typography>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={selectedDenyDiscretionaryMS === "DMS"}
                          onChange={handleDenyDiscretionaryMSChange}
                          value="DMS"
                        />
                      }
                      label="DMS"
                    />
                  </FormGroup>
                </Grid>
              </Stack>
              <Grid item xs={12} sm={5}>
                <Stack gap={2}>
                  <Grid item xs={12}>
                    <Stack direction="row" alignItems="center">
                      {/* <Typography width={190}>Set Off Date:</Typography> */}
                      <BasicDatePicker
                        name="setOffDate"
                        // size="small"
                        // type="date"
                        label="Set Off Date"
                        required={true}
                      />
                    </Stack>
                  </Grid>
                  <Grid item xs={12}>
                    <Stack direction="row" alignItems="center">
                      {/* <Typography width={160}>FIR Date:</Typography> */}
                      <BasicDatePicker
                        name="firDate"
                        // size="small"
                        // type="date"
                        label="FIR Date"
                        required
                      />
                    </Stack>
                  </Grid>
                </Stack>
              </Grid>
            </Stack>
          </Grid>
        </Grid>
        <Grid container gap={3}>
          <Grid item xs={12}>
            <MuiTextField
              label="Reason(s) for Decision:"
              name="reasonForDecision"
              fullWidth
              required
              multiline
              minRows={2}
              sx={{ borderColor: "#B2BAC2" }}
            />
          </Grid>
          <Grid item xs={12}>
            <MuiTextField
              minRows={2}
              multiline
              fullWidth
              required
              label="Board Member(s):"
              name="boardOfMembers"
              sx={{ borderColor: "#B2BAC2" }}
            />
          </Grid>
          <Grid container justifyContent={"right"}>
            <Grid item xs={4}>
              <BasicDatePicker name="date" label="Date" />
            </Grid>
          </Grid>
        </Grid>
      </Stack>
    </Stack>
  );
};
export default ParoleBoardGuidelineMatrixScore;
