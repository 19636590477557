import { Chip, Avatar } from "@mui/material";
// import { Box, Chip, Avatar, Button, IconButton } from "@mui/material";
// import SettingsIcon from '@mui/icons-material/Settings';
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import defaultProfilePic from "../../../../../src/assets/images/profilepic.avif";

export default function ProfileSection({ user, rotated }: any) {
  return (
    <Chip
      sx={{
        height: "48px",
        alignItems: "center",
        color: "black",
        backgroundColor: "#d836307d",
        border: "none",
        borderRadius: "27px",
        "&:hover": {
          backgroundColor: "#E3001B",
          color: "white",
          borderColor: "lightgrey",
        },
      }}
      icon={
        <Avatar src={user?.profileImg ? user.profileImg : defaultProfilePic} />
      }
      label={
        <SettingsOutlinedIcon
          style={{
            transform: rotated ? "rotate(360deg)" : "rotate(0deg)",
            transition: "transform 1s ease",
          }}
        />
      }
      variant="outlined"
    />
  );
}
