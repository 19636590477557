import styled from "@emotion/styled";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
// import { IconPlus } from "@tabler/icons-react";
// import { useCallback } from "react";
// import { useModal } from "mui-modal-provider";
// import AddPaymentsModal from "../AddPaymentsModal";
import Pagination from "../../../components/Pagination";
import SubCard from "../../../components/cards/SubCard";
import moment from "moment";
// import usePayment from "../../../../hooks/usePayment";
import { capitalizeFirstLetter } from "../../../../utils/capitalizeFirstLetter";
import React, { useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import NoResultFound from "../../../components/NoResultFound";

const CustomTableRow = styled(TableRow)(() => ({
  height: "50px",
}));

const CustomTableCell = styled(TableCell)(() => ({
  paddingTop: 0,
  width: 130,
  paddingBottom: 0,
  // fontSize: "15px",
  overflow: "hidden",
  textOverflow: "ellipsis",
}));

const PaymentMade = ({
  caseData,
  caseId,
  totalAmount,
  paymentScheduling,
}: any) => {
  // const { showModal } = useModal();
  // const { paymentScheduling } = usePayment({ caseId: caseId?.id });

  // const handleAdd = useCallback(() => {
  //   const modal: any = showModal(AddPaymentsModal, {
  //     onClose: () => modal.hide(),
  //   });
  // }, [showModal]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [expanded, setExpanded] = React.useState<string | false>(false);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  return (
    <SubCard
      title={"Payments Made"}
      // secondary={
      //   <Button
      //     sx={{
      //       color: "green",
      //       cursor: "pointer",
      //     }}
      //     onClick={handleAdd}
      //   >
      //     <IconPlus />
      //   </Button>
      // }
    >
      {!!paymentScheduling?.payment?.length ? (
        paymentScheduling?.payment?.map((hiring: any, i: number) => {
          const paginatedPayments = hiring.payment_spreads?.slice(
            page * rowsPerPage,
            page * rowsPerPage + rowsPerPage
          );

          const count = hiring.payment_spreads?.filter(
            (payment: any) => payment?.isPaid === true
          );
          return (
            <Accordion
              expanded={expanded === `panel${i}`}
              onChange={handleChange(`panel${i}`)}
              key={i}
              elevation={0}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <Typography
                  sx={{ width: "20%", flexShrink: 0, fontWeight: "bold" }}
                >
                  {/* {`Hiring ${i + 1}`} */}
                  Parole Eligibility Date
                </Typography>
                <Typography sx={{ width: "60%", flexShrink: 0 }}>
                  {moment(hiring?.paroleEligibilityDate).format("MM/DD/YYYY")}
                </Typography>
                <Typography>
                  Total ${Number(hiring?.totalPaidAmount).toFixed(2)}
                </Typography>
              </AccordionSummary>

              <AccordionDetails>
                <Divider />
                <TableContainer
                  key={hiring?._id}
                  sx={{ backgroundColor: "#d3d3d347", maxHeight: 305 }}
                >
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <CustomTableRow>
                        <CustomTableCell>
                          <Typography variant="h5">Due Date</Typography>
                        </CustomTableCell>
                        <CustomTableCell>
                          <Typography variant="h5">Paid On</Typography>
                        </CustomTableCell>
                        <CustomTableCell>
                          {" "}
                          <Typography variant="h5">Type</Typography>
                        </CustomTableCell>
                        <CustomTableCell>
                          {" "}
                          <Typography variant="h5">Payment Method</Typography>
                        </CustomTableCell>
                        <CustomTableCell>
                          {" "}
                          <Typography variant="h5">Paid by</Typography>
                        </CustomTableCell>
                        <CustomTableCell>
                          {" "}
                          <Typography variant="h5">Amount</Typography>
                        </CustomTableCell>
                      </CustomTableRow>
                    </TableHead>
                    <TableBody>
                      {hiring?.payment_spreads?.length ? (
                        paginatedPayments?.map((payment: any, index: number) =>
                          payment?.isPaid ? (
                            <CustomTableRow key={payment?._id}>
                              <CustomTableCell>
                                {moment(payment?.dueDate).format("MM/DD/YYYY")}
                              </CustomTableCell>
                              <CustomTableCell>
                                {moment(payment?.paymentMadeDate).format(
                                  "MM/DD/YYYY"
                                )}
                              </CustomTableCell>

                              <CustomTableCell>
                                {capitalizeFirstLetter(hiring?.type)}
                              </CustomTableCell>

                              <CustomTableCell>
                                {capitalizeFirstLetter(
                                  payment?.transaction?.transaction_data?.method
                                    ?.type
                                ) || capitalizeFirstLetter(hiring?.type)}
                              </CustomTableCell>
                              <CustomTableCell>
                                {capitalizeFirstLetter(payment?.paidBy)}
                              </CustomTableCell>
                              <CustomTableCell>
                                {" "}
                                $ {Number(payment?.payment)?.toFixed(2)}
                              </CustomTableCell>
                            </CustomTableRow>
                          ) : (
                            ""
                          )
                        )
                      ) : (
                        <CustomTableRow>
                          <CustomTableCell colSpan={5} align="center">
                            No payments available
                          </CustomTableCell>
                        </CustomTableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
                <Pagination
                  component="div"
                  count={count?.length || 0}
                  page={page}
                  setPage={setPage}
                  rowsPerPage={rowsPerPage}
                  setRowsPerPage={setRowsPerPage}
                  rowsPerPageOptions={[5, 10, 25]}
                />
              </AccordionDetails>
            </Accordion>
          );
        })
      ) : (
        <NoResultFound>No Payment found</NoResultFound>
      )}
    </SubCard>
  );
};

export default PaymentMade;
