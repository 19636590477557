const DocumentViewer = ({ documentUrl, mimeType }: any) => {
  const renderDocument = () => {
    // Handle images (jpg, png, gif, etc.)
    if (mimeType.startsWith("image")) {
      return (
        <img
          src={documentUrl}
          alt="Document"
          //   style={{ width: "100%", height: "auto" }}
          style={{ width: "100%", height: "calc(100vh - 250px)" }}
        />
      );

      // Handle PDFs
    } else if (mimeType === "application/pdf") {
      return (
        <iframe
          src={documentUrl}
          title="PDF Document"
          //   width="100%"
          //   height="600px"
          style={{
            border: "none",
            width: "100%",
            height: "calc(100vh - 250px)",
          }}
        />
      );

      // Handle Word documents
    } else if (
      mimeType.includes(
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
      ) ||
      mimeType.includes("application/msword")
    ) {
      return (
        <iframe
          src={`https://docs.google.com/gview?url=${documentUrl}&embedded=true`}
          title="Word Document"
          width="100%"
          height="600px"
          style={{
            border: "none",
            width: "100%",
            height: "calc(100vh - 250px)",
          }}
        />
      );

      // Handle Excel documents
    } else if (
      mimeType.includes(
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      ) ||
      mimeType.includes("application/vnd.ms-excel")
    ) {
      return (
        <iframe
          src={`https://docs.google.com/gview?url=${documentUrl}&embedded=true`}
          title="Excel Document"
          style={{
            border: "none",
            width: "100%",
            height: "calc(100vh - 250px)",
          }}
        />
      );

      // Handle PowerPoint presentations
    } else if (
      mimeType.includes(
        "application/vnd.openxmlformats-officedocument.presentationml.presentation"
      ) ||
      mimeType.includes("application/vnd.ms-powerpoint")
    ) {
      return (
        <iframe
          src={`https://docs.google.com/gview?url=${documentUrl}&embedded=true`}
          title="PowerPoint Document"
          style={{
            border: "none",
            width: "100%",
            height: "calc(100vh - 250px)",
          }}
        />
      );

      // Handle plain text files with iframe
    } else if (mimeType === "text/plain") {
      return (
        <iframe
          src={documentUrl}
          title="Text Document"
          width="100%"
          height="600px"
          style={{ border: "none" }}
        />
      );

      // Handle audio or video files
    } else if (mimeType.startsWith("audio") || mimeType.startsWith("video")) {
      return (
        <video controls style={{ width: "100%" }}>
          <source src={documentUrl} type={mimeType} />
          Your browser does not support the video tag.
        </video>
      );

      // Fallback: Download link for unsupported types (e.g., other media or complex files)
    } else {
      return (
        <a href={documentUrl} download>
          Download Document
        </a>
      );
    }
  };

  return <div>{renderDocument()}</div>;
};

export default DocumentViewer;
