import { Box, Button, DialogProps } from "@mui/material";
import BasicModal from "../../components/modal/BasicModal";
import { useSnackbarHelper } from "../../components/Snackbar";

import usePdf from "../../../hooks/useCriminalHistory";

interface Props extends DialogProps {
  onClose: () => void;
  id?: any;
  data?: any;
  caseId?: string;
}

const DeleteCriminalHistoryModal = ({
  onClose,
  id,
  data,
  caseId,
  ...props
}: Props) => {
  const showSnackbar = useSnackbarHelper();

  const { deletePdf, isLoading } = usePdf({ caseId });
  const onSubmit = async () => {
    try {
      const response = await deletePdf(caseId);
      if (response?.status === 200) {
        const message = "Criminal History deleted successfully";
        showSnackbar(message, "success");
      }

      onClose();
    } catch (error: any) {
      const message = error?.response?.data?.message || "An error occurred";
      showSnackbar(message, "error");
    }
  };

  return (
    <Box>
      <BasicModal
        onClose={onClose}
        title="Remove Criminal History"
        content="Do you really want to remove this criminal history?"
        actions={
          <>
            <Button
              variant="contained"
              color="error"
              sx={{ textTransform: "capitalize" }}
              onClick={onSubmit}
              disabled={isLoading}
            >
              Remove
            </Button>
            <Button
              color="error"
              variant="outlined"
              sx={{ textTransform: "capitalize" }}
              onClick={() => onClose()}
            >
              Cancel
            </Button>
          </>
        }
        {...props}
      />
    </Box>
  );
};

export default DeleteCriminalHistoryModal;
