import {
  Autocomplete,
  Button,
  Checkbox,
  IconButton,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { IconEye } from "@tabler/icons-react";
import { debounce } from "lodash";
import moment from "moment";
import { useCallback, useRef, useState } from "react";
import { useNavigate } from "react-router";

import DescriptionIcon from "@mui/icons-material/Description";
import {
  GridActionsCellItem,
  GridColDef,
  GridColumnHeaderParams,
  GridPaginationModel,
  GridSortModel,
} from "@mui/x-data-grid";

import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import BasicButton from "../../components/buttons/BasicButton";
import MainCard from "../../components/cards/MainCard";
import DataGridTable from "../../components/DataGridTable";
import SearchField from "../../components/inputs/SearchField";
// import useCases from "../../../hooks/useCases";
import { useModal } from "mui-modal-provider";
// import useAuth from "../../../hooks/useAuth";
import useDocuSignWaitingList from "../../../hooks/useDocuSignWaitingList";
import ViewRepAgreementModal from "../cases/ViewRepAgreementModal";

const caseStatus = [
  { title: "PENDING" },
  { title: "NOT SIGNED" },
  { title: "COMPLETED" },
  { title: "DECLINED" },
];

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function DocuSign() {
  const navigate = useNavigate();
  const { showModal } = useModal();
  // const { request } = useAuth();

  const [search, setSearch] = useState("");
  const [filterData, setFilterData] = useState<any>(null);
  const [sortModel, setSortModel] = useState<GridSortModel>([
    { field: "createdAt", sort: "desc" },
  ]);

  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    page: 0,
    pageSize: 12,
  });

  const [signedDocument, setSignedDocument] = useState<string | null>(null);

  // const { cases, totalCases, isLoading, refetchCase, handleRefetchUsers } =
  //   useCases(undefined, search, paginationModel, sortModel, false, filterData);

  const {
    docuSignWaitingList,
    isLoading,
    totalDocuSignWaiting,
    refetchWaitingList,
  } = useDocuSignWaitingList(search, paginationModel, sortModel, filterData);

  function capitalizeFirstLetter(status: string) {
    if (!status) return "";
    return status.charAt(0).toUpperCase() + status.slice(1).toLowerCase();
  }

  const checkDocuSignStatus = (
    docuSignStatus: string
  ): "#ADDFAD" | "#fdde6c" | "#FF9494" => {
    const statusColorMap: { [key: string]: "#ADDFAD" | "#fdde6c" } = {
      COMPLETED: "#ADDFAD",
      PENDING: "#fdde6c",
    };

    // Return the mapped color, or "warning" for any unknown status
    return statusColorMap[docuSignStatus] || "#FF9494";
  };

  const handleViewRepAgreement = useCallback(
    async (docuSignUrl: any) => {
      try {
        // const response = await request.get("/docuSign/getSignedDocument", {
        //   params: { envelopeId }, // Pass the envelopeId as a query parameter
        // });
        // const signedDocument = response?.data?.results;

        const modal: any = showModal(ViewRepAgreementModal, {
          onClose: () => {
            modal.hide();
            setSignedDocument(null);
          },
          buffer: docuSignUrl,
          isAwsUrl: true,
        });
      } catch (error) {
        console.error("Error fetching signed document:", error);
        setSignedDocument(null);
      }
    },

    //eslint-disable-next-line
    [showModal]
  );

  const columns: GridColDef[] = [
    {
      field: "caseId",
      headerName: "Case ID#",
      headerClassName: "MuiDataGrid-cell",
      minWidth: 120,
      flex: 1,
      renderCell: (params: any) => params?.row?.caseId || "-",
      renderHeader: (params: any) => params?.colDef?.headerName,
    },
    {
      field: "inmate",
      headerName: "Inmate",
      headerClassName: "MuiDataGrid-cell",
      minWidth: 220,
      flex: 1,
      renderCell: (params: any) => params?.row?.contact?.inmate?.name,
      renderHeader: (params: any) => params?.colDef?.headerName,
    },
    {
      field: "paroleEligibilityDate",
      headerName: "Parole Eligibility Date",
      minWidth: 200,
      flex: 1,
      renderCell: (params: any) =>
        moment(params?.row?.paroleEligibilityDate).format("MM/DD/YYYY") || "-",
      renderHeader: (params: any) => params?.colDef?.headerName,
    },
    {
      field: "paroleStatus",
      headerName: "Parole Status",
      minWidth: 200,
      flex: 1,
      renderCell: (params: any) =>
        params?.row?.contact?.inmate?.paroleReviewInfo?.paroleReviewStatus ||
        "-",
      renderHeader: (params: any) => params?.colDef?.headerName,
    },

    {
      field: "sentOn",
      headerName: "Sent On",
      headerClassName: "MuiDataGrid-cell",
      minWidth: 120,
      flex: 1,
      renderCell: (params: any) =>
        moment(params?.row?.createdAt).format("MM/DD/YYYY") || "-",
      renderHeader: (params: any) => params?.colDef?.headerName,
    },
    {
      field: "signedDate",
      headerName: "Signed Date",
      headerClassName: "MuiDataGrid-cell",
      minWidth: 120,
      flex: 1,
      renderCell: (params: any) =>
        params?.row?.docuSignDate
          ? moment(params?.row?.docuSignDate).format("MM/DD/YYYY")
          : "-",
      renderHeader: (params: any) => params?.colDef?.headerName,
    },
    {
      field: "docuSignStatus",
      headerName: "Status",
      headerClassName: "MuiDataGrid-cell",
      minWidth: 120,
      flex: 1,
      renderCell: (params: any) => (
        <Button
          sx={{
            textTransform: "capitalize",
            color: "black",
            width: 80,
            pointerEvents: "none",
            bgcolor: checkDocuSignStatus(params?.row?.docuSignStatus),

            "&:hover": {
              bgcolor: checkDocuSignStatus(params?.row?.docuSignStatus),
            },
          }}
          size="small"
        >
          {capitalizeFirstLetter(params?.row?.docuSignStatus)}
        </Button>
      ),

      renderHeader: (params: any) => params?.colDef?.headerName,
    },
    {
      field: "repagreement",
      type: "actions",
      headerName: "Document",
      minWidth: 120,
      getActions: (value: any) => {
        const envelopeId = value?.row?.envelopeId;
        const docuSignUrl = value?.row?.docuSignUrl;
        return [
          <Tooltip title="View Rep Agreement">
            <IconButton
              onClick={() => {
                handleViewRepAgreement(docuSignUrl);
                setSignedDocument(envelopeId);
              }}
              disabled={signedDocument === envelopeId}
            >
              <DescriptionIcon fontSize="medium" />
            </IconButton>
          </Tooltip>,
        ];
      },
      renderHeader: (params: GridColumnHeaderParams) => {
        return (
          <Typography sx={{ fontWeight: "bold" }}>
            {params?.colDef?.headerName}
          </Typography>
        );
      },
    },

    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      minWidth: 120,
      getActions: (value: any) => {
        return [
          <Tooltip title="View Case Details">
            <GridActionsCellItem
              icon={<IconEye />}
              label="view"
              onClick={() => {
                navigate(`/cases/${value?.id}`);
              }}
            />
          </Tooltip>,
        ];
      },
      renderHeader: (params: GridColumnHeaderParams) => {
        return (
          <Typography sx={{ fontWeight: "bold" }}>
            {params?.colDef?.headerName}
          </Typography>
        );
      },
    },
  ];

  const debouncedSetSearch = useRef(
    debounce((value) => setSearch(value), 400)
  ).current;

  const handleSearchChange = useCallback(
    (event: any) => {
      debouncedSetSearch(event.target.value);
    },
    [debouncedSetSearch]
  );

  const handleSortModelChange = (newModel: any[]) => {
    setSortModel(newModel);
  };

  // if (isLoadingSignedDocument) {
  //   return <TableSkeleton />;
  // }

  return (
    <MainCard
      title={
        <Typography variant="h1" sx={{ mx: 2 }}>
          DocuSign
        </Typography>
      }
      actions={
        <Stack direction={"row"} alignItems={"center"} spacing={2}>
          <Autocomplete
            multiple
            limitTags={1}
            size="small"
            id="checkboxes-tags-demo"
            options={caseStatus}
            disableCloseOnSelect
            getOptionLabel={(option) => option.title}
            renderOption={(props, option, { selected }) => {
              const { ...optionProps } = props;
              return (
                <li {...optionProps}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.title}
                </li>
              );
            }}
            style={{ width: 250 }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Filter"
                placeholder="DocuSign Status"
              />
            )}
            onChange={(e, data: any) => setFilterData(data)}
          />
          <SearchField
            onChange={handleSearchChange}
            size="small"
            placeholder="Search document"
          />

          <BasicButton
            label={"Update List"}
            variant="contained"
            color="success"
            size={window.innerWidth < 1420 ? "small" : "medium"}
            onClick={() => refetchWaitingList()}
          />
        </Stack>
      }
      content={
        <>
          <DataGridTable
            onPaginationModelChange={setPaginationModel}
            paginationModel={paginationModel}
            column={columns}
            rows={docuSignWaitingList || []}
            isLoading={isLoading}
            totalRows={totalDocuSignWaiting}
            sortModel={sortModel}
            onSortModelChange={handleSortModelChange}
          />
        </>
      }
    />
  );
}
