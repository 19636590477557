import { Box, Button, DialogProps } from "@mui/material";
import BasicModal from "../../components/modal/BasicModal";
import DocumentViewer from "./DocumentViewerModal";
interface Props extends DialogProps {
  onClose: () => void;
  documentUrl?: any;
  mimeType?: any;
}

const DeleteUserModal = ({
  onClose,
  documentUrl,
  mimeType,
  ...props
}: Props) => {
  return (
    <Box>
      <BasicModal
        onClose={onClose}
        size={"lg"}
        title="View Document"
        height={800}
        content={
          <DocumentViewer documentUrl={documentUrl} mimeType={mimeType} />
        }
        actions={
          <>
            <Button
              variant="outlined"
              sx={{ textTransform: "capitalize" }}
              onClick={() => onClose()}
              color="error"
            >
              Close
            </Button>
          </>
        }
        {...props}
      />
    </Box>
  );
};

export default DeleteUserModal;
