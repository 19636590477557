import { useMutation, useQuery, useQueryClient } from "react-query";
import useAuth from "./useAuth";

export default function useSMS(
  smsID?: string,
  caseId?: string,
  sentTo?: string
) {
  const { request, userId } = useAuth();

  const queryClient = useQueryClient();

  const handleRefetchSMSByUserId = () => {
    queryClient.invalidateQueries(["/sms/userId"]);
  };

  const { data: messagesData, isLoading: isLoadingMessages } = useQuery(
    ["/sms/userId", sentTo],
    () =>
      request
        .get(`/sms/${userId}`, {
          params: { sentTo },
        })
        .then(({ data }) => {
          return data as any;
        })
  );

  const { mutateAsync: sendMessage, isLoading: isSendingMessage } = useMutation(
    "/sms",
    (payload: any) =>
      request.post("/sms", payload).then((response) => response?.data as any),
    {
      onSuccess: () => {
        handleRefetchSMSByUserId();
      },
    }
  );

  const { mutateAsync: deleteMessage, isLoading: isDeletingMessage } =
    useMutation(
      `/sms/${smsID}`,
      () =>
        request
          .delete(`/sms/${smsID}`)
          .then((response) => response?.data as any),
      {
        onSuccess: () => {
          handleRefetchSMSByUserId();
        },
      }
    );

  const { mutateAsync: readMessage, isLoading: isReadingMessages } =
    useMutation(
      `/sms/${userId}/read`,
      (payload: any) =>
        request
          .put(`/sms/${userId}/read`, payload)
          .then((response) => response?.data as any),
      {
        onSuccess: () => {
          handleRefetchSMSByUserId();
        },
      }
    );

  return {
    readMessage,
    isReadingMessages,
    messages: messagesData?.messages,
    unreadMessageCounts: messagesData?.unreadMessageCounts,
    isLoadingMessages,
    sendMessage,
    isSendingMessage,
    deleteMessage,
    isDeletingMessage,
  };
}
