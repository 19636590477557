import { useQuery } from "react-query";
import useAuth from "./useAuth";

/**
 * Custom hook for managing progress
 * @hook useProgress
 * @author Priyanshu
 *
 */

export default function useProgress(caseId?: string) {
  const { request } = useAuth();

  const {
    data: progress,
    isLoading: isLoadingProgress,
    refetch: refetchProgress,
  } = useQuery(
    ["/progress", caseId],
    () =>
      request.get("/progress", { params: { caseId } }).then((response: any) => {
        return response?.data?.workflowName as any;
      }),
    { enabled: !!caseId }
  );

  return {
    progress,
    isLoadingProgress,
    refetchProgress,
  };
}
