import styled from "@emotion/styled";
import { Grid, Table, TableCell, TableRow, Typography } from "@mui/material";
import { useMemo, useState } from "react";
import { useParams } from "react-router";
import SubCard from "../../../components/cards/SubCard";
import PaymentScheduling from "./PaymentScheduling";
import SupportContactsTabel from "../SupportContactsTabel";
import PaymentMade from "./paymentMade";
import usePayment from "../../../../hooks/usePayment";
import { capitalizeFirstLetter } from "../../../../utils/capitalizeFirstLetter";
import Pagination from "../../../components/Pagination";
import NoResultFound from "../../../components/NoResultFound";

const CustomTableRow = styled(TableRow)(() => ({
  height: "50px",
}));
const CustomTableCell = styled(TableCell)(() => ({
  paddingTop: 0,
  width: 130,
  paddingBottom: 0,
  // fontSize: "15px",
  overflow: "hidden",
  textOverflow: "ellipsis",
}));

const SupportBilling = ({
  caseData,
  isCaseClosed,
  isAgreementNotSigned,
  isExistingClient,
}: any) => {
  const caseId = useParams();

  const { paymentScheduling } = usePayment(caseId?.id);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const paginatedPayments = paymentScheduling?.payment?.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  const totalAmount = useMemo(() => {
    if (!paymentScheduling?.payment) return 0;

    return paymentScheduling.payment.reduce(
      (total: number, paymentItem: { payment_spreads?: any[] }) => {
        if (paymentItem?.payment_spreads) {
          return (
            total +
            paymentItem.payment_spreads.reduce(
              (sum: number, payment: { isPaid: boolean; payment: number }) => {
                return payment?.isPaid
                  ? sum + Number(payment?.payment || 0)
                  : sum;
              },
              0
            )
          );
        }
        return total;
      },
      0
    );
  }, [paymentScheduling]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <SubCard title={"Fee & Balance"}>
          {!!paginatedPayments?.length ? (
            <>
              <Table>
                <CustomTableRow>
                  <CustomTableCell>
                    <Typography variant="h5">Fee Quoted</Typography>
                  </CustomTableCell>
                  <CustomTableCell>
                    <Typography variant="h5">Balance</Typography>
                  </CustomTableCell>
                  <CustomTableCell>
                    <Typography variant="h5">Rep Type</Typography>
                  </CustomTableCell>
                  <CustomTableCell>
                    <Typography variant="h5">Payment Status</Typography>
                  </CustomTableCell>
                </CustomTableRow>
                {paginatedPayments?.map((payment: any, i: number) => {
                  const calculateBalance = (
                    feeQuoted: number | undefined,
                    totalPaidAmount: number | undefined
                  ): number => {
                    const amount = (feeQuoted || 0) - (totalPaidAmount || 0);
                    return Math.max(amount, 0);
                  };
                  return (
                    <CustomTableRow key={i}>
                      <CustomTableCell>{`$ ${Number(payment?.feeQuoted)?.toFixed(2)}`}</CustomTableCell>
                      <CustomTableCell>
                        ${" "}
                        {calculateBalance(
                          Number(payment?.feeQuoted),
                          Number(payment?.totalPaidAmount)
                        )?.toFixed(2)}
                      </CustomTableCell>
                      <CustomTableCell>
                        {capitalizeFirstLetter(caseData?.repType)}
                      </CustomTableCell>
                      <CustomTableCell>
                        {payment?.isPaymentCompleted ? (
                          <Typography sx={{ color: "#2e7d32" }}>
                            Completed
                          </Typography>
                        ) : (
                          <Typography sx={{ color: "#D83630" }}>
                            Incomplete
                          </Typography>
                        )}
                      </CustomTableCell>
                    </CustomTableRow>
                  );
                })}
              </Table>
              <Pagination
                component="div"
                count={paymentScheduling?.payment?.length || 0}
                page={page}
                setPage={setPage}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
                rowsPerPageOptions={[5, 10, 25]}
              />
            </>
          ) : (
            <NoResultFound>No Balance found</NoResultFound>
          )}
        </SubCard>
      </Grid>

      <Grid item xs={12}>
        <PaymentMade
          caseData={caseData}
          caseId={caseId}
          paymentScheduling={paymentScheduling}
          totalAmount={totalAmount}
        />
      </Grid>

      <Grid item xs={12}>
        <PaymentScheduling
          caseData={caseData}
          caseId={caseId}
          isCaseClosed={isCaseClosed}
          isAgreementNotSigned={isAgreementNotSigned}
          isExistingClient={isExistingClient}
        />
      </Grid>
      <SupportContactsTabel caseData={caseData} />
      {/* <Grid item xs={12}>
        <SubCard title={"Statements"}>
          <Table>
            <CustomTableRow>
              <CustomTableCell></CustomTableCell>
              <CustomTableCell></CustomTableCell>
              <CustomTableCell></CustomTableCell>
              <CustomTableCell></CustomTableCell>

              <CustomTableCell sx={{ textAlign: "right" }}></CustomTableCell>
            </CustomTableRow>
            <CustomTableRow>
              <CustomTableCell></CustomTableCell>
              <CustomTableCell></CustomTableCell>
              <CustomTableCell></CustomTableCell>
            </CustomTableRow>
          </Table>
        </SubCard>
      </Grid> */}
    </Grid>
  );
};

export default SupportBilling;
