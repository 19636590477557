import { Button, Grid, Radio, Stack, Tooltip, Typography } from "@mui/material";
import { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { isValidDate } from "../../../utils/checkDateIsValid";
import { getUnitLocation } from "../../../utils/getUnitLocation";

import moment from "moment";
import { getParoleStatus } from "../../../utils/getParoleStatus";
import SubCard from "../../components/cards/SubCard";
import FormContainer from "../../components/FormContainer";
import { useSnackbarHelper } from "../../components/Snackbar";
import BasicTable from "../../components/table/BasicTable";

function InmateInfo({
  caseData,
  inmate,
  isDocuSignPending,
  updateOffenceHistory,
}: any) {
  const showSnackbar = useSnackbarHelper();

  const Gender = [
    { label: "Male", value: "M" },
    { label: "Female", value: "F" },
    { label: "Non-binary", value: "non-binary" },
    { label: "Agender", value: "agender" },
    { label: "Genderfluid", value: "genderfluid" },
    { label: "Other", value: "other" },
  ];

  const location = getUnitLocation(inmate?.unit) || "";

  const denialReasonsCodes =
    inmate?.paroleReviewInfo?.denialReasons
      ?.map((item: any) => item.code)
      .join(", ") || "";

  const initialValues = {
    selectedOffense: caseData?.result?.selectedOffense || "",
    unit: "",
    unit_location: "",
  };

  // const validationSchema: any = useMemo(
  //   () =>
  //     Yup.object({
  //       sidNumber: Yup.string().required("Required"),
  //       tdcjNumber: Yup.string().required("Required"),
  //       name: Yup.string()
  //         .matches(
  //           /^[a-zA-Z\s,]*$/,
  //           "Name should not contain symbols or numbers"
  //         )
  //         .required("Required"),
  //       gender: Yup.string(),
  //       age: Yup.string(),
  //       projectedReleaseDate: Yup.date().nullable(),
  //       paroleEligibilityDate: Yup.date().nullable(),
  //     }),
  //   []
  // );

  const formContext = useForm({
    defaultValues: initialValues,
    // resolver: yupResolver(validationSchema),
  });
  const { watch, setValue } = formContext;

  const unit = watch("unit");

  useEffect(() => {
    const unitLocation = getUnitLocation(unit);

    setValue("unit_location", unitLocation || "");
  }, [unit, setValue]);

  const columns = [
    { label: "Offense Date", value: "offenseDate" },
    { label: "Offense", value: "offense" },
    { label: "Sentence Date", value: "sentenceDate" },
    { label: "County", value: "county" },
    { label: "Case No.", value: "caseNo" },
    { label: "Sentence (YY-MM-DD)", value: "sentence" },
    { label: "Controlling Offense", value: "selectOffence" },
  ];

  const gender = Gender.find((option) => option.value === inmate?.gender);

  const selectedOffense = watch("selectedOffense");
  const sortedHistory = useMemo(() => {
    return [...(inmate?.offenseHistory || [])].sort(
      (a, b) =>
        new Date(b.offenseDate).getTime() - new Date(a.offenseDate).getTime()
    );
  }, [inmate?.offenseHistory]);

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue("selectedOffense", event.target.value);
  };

  const rows = sortedHistory.map((item) => ({
    ...item,
    select: (
      <Radio
        checked={selectedOffense === item._id}
        value={item?._id}
        onChange={handleRadioChange}
        name="selectedOffense"
      />
    ),
  }));

  const handleSubmit = async (values: any) => {
    try {
      const findOffenceHistory = inmate?.offenseHistory?.find(
        (val: any) => val?._id === values?.selectedOffense
      );
      const data = await updateOffenceHistory({
        selectedOffence: selectedOffense,
        primaryOffense: findOffenceHistory?.offense,
      });
      const message = data?.message || "Success";
      showSnackbar(message, "success");
    } catch (err: any) {
      const message = err?.response?.data?.message || "An error occurred";
      showSnackbar(message, "error");
    }
  };
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <SubCard title="Basic Info:">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Stack direction={"row"} alignItems={"center"} spacing={2}>
                <Typography variant="h5">SID #:</Typography>
                <Typography variant="h6">{inmate?.sidNumber || "-"}</Typography>
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Stack direction={"row"} alignItems={"center"} spacing={2}>
                <Typography variant="h5">TDCJ #:</Typography>
                <Typography variant="h6">
                  {inmate?.tdcjNumber || "-"}
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Stack direction={"row"} alignItems={"center"} spacing={2}>
                <Typography variant="h5">Inmate's Name:</Typography>
                <Typography variant="h6">{inmate?.name || "-"}</Typography>
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Stack direction={"row"} alignItems={"center"} spacing={2}>
                <Typography variant="h5">DOB:</Typography>
                <Typography variant="h6">
                  {inmate?.dob ? moment(inmate?.dob).format("MM/DD/YYYY") : "-"}
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Stack direction={"row"} alignItems={"center"} spacing={2}>
                <Typography variant="h5">Age:</Typography>
                <Typography variant="h6">{inmate?.age || "-"}</Typography>
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Stack direction={"row"} alignItems={"center"} spacing={2}>
                <Typography variant="h5">Gender:</Typography>
                <Typography variant="h6">{gender?.label || "-"}</Typography>
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Stack direction={"row"} alignItems={"center"} spacing={2}>
                <Typography variant="h5">Unit:</Typography>
                <Typography variant="h6">{inmate?.unit || "-"}</Typography>
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Stack direction={"row"} alignItems={"center"} spacing={2}>
                <Typography variant="h5">Unit Location:</Typography>
                <Typography variant="h6">{location || "-"}</Typography>
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Stack direction={"row"} alignItems={"center"} spacing={2}>
                <Typography variant="h5">Parole Release Date:</Typography>
                <Typography variant="h6">
                  {isValidDate(
                    moment(inmate?.projectedReleaseDate).format("MM/DD/YYYY")
                  ) || "-"}
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Stack direction={"row"} alignItems={"center"} spacing={2}>
                <Typography variant="h5">Parole Eligibility Date:</Typography>
                <Typography variant="h6">
                  {isValidDate(
                    moment(inmate?.paroleEligibilityDate).format("MM/DD/YYYY")
                  ) || "-"}
                </Typography>
              </Stack>
            </Grid>
          </Grid>
        </SubCard>
      </Grid>
      <Grid item xs={12} md={6}>
        <SubCard title="Parole Review Information:">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Stack direction={"row"} alignItems={"center"} spacing={2}>
                <Typography variant="h5">Parole Review Status:</Typography>

                <Typography variant="h6">
                  {inmate?.paroleReviewInfo?.paroleReviewStatus}
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Stack direction={"row"} alignItems={"center"} spacing={2}>
                <Typography variant="h5">Last Parole Decision:</Typography>

                <Typography variant="h6">
                  {inmate?.paroleReviewInfo?.lastParoleDecision}
                </Typography>
              </Stack>
            </Grid>

            {getParoleStatus(inmate?.paroleReviewInfo?.lastParoleDecision) ===
              "DENIED" && (
              <>
                <Grid item xs={12}>
                  <Stack direction="column" spacing={2}>
                    <Grid item xs={12}>
                      <Stack
                        direction={"row"}
                        alignItems={"center"}
                        spacing={2}
                      >
                        <Typography variant="h5">Denial reason(s):</Typography>

                        <Typography variant="h6">
                          {denialReasonsCodes}
                        </Typography>
                      </Stack>
                    </Grid>

                    {inmate?.paroleReviewInfo?.denialReasons?.map(
                      (reason: any) => (
                        <Grid item xs={12} key={reason.code}>
                          <Typography variant="h5">{reason.heading}</Typography>
                          <Typography variant="h6" mt={1}>
                            {reason.value}
                          </Typography>
                        </Grid>
                      )
                    )}
                  </Stack>
                </Grid>
                <Grid item xs={12}>
                  <Stack direction={"row"} alignItems={"center"} spacing={2}>
                    <Typography variant="h5">
                      Next Parole Review Date:
                    </Typography>
                    <Typography variant="h6">
                      {inmate?.paroleReviewInfo?.nextParoleDate}
                    </Typography>
                  </Stack>
                </Grid>
              </>
            )}
          </Grid>
        </SubCard>
      </Grid>
      <Grid item xs={12}>
        <FormContainer onSuccess={handleSubmit} formContext={formContext}>
          <SubCard
            title=" Offense History:"
            secondary={
              <Stack direction={"row"}>
                <Tooltip title={"Save Selected Offence"}>
                  <Button
                    color="success"
                    variant="contained"
                    type="submit"
                    size="small"
                    disabled={selectedOffense ? false : true}
                    sx={{
                      marginRight: 1,
                    }}
                  >
                    Save
                  </Button>
                </Tooltip>
              </Stack>
            }
          >
            <BasicTable columns={columns} rows={rows || []} />
          </SubCard>
        </FormContainer>
      </Grid>
    </Grid>
  );
}

export default InmateInfo;
