import { createTheme } from "@mui/material/styles";

// project imports
import themeTypography from "./typography";

/**
 * Represent theme style and structure as per Material-UI
 * @param {JsonObject} customization customization parameter object
 */

export const theme = (customization: any) => {
  const themeOption = {
    customization,
  };

  const themeOptions: any = {
    direction: "ltr",
    mixins: {
      toolbar: {
        minHeight: "48px",
        padding: "16px",
        "@media (min-width: 600px)": {
          minHeight: "48px",
        },
      },
    },
    typography: themeTypography(themeOption),
  };

  const themes = createTheme(themeOptions);

  return themes;
};

export default theme;
