import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, DialogProps, Grid } from "@mui/material";
import { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import * as Yup from "yup";

//components
import { Type } from "../../../constants/MenuSelection";
import useTimeEntry from "../../../hooks/useTimeEntry";
import { useSnackbarHelper } from "../../components/Snackbar";
import BasicAutocomplete from "../../components/inputs/BasicAutocomplete";
import CustomDateTime from "../../components/inputs/CustomDateTime";
import MuiTextField from "../../components/inputs/MuiTextField";
import BasicModal from "../../components/modal/BasicModal";
import NumberField from "../../components/inputs/NumberField";
import moment from "moment";

interface Props extends DialogProps {
  onClose: () => void;
  data?: any;
  caseData?: any;
}

function AddTimeEntryModal({
  onClose,
  data,
  caseData,

  ...props
}: Props) {
  const { addTimeEntry, updateTimeEntry, isLoading } = useTimeEntry({
    caseId: caseData?._id,
  });
  const showSnackbar = useSnackbarHelper();

  const initialValues = useMemo(
    () => ({
      client: `${caseData?.contactId?.firstName} ${caseData?.contactId?.lastName} `,
      // staffName: data ? data?.staffName : null,
      startTime: data?.startTime || new Date(),
      endTime: data?.endTime || null,
      billedMinutes: data?.billedMinutes || null,
      type: data?.type || null,
      note: data?.note || "",
      caseId: caseData?._id,
    }),
    [caseData, data]
  );

  const validationSchema: any = useMemo(
    () =>
      Yup.object({
        client: Yup.string(),

        // staffName: Yup.object().required("Required"),
        billedMinutes: Yup.number()
          .transform((value, originalValue) =>
            originalValue === "" ? null : value
          )
          .nullable()
          .typeError("Only numbers are allowed"),
        startTime: Yup.date().required("Start datetime is required"),
        endTime: Yup.date()
          .min(
            Yup.ref("startTime"),
            "End datetime must be after start datetime"
          )
          .required("Required"),
        type: Yup.string().required("Required"),
        notes: Yup.string(),
        caseId: Yup.string(),
      }),
    []
  );
  const formContext = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
  });

  const { watch, setValue } = formContext;
  const startTime = watch("startTime");
  const endTime = watch("endTime");

  useEffect(() => {
    // Convert the time strings to Date objects
    const startDate = new Date(startTime);
    const endDate = new Date(endTime || startTime + 1);

    // // Calculate the difference in milliseconds
    // const differenceInMilliseconds = endDate.getTime() - startDate.getTime();

    // // Convert milliseconds to minutes, rounding up to ensure full minutes are counted
    // const differenceInMinutes = Math.ceil(differenceInMilliseconds / 1000 / 60);
    // Define the two dates in your format
    const date1 = moment(startDate);
    const date2 = moment(endDate);
    let differenceInMinutes = 0;

    // Check if moment is able to parse them
    if (!date1.isValid() || !date2.isValid()) {
      console.error("Invalid date format. Please check the input.");
    } else {
      // Get the minute values
      startDate.setSeconds(0);
      startDate.setMilliseconds(0);
      endDate.setSeconds(0);
      endDate.setMilliseconds(0);
      const differenceInMilliseconds = endDate.getTime() - startDate.getTime();

      // Convert milliseconds to minutes
      differenceInMinutes = Math.floor(differenceInMilliseconds / 1000 / 60);
    }
    if (differenceInMinutes > 0) {
      setValue("billedMinutes", differenceInMinutes);
    } else {
      setValue("billedMinutes", 0);
    }
  }, [endTime, setValue, startTime]);

  const handleSubmit = async (values: any) => {
    values.client = caseData?.contactId?._id;

    try {
      var response;

      if (data) {
        const timeEntryId = data?._id;
        response = await updateTimeEntry({
          id: timeEntryId,
          payload: { ...values, staffName: values.staffName?._id },
        });
      } else {
        // response= await
        response = await addTimeEntry({
          ...values,
          staffName: values.staffName?._id,
        });
      }
      const message = response?.message || "Success";

      showSnackbar(message, "success");
      onClose();
    } catch (err: any) {
      console.log(err?.response?.data?.message);
    }
  };

  return (
    <BasicModal
      formContext={formContext}
      onClose={onClose}
      onSubmit={handleSubmit}
      title={!data ? "Add New Time" : "Update Time"}
      content={
        <Box>
          <Grid
            container
            spacing={2}
            sx={{
              "& .outlined .MuiOutlinedInput-notchedOutline": {
                border: "2px solid #AFD2F0 !important",
              },
            }}
          >
            {/* <Grid item xs={6}>
              <MuiTextField
                fullWidth
                label="Client Name"
                name="client"
                // placeholder={}
                value={caseData?.contactId?._id}
                disabled
                required
              />
            </Grid> */}
            {/* <Grid item xs={6}>
              <BasicAutocomplete
                name="staffName"
                label="Staff Name"
                options={staff}
                getOptionLabel={(option: any) =>
                  `${option?.firstName} ${option?.lastName}`
                }
                isOptionEqualToValue={(option, values) =>
                  option?.value === values
                }
              />
            </Grid> */}

            <Grid item xs={6}>
              <CustomDateTime label="Start Time" name="startTime" />
            </Grid>
            <Grid item xs={6}>
              <CustomDateTime
                label="End Time"
                name="endTime"
                minDate={startTime}
              />
            </Grid>

            <Grid item xs={6}>
              <NumberField
                name="billedMinutes"
                label="Billed Minutes"
                disabled
              />
            </Grid>
            <Grid item xs={6}>
              <BasicAutocomplete
                label="Type"
                name="type"
                required
                options={Type}
                getOptionValue={(option: any) => option?.value}
                disablePortal={false}
              />
            </Grid>
            <Grid item sm={12}>
              {/* <Controller
                name="note"
                control={formContext.control}
                render={({ field }) => (
                  <CustomTextField
                    {...field}
                    name="note"
                    label="Details"
                    fullWidth
                    multiline
                    rows={4}
                    InputProps={{
                      style: {
                        borderRadius: "12px",
                      },
                      classes: {
                        input: "custom-scrollbar", // Apply the custom scrollbar class here
                      },
                    }}
                  />
                )}
              /> */}
              <MuiTextField
                name="note"
                label="Details"
                multiline
                rows={4}
              ></MuiTextField>
            </Grid>
          </Grid>
        </Box>
      }
      actions={
        <>
          <Button color="error" variant="outlined" onClick={onClose}>
            Cancel
          </Button>
          <Button
            variant="contained"
            color="success"
            sx={{ textTransform: "capitalize" }}
            type="submit"
            disabled={isLoading}
          >
            {!!data ? "Update" : "Add"}
          </Button>
        </>
      }
      {...props}
    />
  );
}

export default AddTimeEntryModal;
