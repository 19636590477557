import {
  Box,
  Collapse,
  IconButton,
  Stack,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  IconCirclePlus,
  IconEdit,
  IconList,
  IconTrash,
  IconX,
} from "@tabler/icons-react";
import moment from "moment";
import { useModal } from "mui-modal-provider";
import { Fragment, useCallback, useState } from "react";
import useWorkflows from "../../../hooks/useWorkflows";
import BasicButton from "../../components/buttons/BasicButton";
import NoResultFound from "../../components/NoResultFound";
import Pagination from "../../components/Pagination";
import TableSkeleton from "../../components/skeleton/TableSkeleton";
import BasicTableContainer from "../../components/table/BasicTableContainer";
import AddWorkFlowModal from "./AddWorkFlowModal";
import AddWorkFlowTodoModal from "./AddWorkFlowTodoModal";
import DeleteWorkFlowModal from "./DeleteWorkFlowModal";
import DeleteWorkflowTodoModal from "./DeleteWorkflowTodoModal";

const CustomTableRow = styled(TableRow)(() => ({
  height: "40px",
}));

const CustomTableCell = styled(TableCell)(() => ({
  paddingTop: 0,
  paddingBottom: 0,
}));

const Workflows = () => {
  const { showModal } = useModal();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [expanded, setExpanded] = useState<string | false>(false);
  const [workflowId, setWorkflowId] = useState<string | undefined>(undefined);

  const { workflows, isLoadingWorkflows, tasks, isLoadingTasks } =
    useWorkflows(workflowId);

  // Sliced rows based on pagination
  const paginatedRows = workflows?.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  const handleExpandClick =
    (panel: string, workflowId: string) => (event: React.MouseEvent) => {
      event.stopPropagation();
      setWorkflowId(expanded === panel ? undefined : workflowId);
      setExpanded(expanded === panel ? false : panel);
    };

  const handleAddEditWorkflow = useCallback(
    (data: any) => {
      const modal: any = showModal(AddWorkFlowModal, {
        onClose: () => modal.hide(),
        data,
      });
    },
    [showModal]
  );

  const handleAddEditTodo = useCallback(
    (workflowId?: string, data?: any, event?: string) => {
      const modal: any = showModal(AddWorkFlowTodoModal, {
        onClose: () => modal.hide(),
        workflowId,
        data,
        taskId: data?._id,
        event,
      });
    },
    [showModal]
  );

  const handleDeleteTodo = useCallback(
    (workflowId?: string, data?: any) => {
      const modal: any = showModal(DeleteWorkflowTodoModal, {
        onClose: () => modal.hide(),
        workflowId,
        taskId: data?._id,
      });
    },
    [showModal]
  );

  const handleDeleteWorkflow = useCallback(
    (data: any) => {
      const modal: any = showModal(DeleteWorkFlowModal, {
        onClose: () => modal.hide(),
        id: data?._id,
      });
    },
    [showModal]
  );

  const columns = [
    { id: "sno", label: "S.No.", minWidth: 20 },
    { id: "task", label: "Task", minWidth: 400 },
    { id: "createdAt", label: "Created Date", isDate: true, minWidth: 150 },
    { id: "updatedAt", label: "Updated Date", isDate: true, minWidth: 150 },
    {
      id: "actions",
      label: " Actions",
      minWidth: 80,
      actions: (row: any) => (
        <>
          <Tooltip title={"Edit Workflow"}>
            <IconButton onClick={() => handleAddEditTodo(workflowId, row)}>
              <IconEdit />
            </IconButton>
          </Tooltip>
          <Tooltip
            title={"Delete Workflow"}
            onClick={() => handleDeleteTodo(workflowId, row)}
          >
            <IconButton color="error">
              <IconTrash />
            </IconButton>
          </Tooltip>
        </>
      ),
    },
  ];

  if (isLoadingWorkflows) {
    return <TableSkeleton />;
  }

  return (
    <Box>
      <Stack
        direction={"row"}
        justifyContent={"flex-end"}
        sx={{ marginBottom: 4 }}
      >
        <BasicButton
          label={"Add Workflow"}
          variant="contained"
          color="success"
          onClick={() => handleAddEditWorkflow(null)}
        />
      </Stack>

      {/* <DataGridTable
        // onPaginationModelChange={setPaginationModel}
        // paginationModel={paginationModel}
        column={columns}
        rows={rows}
        isLoading={false}
        totalRows={100}
        // sortModel={sortModel}
        // onSortModelChange={handleSortModelChange}
      /> */}

      {/* <Stack direction={"row"} sx={{ paddingX: 1 }}>
        <Typography variant="h5" sx={{ width: 100 }}>
          SNo
        </Typography>
        <Typography variant="h5" sx={{ width: 200 }}>
          WorkFlow Name
        </Typography>
        <Typography variant="h5" sx={{ width: 400 }}>
          WorkFlow Description
        </Typography>
        <Typography variant="h5">Actions</Typography>
      </Stack> */}
      {!!paginatedRows?.length ? (
        <Box>
          <TableContainer sx={{ maxHeight: 455 }}>
            <Table>
              <TableHead
                sx={{
                  position: "sticky",
                  top: 0,
                  backgroundColor: "#cce8f1",
                  zIndex: 2,
                }}
              >
                <CustomTableRow>
                  <CustomTableCell sx={{ minWidth: 50 }}>
                    <Typography variant="h5">S.No.</Typography>
                  </CustomTableCell>
                  <CustomTableCell sx={{ minWidth: 400 }}>
                    <Typography variant="h5">Workflow</Typography>
                  </CustomTableCell>
                  <CustomTableCell sx={{ minWidth: 150 }}>
                    <Typography variant="h5">Created Date</Typography>
                  </CustomTableCell>
                  <CustomTableCell sx={{ minWidth: 150 }}>
                    <Typography variant="h5">Updated Date</Typography>
                  </CustomTableCell>
                  <CustomTableCell sx={{ minWidth: 160 }}>
                    <Typography variant="h5">Actions</Typography>
                  </CustomTableCell>
                </CustomTableRow>
              </TableHead>
              <TableBody>
                {paginatedRows?.map((workflow: any, i: number) => (
                  <Fragment key={i}>
                    <CustomTableRow>
                      <CustomTableCell>{i + 1}</CustomTableCell>
                      <CustomTableCell>
                        {workflow?.name?.length > 150
                          ? workflow?.name?.substring(0, 150) + "...."
                          : workflow?.name}
                      </CustomTableCell>
                      <CustomTableCell>
                        {moment(workflow?.createdAt).format(
                          "MM/DD/YYYY, h:mm A"
                        )}
                      </CustomTableCell>
                      <CustomTableCell>
                        {moment(workflow?.updatedAt).format(
                          "MM/DD/YYYY, h:mm A"
                        )}
                      </CustomTableCell>
                      <CustomTableCell>
                        <Tooltip title={"Add Todo"}>
                          <IconButton
                            color="success"
                            onClick={() =>
                              handleAddEditTodo(
                                workflow?._id,
                                null,
                                workflow?.triggerOn?.event
                              )
                            }
                          >
                            <IconCirclePlus />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title={"Edit Workflow"}>
                          <IconButton
                            onClick={() => handleAddEditWorkflow(workflow)}
                          >
                            <IconEdit />
                          </IconButton>
                        </Tooltip>
                        <Tooltip
                          title={"Delete Workflow"}
                          onClick={() => handleDeleteWorkflow(workflow)}
                        >
                          <IconButton color="error">
                            <IconTrash />
                          </IconButton>
                        </Tooltip>
                        <Tooltip
                          title={
                            expanded === `panel${i}`
                              ? "Close Todo's"
                              : "View Todo's"
                          }
                        >
                          <IconButton
                            onClick={handleExpandClick(
                              `panel${i}`,
                              workflow?._id
                            )}
                          >
                            {expanded === `panel${i}` ? (
                              <IconX />
                            ) : (
                              <IconList />
                            )}
                          </IconButton>
                        </Tooltip>
                      </CustomTableCell>
                    </CustomTableRow>
                    <CustomTableRow
                      sx={{
                        display:
                          expanded === `panel${i}` ? "table-row" : "none",
                      }}
                    >
                      <CustomTableCell colSpan={6}>
                        <Collapse
                          in={expanded === `panel${i}`}
                          timeout="auto"
                          unmountOnExit
                        >
                          <BasicTableContainer
                            id={workflow?._id}
                            columns={columns}
                            rows={tasks}
                            isLoading={isLoadingTasks}
                            noResultLabel={"No tasks found!"}
                          />
                        </Collapse>
                      </CustomTableCell>
                    </CustomTableRow>
                  </Fragment>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Pagination
            component="div"
            count={workflows?.length || 0}
            page={page}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            rowsPerPageOptions={[10, 20, 50]}
          />
        </Box>
      ) : (
        <NoResultFound>No workflow found!</NoResultFound>
      )}
    </Box>
  );
};

export default Workflows;
