import GavelIcon from "@mui/icons-material/Gavel";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import PeopleIcon from "@mui/icons-material/People";
import {
  Box,
  ButtonBase,
  Grid,
  Paper,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Logo from "../../../assets/images/CoxLaw.png";
import BasicMenu from "../../components/BasicMenu";

import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { styled } from "@mui/material/styles";
import useAuth from "../../../hooks/useAuth";
import useUser from "../../../hooks/useUser";
import Notification from "./Notification";

const leftMenuList = [
  {
    label: "Cases",
    url: "/cases",
    key: "case",
    icon: <GavelIcon sx={{ color: "#E3001B", fontSize: 18 }} />,
  },
  {
    label: "Contacts",
    url: "/contacts",
    key: "contacts",
    icon: <PeopleIcon sx={{ color: "#E3001B", fontSize: 18 }} />,
  },
  {
    label: "Prospects",
    url: "/prospects",
    key: "prospects",
    icon: <GroupAddIcon sx={{ color: "#E3001B", fontSize: 18 }} />,
  },

  {
    label: "Time Entry",
    url: "/time-entry",
    key: "time_entry",
    icon: <GroupAddIcon sx={{ color: "#E3001B", fontSize: 18 }} />,
  },
  {
    label: "Payments",
    url: "/payments",
    key: "payments",
    icon: <GroupAddIcon sx={{ color: "#E3001B", fontSize: 18 }} />,
  },
  {
    label: "DocuSign",
    url: "/docusign-waiting",
    key: "docusign_waiting",
    icon: <GroupAddIcon sx={{ color: "#E3001B", fontSize: 18 }} />,
  },
  {
    label: "Messages",
    url: "/sms",
    key: "sms",
    icon: <GroupAddIcon sx={{ color: "#E3001B", fontSize: 18 }} />,
  },
];

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 30,
  height: 16,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    color: "#5C5555",
    "&.Mui-checked": {
      transform: "translateX(12px)",
      color: "#5C5555",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#C2B7B6" : "#C2B7B6",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255,255,255,.35)"
        : "rgba(0,0,0,.25)",
    boxSizing: "border-box",
  },
}));

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 12,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: "#E9E8E8",
    // theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: "#54A55E",
    // backgroundColor: theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
  },
}));

/*
Used to header section
*/

export default function Header({ setOpenDrawer }: any) {
  const { userId } = useAuth();
  const { user } = useUser(userId);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const location = useLocation();
  const { request } = useAuth();

  const [activeTab, setactiveTab] = useState("/cases");
  const [ratio, setRatio] = useState(false);
  const [successRate, setSuccessRate] = useState(0);

  const currentYear = new Date().getFullYear();

  async function getSuccessData(approvedFor: string) {
    try {
      const data: any = await request.get("/successRate", {
        params: { approvedFor }, // Correct way to send query parameters
      });
      if (data?.status === 200) {
        const ratio = data?.data?.successRate;
        setSuccessRate(ratio);
      }
    } catch (error) {
      console.log(error);
    }
  }

  const handleResize = () => {
    setScreenWidth(window.innerWidth);
  };

  useEffect(() => {
    //set active tab
    if (location?.pathname?.includes("/cases")) {
      setactiveTab("/cases");
    } else {
      setactiveTab(location?.pathname);
    }
  }, [location?.pathname]);

  useEffect(() => {
    getSuccessData("year");

    window.addEventListener("resize", handleResize);

    // Clean up the event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
    //eslint-disable-next-line
  }, []);

  const changeTabs = useCallback((url: string) => {
    setactiveTab(url);
  }, []);

  const getSuccessRate = useCallback(
    async (value: boolean) => {
      setRatio(value);
      const name: string = value === true ? "lifetime" : "year";

      await getSuccessData(name);
    },

    //eslint-disable-next-line
    []
  );

  return (
    <Paper sx={{ width: "100%" }} onClick={() => setOpenDrawer(null)}>
      {/* <Toolbar> */}
      <Grid container alignItems="center" justifyContent={"space-between"}>
        <Stack
          direction={"row"}
          spacing={screenWidth < 1290 ? 1 : screenWidth < 1480 ? 4 : 10}
        >
          <Grid item>
            <ButtonBase
              disableRipple
              component={Link}
              to="/cases"
              sx={{ ml: 1 }}
            >
              <img src={Logo} alt="Logo" height={64} />
            </ButtonBase>
          </Grid>

          {!(screenWidth < 1270) && (
            <Stack
              direction={"row"}
              spacing={screenWidth < 1420 ? 2 : 4}
              alignItems={"center"}
              // spacing={screenWidth <= 1280 ? 1 : 3}
              // ml={screenWidth <= 1280 ? 3 : 6}
            >
              {leftMenuList.map((item, index) => (
                <Grid item key={item?.key}>
                  <Typography>
                    <Link
                      to={item.url}
                      style={{
                        textDecoration: "none",
                        fontSize: "16px",
                        lineHeight: "21px",
                        fontFamily: "'Poppins', sans-serif",
                        fontWeight: "500",
                        color: activeTab === item?.url ? "#008EBB" : "#364152",
                      }}
                      onClick={() => changeTabs(item?.url)}
                    >
                      {item.label}
                    </Link>
                  </Typography>
                </Grid>
              ))}
            </Stack>
          )}
        </Stack>

        <Grid item>
          <Grid
            container
            alignItems="center"
            spacing={screenWidth < 1420 ? 4 : 4}
          >
            <Grid item>
              {screenWidth > 730 && (
                <Stack
                  direction={"row"}
                  alignItems={"center"}
                  spacing={screenWidth < 1420 ? 1.5 : 2}
                >
                  <Typography
                    sx={{
                      fontSize: "14px",
                      lineHeight: "22px",
                      fontFamily: "'Poppins', sans-serif",
                      fontWeight: "500",
                      color: "#364152",
                    }}
                  >
                    Success Rate
                  </Typography>

                  <Stack>
                    <Stack direction={"row"}>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          lineHeight: "22px",
                          fontFamily: "'Poppins', sans-serif",
                          fontWeight: "500",
                          color: "#848484",
                          mr: 1,
                        }}
                      >
                        Yr. {currentYear}
                      </Typography>
                      <AntSwitch
                        checked={ratio}
                        inputProps={{ "aria-label": "ant design" }}
                        onChange={(e) => getSuccessRate(e.target.checked)}
                      />
                      <Typography
                        sx={{
                          fontSize: "14px",
                          lineHeight: "22px",
                          fontFamily: "'Poppins', sans-serif",
                          fontWeight: "500",
                          color: "#848484",
                          ml: 1,
                        }}
                      >
                        Lifetime
                      </Typography>
                    </Stack>
                    <Box
                      sx={{
                        position: "relative",
                        display: "inline-flex",
                        width: "100%",
                      }}
                    >
                      <BorderLinearProgress
                        variant="determinate"
                        // value={ratio ? 50 : 70}
                        value={successRate}
                        sx={{ width: "100%" }}
                      />
                      <Box
                        sx={{
                          top: 0,
                          left: 0,
                          bottom: 0,
                          right: 0,
                          position: "absolute",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          zIndex: 1,
                        }}
                      >
                        <Typography
                          variant="caption"
                          component="div"
                          sx={{
                            fontSize: "12px",
                            fontFamily: "'Poppins', sans-serif",
                            fontWeight: "500",
                            color: "black",
                            mt: "6px",
                            mb: "4px",
                          }}
                        >
                          {/* {ratio ? "50%" : "70%"} */}
                          {successRate}%
                        </Typography>
                      </Box>
                    </Box>
                  </Stack>
                </Stack>
              )}
            </Grid>
            <Grid item>{screenWidth > 465 && <Notification />}</Grid>

            <Grid item>
              <Stack
                direction={"row"}
                alignItems={"center"}
                spacing={screenWidth < 1420 ? 1 : 2}
              >
                <Grid item>
                  {screenWidth > 465 && user && (
                    <Stack>
                      <Stack direction={"row"}>
                        <Typography
                          sx={{
                            fontSize: "16px",
                            lineHeight: "21px",
                            fontFamily: "'Poppins', sans-serif",
                            fontWeight: "700",
                            color: "#D83630",
                          }}
                        >
                          Hello,&nbsp;
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: "16px",
                            lineHeight: "21px",
                            fontFamily: "'Poppins', sans-serif",
                            fontWeight: "500",
                            color: "#364152",
                            maxWidth: 65,
                          }}
                        >
                          {user?.firstName.length > 10
                            ? `${user.firstName.slice(0, 10)}..`
                            : user?.firstName}
                        </Typography>
                      </Stack>

                      <Typography
                        sx={{
                          fontSize: "15px",
                          lineHeight: "21px",
                          fontFamily: "'Poppins', sans-serif",
                          fontWeight: "400",
                          color: "#808080",
                        }}
                      >
                        {user?.role}
                      </Typography>
                    </Stack>
                  )}
                </Grid>

                <Grid item>
                  <BasicMenu
                    screenWidth={screenWidth}
                    user={user}
                    changeTabs={changeTabs}
                    activeTab={activeTab}
                  />
                </Grid>
              </Stack>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
}
