import { Autocomplete, TextField } from "@mui/material";
import React, { useCallback } from "react";
import { Controller, FieldError, useFormContext } from "react-hook-form";
import styled from "@emotion/styled";

export type AutocompleteFieldProps<T> = {
  name: string;
  label?: string;
  parseError?: (error: FieldError) => string;
  getOptionValue?: (option: any) => string | number | null | undefined;
  options?: T[];
  multiple?: boolean;
  helperText?: string;
  required?: boolean;
  freeSolo?: boolean;
  disablePortal?: boolean;
  disabled?: boolean;
  placeholder?: string;
  defaultValue?: T | T[];
  isOptionEqualToValue?: (option: any, value: any) => boolean;
  getOptionLabel?: (option: any) => string;
  renderOption?: (
    props: React.HTMLAttributes<HTMLLIElement>,
    option: T
  ) => React.ReactNode;
};

// Styled TextField with custom styles
const CustomTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    "& fieldset": { borderRadius: "12px" },
    fontSize: "1rem",
    fontFamily: "'Arial', sans-serif",
    backgroundColor: "#f5f5f500",
    // height: "auto",
    // height: "3.2em",
  },
  "& .MuiInputBase-input": {
    fontSize: "1rem",
  },
  "& .MuiInputLabel-root": {
    fontSize: "0.875rem",
  },
  "& .Mui-focused fieldset": {
    borderColor: "#6F7E8C !important",
  },
  // "& .css-1jy569b-MuiFormLabel-root-MuiInputLabel-root.Mui-focused": {
  //   color: "#6F7E8C !important",
  // },
  "& label.Mui-focused": {
    color: "#6F7E8C",
  },
  "& .css-1ald77x.Mui-focused": {
    color: "#6F7E8C !important",
  },
});

/**
 * BasicAutocomplete component for selecting options with support for React Hook Form.
 * @component BasicAutocomplete
 * @author Priyanshu
 *
 */

const BasicAutocomplete = <T,>({
  parseError,
  name,
  label,
  options = [],
  defaultValue,
  isOptionEqualToValue,
  getOptionLabel,
  renderOption,
  required,
  getOptionValue,
  multiple = false,
  freeSolo = false,
  disablePortal = true,
  disabled = false,
  placeholder,
  ...rest
}: AutocompleteFieldProps<T>): JSX.Element => {
  const { control } = useFormContext();

  const isOptionEqualToValueWrapper = useCallback(
    (option: any, value: any) => {
      if (value == null || value === "") return false;

      // if (typeof value === "string" || typeof option === "string") {
      //   return option === value;
      // }

      if (typeof getOptionValue === "function") {
        return getOptionValue(option) === value;
      }

      return isOptionEqualToValue
        ? isOptionEqualToValue(option, value)
        : (value.id && option.id === value.id) ||
            (value._id && option._id === value._id);
    },
    [isOptionEqualToValue, getOptionValue]
  );

  const getOptionLabelWrapper = useCallback(
    (option: T | string) => {
      if (freeSolo && typeof option === "string") {
        return option;
      }

      const orgOption =
        typeof option !== "object" && getOptionValue
          ? options.find((op) => getOptionValue(op) === option)
          : option;

      if (orgOption == null || orgOption === "") return "";

      return getOptionLabel
        ? getOptionLabel(orgOption)
        : (orgOption as any).label ?? "";
    },
    [getOptionLabel, getOptionValue, options, freeSolo]
  );

  const getValues = (data: any) => {
    if (!data) return null;

    if (typeof getOptionValue !== "function") {
      return data;
    }

    if (Array.isArray(data)) {
      return data.map((item) =>
        typeof item !== "object" ? item : getOptionValue(item)
      );
    }

    return typeof data !== "object" ? data : getOptionValue(data);
  };

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({
        field: { onChange, onBlur, value },
        fieldState: { invalid, error },
      }) => (
        <Autocomplete
          disabled={disabled}
          multiple={multiple}
          freeSolo={freeSolo}
          options={options}
          getOptionLabel={getOptionLabelWrapper}
          renderOption={renderOption}
          isOptionEqualToValue={isOptionEqualToValueWrapper}
          disablePortal={disablePortal}
          renderInput={(params) => (
            <CustomTextField
              {...params}
              label={label ? `${label} ${required ? " *" : ""}` : ""}
              placeholder={placeholder}
              name={name}
              error={invalid}
              onChange={freeSolo ? onChange : () => {}}
              helperText={
                error
                  ? typeof parseError === "function"
                    ? parseError(error)
                    : error.message
                  : rest.helperText
              }
            />
          )}
          onChange={(e, data: any) => {
            if (multiple && Array.isArray(data)) {
              onChange(
                data.map((item) =>
                  typeof item === "string"
                    ? item
                    : getOptionValue
                      ? getOptionValue(item)
                      : item
                )
              );
            } else {
              onChange(
                typeof data === "string"
                  ? data
                  : getOptionValue
                    ? getOptionValue(data) || null
                    : data
              );
            }
          }}
          onBlur={onBlur}
          value={getValues(value) || (multiple ? [] : null)}
          {...rest}
        />
      )}
    />
  );
};

export default BasicAutocomplete;
