import axios, { AxiosRequestConfig } from "axios";
import { useSelector } from "react-redux";

/**
 * Custom hook to manage authenticated API requests and access the current user information.
 * @hook
 * @author Priyanshu
 */

export default function useAuth() {
  const { user } = useSelector((state: any) => state.user);
  const userId = user?.userId || null;

  const config: AxiosRequestConfig = {
    baseURL: `${process.env.REACT_APP_API_URL}`,
    headers: {
      Authorization: `Bearer ${user?.token}`,
    },
  };

  const request = axios.create(config);

  // Return the Axios instance and user ID.
  return {
    request,
    userId,
  };
}
