import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import {
  Stack,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import ParoleBoardGuidelineMatrixScore from "./ParoleBoardGuideLineMatrixScore";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  border: "1px solid black",
  cursor: "pointer",
}));

const RiskItemForm = ({
  scores,
  setScores,
  computeRiskLevel,
  tdcjId,
  inmateName,
  handleParoleChange,
  handleDenyParoleChange,
  handleDiscretionaryMSChange,
  handleDenyDiscretionaryMSChange,
  selectedParole,
  selectedDenyParole,
  selectedDiscretionaryMS,
  selectedDenyDiscretionaryMS,
  primaryOffense,
}: any) => {
  const handleInputChange = (name: string, value: any, point: number) => {
    setScores({
      ...scores,
      [name]: { value: value, point: point },
    });
  };

  const { totalStaticRiskScore, totalDynamicRiskScore, totalRiskScore } =
    computeRiskLevel();

  return (
    <Stack
      sx={{
        padding: "20px",
        // maxWidth: { lg: "1024px", md: "800px", sm: "500px" },
        width: "100%",
        alignSelf: "center",
      }}
    >
      <Stack alignItems="center">
        <Typography variant="h6" gutterBottom>
          Risk Item Factors Scale
        </Typography>
        <Stack
          direction="row"
          justifyContent="space-between"
          spacing={2}
          sx={{ width: "100%", marginBottom: "20px" }}
          alignItems={"center"}
        >
          <Stack
            width={600}
            spacing={1}
            alignItems={"center"}
            direction={"row"}
          >
            <Typography fontSize={"16px"} fontWeight={900}>
              Inmate's Name :
            </Typography>
            <Typography fontSize={"15px"}>{inmateName}</Typography>
          </Stack>
          <Stack
            width={300}
            spacing={1}
            alignItems={"center"}
            direction={"row"}
          >
            <Typography fontWeight={900}>TDCJ-ID#:</Typography>
            <Typography
              style={{ borderBottom: "1px solid black", padding: "0 10px" }}
            >
              {tdcjId}
            </Typography>
          </Stack>
        </Stack>
      </Stack>
      <Stack fontSize={"15px"} mb={5}>
        Instructions: Circle the correct score for each item and compute the
        correct risk level designation using the instruction guide.
      </Stack>
      <TableContainer>
        <Table>
          <TableBody>
            <TableRow className="table_row table_heading">
              <StyledTableCell
                className="table_cell"
                sx={{
                  fontWeight: "bold",
                  fontSize: "15px",
                  width: "45%",
                }}
              >
                1. Age at First Commitment
              </StyledTableCell>
              <StyledTableCell className="table_cell"></StyledTableCell>
              <StyledTableCell
                className="table_cell"
                sx={{ fontWeight: "bold", fontSize: "15px" }}
              >
                6. Current Age
              </StyledTableCell>
              <StyledTableCell
                className="table_cell"
                sx={{ textAlign: "center", width: "20px" }}
              ></StyledTableCell>
            </TableRow>
            <TableRow className="table_row">
              <StyledTableCell className="table_cell" style={{ height: "5px" }}>
                26 years or older
              </StyledTableCell>
              <StyledTableCell
                className={`table_cell ${
                  scores?.ageAtFirstCommitment?.point === 0 ? "is-selected" : ""
                }`}
                onClick={() =>
                  handleInputChange(
                    "ageAtFirstCommitment",
                    "26 years or older",
                    0
                  )
                }
              >
                0
              </StyledTableCell>
              <StyledTableCell className="table_cell">
                45 and above
              </StyledTableCell>
              <StyledTableCell
                className={`table_cell ${
                  scores?.currentAge?.point === 0 ? "is-selected" : ""
                }`}
                onClick={() =>
                  handleInputChange("currentAge", "45 and above", 0)
                }
                sx={{ textAlign: "center", width: "20px" }}
              >
                0
              </StyledTableCell>
            </TableRow>
            <TableRow className="table_row">
              <StyledTableCell className="table_cell">18 to 25</StyledTableCell>
              <StyledTableCell
                className={`table_cell ${
                  scores?.ageAtFirstCommitment?.point === 1 ? "is-selected" : ""
                }`}
                onClick={() =>
                  handleInputChange("ageAtFirstCommitment", "18 to 25", 1)
                }
              >
                1
              </StyledTableCell>
              <StyledTableCell className="table_cell">35 - 44</StyledTableCell>
              <StyledTableCell
                className={`table_cell ${
                  scores?.currentAge?.point === 1 ? "is-selected" : ""
                }`}
                onClick={() => handleInputChange("currentAge", "35 - 44", 1)}
                sx={{ textAlign: "center", width: "20px" }}
              >
                1
              </StyledTableCell>
            </TableRow>
            <TableRow className="table_row">
              <StyledTableCell className="table_cell">
                17 years or younger
              </StyledTableCell>
              <StyledTableCell
                className={`table_cell ${
                  scores?.ageAtFirstCommitment?.point === 2 ? "is-selected" : ""
                }`}
                onClick={() =>
                  handleInputChange(
                    "ageAtFirstCommitment",
                    "17 years or younger",
                    2
                  )
                }
              >
                2
              </StyledTableCell>
              <StyledTableCell className="table_cell">25 - 34</StyledTableCell>
              <StyledTableCell
                className={`table_cell ${
                  scores?.currentAge?.point === 2 ? "is-selected" : ""
                }`}
                onClick={() => handleInputChange("currentAge", "25 - 34", 2)}
                sx={{ textAlign: "center", width: "20px" }}
              >
                2
              </StyledTableCell>
            </TableRow>
            <TableRow className="table_row">
              <StyledTableCell
                className="table_cell"
                sx={{ fontWeight: "bold", fontSize: "15px" }}
              >
                2. History of Revocations
              </StyledTableCell>
              <StyledTableCell className="table_cell"></StyledTableCell>
              <StyledTableCell className="table_cell">Under 25</StyledTableCell>
              <StyledTableCell
                className={`table_cell ${
                  scores?.currentAge?.point === 3 ? "is-selected" : ""
                }`}
                onClick={() => handleInputChange("currentAge", "Under 25", 3)}
                sx={{ textAlign: "center", width: "20px" }}
              >
                3
              </StyledTableCell>
            </TableRow>

            <TableRow className="table_row table_heading">
              <StyledTableCell className="table_cell">
                No parole or probation revocations
              </StyledTableCell>
              <StyledTableCell
                className={`table_cell ${
                  scores?.historyOfRevocations?.point === 0 ? "is-selected" : ""
                }`}
                onClick={() =>
                  handleInputChange(
                    "historyOfRevocations",
                    "  No parole or probation revocations",
                    0
                  )
                }
              >
                0
              </StyledTableCell>
              <StyledTableCell
                className="table_cell"
                sx={{ fontWeight: "bold", fontSize: "15px" }}
              >
                7. Security Threat Group Membership
              </StyledTableCell>
              <StyledTableCell
                className="table_cell"
                sx={{ textAlign: "center", width: "20px" }}
              ></StyledTableCell>
            </TableRow>
            <TableRow className="table_row">
              <StyledTableCell className="table_cell">
                Had one revocation
              </StyledTableCell>
              <StyledTableCell
                className={`table_cell ${
                  scores?.historyOfRevocations?.point === 1 ? "is-selected" : ""
                }`}
                onClick={() =>
                  handleInputChange(
                    "historyOfRevocations",
                    "Had one revocation",
                    1
                  )
                }
              >
                1
              </StyledTableCell>
              <StyledTableCell className="table_cell">No</StyledTableCell>
              <StyledTableCell
                className={`table_cell ${
                  scores?.securityThreatGroupMembership?.point === 0
                    ? "is-selected"
                    : ""
                }`}
                onClick={() =>
                  handleInputChange("securityThreatGroupMembership", "No", 0)
                }
                sx={{ textAlign: "center", width: "20px" }}
              >
                0
              </StyledTableCell>
            </TableRow>
            <TableRow className="table_row">
              <StyledTableCell className="table_cell">
                Had more than one revocation
              </StyledTableCell>
              <StyledTableCell
                className={`table_cell ${
                  scores?.historyOfRevocations?.point === 3 ? "is-selected" : ""
                }`}
                onClick={() =>
                  handleInputChange(
                    "historyOfRevocations",
                    " Had more than one revocation",
                    3
                  )
                }
              >
                3
              </StyledTableCell>
              <StyledTableCell className="table_cell">Yes</StyledTableCell>
              <StyledTableCell
                className={`table_cell ${
                  scores?.securityThreatGroupMembership?.point === 2
                    ? "is-selected"
                    : ""
                }`}
                onClick={() =>
                  handleInputChange("securityThreatGroupMembership", "Yes", 2)
                }
                sx={{ textAlign: "center", width: "20px" }}
              >
                2
              </StyledTableCell>
            </TableRow>

            <TableRow className="table_row table_heading">
              <StyledTableCell
                className="table_cell"
                sx={{ fontWeight: "bold", fontSize: "15px" }}
              >
                3. Other Incarcerations (Juv. & Adult)
              </StyledTableCell>
              <StyledTableCell className="table_cell"></StyledTableCell>
              <StyledTableCell
                className="table_cell"
                sx={{ fontWeight: "bold", fontSize: "15px" }}
                colSpan={2}
              >
                8. Completed Education/OJT/Vocational Program
              </StyledTableCell>
              {/* <StyledTableCell className="table_cell"></StyledTableCell> */}
            </TableRow>
            <TableRow className="table_row">
              <StyledTableCell className="table_cell">None</StyledTableCell>
              <StyledTableCell
                className={`table_cell ${
                  scores?.otherIncarcerations?.point === 0 ? "is-selected" : ""
                }`}
                onClick={() =>
                  handleInputChange("otherIncarcerations", "None", 0)
                }
              >
                0
              </StyledTableCell>
              <StyledTableCell className="table_cell">
                Yes or GED/High School Degree
              </StyledTableCell>
              <StyledTableCell
                className={`table_cell ${
                  scores?.completedEducation?.point === 0 ? "is-selected" : ""
                }`}
                onClick={() =>
                  handleInputChange(
                    "completedEducation",
                    "Yes or GED/High School Degree",
                    0
                  )
                }
                sx={{ textAlign: "center", width: "20px" }}
              >
                0
              </StyledTableCell>
            </TableRow>
            <TableRow className="table_row">
              <StyledTableCell className="table_cell">1-2</StyledTableCell>
              <StyledTableCell
                className={`table_cell ${
                  scores?.otherIncarcerations?.point === 1 ? "is-selected" : ""
                }`}
                onClick={() =>
                  handleInputChange("otherIncarcerations", "1-2", 1)
                }
              >
                1
              </StyledTableCell>
              <StyledTableCell className="table_cell">No</StyledTableCell>
              <StyledTableCell
                className={`table_cell ${
                  scores?.completedEducation?.point === 2 ? "is-selected" : ""
                }`}
                onClick={() => handleInputChange("completedEducation", "No", 2)}
                sx={{ textAlign: "center", width: "20px" }}
              >
                2
              </StyledTableCell>
            </TableRow>
            <TableRow className="table_row">
              <StyledTableCell className="table_cell">
                3 and above
              </StyledTableCell>
              <StyledTableCell
                className={`table_cell ${
                  scores?.otherIncarcerations?.point === 2 ? "is-selected" : ""
                }`}
                onClick={() =>
                  handleInputChange("otherIncarcerations", "    3 and above", 2)
                }
              >
                2
              </StyledTableCell>
              <StyledTableCell
                className="table_cell"
                sx={{ fontWeight: "bold", fontSize: "15px" }}
              >
                9. Disciplinary Conduct
              </StyledTableCell>
              <StyledTableCell
                className="table_cell"
                sx={{ textAlign: "center", width: "20px" }}
              ></StyledTableCell>
            </TableRow>

            <TableRow className="table_row table_heading">
              <StyledTableCell
                className="table_cell"
                sx={{ fontWeight: "bold", fontSize: "15px" }}
              >
                4. Employment History
              </StyledTableCell>
              <StyledTableCell className="table_cell"></StyledTableCell>
              <StyledTableCell className="table_cell">
                Good time awarded
              </StyledTableCell>
              <StyledTableCell
                className={`table_cell ${
                  scores?.disciplinaryConduct?.point === 0 ? "is-selected" : ""
                }`}
                onClick={() =>
                  handleInputChange(
                    "disciplinaryConduct",
                    "Good time awarded",
                    0
                  )
                }
                sx={{ textAlign: "center", width: "20px" }}
              >
                0
              </StyledTableCell>
            </TableRow>
            <TableRow className="table_row">
              <StyledTableCell className="table_cell">
                Employed 6 months prior to prison
              </StyledTableCell>
              <StyledTableCell
                className={`table_cell ${
                  scores?.employmentHistory?.point === 0 ? "is-selected" : ""
                }`}
                onClick={() =>
                  handleInputChange(
                    "employmentHistory",
                    "  Employed 6 months prior to prison",
                    0
                  )
                }
              >
                0
              </StyledTableCell>
              <StyledTableCell className="table_cell">
                Has been demoted in class below entry status or lost good time
                in the last 18 months
              </StyledTableCell>
              <StyledTableCell
                className={`table_cell ${
                  scores?.disciplinaryConduct?.point === 1 ? "is-selected" : ""
                }`}
                onClick={() =>
                  handleInputChange(
                    "disciplinaryConduct",
                    "Has been demoted in class below entry status or lost good time in the last 18 months",
                    1
                  )
                }
                sx={{ textAlign: "center", width: "20px" }}
              >
                1
              </StyledTableCell>
            </TableRow>
            <TableRow className="table_row">
              <StyledTableCell className="table_cell">
                None or less than 6 months
              </StyledTableCell>
              <StyledTableCell
                className={`table_cell ${
                  scores?.employmentHistory?.point === 1 ? "is-selected" : ""
                }`}
                onClick={() =>
                  handleInputChange(
                    "employmentHistory",
                    "None or less than 6 months",
                    1
                  )
                }
              >
                1
              </StyledTableCell>
              <StyledTableCell className="table_cell">
                Demoted in class below entry status and lost good time in the
                last 18 months
              </StyledTableCell>
              <StyledTableCell
                className={`table_cell ${
                  scores?.disciplinaryConduct?.point === 2 ? "is-selected" : ""
                }`}
                onClick={() =>
                  handleInputChange(
                    "disciplinaryConduct",
                    "Demoted in class below entry status and lost good time in the last 18 months",
                    2
                  )
                }
                sx={{ textAlign: "center", width: "20px" }}
              >
                2
              </StyledTableCell>
            </TableRow>
            <TableRow className="table_row">
              <StyledTableCell className="table_cell"></StyledTableCell>
              <StyledTableCell className="table_cell"></StyledTableCell>
              <StyledTableCell className="table_cell">
                Zero Balance of Good time
              </StyledTableCell>
              <StyledTableCell
                className={`table_cell ${
                  scores?.disciplinaryConduct?.point === 3 ? "is-selected" : ""
                }`}
                onClick={() =>
                  handleInputChange(
                    "disciplinaryConduct",
                    "Zero Balance of Good time",
                    3
                  )
                }
                sx={{ textAlign: "center", width: "20px" }}
              >
                3
              </StyledTableCell>
            </TableRow>

            <TableRow className="table_row table_heading">
              <StyledTableCell
                className="table_cell"
                sx={{ fontWeight: "bold", fontSize: "15px" }}
              >
                5. Commitment Offense
              </StyledTableCell>
              <StyledTableCell className="table_cell"></StyledTableCell>
              <StyledTableCell
                className="table_cell"
                sx={{ fontWeight: "bold", fontSize: "15px" }}
              >
                10. Current Custody Level
              </StyledTableCell>
              <StyledTableCell
                className="table_cell"
                sx={{ textAlign: "center", width: "20px" }}
              ></StyledTableCell>
            </TableRow>
            <TableRow className="table_row">
              <StyledTableCell className="table_cell">
                All others
              </StyledTableCell>
              <StyledTableCell
                className={`table_cell ${
                  scores?.commitmentOffense?.point === 0 ? "is-selected" : ""
                }`}
                onClick={() =>
                  handleInputChange("commitmentOffense", "All others", 0)
                }
              >
                0
              </StyledTableCell>
              <StyledTableCell className="table_cell">
                Minimum Out, Outside Trustee, Level 1
              </StyledTableCell>
              <StyledTableCell
                className={`table_cell ${
                  scores?.currentCustodyLevel?.point === 0 ? "is-selected" : ""
                }`}
                onClick={() =>
                  handleInputChange(
                    "currentCustodyLevel",
                    "Minimum Out, Outside Trustee, Level 1",
                    0
                  )
                }
                sx={{ textAlign: "center", width: "20px" }}
              >
                0
              </StyledTableCell>
            </TableRow>
            <TableRow className="table_row">
              <StyledTableCell className="table_cell" rowSpan={2}>
                Auto Theft (UUMV), Burglary of A Vehicle, Burglary or Forgery
              </StyledTableCell>
              <StyledTableCell
                className={`table_cell ${
                  scores?.commitmentOffense?.point === 1 ? "is-selected" : ""
                }`}
                rowSpan={2}
                onClick={() =>
                  handleInputChange(
                    "commitmentOffense",
                    "Auto Theft (UUMV), Burglary of A Vehicle, Burglary or Forgery",
                    1
                  )
                }
              >
                1
              </StyledTableCell>
              <StyledTableCell className="table_cell">
                Minimum In, Protective In, Level II and Level III
              </StyledTableCell>
              <StyledTableCell
                className={`table_cell ${
                  scores?.currentCustodyLevel?.point === 1 ? "is-selected" : ""
                }`}
                onClick={() =>
                  handleInputChange(
                    "currentCustodyLevel",
                    "Minimum In, Protective In, Level II and Level III",
                    1
                  )
                }
                sx={{ textAlign: "center", width: "20px" }}
              >
                1
              </StyledTableCell>
            </TableRow>
            <TableRow className="table_row">
              <StyledTableCell className="table_cell">
                Medium, Close, or Admin. Segregation
              </StyledTableCell>
              <StyledTableCell
                className={`table_cell ${
                  scores?.currentCustodyLevel?.point === 2 ? "is-selected" : ""
                }`}
                onClick={() =>
                  handleInputChange(
                    "currentCustodyLevel",
                    "Medium, Close, or Admin. Segregation",
                    2
                  )
                }
                sx={{ textAlign: "center", width: "20px" }}
              >
                2
              </StyledTableCell>
            </TableRow>
            <TableRow className="table_row">
              <StyledTableCell className="table_cell">
                Total Static Risk Store
              </StyledTableCell>
              <StyledTableCell className="table_cell ">
                {totalStaticRiskScore}
              </StyledTableCell>
              <StyledTableCell className="table_cell">
                Total Dynamic Risk Store
              </StyledTableCell>
              <StyledTableCell
                className="table_cell "
                sx={{ textAlign: "center", width: "20px" }}
              >
                {totalDynamicRiskScore}
              </StyledTableCell>
            </TableRow>
          </TableBody>
          <TableRow className="table_row" sx={{ borderTop: "2px solid black" }}>
            <StyledTableCell className="table_cell" colSpan={4} align="center">
              Total Risk Store: (add static risk and dynamic risk scores):
              <span
                style={{
                  borderBottom: "1px solid black",
                  padding: "0 20px",
                  textAlign: "center",
                }}
              >
                {totalRiskScore}
              </span>
              Points
            </StyledTableCell>
          </TableRow>
          <TableRow className="table_row">
            <StyledTableCell colSpan={4} sx={{ textAlign: "center" }}>
              <Stack fontWeight={"bold"}>
                Overall Risk Level: (Check correct Risk Level)
              </Stack>
              Low Risk (0-5 pts){" "}
              {totalRiskScore < 6 && totalRiskScore != null ? (
                <span
                  style={{
                    borderBottom: "1px solid black",
                    padding: "0px 5px",
                  }}
                >
                  <CheckOutlinedIcon sx={{ color: "red", mb: "-5px" }} />
                </span>
              ) : (
                "___"
              )}{" "}
              Moderate risk (6-8 pts){" "}
              {totalRiskScore < 9 && totalRiskScore > 5 ? (
                <span
                  style={{
                    borderBottom: "1px solid black",
                    padding: "0px 5px",
                  }}
                >
                  <CheckOutlinedIcon sx={{ color: "red", mb: "-5px" }} />
                </span>
              ) : (
                "___"
              )}{" "}
              High risk (9-11 pts){" "}
              {totalRiskScore < 12 && totalRiskScore > 8 ? (
                <span
                  style={{
                    borderBottom: "1px solid black",
                    padding: "0px 5px",
                  }}
                >
                  <CheckOutlinedIcon sx={{ color: "red", mb: "-5px" }} />
                </span>
              ) : (
                "___"
              )}{" "}
              Highest risk (12+ pts)
              {totalRiskScore >= 12 && totalRiskScore > 8 ? (
                <span
                  style={{
                    borderBottom: "1px solid black",
                    padding: "0px 5px",
                  }}
                >
                  <CheckOutlinedIcon sx={{ color: "red", mb: "-5px" }} />
                </span>
              ) : (
                " ___"
              )}{" "}
            </StyledTableCell>
          </TableRow>
        </Table>
      </TableContainer>
      <ParoleBoardGuidelineMatrixScore
        tdcjId={tdcjId}
        inmateName={inmateName}
        handleParoleChange={handleParoleChange}
        handleDenyParoleChange={handleDenyParoleChange}
        handleDiscretionaryMSChange={handleDiscretionaryMSChange}
        handleDenyDiscretionaryMSChange={handleDenyDiscretionaryMSChange}
        selectedParole={selectedParole}
        selectedDenyParole={selectedDenyParole}
        selectedDiscretionaryMS={selectedDiscretionaryMS}
        selectedDenyDiscretionaryMS={selectedDenyDiscretionaryMS}
        primaryOffense={primaryOffense}
      />
    </Stack>
  );
};

export default RiskItemForm;
