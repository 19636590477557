import {
  Box,
  Button,
  DialogProps,
  Grid,
  Stack,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";

import BasicModal from "../../components/modal/BasicModal";

import { useCallback, useMemo, useRef, useState } from "react";

import { useForm } from "react-hook-form";
// import Checkbox from "../../components/inputs/CheckBox";
import { yupResolver } from "@hookform/resolvers/yup";
import ReactCrop, {
  centerCrop,
  convertToPixelCrop,
  makeAspectCrop,
  PixelCrop,
  type Crop,
} from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import SignatureCanvas from "react-signature-canvas";
import * as Yup from "yup";
import useUser from "../../../hooks/useUser";
import { useSnackbarHelper } from "../../components/Snackbar";
import { uploadImageToS3 } from "../../components/common/uploadImages";
// import TextArea from "../../components/inputs/TextArea";

interface Props extends DialogProps {
  onClose: () => void;
  userId?: any;
}

function SignatureModal({ onClose, userId, ...props }: Props) {
  const [signatureImg, setSignatureImg] = useState<any>("");
  const [previewUrl, setPreviewUrl] = useState<any>("");
  const { user, updateProfile, isUpdatingProfile } = useUser(userId);
  const showSnackbar = useSnackbarHelper();
  const signaturePadRef = useRef<any>(null);
  const signatureInputRef = useRef<any>(null);
  const handleAvatarClick = () => {
    console.log("test", signatureInputRef);
    signatureInputRef.current.click();
  };
  const clearSignature = () => {
    if (signaturePadRef.current) {
      signaturePadRef.current.clear();
    }
  };
  const initialValues = {
    profileImg: user?.profileImg || "",
    signature: user?.signature || "",
  };

  const validationSchema: any = useMemo(
    () =>
      Yup.object({
        profileImg: Yup.string(),
        signature: Yup.string(),
      }),
    []
  );

  const formContext = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
  });

  const handleSubmit = async (values?: any) => {
    const folderName = "signatures";
    let data: any;

    try {
      if (activeTab === 0 && signaturePadRef.current) {
        if (signaturePadRef.current.isEmpty()) {
          throw new Error(
            "Signature pad is empty. Please provide a signature."
          );
        }
        const canvas = signaturePadRef.current.getCanvas();
        const blob = await new Promise<Blob>((resolve, reject) => {
          canvas.toBlob((blob: any) => {
            if (blob) {
              resolve(blob);
            } else {
              reject(new Error("Failed to create blob"));
            }
          }, "image/png"); // Specify MIME type
        });

        // Create a File object with a name and type
        const fileName = `${Date.now()}.png`;
        const file = new File([blob], fileName, { type: "image/png" });
        data = {
          file: file,
          userId: user._id,
          folderName: folderName,
        };
      } else {
        // Fallback data if no signature pad is used
        data = await createFileFromCanvas();
        if (!data) {
          throw new Error("No image data to upload");
        }
      }

      // Upload the image to S3
      const res = await uploadImageToS3(data);
      if (!res?.Location) {
        throw new Error("Error while uploading Signature");
      }

      // Prepare and send update request
      const payload = {
        profileImg: user?.profileImg || "",
        signature: res.Location,
      };
      const updateUsr = await updateProfile(payload);
      setPreviewUrl(updateUsr?.user?.signature);
      showSnackbar(updateUsr?.message || "Success", "success");
      onClose();
    } catch (error: any) {
      // Show appropriate error message
      const message = error?.message || "An error occurred";
      showSnackbar(message, "error");
    }
  };

  //   const handleSubmit = async (values?: any) => {
  //     const folderName = "signatures";
  //     let data;

  //     if (activeTab === 0 && signaturePadRef.current) {
  //       const canvas = signaturePadRef.current.getCanvas();
  //       canvas.toBlob((blob:any) => {
  //         if (blob) {
  //              data = {
  //                 file: signatureImg,
  //                 userId: user._id,
  //                 folderName: folderName,
  //               };
  //         } else {
  //           console.error("Failed to create blob");
  //         }
  //       });

  //     //   const blob = await canvas.toBlob()

  //     //   console.log(blob);

  //       console.log("canvals", canvas);
  //       // Get the data URL of the signature
  //       //   const dataURL = signaturePadRef.current.toDataURL("image/png");
  //     } else {
  //       console.log("valuesssssss", values);
  //       const folderName = "signatures";
  //        data = {
  //         file: signatureImg,
  //         userId: user._id,
  //         folderName: folderName,
  //       };
  //     }
  //     const res = await uploadImageToS3(data);
  //     if (!res?.Location) {
  //       return showSnackbar("Error while uploading Signature", "error");
  //     }
  //     try {
  //       const payload = {
  //         profileImg: user?.profileImg || "",
  //         signature: res?.Location,
  //       };
  //       console.log("payload", payload);
  //       const updateUsr = await updateProfile(payload);
  //       setPreviewUrl(updateUsr?.user?.signature);
  //       const message = updateUsr?.message || "Success";
  //       showSnackbar(message, "success");
  //       onClose();
  //     } catch (error: any) {
  //       const message = error?.response?.data?.message || "An error occurred";
  //       showSnackbar(message, "error");
  //     }
  //   };
  const [activeTab, setActiveTab] = useState(0);

  const handleTabChange = (event: any, newValue: any) => {
    setActiveTab(newValue);
  };
  const handleFileChange = async (event: any) => {
    const file = event.target.files[0];
    if (file) {
      // Convert file to Blob and create an object URL
      const preview = URL.createObjectURL(file);
      setPreviewUrl(preview);
      setSignatureImg(file);
      // console.log("file>>>", file);
      // Uncomment if you want to limit the file size
      // if (file.size > 5 * 1024 * 1024) {
      //   return showSnackbar("File size should be less than 5MB", "error");
      // }
    }
    if (file.size > 5 * 1024 * 1024) {
      return showSnackbar("File size should be less than 5MB", "error");
    }
  };

  const previewCanvasRef = useRef<HTMLCanvasElement>(null);
  const imgRef = useRef<HTMLImageElement>(null);
  const [crop, setCrop] = useState<Crop>();
  const [completedCrop, setCompletedCrop] = useState<PixelCrop | null>(null);
  const [aspect, setAspect] = useState<number | undefined>(10 / 3);

  function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {
    function centerAspectCrop(
      mediaWidth: number,
      mediaHeight: number,
      aspect: number
    ) {
      return centerCrop(
        makeAspectCrop(
          {
            unit: "%",
            width: 900,
          },
          aspect,
          mediaWidth,
          mediaHeight
        ),
        mediaWidth,
        mediaHeight
      );
    }

    if (aspect) {
      const { width, height } = e.currentTarget;
      setCrop(centerAspectCrop(width, height, aspect));
    }
  }

  const createFileFromCanvas = useCallback(() => {
    return new Promise<any>((resolve, reject) => {
      if (!completedCrop || !imgRef.current || !previewCanvasRef.current) {
        return reject(new Error("Image not selected"));
      }

      const canvas = previewCanvasRef.current;
      const image = imgRef.current;
      const scaleX = image.naturalWidth / image.width;
      const scaleY = image.naturalHeight / image.height;
      const ctx = canvas.getContext("2d");

      if (!ctx) {
        return reject(new Error("No context available"));
      }

      const pixelCrop = convertToPixelCrop(
        completedCrop,
        image.width,
        image.height
      );

      canvas.width = pixelCrop.width;
      canvas.height = pixelCrop.height;

      ctx.drawImage(
        image,
        pixelCrop.x * scaleX,
        pixelCrop.y * scaleY,
        pixelCrop.width * scaleX,
        pixelCrop.height * scaleY,
        0,
        0,
        pixelCrop.width,
        pixelCrop.height
      );

      canvas.toBlob(
        (blob) => {
          if (!blob) {
            return reject(new Error("Failed to create blob"));
          }

          const file = new File([blob], `${Date.now()}.png`, {
            type: "image/png",
            lastModified: Date.now(),
          });

          const data = {
            file: file,
            userId: user._id,
            folderName: "signatures",
          };
          resolve(data);
        },
        "image/png",
        1
      );
    });
  }, [completedCrop, user]);

  return (
    <Box>
      <BasicModal
        formContext={formContext}
        onClose={onClose}
        onSubmit={handleSubmit}
        title={"Edit Signature"}
        content={
          <Box>
            <Tabs value={activeTab} onChange={handleTabChange} sx={{ mb: 2 }}>
              <Tab label="Draw" />
              <Tab label="Upload" />
            </Tabs>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box
                  sx={{
                    bgcolor: "grey.100",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    mb: 2,
                    borderRadius: "5px",
                  }}
                >
                  {activeTab === 0 ? (
                    <Box
                      p={1}
                      sx={{
                        width: "100%",
                      }}
                    >
                      <SignatureCanvas
                        ref={signaturePadRef}
                        //   penColor="black"
                        canvasProps={{
                          width: 500,
                          height: 200,
                          className: "signature-canvas",
                        }}
                      />
                    </Box>
                  ) : (
                    <Stack
                      p={1}
                      justifyContent="center"
                      alignItems={"center"}
                      sx={{
                        width: "100%",
                        height: "150px",
                      }}
                    >
                      <input
                        type="file"
                        accept="image/*"
                        name="signature"
                        style={{ display: "none" }}
                        ref={signatureInputRef}
                        onChange={handleFileChange}
                      />
                      {signatureImg ? (
                        <ReactCrop
                          crop={crop}
                          onChange={(_: any, percentCrop: any) =>
                            setCrop(percentCrop)
                          }
                          onComplete={(c: any) => setCompletedCrop(c)}
                        >
                          <img
                            ref={imgRef}
                            alt="Crop me"
                            src={previewUrl}
                            style={{ width: "600px", height: "160px" }}
                            onLoad={onImageLoad}
                          />
                        </ReactCrop>
                      ) : (
                        <Button
                          variant="contained"
                          color="primary"
                          sx={{ textTransform: "capitalize" }}
                          onClick={handleAvatarClick}
                        >
                          Upload Your Signature
                        </Button>
                      )}
                    </Stack>
                  )}
                </Box>
              </Grid>
            </Grid>
            {/* <Typography variant="caption" color="text.secondary" sx={{ mt: 2, display: 'block' }}>
          Supported formats: PNG, JPEG
        </Typography> */}
            <Stack direction={"row"} justifyContent={"space-between"}>
              <Typography variant="caption" color="text.secondary">
                Maximum file size: 5MB
              </Typography>
              {activeTab === 0 && (
                <Button onClick={clearSignature}>Clear Signature</Button>
              )}
            </Stack>
          </Box>
        }
        actions={
          <>
            <Button
              color="error"
              variant="outlined"
              onClick={onClose}
              sx={{ textTransform: "capitalize" }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              disabled={isUpdatingProfile}
              color="success"
              sx={{ textTransform: "capitalize" }}
              type="submit"
            >
              Save
            </Button>
          </>
        }
        {...props}
      />
      <div style={{ display: "block" }}>
        <canvas ref={previewCanvasRef} style={{ display: "none" }} />
      </div>
    </Box>
  );
}

export default SignatureModal;
