import moment from "moment";

export function isValidDate(date: any) {
  if (!date) {
    return null;
  }

  const format = "MM/DD/YYYY";
  const isValid = moment(date, format, true).isValid();

  if (isValid) {
    return date;
  }

  return null;
}
