import { Box, Button, DialogProps } from "@mui/material";
import BasicModal from "../../components/modal/BasicModal";
import { useSnackbarHelper } from "../../components/Snackbar";
import useTodo from "../../../hooks/useTodo";

interface Props extends DialogProps {
  onClose: () => void;
  data?: any;
  todoId?: string;
  caseId?: string;
}

const DeleteTodoModal = ({
  onClose,
  data,
  todoId,
  caseId,
  ...props
}: Props) => {
  const { deleteTodo } = useTodo(caseId);
  const showSnackbar = useSnackbarHelper();

  const handleDeleteTodo = async () => {
    const response = await deleteTodo(todoId);
    const message = response?.data?.message;
    showSnackbar(message, "success");
    onClose();
  };

  return (
    <Box>
      <BasicModal
        onClose={onClose}
        onSubmit={handleDeleteTodo}
        title="Delete To do"
        content="Do you really want to delete this todo? "
        actions={
          <>
            <Button
              variant="contained"
              type="submit"
              color="error"
              sx={{ textTransform: "capitalize" }}
            >
              Delete
            </Button>
            <Button
              color="error"
              variant="outlined"
              sx={{ textTransform: "capitalize" }}
              onClick={() => onClose()}
            >
              Cancel
            </Button>
          </>
        }
        {...props}
      />
    </Box>
  );
};

export default DeleteTodoModal;
