import { Box } from "@mui/material";
import { useEffect, useState } from "react";

const PdfViewer = ({ buffer, isAwsUrl }: any) => {
  const [pdfUrl, setPdfUrl] = useState("");

  useEffect(() => {
    if (buffer) {
      // Convert Buffer to ArrayBuffer
      const arrayBuffer = new Uint8Array(buffer).buffer;

      // Convert ArrayBuffer to Blob
      const blob = new Blob([arrayBuffer], { type: "application/pdf" });

      // Create an object URL for the Blob
      const url = !isAwsUrl ? URL.createObjectURL(blob) : buffer;
      setPdfUrl(url);

      // Clean up the object URL when the component unmounts
      return () => URL.revokeObjectURL(url);
    }
  }, [buffer, isAwsUrl]);

  // console.log({ pdfUrl });

  return (
    <Box>
      {pdfUrl && (
        <iframe
          src={pdfUrl}
          // style={{ width: "100%", height: "calc(100vh - 350px)" }}
          style={{ width: "100%", height: "calc(100vh - 250px)" }}
          title="PDF Viewer"
        ></iframe>
      )}
    </Box>
  );
};

export default PdfViewer;
