import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { Box, Stack, Typography } from "@mui/material";
import { useModal } from "mui-modal-provider";
import { useCallback, useEffect, useRef, useState } from "react";
import usePdf from "../../../hooks/useCriminalHistory";
import DeleteCriminalHistoryModal from "./DeleteCriminalHistoryModal";

import { uploadImageToS3 } from "../../components/common/uploadImages";
import TableSkeleton from "../../components/skeleton/TableSkeleton";
import { useSnackbarHelper } from "../../components/Snackbar";

function CriminalHistory({ caseData }: any) {
  const showSnackbar = useSnackbarHelper();

  const { addPdf, casePdf, isLoading } = usePdf({
    caseId: caseData?._id,
  });

  const [submitLoading, setSubmitLoading] = useState(true);

  const { showModal } = useModal();
  const inputRef = useRef<HTMLInputElement>(null);

  const dpsURL = caseData?.dpsReportURL;

  const handleDelete = useCallback(
    (data: any) => {
      const modal: any = showModal(DeleteCriminalHistoryModal, {
        onClose: () => modal.hide(),
        id: data?._id,
        data: data,
        caseId: caseData?._id,
      });
    },

    //eslint-disable-next-line
    [showModal]
  );

  const handleBoxClick = () => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.files) {
      setSubmitLoading(true);
      const file = event.target.files[0];

      const data = {
        file: file,
        userId: caseData?._id,
        folderName: "criminal_history",
      };

      const res = await uploadImageToS3(data);
      const fileLocation = res?.Location;

      if (fileLocation) {
        const data = {
          fileUrl: fileLocation,
          caseId: caseData?._id,
        };

        const response = await addPdf(data);
        const fileUrl = response?.data?.fileUrl;

        if (fileUrl) {
          const message = "Criminal History added successfully";
          showSnackbar(message, "success");
        }
        setSubmitLoading(false);
      }

      //uploadPdf(file);
      // Handle the file upload logic here
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setSubmitLoading(false);
    }, 500);
  }, []);

  if (isLoading || submitLoading) {
    return <TableSkeleton />;
  }

  return (
    <Stack
      sx={{
        // border: "1px solid",
        justifyContent: "center",
        alignItems: "center",
        height: "calc(100vh - 290px)",
      }}
    >
      {!dpsURL || dpsURL?.length === 0 ? (
        <Box
          onClick={handleBoxClick}
          sx={{
            border: "2px dashed gray",
            borderRadius: "8px",
            width: "fit-content",
            height: "fit-content",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            // cursor: "pointer",
            transition: "border-color 0.2s",
            padding: 10,
            paddingX: 20,
          }}
        >
          <input
            ref={inputRef}
            type="file"
            accept="application/pdf"
            style={{ display: "none" }}
            onChange={handleFileChange}
            disabled
          />
          <PictureAsPdfIcon sx={{ fontSize: 40, color: "gray" }} />
          <Typography variant="body1" color="textSecondary">
            No DPS report
          </Typography>
        </Box>
      ) : (
        <>
          <iframe
            title="PDF Viewer"
            // src={`http://localhost:8000/pdf/${casePdf?.document}`}
            src={dpsURL}
            style={{ width: "99%", height: "calc(100vh - 345px)" }}
          />
          {/* <Grid container>
            <Grid item xs={12}>
              {" "}
              <Button
                color="error"
                variant="outlined"
                sx={{ float: "right", mr: 1, mt: 1 }}
                onClick={() => {
                  handleDelete(casePdf);
                }}
              >
                Remove
              </Button>
            </Grid>
          </Grid> */}
        </>
      )}
    </Stack>
  );
}

export default CriminalHistory;
