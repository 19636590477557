import { Controller, FieldValues, UseControllerProps } from "react-hook-form";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Typography } from "@mui/material";
import dayjs, { Dayjs } from "dayjs";
import styled from "@emotion/styled";

interface BasicDatePickerProps extends UseControllerProps<FieldValues> {
  label: string;
  required?: boolean;
  parseError?: string;
  helperText?: string;
  onChange?: (date: Date | null) => void;
  minDate?: Dayjs;
  disabled?: boolean;
  backgroundColor?: string;
}

const CustomDatePicker = styled(DatePicker)<{ error?: boolean }>(
  ({ error, theme }) => ({
    "& .MuiOutlinedInput-root": {
      "& fieldset": { borderRadius: "12px" },
      fontSize: "1rem",
      fontFamily: "'Arial', sans-serif",
      backgroundColor: "#f5f5f500",
      height: "3.2em",
      "&.Mui-focused fieldset": {
        borderColor: "#6F7E8C !important",
      },
      ...(error && {
        "& fieldset": {
          borderColor: "#d32f2f !important",
          borderRadius: "12px",
        },
      }),
    },
    "& .MuiInputBase-input": {
      fontSize: "1rem",
    },
    "& label.Mui-focused": {
      color: "#6F7E8C",
    },
    "& .MuiInputLabel-root": {
      fontSize: "0.875rem",
      color: error ? "#d32f2f" : "#6F7E8C",
    },
  })
);

/**
 * BasicDatePicker component for selecting dates with support for React Hook Form.
 * @component BasicDatePicker
 * @author Priyanshu
 *
 */
const BasicDatePicker = ({
  name,
  label,
  required = false,
  parseError,
  helperText,
  onChange: handleChange,
  minDate = dayjs("2018-01-01"),
  disabled = false,
  backgroundColor = "none",
  ...rest
}: BasicDatePickerProps) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Controller
        name={name}
        render={({
          field: { onBlur, onChange, value },
          fieldState: { error, invalid },
        }) => (
          <>
            <CustomDatePicker
              name={name}
              value={value ? dayjs(value) : null}
              onChange={(date) => {
                onChange(date ? date.toDate() : null);
                if (handleChange) {
                  handleChange(date ? date.toDate() : null);
                }
              }}
              error={invalid}
              minDate={minDate}
              label={label ? `${label} ${required ? " *" : ""}` : ""}
              sx={{ width: "100%" }}
              disabled={disabled}
              {...rest}
            />
            <Typography
              sx={{
                ml: 1.5,
                color: "#d32f2f",
                fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
                fontWeight: 400,
                fontSize: "0.75rem",
              }}
            >
              {error?.message || ""}
            </Typography>
          </>
        )}
      />
    </LocalizationProvider>
  );
};

export default BasicDatePicker;
